import React from 'react'
import PropTypes from 'prop-types'
import { Plugins } from '@leverege/plugin'
import { TextInput } from '@leverege/ui-elements'

import Util from '../Util'
import S from './LatLonEditor.css'

export default class GeoPointObjectEditor extends React.Component {

  static propTypes = {
    value : PropTypes.shape( {
      lat : PropTypes.number,
      lon : PropTypes.number
    } )
  }

  static defaultProps = {
    value : null
  }

  static TYPE = 'latLon'
  static NAME = 'Lat/Lon'

  constructor( props ) {
    super( props )

    this.state = {
      value : props.value
    }
  }

  static getDerivedStateFromProps( nextProps, prevState ) {
    if ( nextProps.value ) {
      if ( nextProps?.value?.lat !== prevState?.value?.lat && nextProps?.value?.lon !== prevState?.value?.lon ) {
        return {
          value : nextProps.value
        }
      }
    }

    return null
  }

  onChange = ( value, event ) => {
    const { onChange, eventData } = this.props

    this.setState( {
      value
    }, () => {
      const lat = value?.lat
      const lon = value?.lon

      if ( typeof lat === 'number' && typeof lon === 'number' && onChange ) {
        onChange( { data : eventData, value, event } )
      }
    } )
  }

  onLatChange = ( evt ) => {

    const { value } = this.state
    const valid = Util.isValidLatStr( evt.value )
    let toSend = evt.value.toLowerCase()
    if ( !valid ) {
      toSend = value?.lat
    } else if ( Number( toSend ) === Number( `${Number( toSend )}` ) ) {
      toSend = Number( toSend )
    } else if ( toSend.endsWith( 'n' ) ) {
      toSend = toSend.slice( 0, -1 )
    } else if ( toSend.endsWith( 's' ) ) {
      toSend = `-${toSend.slice( 0, -1 )}`
    }

    return this.onChange( {
      ...value,
      lat : Util.degToFloat( toSend )
    }, evt?.event )
  }

  onLonChange = ( evt ) => {
    const { value } = this.state
    const valid = Util.isValidLonStr( evt.value )
    let toSend = evt.value.toLowerCase()

    if ( !valid ) {
      toSend = value?.lon
    } else if ( Number( toSend ) === Number( `${Number( toSend )}` ) ) {
      toSend = Number( toSend )
    } else if ( toSend.endsWith( 'e' ) ) {
      toSend = toSend.slice( 0, -1 )
    } else if ( toSend.endsWith( 'w' ) ) {
      toSend = `-${toSend.slice( 0, -1 )}`
    }

    return this.onChange( {
      ...value,
      lon : Util.degToFloat( toSend )
    }, evt?.event )
  }

  render() {
    const { value } = this.state
    return (
      <>
        <TextInput className={S.input} hint="Latitude" value={value?.lat ? `${value?.lat}` : null} onChange={this.onLatChange} eventData="lat" />
        <TextInput className={S.input} hint="Longitude" value={value?.lon ? `${value?.lon}` : null} onChange={this.onLonChange} eventData="lon" />
      </>
    )
  }
}

Plugins.add( 'GeoPointEditor', {
  type : GeoPointObjectEditor.TYPE,
  name : GeoPointObjectEditor.NAME,
  sortKey : 'a', // put at top
  editor : GeoPointObjectEditor
} )
