import { ns } from '@leverege/i18n'

const { t, tIcon } = ns( 'condition.RemoveRuleAction' )

export default {
  id : 'condition.objectizer.RemoveRuleAction', 
  matches : {
    client : 'condition.ObjectizerEditor',
    use : 'ruleActionBar'
  },
  layout : {
    sort : 'zzz'
  },

  appearance( context ) {
    return { name : t( 'name' ), icon : tIcon( 'icon', 'fa fa-times fa-fw' ) }
  },

  handles( context ) { return context.onRemove != null },
  perform( { context } ) {
    context.onRemove( { data : context.eventData } )
  }
}
