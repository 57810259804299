import { Plugins } from '@leverege/plugin'

import Fonts from './Fonts'
import FontModel from './FontModel'
import FontEditor from './FontEditor'

// Should this be separate and deliberate?
Plugins.add( 'Model', { type : FontModel.TYPE, model : FontModel } )
Plugins.add( 'ModelEditor', { type : FontModel.TYPE, editor : FontEditor } )

export {
  Fonts,
  FontModel,
  FontEditor
}
