import { useDrag, useDrop } from 'react-dnd'
import { flushSync } from 'react-dom'

function useCardDrag( { value, dragType = 'uiDashboardCard', onRemoveCard, collect } ) {
  return useDrag( {
    type : dragType,
    item : { type : dragType, item : value },
    // isDragging : ( monitor ) => {
    //   console.log( 'isoff', monitor.getInitialSourceClientOffset())
    //   console.log( 'coff', monitor.getClientOffset())
    //   console.log( 'scoff', monitor.getSourceClientOffset())
    //   console.log( 'doff', monitor.getDifferenceFromInitialOffset())
    // },
    end : ( item, monitor ) => {
      let result = null
      flushSync( () => { result = monitor.getDropResult() } )
      const shouldRemove = result && result.dropEffect === 'move' && result.dropResult !== 'move'
      if ( result && result.droppedItem != null && shouldRemove ) {
        onRemoveCard( { data : value, prompt : false } )
      }
    },
    collect : ( monitor ) => {
      const c = collect ? collect( monitor ) : { }
      return {
        isDragging : monitor.isDragging(),
        ...c
      } 
    }
  } )
}

function useCardDrop( { value, accept = 'uiDashboardCard', index, onDropCard, canDrop, drop, isDragging }, collect ) {
  return useDrop( {
    accept,
    canDrop : isDragging === true ? false : ( 
      ( item ) => {
        if ( canDrop && !canDrop( item ) ) {
          return false
        }
        return onDropCard != null && item.item !== value
      } ),
    collect : ( monitor, props ) => {
      const c = collect ? collect( monitor ) : { }
      return {
        isOver : monitor.isOver( { shallow : true } ),
        canDrop : monitor.canDrop(),
        ...c
      }
    },
    drop : drop || ( ( item, monitor ) => {
      if ( monitor.didDrop() ) {
        return monitor.getDropResult() // { droppedItem : null, isMove : false, dropResult : 'alreadyHandled' }
      }
      try {
        const isMove = onDropCard( { index, item : item.item, value } )
        return { droppedItem : item, dropTarget : value, dropResult : isMove ? 'move' : 'copy' }
      } catch ( ex ) {
        return { droppedItem : null, dropTarget : value, dropResult : 'error' }
      }
    } )
  } )
}

/**
 * Creates a function that accepts an event object. This will call
 * set( model, event.data, event.value ) and then call onChange( {
 * value : newModel, changing : false, data : eventData } ). This is
 * useful for cards that have lists of other cards
 */
function createOnCardChange( { set, value, eventData, onChange } ) {
  return ( evt ) => {
    const { value, data } = evt
    const nModel = set( value, data, value )
    if ( onChange && nModel !== value ) {
      onChange( { value : nModel, changing : false, data : eventData } )
    }
  }
}

export { useCardDrag, useCardDrop, createOnCardChange }
