import Path from '@leverege/path'
import MapStyle from './MapStyle'
import MapControl from './MapControl'

class ReduxMapOptions {
  
  constructor( component, mapName, { UI }, dispatch ) {
    this.UI = UI
    this.component = component
    this.mapName = mapName
    this.mapStyle = `map/${mapName}/style`
    this.viewPosition = `map/${mapName}/viewPosition`
    this.dispatch = dispatch
  }

  onMapReady = ( map ) => {
    const handleMoveEnd = () => {
      const { store } = this.component.context
      const dispatch = ( store && store.dispatch ) || this.dispatch
      const c = map.getCenter()
      const z = map.getZoom()
      dispatch( this.UI.set( this.viewPosition, { lat : c.lat, lon : c.lng, zoom : z } ) )
    }
    map.on( 'moveend', handleMoveEnd )
    handleMoveEnd()
  }

  onNextMapStyle = ( e ) => {
    const { mapStyle } = this.component.props
    const { store } = this.component.context
    const dispatch = ( store && store.dispatch ) || this.dispatch
    dispatch( this.UI.set( this.mapStyle, MapStyle.next( mapStyle ) ) )
  }

  onZoomIn = ( opts ) => { 
    MapControl.zoomIn( this.mapName, opts )
  }

  onZoomOut = ( opts ) => { 
    MapControl.zoomOut( this.mapName, opts )
  }
}

ReduxMapOptions.getInitialPosition = ( state, mapName ) => {
  return Path.get( `ui/map/${mapName}/viewPosition`, state, null )
}

ReduxMapOptions.getMapStyle = ( state, mapName ) => {    
  const style = Path.get( `ui/map/${mapName}/style`, state, MapStyle.VECTOR )
  return style
}

ReduxMapOptions.getMapOptions = ( state, mapName ) => {
  return Path.get( `ui/map/${mapName}`, state, { } )
}

export default ReduxMapOptions
