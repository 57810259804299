import ModelUtil from '@leverege/model-util'

import I18NNamespaceModel from './I18NNamespaceModel.js'

const TYPE = 'imagine.I18NBlueprintLanguageModel'

function create() {
  return {
    type : TYPE,
    namespaces : {
      translation : I18NNamespaceModel.create()
    }
  }
}

const exp = {
  TYPE,
  type : TYPE,
  create,
  ...ModelUtil.createAllMap( 'namespaces' ),
  getTranslationData( model ) {
    return exp.getNamespaceEntries( model )
      .reduce( ( prev, [ key, value ] ) => {
        return {
          ...prev,
          [key] : I18NNamespaceModel.getTranslationData( value )
        }
      }, {} )
  }
}

export default exp
