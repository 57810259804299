import ModelUtil from '@leverege/model-util'

const TYPE = 'style.Font'

function create( ) {
  return {
    type : TYPE,
    family : null,
    size : null,
    weight : null,
    style : null
  }  
}

function toCss( model, extend ) {
  const opt = extend || { }
  
  if ( model.family ) { opt.fontFamily = model.family }
  if ( model.size ) { opt.fontSize = model.size }
  if ( model.weight ) { opt.fontWeight = model.weight }
  if ( model.style ) { opt.fontStyle = model.style }
  
  return opt
}

export default {
  TYPE,
  create,
  toCss,
  ...ModelUtil.createAllValue( 'family' ),
  ...ModelUtil.createAllValue( 'size' ),
  ...ModelUtil.createAllValue( 'weight' ),
  ...ModelUtil.createAllValue( 'style' ),
}