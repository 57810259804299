import React from 'react'
import { Content, List, Flex, Button, Text, Pane, ListSelectors } from '@leverege/ui-elements'

import { ModelEditorFactory } from './Factory.js'
import Constants from './Constants.js'

import S from './ModelMapEditor.css'

export default class ModelMapEditor extends React.PureComponent {

  static EVT_CHILD_CHANGE = Constants.EVT_CHILD_CHANGE
  static EVT_CHILD_REMOVE = Constants.EVT_REMOVE_CHANGE

  static defaultProps = {
    paneVariant : 'collectionEditor',
    listSectionVariant : 'collectionEditorListSection',
    listHeaderVariant : 'collectionEditorListHeader',
    listContentVariant : 'collectionEditorListContent',
    listFooterVariant : 'collectionEditorListFooter',
    selectionSectionVariant : 'collectionEditorSelectionSection',
    selectionHeaderVariant : 'collectionEditorSelectionHeader',
    selectionContentVariant : 'collectionEditorSelectionContent',
    selectionFooterVariant : 'collectionEditorSelectionFooter',
    sort : ( a, b ) => { return a[0].localeCompare( b[0] ) },
    autoSelect : true,
    propKeyForSelected : 'itemType',
    modelEditorFactory : ModelEditorFactory,
    renderer : Renderer,
    rendererNameKey : 'name', // for default renderer
    rendererIsRemovable : true, // for default renderer
  }

  constructor( props ) {
    super( props )
    if ( props.autoSelect ) {
      const { value, model, sort, filter } = props
      let items = value && model && model.entries( value )
      if ( typeof sort === 'function' ) {
        items.sort( sort )
      }

      if ( typeof filter === 'function' ) {
        items = items.filter( filter )
      }

      this.state = { selected : items && items.length > 0 ? items[0][0] : null }
    } else {
      this.state = { selected : null }
    }
    this.selector = new ListSelectors.KeySelector( { // eslint-disable-line
      useMeta : true,
      getSelected : ( ) => { return this.state.selected }, // eslint-disable-line
      setSelected : ( selected, cb ) => { this.setState( { selected }, cb ) }
    } )
  }

  onChildChange = ( e ) => {
    const { value, model, onChange, eventData } = this.props

    if ( onChange ) {
      const key = e.data

      // Replace it, creating a new model
      const nModel = model.set( value, key, e.value )

      // fire the event
      onChange( { value : nModel, data : eventData, oldValue : value } )
    }
  }

  onEvent = ( evt ) => {
    const { value, model, onChange, eventData } = this.props

    if ( evt.type === Constants.EVT_CHILD_REMOVE ) {
      if ( onChange ) {
        // If its a remove, remove the item from the list and send it
        const nModel = model.remove( value, evt.item[0] )
        onChange( { value : nModel, data : eventData, oldValue : value } )
      }
    } else if ( evt.type === Constants.EVT_CHILD_CHANGE ) {
      /**
       * Expects { data : key, value: new value}
       */
      this.onChildChange( evt )
    }
  }

  render() {

    const { 
      value, model, paneVariant, sort,
      listSectionVariant, selectionSectionVariant, filter, allowCollapseToOne = false } = this.props

    // We are going to use a list of entry objects, [key, value]
    let items = model.entries( value )

    if ( typeof sort === 'function' ) {
      items.sort( sort )
    }

    if ( typeof filter === 'function' ) {
      items = items.filter( filter )
    }

    const collapse = allowCollapseToOne && items.length === 1

    const { selected } = this.state
    const selectedItem = model.get( value, selected )

    return (
      <Content variant={paneVariant} horizontal>
        {!collapse && (
          <Content.Header variant={listSectionVariant}>
            <div className={S.control}>
              { this.getListHeader( selectedItem, selected ) }
              { this.getListContent( items, selectedItem, selected ) }
              { this.getListFooter( selectedItem, selected ) }
            </div>
          </Content.Header>
        )}
        <Content.Area variant={selectionSectionVariant}>
          <Content>
            { this.getSelectionHeader( selectedItem, selected ) }
            {this.getSelectionEditor( selectedItem, selected )}
            { this.getSelectionFooter( selectedItem, selected ) }
          </Content>
        </Content.Area>
      </Content>
    )
  }

  getListHeader( item, key ) {
    const { header, listHeaderVariant } = this.props
    if ( header ) {
      const hdr = header( item, key )
      if ( hdr ) {
        return <Pane variant={listHeaderVariant} className={S.header}>{hdr}</Pane>
      }
    }
    return null
  }

  getListContent( items, item, key ) {
    const { 
      onEvent, renderer, itemProps,
      listContentVariant,
      listClassName, 
      rendererNameKey : nameKey, rendererIsRemovable : removable } = this.props

    const iProps = ( renderer === Renderer ) ? { ...itemProps, nameKey, removable } : itemProps

    return (
      <Pane variant={listContentVariant} className={S.listPane}>
        <List onEvent={onEvent || this.onEvent} selector={this.selector} className={listClassName}>
          <List.Dynamic items={items} component={renderer} itemProps={iProps} />
        </List>
      </Pane>
    )
  }

  getListFooter( item, key ) {
    const { footer, listFooterVariant } = this.props
    if ( footer ) {
      const ftr = footer( item, key )
      if ( ftr ) {
        return <Pane variant={listFooterVariant} className={S.footer}>{ftr}</Pane>
      }
    }
    return null
  }

  /**
   * Returns the header for the editor section
   */
  getSelectionHeader( item, key ) {
    const { selectionHeaderVariant, selectionHeader } = this.props
    if ( selectionHeader ) {
      const hdr = selectionHeader( item, key )
      if ( hdr ) {
        return <Content.Header variant={selectionHeaderVariant}>{hdr}</Content.Header> 
      }
    }
    return null
  }

  /**
   * Returns the header for the editor section
   */
  getSelectionEditor( item, key ) {
    const { 
      selectionEditor, selectionContentVariant, 
      selectionProps, propKeyForSelected,
      modelEditorFactory = ModelEditorFactory } = this.props

    let editor = null
    if ( selectionEditor ) {
      editor = selectionEditor( item, key, selectionProps )
    } else {
      const eProps = { 
        ...selectionProps, 
        onChange : this.onChildChange, 
        eventData : key 
      }
      if ( propKeyForSelected ) {
        eProps[propKeyForSelected] = key
      }
      editor = modelEditorFactory.create( item, eProps )

    }
    return (
      <Content.Area variant={selectionContentVariant}>
        {editor}
      </Content.Area>
    )
  }

  /**
   * Returns the footer for the editor section
   */
  getSelectionFooter( item, key ) {
    const { selectionFooterVariant, selectionFooter } = this.props
    if ( selectionFooter ) {
      const hdr = selectionFooter( item, key )
      if ( hdr ) {
        return <Content.Footer variant={selectionFooterVariant}>{hdr}</Content.Footer> 
      }
    }
    return null
  }
}

function Renderer( props ) {
  const { item, onEvent, nameKey, removable = true, ...rest } = props
  const [ key, value ] = item
  let name = nameKey ? value[nameKey] : value.name
  if ( name == null ) {
    name = value.displayName || value.name || key
  }  
  
  const onDelete = removable ? ( e ) => { 
    onEvent( { type : Constants.EVT_CHILD_REMOVE, item, data : props.eventData, } ) 
  } : null
  
  return (
    <List.Item item={item} {...rest}>
      <Flex variant="rowM" justify="space-between" align="center">
        <Text variant="listItemSubtitle">{name}</Text>
        { removable && <Button variant="link" eventData={item} onClick={onDelete} icon="fa fa-trash" /> }
      </Flex>
    </List.Item> 
  )
}
