/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

import I18N from '@leverege/i18n'
import { Pane } from '@leverege/ui-elements'

import GridView from './GridView'
import DashboardContext from '../shared/DashboardContext'

const { t } = I18N.ns( 'uiDashboard.view.DashboardView' )

export default function Dashboard( props ) {
  const { 
    models, value, sharedProps, editors, optionEditors,
    viewers, options, style, className, onChange, eventData,
    variant = 'dashboard', getModelCreators,
    noDataView : NoDataView = DefaultNoDataView,
    noDataText = t( 'noDataText' )
  } = props

  let content = <GridView onChange={onChange} eventData={eventData} value={value}/>

  if ( value.cards.length === 0 ) {
    content = <NoDataView noDataText={noDataText} />
  }

  return (
    <DashboardContext 
      models={models} 
      editors={editors} 
      viewers={viewers} 
      optionEditors={optionEditors} 
      sharedProps={sharedProps}
      getModelCreators={getModelCreators}
      options={options}>
      <Pane variant={variant} style={style} className={className}>
        {content}
      </Pane>
    </DashboardContext>
  )
}

function DefaultNoDataView( props ) {
  const { noDataText } = props
  return (
    <Pane
      variant="dashboardNoData"
      style={{
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        height : '100%'
      }}>
      <div>{noDataText}</div>
    </Pane>
  )
}
