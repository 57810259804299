import React from 'react'
import Factory from '@leverege/factory'

import DateTimeRangeEditor from './option/DateTimeRangeEditor'

import DashboardEditor from './editor/DashboardEditor'
import CardEditor from './editor/CardEditor'
import GridEditor from './editor/GridEditor'
import CardGrid from './editor/CardGrid'
import CardSelector from './editor/CardSelector'
import EditorFactory from './editor/EditorFactory'
import * as CardUtil from './editor/CardUtil'

import DashboardView from './view/DashboardView'
import CardView from './view/CardView'
import GridView from './view/GridView'
import CardGridView from './view/CardGridView'
import ViewerFactory from './view/ViewerFactory'

import CardTitle from './shared/CardTitle'
import Selector from './shared/Selector'
import DashboardContext, { useDashboardContext, DashboardContextType, DashboardContextConsumer, DashboardContextProvider } from './shared/DashboardContext'
import ToolbarContext from './shared/ToolbarContext'

import { Card, Grid, Dashboard } from './model'

const DashboardPlugin = {
  type : Dashboard.TYPE,
  name : 'Dashboard',
  model : Dashboard,
  isInternal : true,
  isContainer : true
}

const CardPlugin = {
  type : Card.TYPE,
  name : 'Card',
  editor : CardEditor,
  model : Card,
  viewer : CardView,
  isInternal : true,
  isContainer : true
}

const GridPlugin = {
  type : Grid.TYPE,
  name : 'Grid',
  editor : GridEditor,
  menuEditor : GridEditor.MenuEditor,
  model : Grid,
  viewer : GridView,
  isContainer : true,
  icon : 'fa fa-th'
}

function createModelFactory( registry = null ) {
  const f = new Factory( { registry, creationKey : 'model', strategy : Factory.LOOKUP } )
  if ( f.get( Dashboard.TYPE ) == null ) {
    f.add( Dashboard.TYPE, DashboardPlugin )
  }
  if ( f.get( Card.TYPE ) == null ) {
    f.add( Card.TYPE, CardPlugin )
  }
  if ( f.get( Grid.TYPE ) == null ) {
    f.add( Grid.TYPE, GridPlugin )  
  }
  return f
} 

function createEditorFactory( registry = null ) {
  const f = new Factory( { registry, creationKey : 'editor', strategy : Factory.reactStrategy( React ) } )
  if ( f.get( Card.TYPE ) == null ) {
    f.add( Card.TYPE, CardPlugin )
  }
  if ( f.get( Grid.TYPE ) == null ) {
    f.add( Grid.TYPE, GridPlugin )  
  }
  return f
} 

function createViewerFactory( registry = null ) {
  const f = new Factory( { registry, creationKey : 'viewer', strategy : Factory.reactStrategy( React ) } )
  if ( f.get( Card.TYPE ) == null ) {
    f.add( Card.TYPE, CardPlugin )
  }
  if ( f.get( Grid.TYPE ) == null ) {
    f.add( Grid.TYPE, GridPlugin )  
  }
  return f
}

const DateTimeRangeEditorPlugin = {
  type : DateTimeRangeEditor.TYPE,
  editor : DateTimeRangeEditor
}

function createOptionEditorsFactory( registry = null ) {
  const f = new Factory( { registry, creationKey : 'editor', strategy : Factory.reactStrategy( React, null ) } )
  if ( f.get( DateTimeRangeEditor.TYPE ) == null ) {
    f.add( DateTimeRangeEditor.TYPE, DateTimeRangeEditorPlugin )
  }
  return f
} 

export default {
  createModelFactory,
  createViewerFactory,
  createEditorFactory,
  createOptionEditorsFactory,
}

export {

  DashboardEditor,
  CardEditor,
  GridEditor,
  CardGrid,
  CardSelector,
  EditorFactory,
  CardUtil,

  DateTimeRangeEditor,

  DashboardView,
  CardView,
  GridView,
  CardGridView,
  ViewerFactory,

  CardTitle,
  Selector,
  DashboardContext,
  useDashboardContext,

  ToolbarContext,

  Card, 
  Grid, 
  Dashboard,
  CardPlugin,
  GridPlugin,
  DashboardPlugin,
  DateTimeRangeEditorPlugin,
  DashboardContextType,
  DashboardContextProvider,
  DashboardContextConsumer
}
