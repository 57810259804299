/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Layout } from '@leverege/plugin'
import { Popup, Button, ToggleButton } from '@leverege/ui-elements'
import { R } from '@leverege/value-resolver'

import Util from './Util.js'

import S from './MenuFactory.css'

const BUTTON_STYLE = { flex : '1 1' }

/**
 * This Factory will create Popup.Item and Popup.Menu objects
 * as needed to support the actions and submenus
 */
export default class MenuFactory {
  
  onClick = ( evt ) => {
    const { action } = evt.data
    if ( typeof action?.perform === 'function' ) {
      // const result =  // ToDo: report back a cancellable action?
      action.perform( { sourceEvent : evt.sourceEvent, ...evt.data } )
      // if ( isThenable( result ) ) {
      // cancel/catch/etc
      // }
    }  
  }

  createComponent( props, opts ) {
    const { context, action, onClose } = props

    if ( action.handles == null || !action.handles( context ) ) {
      return null
    }
    const {
      id, name : rname, disabled, visible,
      checked, checkStyle, icon : ricon, help : rhelp, iconOn, iconOff, iconOpts, imageOpts,
      useButton, buttonVariant, accessory, sublayout, sublayoutContext } = action.appearance( props )

    if ( visible === false ) {
      return null
    }
    const name = R( rname )
    const icon = R( ricon )
    const help = R( rhelp )

    if ( useButton ) {
      const cb = ( e ) => {
        this.onClick( e )
        onClose( )
      }
      let btn
      if ( checked == null ) {
        btn = (
          <Button
            id={id}
            variant={buttonVariant}
            icon={icon}
            iconOpts={iconOpts}
            imageOpts={imageOpts}
            style={BUTTON_STYLE}
            disabled={disabled} 
            onClick={cb} 
            eventData={props}>
            {name}
          </Button>
        )
      } else {
        btn = (
          <ToggleButton
            id={id}
            variant={buttonVariant}
            value={checked} 
            icon={icon}
            iconOpts={iconOpts}
            imageOpts={imageOpts}
            style={BUTTON_STYLE}
            disabled={disabled} 
            onClick={cb} 
            eventData={props}>
            {name}
          </ToggleButton>
        )
      }
      return <div className={S.buttonHolder} key={action.key || `${name}-${id}`} justify="stretch">{btn}</div>
    }

    // add icon if factory says too
    return (
      <Popup.Item
        id={id}
        key={action.key || `${name}-${id}`} 
        checked={checked} 
        checkStyle={checkStyle}
        icon={icon}
        help={help}
        iconOpts={iconOpts}
        checkIconOn={checkStyle === 'check' ? iconOn : null}
        checkIconOff={checkStyle === 'check' ? iconOff : null}
        radioIconOn={checkStyle === 'radio' ? iconOn : null}
        radioIconOff={checkStyle === 'radio' ? iconOff : null}
        disabled={disabled} 
        onClose={onClose}
        onClick={this.onClick} 
        eventData={props}
        accessory={accessory}
        submenu={() => this.createComponents( R( sublayout, sublayoutContext ), sublayoutContext )}>
        {name}
      </Popup.Item>
    )
  }

  /**
   * Returns a menu with submenu in it.
   */
  createComponents( rootLayoutNode, cxt, onClose ) {
    if ( rootLayoutNode == null ) {
      return null
    }
    let separator = null
    const r = []
    const items = Array.isArray( rootLayoutNode ) ? rootLayoutNode : rootLayoutNode.items
    items.forEach( ( item, index ) => {
      let cmp = null
      let sort = null
      let sectionName = null
      if ( item.type === Layout.NODE_TYPE ) {
        // We need to know if the menu should show up. Call handles
        // TODO: We can do the handles above this call to remove item
        // from this location
        if ( !Util.handles( item.items, cxt ) ) {
          return
        }
        sort = R(
          item.layout?.sort !== undefined ?
            item.layout?.sort :
            item.items?.[0]?.layout?.sort, cxt
        )
        sectionName = R(
          item.layout?.sectionName !== undefined ?
            item.layout?.sectionName :
            item.items?.[0]?.layout?.sectionName, cxt
        )
        cmp = (
          <Popup.Menu 
            onClose={onClose}
            key={item.name + index}
            icon={R( item.icon, cxt )}
            help={R( item.help, cxt )}
            iconOpts={R( item.iconOpts, cxt )}
            title={R( item.name, cxt )}
            render={( onClose ) => {
              return this.createComponents( item, cxt, onClose )
            }} />
        )
      } else {
        const { customComponent : Component } = item 
        sort = R( item.layout?.sort, cxt )
        sectionName = R( item.layout?.sectionName, cxt )
        if ( typeof item.getActions === 'function' ) {
          const childRootLayoutNode = item.getActions( cxt )
          Util.sortLayoutNodeItems( childRootLayoutNode )
          cmp = this.createComponents( childRootLayoutNode, cxt, onClose )
        } else if ( Component == null ) {
          cmp = this.createComponent( { context : cxt, action : item, onClose } )
        } else {
          cmp = <Component key={item.key} context={cxt} action={item} onClose={onClose}/>
        } 
      }
      const isArray = Array.isArray( cmp )
      if ( cmp == null || ( isArray && cmp.length === 0 ) ) {
        return
      }

      // Do we have a section?
      const idx = sort ? sort.lastIndexOf( '.' ) : -1
      const key = idx < 0 ? null : sort.substring( 0, idx )

      if ( cmp != null && r.length > 0 ) {
        // do we need to add a separator?
        if ( idx >= 0 ) {
          // If the key is not the same as separator, we are in a new section. Add separator.
          if ( key !== separator ) {
            separator = key
            r.push( <Popup.Divider key={`sep-${key}`}/> )
            if ( sectionName ) {
              r.push( <Popup.Header key={`header-${key}`}>{sectionName}</Popup.Header> )
            }
            if ( isArray ) { 
              r.push( ...cmp ) 
            } else {
              r.push( cmp )
            }
            return
          } 
          // We are in the same separator. Just add component
          if ( isArray ) { 
            r.push( ...cmp ) 
          } else {
            r.push( cmp )
          }
          return  
        } 
        
        if ( separator ) {
          // We are not in a section, but the section is active. Add closing separator
          separator = '__not_grouped__'
          r.push( <Popup.Divider key={`sep-${index}`}/> )
          if ( isArray ) { 
            r.push( ...cmp ) 
          } else {
            r.push( cmp )
          }
          return
        }
      }
      if ( cmp ) {
        separator = key
        if ( r.length === 0 && key && sectionName ) {
          r.push( <Popup.Header key={`header-${key}`}>{sectionName}</Popup.Header> )
        }
        if ( isArray ) { 
          r.push( ...cmp ) 
        } else {
          r.push( cmp )
        }
      }

    } )
    return r
  }
}
