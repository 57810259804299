import Unit from '@leverege/unit' 
import NumberCreator from './NumberCreator' 

function createUnit( typeName, name, baseType ) {
  return {
  
    name() { return name },
    typeName() { return typeName },
    baseType() { return baseType },
    isUnit() { return true; },
    categoryType() { return 'float'; },
    create( path, name ) {
      const attr = NumberCreator.create.call( this, path, name );
      attr.base = this.baseType();
      return attr;
    },
    createFrom( attr ) {
      const cAttr = NumberCreator.createFrom.call( this, attr );
      cAttr.base = this.baseType();
      return cAttr;
    }
  }
}

const exp = {
  getCreators,
  createUnit,

  Angle : createUnit( Unit.ANGLE, 'Angle', Unit.radians.symbol ),
  Length : createUnit( Unit.LENGTH, 'Length', Unit.meters.symbol ),
  Surface : createUnit( Unit.SURFACE, 'Surface', Unit.m2.symbol ),
  Volume : createUnit( Unit.VOLUME, 'Volume', Unit.millilitres.symbol ),
  FlowRate : createUnit( Unit.FLOW_RATE, 'Flow', Unit.sccm.symbol ),
  AmountOfSubstance : createUnit( Unit.AMOUNT_OF_SUBSTANCE, 'Amount of Substance', Unit.moles.symbol ),

  Speed : createUnit( Unit.SPEED, 'Speed', Unit.metersPerSecond.symbol ),
  Acceleration : createUnit( Unit.ACCELERATION, 'Acceleration', Unit.metersPerSecond2.symbol ),

  Temperature : createUnit( Unit.TEMPERATURE, 'Temperature', Unit.celsius.symbol ),
  
  Force : createUnit( Unit.FORCE, 'Force', Unit.newton.symbol ),
  Mass : createUnit( Unit.MASS, 'Mass', Unit.kilograms.symbol ),
  Pressure : createUnit( Unit.PRESSURE, 'Pressure', Unit.Pa.symbol ),
  
  Time : createUnit( Unit.TIME, 'Time', Unit.seconds.symbol ),
  Frequency : createUnit( Unit.FREQUENCY, 'Frequency', Unit.hertz.symbol ),
  
  Energy : createUnit( Unit.ENERGY, 'Energy', Unit.joules.symbol ),
  Power : createUnit( Unit.POWER, 'Power', Unit.watts.symbol ),
  Current : createUnit( Unit.CURRENT, 'Current', Unit.amperes.symbol ),
  LuminousIntensity : createUnit( Unit.LUMINOUS_INTENSITY, 'Luminous Intensity', Unit.candela.symbol ),

  ElectricCharge : createUnit( Unit.ELECTRIC_CHARGE, 'Electric Charge', Unit.coulombs.symbol ),
  ElectricCapacitance : createUnit( Unit.ELECTRIC_CAPACITANCE, 'Electric Capacitance', Unit.farads.symbol ),
  ElectricPotential : createUnit( Unit.ELECTRIC_POTENTIAL, 'Electric Potential', Unit.volts.symbol ),
  ElectricResistance : createUnit( Unit.ELECTRIC_RESISTANCE, 'Electric Resistance', Unit.ohms.symbol ),
  ElectricInductance : createUnit( Unit.ELECTRIC_INDUCTANCE, 'Electric Inductance', Unit.henry.symbol ),
  ElectricConductance : createUnit( Unit.ELECTRIC_CONDUCTANCE, 'Electric Conductance', Unit.siemens.symbol ),

  MagneticFlux : createUnit( Unit.MAGNETIC_FLUX, 'Magnetic Flux', Unit.webers.symbol ),
  MagneticFluxDensity : createUnit( Unit.MAGNETIC_FLUX_DENSITY, 'Magnetic Flux Density', Unit.teslas.symbol ),

  Bit : createUnit( Unit.BIT, 'Bit', Unit.bits.symbol )

}

function getCreators() {
  return [
    exp.Angle, exp.Length, exp.Surface, exp.Volume, exp.FlowRate, exp.AmountOfSubstance,
    exp.Speed, exp.Acceleration,
    exp.Temperature,
    exp.Force, exp.Mass, exp.Pressure,
    exp.Time, exp.Frequency,
    exp.Energy, exp.Power, exp.Current, exp.LuminousIntensity,
    exp.ElectricCharge, exp.ElectricCapacitance, 
    exp.ElectricPotential, exp.ElectricResistance,
    exp.ElectricInductance, exp.ElectricConductance,
    exp.MagneticFlux, exp.MagneticFluxDensity,
    exp.Bit 
  ];
}

export default exp;
