import React from 'react'

import ContextButton from './ContextButton.jsx'
import ActionsLayout from './ActionsLayout.js'

/**
 * This adds the context menu option to a toolbar's layout
 * @param {object} options
 * @param {array} options.actions  the array of actions. If not present, matches will be used
 * @param {object} options.context the matches object used to filter the plugins
 * @param {string} options.actionType the plugin name to lookup. Defaults to 'Action'
 * @param {string} options.hasContextMenu if true, an automatic context menu will be added to the layout
 * @param {string} options.contextMenuOptions sent to the ContextButton to define its options
 */
export default class ToolbarActionsLayout extends ActionsLayout {
  
  appendToRoot( root ) {
    if ( this.options.hasContextMenu ) {
      const opts = this.options.contextMenuOptions || {}
      const { toggleAttributes, hideWhenEmpty, matchContext : cmm, mustMatchContext } = opts
      
      const matchContext = cmm || { ...( this.options?.context || {} ), use : 'contextMenu', usedIn : 'toolbar' }
      root.items.push( {
        key : 'autoToolbarContextMenu',
        handles : ( ) => true,
        customComponent : ( { context, factory } ) => {
          return (
            <ContextButton
              key="toolbarContextMenu"
              toggleVariant={factory?.popupToggleVariant}
              toggleAttributes={{ 
                iconOn : 'fa fa-fw fa-ellipsis-v', 
                iconOff : 'fa fa-fw fa-ellipsis-v', 
                ...toggleAttributes }}
              context={context}
              matchContext={matchContext}
              mustMatchContext={mustMatchContext}
              hideWhenEmpty={hideWhenEmpty} />
          )
        }
      } )
      
    }
  }
}
