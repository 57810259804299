import ModelUtil from '@leverege/model-util'

const TYPE = 'uiDashboardGrid'

function create( ) {
  return {
    type : TYPE,
    columns : 2,
    columnSize : '1fr',
    rowGap : '24px',
    columnGap : '24px',
    options : { },
    cards : [ ]
  }
}

// Register options
function getRequired( model, factory, dashboardContext ) {
  if ( Array.isArray( model.cards ) ) {
    const rtn = new Set()
    for ( let n = 0; n < model.cards.length; n++ ) {
      const child = factory.create( model.cards[n] )
      if ( child && typeof child.getRequired === 'function' ) {
        const req = child.getRequired( model.cards[n], factory, dashboardContext ) 
        if ( typeof req === 'string' ) {
          rtn.add( req )
        } else if ( Array.isArray( req ) ) {
          req.forEach( r => rtn.add( r ) )
        }
      }
    }
    return Array.from( rtn )
  }
  return []
}

export default { 
  TYPE, 
  options : { },
  isLayout : true,
  create,
  setOptions : ModelUtil.createSetValue( 'options' ),
  getOptions : ModelUtil.createGetValue( 'options' ),
  setColumns : ModelUtil.createSetValue( 'columns' ),
  getColumns : ModelUtil.createGetValue( 'columns' ),
  setColumnSize : ModelUtil.createSetValue( 'columnSize' ),
  getColumnSize : ModelUtil.createGetValue( 'columnSize' ),
  ...ModelUtil.createAllValue( 'rowGap' ),
  ...ModelUtil.createAllValue( 'columnGap' ),
  ...ModelUtil.createAllArray( 'cards', 'Card', 'Cards' ),
  getRequired
}
