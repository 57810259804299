/**
 * This represents a global access for the networks. Call
 * networks.set() to initialize them
 */

let lookup = {}
const listeners = new Set()
 
const get = () => {
  return lookup
}
 
const getById = ( id ) => {
  return lookup[id]
}
 
const add = ( network ) => {
  if ( network ) {
    lookup[network.id] = network
    fireEvent( { type : 'networkAdded', network, networks : lookup } )
  }
}
 
const remove = ( network ) => {
  if ( network && lookup[network.id] != null ) {
    delete lookup[network.id]
    fireEvent( { type : 'networkRemoved', network, networks : lookup } )
  }
}
 
const clear = ( fire = true ) => { 
  lookup = {}
  if ( fire ) {
    fireEvent( { type : 'networksCleared' } )
  }
}
 
/**
  * 
  * @param {Array<enm>} enums 
  * @param {boolean} clear if true, the current set is cleared
  * @returns 
  */
const set = ( networkArray, clearOpt = true ) => {
  if ( clearOpt ) {
    clear( false )
  }
   
  if ( Array.isArray( networkArray ) ) {
     
    for ( let n = 0; n < networkArray.length; n++ ) {
      lookup[networkArray[n].id] = networkArray[n]
    }
  }
  fireEvent( { type : 'networksSet', networks : lookup } )
}

const removeListener = ( listener ) => {
  listeners.delete( listener )
}
 
const addListener = ( listener ) => {
  listeners.add( listener )
  return ( ) => { removeListener( listener ) }
}
 
function fireEvent( event ) {
  listeners.forEach( ( l ) => {
    try {
      l( event )
    } catch ( err ) { }
  } )
}

export default {
  get,
  getById,
  add,
  remove,
  clear,
  set,
  removeListener,
  addListener
}
