/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import MapboxDraw from '@mapbox/mapbox-gl-draw'

function noOp() { }

const SimpleSelectMode = { ...MapboxDraw.modes.simple_select, clickAnywhere : noOp }
const DirectSelectMode = { ...MapboxDraw.modes.direct_select, clickAnywhere : noOp }
const DrawPolygon = { ...MapboxDraw.modes.draw_polygon }
const DrawPoint = { }

function isEventAtCoordinates( event, coordinates, thres = 3 ) {
  if ( !event.lngLat || coordinates == null ) return false
  if ( event.lngLat.lng === coordinates[0] && event.lngLat.lat === coordinates[1] ) {
    return true
  }
  // convert to screen and check
  if ( typeof event.target?.project === 'function' ) {
    const p = event.target.project( coordinates )
    if ( Math.abs( p.x - event.point.x ) < thres && Math.abs( p.y - event.point.y ) < thres ) {
      return true
    }
  }
  return false
}

/**
 * Make the delete remove the select coord (normal behavior) or the last added point, and
 * finally the entire feature
 */
DirectSelectMode.onTrash = function( state, evt ) {
  if ( state.selectedCoordPaths?.length === 0 ) {
    const f = state.feature
    if ( f.coordinates == null || f.coordinates.length <= 0 || f.coordinates[0].length <= 3 ) {
      this.deleteFeature( this.getSelectedIds() )
    } else {
      f.removeCoordinate( `0.${f.coordinates[0].length - 1}` )
      this.fireUpdate();
    }
  } else {
    MapboxDraw.modes.direct_select.onTrash.apply( this, [ state, evt ] )
  }
}

/**
 * Keep the shape selected but get out of add point mode
 */
DirectSelectMode.clickNoTarget = function( state, e ) {
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  state.feature.changed();

  this.changeMode( 'simple_select', { featureIds : [ state.featureId ] } );
}

DrawPolygon.clickAnywhere = function( state, e ) {
  const curr = state.currentVertexPosition
  if ( curr < 3 && isEventAtCoordinates( e, state.polygon.coordinates[0][curr - 1] ) ) {
    return
  }
  if ( curr === 2 && isEventAtCoordinates( e, state.polygon.coordinates[0][0] ) ) {
    return
  }
  MapboxDraw.modes.draw_polygon.clickAnywhere.apply( this, [ state, e ] )
}

DrawPolygon.clickOnVertex = function( state, e ) {
  const curr = state.currentVertexPosition
  if ( curr < 3 && isEventAtCoordinates( e, state.polygon.coordinates[0][curr - 1] ) ) {
    return
  }
  if ( curr === 2 && isEventAtCoordinates( e, state.polygon.coordinates[0][0] ) ) {
    return
  }
  MapboxDraw.modes.draw_polygon.clickOnVertex.apply( this, [ state, e ] )
}

DrawPolygon.onStop = function( state, e ) {
  if ( state.currentVertexPosition > 2 ) {
    MapboxDraw.modes.draw_polygon.onStop.apply( this, [ state, e ] )
  }
}

// --- Draw Point ---

DrawPoint.onSetup = function( opts ) {
  return {
    point : null
  }
}

DrawPoint.onClick = function( state, e ) {
  if ( state.point == null ) {
    state.point = this.newFeature( {
      type : 'Feature',
      properties : { },
      geometry : {
        type : 'Point',
        coordinates : [ e.lngLat.lng, e.lngLat.lat ]
      }
    } )
  
    this.addFeature( state.point ); // puts the point on the map
    this.map.fire( 'draw.create', { features : [ state.point.toGeoJSON() ] } )
    this.changeMode( 'simple_select', { featureIds : [ state.point.id ] } ) 
  } 
  // else {
  //   state.point.updateCoordinate( '', e.lngLat.lng, e.lngLat.lat )
  //   this.map.fire( 'draw.update', { features : [ state.point.toGeoJSON() ] } )
  // }
}
DrawPoint.onTap = DrawPoint.onClick

export default {
  DrawPolygon,
  SimpleSelect : SimpleSelectMode,
  DirectSelect : DirectSelectMode,
  DrawPoint
}
