export default {
  
  name() {
    return 'Step'
  },

  typeName() {
    return 'step' 
  },

  handles( attributeType ) {
    return attributeType.typeName() !== 'geoPoint'
  },

  create() { 
    return { 
      type : this.typeName(), 
      update : 30000,
      stepAt : 1
    }
  }
}
