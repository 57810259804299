import React from 'react'
import PropTypes from 'prop-types'
import distance from '@turf/distance'
import { point } from '@turf/helpers'
import ReactMapboxGl, { ZoomControl, RotationControl } from 'react-mapbox-gl'

import Util from './Util'

const MapboxMap = ReactMapboxGl( {
  accessToken : process.env.MAPBOX_APIKEY,
  maxZoom : Number( process.env.MAPBOX_MAX_ZOOM ) || 20,
  pitchWithRotate : process.env.MAPBOX_PITCH_WITH_ROTATE === 'true' ||
    process.env.MAPBOX_PITCH_WITH_ROTATE === true
} )

export default class MapView extends React.Component {
  constructor( props ) {
    super( props )
    this.isIPad = Util.isIPad()
    this.initialSet = false
    this.state = {}
    this.highlight = []
  }

  componentDidMount() {
    this.addEventListeners()
  }

  componentWillUnmount() {
    this.removeEventListeners()
  }

  addEventListeners() {
    const { mapName } = this.props

    if ( mapName ) {
      window.addEventListener( `${mapName}-flyTo`, this.onFlyTo )
      window.addEventListener( `${mapName}-zoomIn`, this.onZoomIn )
      window.addEventListener( `${mapName}-zoomOut`, this.onZoomOut )
      window.addEventListener( `${mapName}-resetNorth`, this.onResetNorth )
      window.addEventListener( `${mapName}-jumpTo`, this.onJumpTo )
    }
  }

  removeEventListeners() {
    const { mapName } = this.props
    if ( mapName ) {
      window.removeEventListener( `${mapName}-flyTo`, this.onFlyTo )
      window.removeEventListener( `${mapName}-zoomIn`, this.onZoomIn )
      window.removeEventListener( `${mapName}-zoomOut`, this.onZoomOut )
      window.removeEventListener( `${mapName}-resetNorth`, this.onResetNorth )
      window.removeEventListener( `${mapName}-jumpTo`, this.onJumpTo )
    }
  }

  componentDidUpdate( prevProps ) {
    const { mapName } = this.props
    const { mapName : pMapName } = prevProps

    if ( mapName !== pMapName ) {
      this.removeEventListeners()
      this.addEventListeners()
    }
  }

  /**
   *  Fly to listeners
   **/
  onFlyTo = ( evt ) => {
    if ( evt.detail.bounds ) {
      this.fitBounds( evt.detail.bounds, evt.detail.options || {} )
    } else {
      this.flyTo( evt.detail.lat, evt.detail.lon, evt.detail.zoom, evt.detail.bearing )
    }
  }

  onJumpTo = ( evt ) => {
    if ( evt.detail.bounds ) {
      this.fitBounds( evt.detail.bounds, { ...( evt.detail.options || {} ), duration : 0 } )
    } else {
      this.jumpTo( evt.detail.lat, evt.detail.lon, evt.detail.zoom, evt.detail.bearing )
    }
  }

  onZoomIn = ( event ) => {
    if ( this.map ) {
      this.map.zoomIn( event.detail.options )
    }
  }

  onZoomOut = ( event ) => {
    if ( this.map ) {
      this.map.zoomOut( event.detail.options )
    }
  }
  
  onResetNorth = ( event ) => {
    if ( this.map ) {
      this.map.resetNorth( event.detail.options )
    }
  }

  setupMap = ( map ) => {
    const { onMapReady } = this.props
    this.map = map

    const start = this.centerOnWhenReady
    this.centerOnWhenReady = null

    if ( start ) {
      if ( Array.isArray( start ) ) {
        map.fitBounds( start, { duration : 0 } )
      } else {
        map.jumpTo( { center : [ start.lon, start.lat ], zoom : start.zoom == null ? 14 : start.zoom } )
      }
    }

    if ( onMapReady ) {
      onMapReady( map, this )
    }
    // Hack: Map doesnt seem to have the correct size. Kick it when its ready
    setTimeout( ( ) => {
      window.dispatchEvent( new Event( 'resize' ) )
    }, 1 )
  }

  flyTo( lat, lon, zoom, bearing, duration ) {
    if ( this.map ) {

      if ( zoom === -1 || zoom == null ) {
        zoom = this.map.getZoom()
      }
      let c
      if ( lat == null || lon == null ) {
        c = this.map.getCenter()
      } 
      const opts = { center : [ lon || c.lng, lat || c.lat ], zoom, duration }
      // if bearing is passed in as null it can break mapbox
      if ( bearing != null ) {
        opts.bearing = bearing
      }
      if ( opts.duration == null ) {
        this.durationToCenter( [ lon, lat ], opts )
      }
      this.addInFlightListener()
      this.map.flyTo( opts )
    } else {
      this.centerOnWhenReady = { lat, lon, zoom }
    }
  }

  jumpTo( lat, lon, zoom, bearing ) {
    if ( this.map ) {
      if ( zoom === -1 || zoom == null ) {
        zoom = this.map.getZoom()
      }
      let c
      if ( lat == null || lon == null ) {
        c = this.map.getCenter()
      } 
      const opts = { center : [ lon || c.lng, lat || c.lat ], zoom }
      if ( bearing != null ) {
        opts.bearing = bearing
      }
      this.addInFlightListener()
      this.map.jumpTo( opts )
    } else {
      this.centerOnWhenReady = { lat, lon, zoom }
    }
  }

  fitBounds( bnds, opts = {} ) {
    if ( this.map ) {
      if ( opts.duration == null ) {
        this.durationToCenter( bnds, opts )
      }
      this.addInFlightListener()
      this.map.fitBounds( bnds, opts )
    } else {
      this.centerOnWhenReady = bnds
    }
  }

  addInFlightListener() {
    this.map.setMinZoom( 0 )
    this.inFlight = true
    const cb = ( evt ) => {
      if ( this.map ) {
        this.map.setMinZoom( this.props.minZoom || 0 )
        this.map.off( 'moveend', cb )
        this.inFlight = false
        this.forceUpdate();
      }
    }
    this.map.on( 'moveend', cb )
  }

  durationToCenter( p, opts ) {
    if ( Array.isArray( p[0] ) ) {
      p = [ ( p[0][0] + p[1][0] ) * 0.5, ( p[0][1] + p[1][1] ) * 0.5 ]
    }
    const c = this.map.getCenter()
    // dist in km
    const d = distance( point( [ p[1], p[0] ] ), point( [ c.lng, c.lat ] ) )
    if ( d > 100000 ) {
      opts.duration = Math.min( 3000, 1250 + ( d / 1000 ) )
    } else {
      opts.duration = 300
    }
  }

  shouldComponentUpdate( nextProps, nextState ) {
    if ( this.map && ( this.map.isMoving() || this.map.isZooming() || this.map.isRotating() ) ) {
      return false
    }
    return true
  }

  render() {
    const { pointer, layers, popups, baseLayers, mapStyle, overlays, className, useInitialPosition = true, initialPosition, zoomRotateControl = { }, ...rest } = this.props

    const { enabled = false, position = 'top-right', containerStyle } = zoomRotateControl
    const zrStyle = containerStyle || {
      border : 'none',
      boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)'
    }

    let cStyle
    if ( this.isIPad ) {
      cStyle = {
        position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, maxWidth : 1024, maxHeight : 1024
      }
    } else {
      cStyle = {
        position : 'absolute', top : 0, left : 0, right : 0, bottom : 0
      }
    }
    const pos = useInitialPosition === false ? { } : {
      center : initialPosition ? [ initialPosition.lon, initialPosition.lat ] : [ -77.2502381, 39.186753 ],
      zoom : initialPosition ? [ initialPosition.zoom ] : [ 16 ]
    }
    return (
      <div className={className}>
        <MapboxMap
          ref={( r ) => { this.mapbox = r }}
          resized={this.props.resized}
          style={mapStyle}
          {...pos}
          movingMethod="jumpTo"
          onStyleLoad={this.setupMap}
          containerStyle={cStyle}
          attributionControl="true"
          {...rest}>
          {layers}
          {popups}
          {enabled && <ZoomControl position={position} style={zrStyle} /> }
          {enabled && <RotationControl position={position} style={zrStyle} /> }
          { pointer && pointer.render && pointer.render()}
        </MapboxMap>
        {pointer && pointer.getComponents()}
      </div>
    )
  }
}
