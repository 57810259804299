/* eslint-disable max-len */
const COLOR = '#D20C0C'

export default {
 create : ( options ) => {
    const opts = options || { }
    const { color = COLOR, icon, iconSize = 0.5 } = opts
    const {
      lineColor = color, 
      fillColor = color,
      pointRadius = 5,
      pointColor = color,
      haloPointRadius = 7,
      haloPointColor = '#FFF',
  
      selectedHaloPointColor = '#FFF',
      selectedHaloPointRadius = 9,
      selectedPointRadius = 7,
      selectedPointColor = color,
      
      midPointRadius = 3, 
      midPointColor = color, 
  
      lineWidth = 2, 
      lineDash = [ 0.2, 2 ],
      fillOpacity = 0.1,
      staticFillColor = color,
      staticOutlineColor = color,
      staticFillOpacity = 0.1,
      staticLineWidth = 2,
      staticLineColor = color,
  
    } = opts || { }
  
    return [
      // points
      {
        id : 'gl-draw-point-feature',
        type : icon ? 'symbol' : 'circle',
        filter : [ 'all', [ '==', 'meta', 'feature' ], [ '==', '$type', 'Point' ], /* [ '==', 'active', 'false' ], [ '==', 'mode', 'draw_point' ]*/ ],
        paint : icon != null ? {} : {
          'circle-radius' : pointRadius,
          'circle-color' : '#FF0000',
        },
        layout : icon == null ? {} : {
          'icon-image' : icon,
          'icon-size' : iconSize,
          'icon-allow-overlap' : true,
          'text-allow-overlap' : true,
          'text-ignore-placement' : true,
          'text-optional' : true,
        }
      },
  
      // ACTIVE (being drawn)
      // line stroke
      {
        id : 'gl-draw-line',
        type : 'line',
        filter : [ 'all', [ '==', '$type', 'LineString' ], [ '!=', 'mode', 'static' ] ],
        layout : {
          'line-cap' : 'round',
          'line-join' : 'round'
        },
        paint : {
          'line-color' : lineColor,
          'line-dasharray' : lineDash,
          'line-width' : lineWidth
        }
      },
      // polygon fill
      {
        id : 'gl-draw-polygon-fill',
        type : 'fill',
        filter : [ 'all', [ '==', '$type', 'Polygon' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'fill-color' : fillColor,
          'fill-outline-color' : color,
          'fill-opacity' : fillOpacity
        }
      },
      // polygon outline stroke
      // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
      {
        id : 'gl-draw-polygon-stroke-active',
        type : 'line',
        filter : [ 'all', [ '==', '$type', 'Polygon' ], [ '!=', 'mode', 'static' ] ],
        layout : {
          'line-cap' : 'round',
          'line-join' : 'round'
        },
        paint : {
          'line-color' : lineColor,
          'line-dasharray' : lineDash,
          'line-width' : lineWidth
        }
      },
      // vertex point halos
      {
        id : 'gl-draw-polygon-and-line-vertex-halo',
        type : 'circle',
        filter : [ 'all', [ '==', 'meta', 'vertex' ], [ '==', '$type', 'Point' ], [ '==', 'active', 'false' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'circle-radius' : haloPointRadius,
          'circle-color' : haloPointColor
        }
      },
      // vertex points
      {
        id : 'gl-draw-polygon-and-line-vertex',
        type : 'circle',
        filter : [ 'all', [ '==', 'meta', 'vertex' ], [ '==', '$type', 'Point' ], [ '==', 'active', 'false' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'circle-radius' : pointRadius,
          'circle-color' : pointColor,
        }
      },
  
      // midpoints points
      {
        id : 'gl-draw-polygon-and-line-midpoint',
        type : 'circle',
        filter : [ 'all', [ '==', 'meta', 'midpoint' ], [ '==', '$type', 'Point' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'circle-radius' : midPointRadius,
          'circle-color' : midPointColor,
        }
      },
  
      // vertex point halos
      {
        id : 'gl-draw-polygon-and-line-vertex-halo-active',
        type : 'circle',
        filter : [ 'all', [ '==', 'meta', 'vertex' ], [ '==', '$type', 'Point' ], [ '==', 'active', 'true' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'circle-radius' : selectedHaloPointRadius,
          'circle-color' : selectedHaloPointColor
        }
      },
      // vertex points
      {
        id : 'gl-draw-polygon-and-line-vertex-active',
        type : 'circle',
        filter : [ 'all', [ '==', 'meta', 'vertex' ], [ '==', '$type', 'Point' ], [ '==', 'active', 'true' ], [ '!=', 'mode', 'static' ] ],
        paint : {
          'circle-radius' : selectedPointRadius,
          'circle-color' : selectedPointColor,
        }
      },
  
      // INACTIVE (static, already drawn)
      // line stroke
      {
        id : 'gl-draw-line-static',
        type : 'line',
        filter : [ 'all', [ '==', '$type', 'LineString' ], [ '==', 'mode', 'static' ] ],
        layout : {
          'line-cap' : 'round',
          'line-join' : 'round'
        },
        paint : {
          'line-color' : staticLineColor,
          'line-width' : staticLineWidth
        }
      },
      // polygon fill
      {
        id : 'gl-draw-polygon-fill-static',
        type : 'fill',
        filter : [ 'all', [ '==', '$type', 'Polygon' ], [ '==', 'mode', 'static' ] ],
        paint : {
          'fill-color' : staticFillColor,
          'fill-outline-color' : staticOutlineColor,
          'fill-opacity' : staticFillOpacity
        }
      },
      // polygon outline
      {
        id : 'gl-draw-polygon-stroke-static',
        type : 'line',
        filter : [ 'all', [ '==', '$type', 'Polygon' ], [ '==', 'mode', 'static' ] ],
        layout : {
          'line-cap' : 'round',
          'line-join' : 'round'
        },
        paint : {
          'line-color' : staticLineColor,
          'line-width' : staticLineWidth
        }
      }
    ]
  }
}
