import React, { useCallback } from 'react'
import { ItemLayoutSelector, ModelFactory, ModelMapEditor } from '@leverege/ui-plugin'
import { Button, Flex, List, Text } from '@leverege/ui-elements'

import I18NAttributeModel from '../models/I18NAttributeModel.js'

import S from './AttributeTranslationEditor.css'

function AttributeListRenderer( props ) {
  const { onEvent, deleteable = true, onParentChange, eventData, ...rest } = props
  let { item } = props
  const key = item[0]
  item = item[1]

  const onDelete = deleteable ? ( e ) => {
    onEvent( { type : 'child.remove', item : [ key, item ], data : eventData, } )
  } : null

  let name
  const model = ModelFactory.get( item.type )
  if ( model ) {
    name = model?.getName( item )
  }
  if ( name == null ) {
    name = item.displayName || item.name || item.type || 'Unknown'
  }

  return (
    <List.Item item={item} {...rest}>
      <Flex variant="rowM" justify="space-between" align="center">
        <Text variant="listItemSubtitle">{name}</Text>
        { deleteable && <Button variant="link" eventData={item} onClick={onDelete} icon="fa fa-trash" /> }
      </Flex>
    </List.Item>
  )
}

export default function AttributeTranslationEditor( props ) {
  const { value, eventData, model, onChange, blueprint } = props

  const onAddAttribute = useCallback( ( evt ) => {
    const nModel = model.set( value, evt.value.id, evt.value.create() )
    onChange( { data : eventData, value : nModel, oldValue : value } )
  }, [ model, value, eventData ] )

  const header = useCallback( () => {
    const avail = blueprint?.data?.attributes?.map( ( attribute ) => {
      return {
        id : attribute.id,
        name : attribute.name,
        layout : {
          group : []
        },
        create : () => {
          return I18NAttributeModel.create( { name : attribute.name, id : attribute.id } )
        }
      }
    } )

    if ( !avail ) {
      return null
    }
    
    return (
      <Flex align="center" justify="space-between">
        <ItemLayoutSelector
          showFilter
          options={avail}
          onSelect={onAddAttribute}
          icon="fa fa-plus"
          title="Add Attribute" />
      </Flex>
    )
  }, [ onAddAttribute, blueprint ] )

  return (
    <ModelMapEditor
      listClassName={S.list}
      header={header}
      eventData={eventData}
      value={value}
      model={model}
      onChange={onChange}
      renderer={AttributeListRenderer}
      selectionProps={{
        model : I18NAttributeModel.translations(),
        blueprint
      }} />
  )
}
