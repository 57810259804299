import React from 'react'
import { Pane, Text } from '@leverege/ui-elements'
import { DragDropView } from '@leverege/ui-plugin'
import { ns } from '@leverege/i18n'

import S from './LogicConditionEditor.css'

const { t } = ns( 'condition.LogicConditionEditor' )

export default function NoRows( props ) {
  const { value, onDrop } = props
  return (
    <DragDropView
      accept="condition"
      onDrop={onDrop}
      value={value}>
      <Pane
        variant="conditionRowEmpty|conditionRow"
        layout="flex:conditionRowEmpty|conditionRow">
        <Text variant="caption">{t( 'noRows' )}</Text>
      </Pane>
    </DragDropView>
  )
}
