// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.editor_K44Rw {
  min-width: 300px;
  display: grid;
  row-gap: var( --size-paddingSmall, 8px );
  column-gap: var( --size-paddingMedium, 16px );
  grid-template-areas: "f f f f s s" "w w w y y y";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.family_SUD0a {
  grid-area: f
}

.size_EhoOz { 
  grid-area: s;
}

.weight_QNQE5 { 
  grid-area: w
}

.style_LAE1p { 
  grid-area: y
}
`, "",{"version":3,"sources":["webpack://./src/FontEditor.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,aAAa;EACb,wCAAwC;EACxC,6CAA6C;EAC7C,gDAAgD;EAChD,8CAA8C;AAChD;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;AACF;;AAEA;EACE;AACF","sourcesContent":["\n.editor {\n  min-width: 300px;\n  display: grid;\n  row-gap: var( --size-paddingSmall, 8px );\n  column-gap: var( --size-paddingMedium, 16px );\n  grid-template-areas: \"f f f f s s\" \"w w w y y y\";\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;\n}\n\n.family {\n  grid-area: f\n}\n\n.size { \n  grid-area: s;\n}\n\n.weight { \n  grid-area: w\n}\n\n.style { \n  grid-area: y\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `editor_K44Rw`,
	"family": `family_SUD0a`,
	"size": `size_EhoOz`,
	"weight": `weight_QNQE5`,
	"style": `style_LAE1p`
};
export default ___CSS_LOADER_EXPORT___;
