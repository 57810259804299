/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Flex, Pane, Text } from '@leverege/ui-elements'
import { DragDropView, ModelEditorFactory, Toolbar, useSharedContext } from '@leverege/ui-plugin'
import { ns } from '@leverege/i18n'

import S from './LogicConditionEditor.css'

const { t } = ns( 'condition.LogicConditionEditor' )

const TYPE = 'condition'

const PreConditionMatches = { 
  client : 'condition.LogicEditor',
  use : 'preConditionActionBar'
}

const ConditionMatches = { 
  client : 'condition.LogicEditor',
  use : 'conditionActionBar'
}

export default function ConditionRow( props ) {
  const { 
    parent, value, index, 
    onDrop, onRemove, onChange } = props
  const { conditionOptions, conditionCreators } = useSharedContext()

  const editorFactory = conditionOptions?.editorFactory
    
  const eProps = { value, eventData : value, onChange, parent }
  let editor = editorFactory?.create( value, eProps ) || ModelEditorFactory.create( value, eProps )
  if ( editor == null ) {
    editor = <Text variant="caption">{t( 'noEditor', { type : value.type } )}</Text>
  }

  return (
    <DragDropView
      value={value}
      index={index}
      onRemove={onRemove}
      onDrop={onDrop}
      accept={TYPE}
      dragType={TYPE}>
      <Pane variant="conditionRow" layout="flex:conditionRow">
        <Flex className={S.editorFlex} variant="conditionRowFlex">
          <Toolbar
            variant="preConditionBar|actionBar"
            prefer="icon"
            matchContext={PreConditionMatches}
            context={{
              ...props,
              conditionCreators,
              conditionOptions
            }} />
          {editor}
        </Flex>
        <Toolbar
          variant="conditionBar|actionBar"
          prefer="icon"
          matchContext={ConditionMatches}
          context={{
            ...props,
            conditionCreators,
            conditionOptions
          }} />
      </Pane>
    </DragDropView>
  )
}
