import ModelUtil from '@leverege/model-util'

const TYPE = 'uiDashboardCard'

function create( content = null ) {
  
  return {
    type : TYPE,
    title : null,
    icon : null,
    titleVisible : content?.type !== 'uiDashboardGrid',
    style : content?.type === 'uiDashboardGrid' ? 'grid' : null,
    deletable : true,
    width : 1,
    height : 1,
    providers : { },
    content
  }
}

// Register options
function getRequired( model, factory, dashboardContext ) {
  const mdl = factory.create( model.content )
  if ( mdl && typeof mdl.getRequired === 'function' ) {
    return mdl.getRequired( model.content, factory, dashboardContext ) 
  }
  return null
}

export default {  
  TYPE,
  create,
  ...ModelUtil.createAllValue( 'icon' ),
  setTitle : ModelUtil.createSetValue( 'title' ),
  getTitle : ModelUtil.createGetValue( 'title' ),
  setStyle : ModelUtil.createSetValue( 'style' ),
  getStyle : ModelUtil.createGetValue( 'style' ),
  setTitleVisible : ModelUtil.createSetValue( 'titleVisible' ),
  isTitleVisible : ModelUtil.createGetValue( 'titleVisible' ),
  setWidth : ModelUtil.createSetValue( 'width' ),
  setHeight : ModelUtil.createSetValue( 'height' ),
  setContent : ModelUtil.createSetValue( 'content' ),
  getRequired
}
