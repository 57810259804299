import React from 'react'
import PropTypes from 'prop-types'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
// import DrawPloygon from '@mapbox/mapbox-gl-draw-static-mode'
import { MapContext } from 'react-mapbox-gl/lib-esm/context'

export default class DrawControl extends React.Component {

  static STATIC = 'static';
  static DRAW_POLYGON = 'draw_polygon';
  static SELECT = 'simple_select';

  static contextType = MapContext

  static defaultProps = {
    onDrawActionable : () => {},
    onDrawCombine : () => {},
    onDrawCreate : () => {},
    onDrawDelete : () => {},
    onDrawModeChange : ( e ) => {
    },
    onDrawRender : () => {},
    onDrawSelectionChange : () => {},
    onDrawUncombine : () => {},
    onDrawUpdate : () => {},
    keybindings : false,
    position : 'top-left',
    controls : {
      point : false,
      line_string : false,
      polygon : false,
      trash : false,
      combine_features : false,
      uncombine_features : false
    }
  };

  static propTypes = {
    mapName : PropTypes.string.isRequired,
    boxSelect : PropTypes.bool,
    clickBuffer : PropTypes.number,
    controls : PropTypes.shape( {
      point : PropTypes.bool,
      line_string : PropTypes.bool,
      polygon : PropTypes.bool,
      trash : PropTypes.bool,
      combine_features : PropTypes.bool,
      uncombine_features : PropTypes.bool
    } ),
    default_mode : PropTypes.string,
    displayControlsDefault : PropTypes.bool,
    keybindings : PropTypes.bool,
    modes : PropTypes.object,
    position : PropTypes.string,
    onDrawActionable : PropTypes.func,
    onDrawCombine : PropTypes.func,
    onDrawCreate : PropTypes.func,
    onDrawDelete : PropTypes.func,
    onDrawModeChange : PropTypes.func,
    onDrawRender : PropTypes.func,
    onDrawSelectionChange : PropTypes.func,
    onDrawUncombine : PropTypes.func,
    onDrawUpdate : PropTypes.func,
    touchBuffer : PropTypes.number,
    touchEnabled : PropTypes.bool,
    styles : PropTypes.arrayOf( PropTypes.object )
  };

  // onDrawModeChange = ( e ) => {
  //   console.log( 'NEW MODE====', e )
  // }

  componentDidMount() {

    const {
      mapName,
      modes,
      onDrawActionable,
      onDrawCombine,
      onDrawCreate,
      onDrawDelete,
      onDrawModeChange,
      onDrawRender,
      onDrawSelectionChange,
      onDrawUncombine,
      onDrawUpdate,
      position
    } = this.props;

    const map = this.context
    this.draw = new MapboxDraw( {
      ...this.props,
      modes : {
        static : StaticMode,
        ...MapboxDraw.modes,
        ...modes
      }
    } )
    map.addControl( this.draw, position );
    // Hook draw events
    map.on( 'draw.actionable', onDrawActionable );
    map.on( 'draw.combine', onDrawCombine );
    map.on( 'draw.create', onDrawCreate );
    map.on( 'draw.delete', onDrawDelete );
    map.on( 'draw.modechange', onDrawModeChange );
    map.on( 'draw.render', onDrawRender );
    map.on( 'draw.selectionchange', onDrawSelectionChange );
    map.on( 'draw.uncombine', onDrawUncombine );
    map.on( 'draw.update', onDrawUpdate );

    window.addEventListener( `${mapName}-cancelDraw`, this.onCancelDraw )
  }

  getSelectedIds() {
    return this.draw && this.draw.getSelectedIds()
  }

  componentWillUnmount() {
    // console.log( 'UNMOUNT DRAW CONTROL' )
    const map = this.context
    if ( !map || !map.getStyle() ) {
      return;
    }
    const {
      mapName,
      modes,
      onDrawActionable,
      onDrawCombine,
      onDrawCreate,
      onDrawDelete,
      onDrawModeChange,
      onDrawRender,
      onDrawSelectionChange,
      onDrawUncombine,
      onDrawUpdate,
      position
    } = this.props;

    map.removeControl( this.draw );
    // Hook draw events
    map.off( 'draw.actionable', onDrawActionable );
    map.off( 'draw.combine', onDrawCombine );
    map.off( 'draw.create', onDrawCreate );
    map.off( 'draw.delete', onDrawDelete );
    map.off( 'draw.modechange', onDrawModeChange );
    map.off( 'draw.render', onDrawRender );
    map.off( 'draw.selectionchange', onDrawSelectionChange );
    map.off( 'draw.uncombine', onDrawUncombine );
    map.off( 'draw.update', onDrawUpdate );

    window.removeEventListener( `${mapName}-cancelDraw`, this.onCancelDraw )

  }

  onCancelDraw = ( e ) => {
    if ( this.draw ) {
      this.draw.trash()
    }
  }

  getSnapshotBeforeUpdate( prevProps, prevState ) {
    const { mode = DrawControl.STATIC, selectedIds = [], features } = this.props
    const { selectedIds : pSelectedIds = [], features : pFeatures } = prevProps
    
    let eq = true
    if ( selectedIds.length === pSelectedIds.length ) {
      for ( let n = 0; n < selectedIds.length; n++ ) {
        if ( selectedIds[n] !== pSelectedIds[n] ) {
          eq = false
          break
        }
      }
    } else {
      eq = false
    }

    // features changed
    if ( pFeatures !== features ) {
      this.draw.set( features )
    }

    // mode change and new mode is direct select and we have features and we have a featureId
    if ( mode !== this.draw.getMode() && mode === 'direct_select' &&
         ( features && features.features && features.features.length > 0 ) && 
           ( selectedIds && selectedIds.length > 0 ) ) {
      // in order to enter direct_select you must have a selected feature or they yell at you
      this.draw.changeMode( 'direct_select', { featureId : selectedIds[0], featureIds : selectedIds } )
    // selected id's changed, and mode is static, and mode didn't change
    } else if ( !eq && this.draw.getMode() === DrawControl.STATIC && this.draw.getMode() === mode ) {
      // go into simple select with new selection
      this.draw.changeMode( DrawControl.SELECT, { featureId : selectedIds[0], featureIds : selectedIds } )
    // we're changing mode or selected id's changed ( but current mode isn't direct select )
    } else if ( ( mode !== this.draw.getMode() || !eq ) && mode !== 'direct_select' ) {
      this.draw.changeMode( mode, { featureId : selectedIds[0], featureIds : selectedIds } )
    }

    return null
  }

  componentDidUpdate() {

  }

  render() {
    return null
  }
}
