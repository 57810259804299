import React from 'react'
import { connect } from 'react-redux'
import { Selection } from '@leverege/ui-redux'
import { ContextButton, Toolbar } from '@leverege/ui-plugin'
import { SelectionCard } from '@leverege/ui-attributes'

class Card extends React.Component {

  onClose = ( ) => {
    const { dispatch } = this.props
    dispatch( Selection.clear( 'selected' ) )
  }

  render( ) {
    const { selectionModel, getContext, selected, toolbarLayout } = this.props
    if ( selectionModel && !Selection.isEmpty( selected ) ) {
      const cxt = getContext( selected.items )
      const controls = (
        <Toolbar key="toolbar" 
          variant="itemBar" 
          hasContextMenu 
          context={cxt} 
          actionsLayout={toolbarLayout} 
          prefer="icon" /> 
      )
      return (
        <SelectionCard 
          model={selectionModel} 
          controls={controls}
          context={cxt}
          onClose={this.onClose} />
      )
    }
    return null
  }
}

export default connect( ( state, props ) => {

  return {
    selectionModel : props.getSelectionModel(),
    selected : Selection.get( state, 'selected' )
  }
} )( Card )
