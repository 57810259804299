// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.layout_BrQQf {
  display : flex;
  flex-direction: row;
  align-items: stretch;
  justify-content : flex-start;
  overflow : hidden;
}

/* .layout > :first-child {
  margin-right: var( --controlGap, 8px );
} */

.controls_Cr3xg {
  flex : 1 0 0;
  overflow : auto;
}

.title_iQidJ {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content : center;
  column-gap: var( --iconGap, 8px );
}`, "",{"version":3,"sources":["webpack://./src/view/TitleBar.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;;GAEG;;AAEH;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wBAAwB;EACxB,iCAAiC;AACnC","sourcesContent":["\n.layout {\n  display : flex;\n  flex-direction: row;\n  align-items: stretch;\n  justify-content : flex-start;\n  overflow : hidden;\n}\n\n/* .layout > :first-child {\n  margin-right: var( --controlGap, 8px );\n} */\n\n.controls {\n  flex : 1 0 0;\n  overflow : auto;\n}\n\n.title {\n  display: grid;\n  grid-auto-flow: column;\n  align-items: center;\n  justify-content : center;\n  column-gap: var( --iconGap, 8px );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_BrQQf`,
	"controls": `controls_Cr3xg`,
	"title": `title_iQidJ`
};
export default ___CSS_LOADER_EXPORT___;
