function toDisplay( unit, attr ) {
  
  if ( unit === 'degC' ) {
    return '°C'
  }
  if ( unit === 'degF' ) {
    return '°F'
  }
  return unit
}

export default {
  toDisplay
}
