import Grid from './Grid'

const TYPE = 'uiDashboard'

function create( ) {
  const mdl = Grid.create()
  mdl.type = TYPE
  return mdl
}

export default { 
  ...Grid,
  TYPE,
  create
}
