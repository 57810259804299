// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardLayout_f0ZoP {
  display : grid;
  grid-template-columns: repeat( 2, 1fr );
  grid-template-rows: auto;
  grid-auto-flow: row;
  gap: var( --size-padXLarge, 24px );
}`, "",{"version":3,"sources":["webpack://./src/view/CardGridView.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uCAAuC;EACvC,wBAAwB;EACxB,mBAAmB;EACnB,kCAAkC;AACpC","sourcesContent":[".cardLayout {\n  display : grid;\n  grid-template-columns: repeat( 2, 1fr );\n  grid-template-rows: auto;\n  grid-auto-flow: row;\n  gap: var( --size-padXLarge, 24px );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardLayout": `cardLayout_f0ZoP`
};
export default ___CSS_LOADER_EXPORT___;
