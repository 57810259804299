import ModelUtil from '@leverege/model-util'

import I18NBlueprintLanguageModel from './I18NBlueprintLanguageModel.js'
import I18NNamespaceModel from './I18NNamespaceModel.js'

const TYPE = 'imagine.I18NBlueprintModel'

function create( extend ) {
  return {
    type : TYPE,
    data : {
      en : I18NBlueprintLanguageModel.create()
    },
    ...extend
  }
}

const exp = {
  TYPE,
  type : TYPE,
  create,
  ...ModelUtil.createAllValue( 'data' ),
  // Using the singular for the actual path here to make the i18n rollup easier
  ...ModelUtil.createAllMap( 'data', 'language', 'languages' ),
  getTranslationData( model ) {
    if ( !model ) {
      return null
    }

    return exp.getLanguageEntries( model )
      .reduce( ( prev, [ lang, value ] ) => {
        return {
          ...prev,
          [lang] : I18NBlueprintLanguageModel
            .getNamespaceEntries( value )
            .reduce( ( prev, [ namespace, namespaceValue ] ) => {
              return {
                ...prev,
                [namespace] : {
                  imagine : {
                    blueprint : {
                      [model.id] : I18NNamespaceModel.getTranslationData( namespaceValue )
                    }
                  }
                }
              }
            }, {} )
        }
      }, {} )
  }
}

export default exp
