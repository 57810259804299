import React from 'react'
import { ns } from '@leverege/i18n'
import { IconUtil } from '@leverege/ui-elements'

const { tIcon } = ns( 'condition.LogicConditionEditor' )

export default {
  id : 'condition.logic.DragGrabberAction', 
  matches : {
    client : 'condition.LogicEditor',
    use : 'preConditionActionBar'
  },
  layout : {
    sort : 'aaa'
  },
  handles( context ) { return true },
  customComponent : DragGrabber
}

function DragGrabber() {
  const icon = tIcon( 'moveConditionIcon', 'fa fa-fw fa-bars' )

  return (
    <div style={{
      cursor : 'move',
      display : 'flex',
      alignItems : 'center',
      color : 'var( --color-base500 )'
    }}>
      {IconUtil.create( icon, { height : '24px', width : '24px' } )}
    </div>
  )
}
