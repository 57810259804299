import { connect } from 'react-redux'
import { ValuesCache } from '@leverege/value-cache'

import ConnectView from './ConnectView.js'

const caches = new ValuesCache()

/**
 * This will return a function that behaves exactly as redux's connect, but
 * with ConnectPlugins running based on the matches given. To use this, take this:
 *   import { connect } from 'react-redux'
 *   export default connect( ... )( MyComponent )
 * and do this:
 *   import { createConnect } from 'ui-redux'
 *   const connect = createConnect( 'MyComponent', { client : 'MyComponent', ... } )
 *   export default connect( ... )( MyComponent )
 * 
 * Currently this assumes the matches will be the same for all invokations where clientName is the same.
 * @param {string | function} clientName is the name of the client that is using this function.
 * Its purpose to to create a filtered view of the Plugins once, without having to
 * evaulate the plugins everytime connect is called. If this is a function, it will be called
 * with ( stateOrDispatch, props ) to retreive the string. This allow client to come from the 
 * props
 * @param {object} context the context object used by the plugins to decide if it
 * should take part in the connect method for the client. This often will look like
 * this : { client : 'myClientName' }. The 'myClientName' name is usually also used as 
 * the supplied clientName too.  If this is a function, it will be called
 * with ( stateOrDispatch, props ) to retreive the string. This allow client to come from the 
 * props
 * @returns {function} a redux connect method
 */
function createConnect( clientName, matches ) {

  const delayed = ( typeof clientName === 'function' || typeof matches === 'function' ) 
  // create a view of the plugins based on the matches content. 
  let cView = delayed ? null : ConnectView.create( clientName, matches )

  // Redux Factory holders
  let stpFunc = null
  let dtpFunc = null

  return ( mapStateToProps, mapDispatchToProps, mergeProps, options ) => {

    // Same function for state or dispatch. Invoke pre plugins, normal mechanism,
    // and post plugins and merge/return the result.
    const toProps = ( stateOrDispatch, props, func, isStp ) => {

      if ( delayed ) {
        const client = typeof clientName === 'function' ? clientName( stateOrDispatch, props ) : clientName 
        const match = typeof matches === 'function' ? matches( stateOrDispatch, props ) : matches 
        if ( client ) {
          cView = caches.calc( client, ( c ) => { return ConnectView.create( c, match ) }, client )
        }
      }
      // run all the pre mapStateToProps Plugins
      const map = cView == null ? null : cView[isStp ? 'mapStateToProps' : 'mapDispatchToProps']
      const pre = map == null ? null : map( stateOrDispatch, props )

      // run all the normal. Support redux factory
      let norm = null
      if ( isStp && stpFunc ) {
        norm = stpFunc( stateOrDispatch, props )
      } else if ( !isStp && dtpFunc ) {
        norm = dtpFunc( stateOrDispatch, props )
      } else {
        norm = func == null ? null : func( stateOrDispatch, props ) 
        if ( typeof norm === 'function' ) {
          if ( isStp ) { 
            stpFunc = norm 
          } else { 
            dtpFunc = norm 
          }
          norm = norm( stateOrDispatch, props )
        } 
      }
      const currentProps = pre && norm ? { ...pre, ...norm } : norm || pre || null
      const postMap = cView == null ? null : cView[isStp ? 'postMapStateToProps' : 'postMapDispatchToProps']
      const post = postMap == null ? null : postMap( stateOrDispatch, props, currentProps )

      if ( post && currentProps ) {
        return { ...currentProps, ...post }
      }
      return post || currentProps || ( isStp ? { } : { dispatch : stateOrDispatch } )
    }

    const stp = ( state, props ) => { return toProps( state, props, mapStateToProps, true ) }
    const dtp = ( dispatch, props ) => { return toProps( dispatch, props, mapDispatchToProps, false ) }
    return connect( stp, dtp, mergeProps, options )
  }
}

export default createConnect
