export default {
  
  name() {
    return 'Random Boolean'
  },

  typeName() {
    return 'randomBoolean' 
  },

  handles( attributeType ) {
    return attributeType.categoryType() === 'boolean'
  },

  create( path, name ) { 
    return { 
      type : this.typeName(), 
      update : 30000
    }
  }
}
