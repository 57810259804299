export default {
  
  name() {
    return 'Random Number'
  },

  typeName() {
    return 'randomNumber' 
  },

  handles( attributeType ) {
    return attributeType.categoryType() === 'float'
  },

  create( path, name ) { 
    return { 
      type : this.typeName(), 
      update : 30000,
      range1 : 0,
      range2 : 10
    }
  }
}
