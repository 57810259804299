import Path from '@leverege/path'
import Area from '@turf/area' 

function createAccess( value, dPath ) {
  if ( typeof value === 'string' ) {
    return Path( value )
  }
  if ( typeof value === 'function' ) {
    return { get : value } // make it look like Path
  }
  return dPath == null ? null : Path( dPath )
}

function checkLayers( layers, map ) {
  if ( layers == null ) {
    return layers
  }
  return layers.filter( l => map.getLayer( l ) != null )
}

/**
 * Returns the smallest feature
 * @param {Array} features array of geoJsons
 */
function getSmallestArea( features ) {
  if ( features == null ) {
    return null
  }
  if ( features.length === 1 ) {
    return features[0]
  }

  let area = Area( features[0] )
  let smallest = features[0]

  for ( let n = 1; n < features.length; n++ ) {
    const a = Area( features[n] )
    if ( a < area ) {
      smallest = features[n]
      area = a
    }
  }    
  return smallest
}

function isIPad() {
  const ua = navigator.userAgent
  let isiPad = ua.match( /iPad/i ) != null

  // For use within iPad developer UIWebView
  // Thanks to Andrew Hedges!
  isiPad = isiPad || /iPad/i.test( ua ) || /iPhone OS 3_1_2/i.test( ua ) || /iPhone OS 3_2_2/i.test( ua )
  return isiPad
}

function isTouchDevice() {
  return ( ( 'ontouchstart' in window ) ||
     ( navigator.maxTouchPoints > 0 ) ||
     ( navigator.msMaxTouchPoints > 0 ) )
}

export default {
  createAccess,
  checkLayers,
  getSmallestArea,
  isIPad,
  isTouchDevice
}