export default {
  
  name() {
    return 'Jitter'
  },

  typeName() {
    return 'jitter' 
  },

  handles( attributeType ) {
    return attributeType.categoryType() === 'float'
  },

  create( path, name ) { 
    return { 
      type : this.typeName(), 
      update : 30000,
      range1 : -0.1,
      range2 : 0.1,
      offInitial : false
    }
  }
}
