import React from 'react'
import { ModelMapEditor } from '@leverege/ui-plugin'

import I18NBlueprintLanguageModel from '../models/I18NBlueprintLanguageModel.js'

export default function LanguageEditor( props ) {
  const { value, eventData, onChange, blueprint } = props

  return (
    <ModelMapEditor
      allowCollapseToOne
      eventData={eventData}
      value={value}
      model={I18NBlueprintLanguageModel.namespaces()}
      onChange={onChange}
      selectionProps={{ blueprint }} />
  )
}
