import React from 'react'
import { Button, Flex, Pane, Text } from '@leverege/ui-elements'

import ToolBar from '../action/Toolbar.jsx'

import S from './TitleBar.css'

const styleCache = { }

/**
 * Used to place layout ICON TITLE Toolbar ... Extra
 * 
 * <TitleBar title="Users" toolbarProps={tbp}/>
 * 
 * Is the same as
 * <TitleBar title="Users">
 *   <Toolbar {...tbp}/>
 * </TitleBar>
 * 
 * This does not need to be used with a Toolbar. Each child will be placed
 * in the controls section. 
 *
 * Props
 * icon : either a url or a font icon class name 
 * title : The string to display in the title location, or a custom Component 
 * onClick : If not null, supplying this will cause a Button with icon and title to be used. This will 
 *           be the on click, 
 * toolbarProps : If supplied, a Toolbar will be constructed as the first element in controls
 * variant : the base variant name. This is given to the outer Pane.
 * titleVariant : the variant given to the Text title. defaults to variant.
 * controlsVariant : the variant given to the Text title. defaults to <variant>Controls.
 * layoutVariant : the variant given to the Pane's layout. defaults to flex:<variant>.
 */
export default class TitleBar extends React.Component {

  getStyle( variant ) {
    const name = variant || 'title'
    if ( styleCache[name] ) {
      return styleCache[name] 
    }

    const arr = name.split( '|' )
    const style = { }
    styleCache[name] = style

    let lastIconGap = '8px'
    for ( let n = arr.length - 1; n >= 0; n-- ) {
      lastIconGap = `var( --size-${arr[n]}IconGap, ${lastIconGap} )`
    }
    style['--iconGap'] = lastIconGap
    return style
  }

  render( ) {
    const { 
      icon, title, onClick, children, toolbarProps,
      variant, titleVariant, controlsVariant, layoutVariant } = this.props
    const tVariant = titleVariant || variant
    const lVariant = layoutVariant || `flex:${variant}`
    const cVariant = controlsVariant || `${variant}Controls`
    let tView
    if ( title == null || typeof title === 'string' ) {
      if ( onClick ) {
        tView = <Button variant={tVariant} onClick={onClick} icon={icon}>{title}</Button>
      } else {
        tView = <Text variant={tVariant} icon={icon}>{title}</Text>
      }
    } else {
      tView = title
    }
    
    return (
      <Pane className={S.layout} layout={lVariant} variant={variant} style={this.getStyle( variant )}>
        {tView}
        <Flex variant={cVariant} className={S.controls}>
          {toolbarProps && <ToolBar {...toolbarProps}/>}
          {children}
        </Flex>
      </Pane>
    )
  }
}
