import React from 'react'
import { Content, Tree, Pane, Button } from '@leverege/ui-elements'
import { ValueCache } from '@leverege/value-cache'

import { ModelEditorFactory } from '../Factory.js'
import Constants from '../Constants.js'
import NodeDelegateFactory from './NodeDelegateFactory.js'

import S from './ModelTreeEditor.css'

function DeleteNode( props ) {
  const { onEvent, data } = props
  const onClick = () => {
    onEvent( { type : Constants.EVT_CHILD_REMOVE, node : data } )
  }
  return data?.canDelete ? <Button variant="link" onClick={onClick} icon="fa fa-trash-o" /> : null
}

/**
 * Creates an editor with a Tree and an Editor section. When an element
 * in the tree is selected, its editor will appear in the Editor section.
 */
export default class ModelTreeEditor extends React.PureComponent {
  
  static EVT_CHILD_CHANGE = Constants.EVT_CHILD_CHANGE
  static EVT_CHILD_REMOVE = Constants.EVT_CHILD_REMOVE

  static defaultProps = {
    paneVariant : 'collectionEditor',
    treeSectionVariant : 'collectionEditorTreeSection',
    treeHeaderVariant : 'collectionEditorTreeHeader',
    treeContentVariant : 'collectionEditorTreeContent',
    treeFooterVariant : 'collectionEditorTreeFooter',
    selectionSectionVariant : 'collectionEditorSelectionSection',
    selectionHeaderVariant : 'collectionEditorSelectionHeader',
    selectionContentVariant : 'collectionEditorSelectionContent',
    selectionFooterVariant : 'collectionEditorSelectionFooter',
    autoSelect : true,
    defaultSelected : [],
    nodeRenderer : null,
    iconRenderer : null,
    postTitleComponent : DeleteNode,
    preTitleComponent : null,
    rendererNameKey : 'name', // for default renderer
    rendererIsRemovable : true, // for default renderer,
    delegateFactory : NodeDelegateFactory,
  }

  constructor( props ) {
    super( props )
    const { autoSelect, defaultSelected } = props
    this.nodeProps = new ValueCache()
    this.state = { selected : autoSelect ? defaultSelected : [] }
  }

  onChildChange = ( e ) => {
    const { value, model, onChange, eventData } = this.props

    if ( onChange ) {
      // Replace it, creating a new model
      // args are modelValue, oldNodeValue, newNodeValue
      const nModel = model.set( value, e.value, e.node )

      // fire the event
      onChange( { value : nModel, data : eventData, oldValue : value } )
    }
  }

  onEvent = ( evt ) => {
    const { value, model, onChange, eventData } = this.props
    if ( evt.type === Constants.EVT_CHILD_REMOVE ) {
      if ( onChange ) {
        // If its a remove, remove the item from the tree and send it
        const nModel = model.remove( value, evt.node )
        onChange( { value : nModel, data : eventData, oldValue : value } )
      }
    } else if ( evt.type === Constants.EVT_CHILD_CHANGE ) {
      /**
       * Expects { data : old value, value: new value}
       */
      this.onChildChange( evt )
    }

  }

  render() {

    const {
      value,
      model,
      paneVariant,
      treeSectionVariant,
      selectionSectionVariant,
      allowCollapseToOne = false
    } = this.props

    const tree = model.getTree( value )

    const { selected } = this.state
    const selectedItem = selected

    const collapse = allowCollapseToOne && tree.length === 1
    
    return (
      <Content variant={paneVariant} horizontal>
        {!collapse && (
          <Content.Header variant={treeSectionVariant}>
            <div className={S.control}>
              { this.getTreeHeader( selectedItem ) }
              { this.getTreeContent( tree, selectedItem ) }
              { this.getTreeFooter( selectedItem ) }

            </div>
          </Content.Header>
        )}
        <Content.Area variant={selectionSectionVariant}>
          <Content>
            { this.getSelectionHeader( selectedItem ) }
            { this.getSelectionContent( selectedItem ) }
            { this.getSelectionFooter( selectedItem ) }
          </Content>
        </Content.Area>
      </Content>
    )
  }

  getTreeHeader( item ) {
    const { treeHeader, treeHeaderVariant } = this.props
    if ( treeHeader ) {
      const hdr = treeHeader( item )
      if ( hdr ) {
        return <Pane variant={treeHeaderVariant} className={S.header}>{hdr}</Pane>
      }
    }
    return null
  }

  onSelect = ( evt ) => {
    const { node } = evt
    const { onSelect } = this.props
    const selected = node
    if ( onSelect ) {
      onSelect( evt )
    }
    this.setState( { selected } )
  }

  getNodeProps( pNodeProps ) {
    return pNodeProps
  }

  getTreeContent( value, selected ) {
    const { 
      nodeRenderer, iconRenderer, treeContentVariant, nodeProps,
      switcherIcon, preTitleComponent, postTitleComponent, treeProps,
      delegateFactory, model
    } = this.props
    
    const extra = {}
    if ( iconRenderer ) {
      extra.iconRenderer = iconRenderer
    }
    if ( nodeRenderer ) {
      extra.nodeRenderer = nodeRenderer
    }
    if ( switcherIcon ) {
      extra.switcherIcon = switcherIcon
    }
    return (
      <Pane variant={treeContentVariant} className={S.treePane}>
        <Tree
          selectedKeys={[ selected?.key ]}
          data={model.getTree( value )}
          delegateFactory={delegateFactory}
          onSelect={this.onSelect}
          onEvent={this.onEvent}
          preTitleComponent={preTitleComponent}
          postTitleComponent={postTitleComponent}
          nodeProps={this.nodeProps.calc( this.getNodeProps, nodeProps )}
          {...treeProps}
          {...extra} />
      </Pane>
    )
  }

  getTreeFooter( item ) {
    const { treeFooter, treeFooterVariant } = this.props
    if ( treeFooter ) {
      const ftr = treeFooter( item )
      if ( ftr ) {
        return <Pane variant={treeFooterVariant} className={S.footer}>{ftr}</Pane>
      }
    }
    return null
  }

  /**
   * Returns the header for the selection section
   */
  getSelectionHeader( item ) {
    const { selectionHeaderVariant, selectionHeader } = this.props
    if ( selectionHeader ) {
      const hdr = selectionHeader( item )
      if ( hdr ) {
        return <Content.Header variant={selectionHeaderVariant}>{hdr}</Content.Header> 
      }
    }
    return null
  }

  /**
   * Returns the selection area for the editor section
   */
  getSelectionContent( node ) {
    const { 
      selectionContent, selectionContentVariant, selectedProps,
      modelEditorFactory = ModelEditorFactory } = this.props

    let editor = null
    if ( selectionContent ) {
      editor = selectionContent( node.data, selectedProps )
    } else {
      const eProps = { 
        ...selectedProps, 
        onChange : this.onChildChange, 
        eventData : node.data
      }
      editor = modelEditorFactory.create( node.data, eProps )

    }
    return (
      <Content.Area variant={selectionContentVariant}>
        {editor}
      </Content.Area>
    )
  }

  /**
   * Returns the footer for the selection section
   */
  getSelectionFooter( item ) {
    const { selectionFooterVariant, selectionFooter } = this.props
    if ( selectionFooter ) {
      const hdr = selectionFooter( item )
      if ( hdr ) {
        return <Content.Footer variant={selectionFooterVariant}>{hdr}</Content.Footer> 
      }
    }
    return null
  }
}
