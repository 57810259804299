import { ObjectizerModel } from '@leverege/condition'
import { ns } from '@leverege/i18n'

const { t, tIcon } = ns( 'condition.DuplicateRuleAction' )

export default {
  id : 'condition.objectizer.DuplicateRuleAction', 
  matches : {
    client : 'condition.ObjectizerEditor',
    use : 'ruleActionBar'
  },
  layout : {
    group : [ 'more' ],
    groupIcons : [ 'fa fa-ellipsis-v fa-fw' ],
    sort : 'duplicate'
  },

  appearance( context ) {
    return { name : t( 'name' ), icon : tIcon( 'icon', 'fa fa-clone fa-fw' ) }
  },

  handles( context ) { return context.onObjectizerChange != null },
  perform( { context } ) {
    const { objectizerModel, value : rule, onObjectizerChange : onChange, onObjectizerEventData : data } = context
    const value = ObjectizerModel.addRule( objectizerModel, { ...rule } )
    onChange( { data, value } )
  }
}
