import JitterUpdaterCreator from './JitterUpdaterCreator' 
import FunctionUpdaterCreator from './FunctionUpdaterCreator' 
import RandomNumberUpdaterCreator from './RandomNumberUpdaterCreator' 
import RandomBooleanUpdaterCreator from './RandomBooleanUpdaterCreator' 
import ConstantUpdaterCreator from './ConstantUpdaterCreator' 

const TYPES = [ JitterUpdaterCreator, RandomNumberUpdaterCreator, 
  RandomBooleanUpdaterCreator, FunctionUpdaterCreator ]// , 
// ConstantUpdaterCreator ]

function getCreators() {
  return [].concat( TYPES );
}

function getCreator( updaterType ) {
  if ( updaterType == null ) {
    return null;
  }
  for ( let n = 0; n < TYPES.length; n++ ) {
    if ( TYPES[n].typeName() === updaterType ) {
      return TYPES[n];
    }
  }
  return null;
}

/**
 * Returns an array of updater creators that support the given attribute object.
 **/
function getCreatorsForAttribute( attr ) {

  if ( attr == null ) {
    return [];
  }

  const rtn = [];
  for ( let n = 0; n < TYPES.length; n++ ) {
    if ( TYPES[n].handles( attr ) ) {
      rtn.push( TYPES[n] );
    }
  }
  return rtn;
}

export default {
  getCreator,
  getCreators,
  getCreatorsForAttribute,
  JitterUpdaterCreator,
  RandomNumberUpdaterCreator,
  RandomBooleanUpdaterCreator,
  FunctionUpdaterCreator,
  ConstantUpdaterCreator
}
