function sortAttributesByPath( attrs ) {
  const sort = [].concat( attrs );
  sort.sort( ( a, b ) => {
    if ( a.path < b.path ) { return -1; }
    if ( a.path > b.path ) { return 1; }
    return 0;
  } );
  return sort
}

export default sortAttributesByPath
