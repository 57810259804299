// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.form_DE49T {
  min-width: 400px;
  position : relative;
  height : 100%;
}

.content_KsgrH {
  height : 100%;
}

.area_O2Vbc {
  NOpadding : 0 var( --size-padLarge, 16px );
}

.spacer_NuXHt {
  padding-top : var( --size-padLarge, 16px );
}

.footer_Yv40D {
  padding : var( --size-padLarge, 16px );
  display : grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: var( --size-padMedium, 8px );
}
.delete_PUtnD {
  justify-self: start;
}

.okayCancel_Q5Aae {
  justify-self: end;
  display : grid;
  justify-content: center;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;
  column-gap: var( --size-padMedium, 8px );

}
`, "",{"version":3,"sources":["webpack://./src/editor/OkayCancelEditor.css"],"names":[],"mappings":";;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,sCAAsC;EACtC,cAAc;EACd,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;EAC9B,wCAAwC;AAC1C;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,uBAAuB;EACvB,oBAAoB;EACpB,8BAA8B;EAC9B,wCAAwC;;AAE1C","sourcesContent":["\n\n.form {\n  min-width: 400px;\n  position : relative;\n  height : 100%;\n}\n\n.content {\n  height : 100%;\n}\n\n.area {\n  NOpadding : 0 var( --size-padLarge, 16px );\n}\n\n.spacer {\n  padding-top : var( --size-padLarge, 16px );\n}\n\n.footer {\n  padding : var( --size-padLarge, 16px );\n  display : grid;\n  justify-content: center;\n  align-items: center;\n  grid-template-columns: 1fr 1fr;\n  column-gap: var( --size-padMedium, 8px );\n}\n.delete {\n  justify-self: start;\n}\n\n.okayCancel {\n  justify-self: end;\n  display : grid;\n  justify-content: center;\n  align-items: stretch;\n  grid-template-columns: 1fr 1fr;\n  column-gap: var( --size-padMedium, 8px );\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form_DE49T`,
	"content": `content_KsgrH`,
	"area": `area_O2Vbc`,
	"spacer": `spacer_NuXHt`,
	"footer": `footer_Yv40D`,
	"delete": `delete_PUtnD`,
	"okayCancel": `okayCancel_Q5Aae`
};
export default ___CSS_LOADER_EXPORT___;
