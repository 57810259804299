export default {
  
  name() {
    return 'Function'
  },

  typeName() {
    return 'function' 
  },

  handles( attributeType ) {
    return attributeType.categoryType() === 'float'
  },

  create( path, name ) { 
    return { 
      type : this.typeName(), 
      update : 30000,
      function : 'sin(t)'
    }
  }
}
