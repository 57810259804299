import React, { useContext } from 'react'

const Context = React.createContext( {} )

/**
 * The ShareContext is a ReactContext that lets you contribute
 * name values to the context. If the prop 'inherit' is supplied
 * as false, the context will be cleared when passing to the children.
 */
export default class SharedContext extends React.Component {

  static contextType = Context

  constructor( props ) {
    super( props )
    this.cached = {}
  }

  getSharedContext( props ) {
    const inherit = props.inherit !== false
    const cached = this.cached
    // const props = this.props
    const global = this.context
  
    // merge Props and context.
    const nCxt = inherit ? { ...global, ...props } : { ...props }
    delete nCxt.children

    const cKeys = Object.keys( cached )
    const nKeys = Object.keys( nCxt )
    if ( nKeys.length !== cKeys.length ) {
      this.cached = nCxt
      return nCxt
    }

    for ( let n = 0; n < cKeys.length; n++ ) {
      const key = cKeys[n]
      if ( cached[key] !== nCxt[key] ) {
        this.cached = nCxt
        return nCxt
      }
    }
    return cached
  }

  shouldComponentUpdate( nextProps, nextState ) {
    const { children } = this.props
    const { children : nextChildren } = nextProps

    if ( children !== nextChildren ) {
      return true
    }

    const curr = this.cached
    const shared = this.getSharedContext( nextProps )

    return curr !== shared
  }

  render() {
    const { children } = this.props
    const shared = this.getSharedContext( this.props )

    return (
      <Context.Provider value={shared} >
        {children}
      </Context.Provider>
    )
  }
}

const useSharedContext = ( key ) => {
  const cxt = useContext( Context ) 
  return key == null ? cxt : cxt?.[key]
}

SharedContext.Type = Context
SharedContext.Provider = Context.Provider
SharedContext.Consumer = Context.Consumer

export { useSharedContext }
