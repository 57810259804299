const DEVICE = 'device'
const USER = 'user'
const CALCULATED = 'calculated'

function displayName( type ) {
  // i18n
  switch ( type ) {
    case DEVICE: return 'Device'
    case USER: return 'User'
    case CALCULATED: return 'Calculated'
    default: return 'Unknown'
  } 
}

function values() {
  return [ 
    { value : DEVICE, name : displayName( DEVICE ) },
    { value : USER, name : displayName( USER ) },
    { value : CALCULATED, name : displayName( CALCULATED ) },
  ]
}

export default {
  DEVICE,
  USER,
  CALCULATED,

  values,
  displayName
}
