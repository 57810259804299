import React, { useMemo } from 'react'
import TranslationsEditor from './TranslationsEditor.jsx'

const getStandardTranslations = ( source ) => {
  const lcName = source?.name || 'Items'
  if ( source?.type === 'relationship' ) {
    if ( source?.relationshipType === 'oneToMany' || source?.relationshipType === 'manyToMany' ) {
      return [ {
        value : lcName,
        label : 'Name (Plural)',
        eventData : 'name'
      }, {
        value : `{{count}} ${lcName}`,
        label : 'Name with Count (Singular)',
        eventData : 'nameWithCount_one'
      }, {
        value : `{{count}} ${lcName}`,
        label : 'Name With Count',
        eventData : 'nameWithCount_other'
      } ]
    }
  }

  const standardTranslations = [ {
    value : lcName,
    label : 'Name (Singular)',
    eventData : 'name_one'
  }, {
    value : `${lcName}s`,
    label : 'Name (Plural)',
    eventData : 'name_other'
  }, {
    value : `{{count}} ${lcName}`,
    label : 'Name with Count (Singular)',
    eventData : 'nameWithCount_one'
  }, {
    value : `{{count}} ${lcName}s`,
    label : 'Name With Count (Plural)',
    eventData : 'nameWithCount_other'
  } ]

  if ( source?.type === 'boolean' ) {
    const booleanTranslations = [ {
      value : 'True',
      label : 'True Value',
      eventData : 'trueValue'
    }, {
      value : 'False',
      label : 'False Value',
      eventData : 'falseValue'
    }, {
      value : '-',
      label : 'Unset Value',
      eventData : 'unsetValue'
    } ]

    return [ ...standardTranslations, ...booleanTranslations ]
  }

  return standardTranslations
}

export default function AttributeTranslationsEditor( props ) {
  const { blueprint, value, ...rest } = props

  const attribute = useMemo( () => {
    return blueprint?.data?.attributes?.find( a => a.id === value.id )
  }, [ blueprint, value ] )

  return (
    <TranslationsEditor
      source={attribute}
      value={value}
      getStandardTranslations={getStandardTranslations}
      {...rest} />
  )
}
