import React from 'react'
import { connect } from 'react-redux'
import { Button, Content, Dialogs, Pane, TabPane } from '@leverege/ui-elements'
import StringUtil from '@leverege/string-util'
import { ValueCache } from '@leverege/value-cache'
import { ModelEditorFactory } from '@leverege/ui-plugin'

import I18NSystemModel from '../models/I18NSystemModel.js'
import AddUIDialog from '../views/AddUIDialog.jsx'

import S from './BlueprintTranslationScreen.css'

class SystemTranslationScreen extends React.Component {

  componentDidMount() {
    const { delegate, dispatch } = this.props
    dispatch(
      delegate.resource( 'i18n' ).get( { children : true } )
    )
  }

  onChange = ( { data, value } ) => {
    const { dispatch, delegate } = this.props

    let action

    if ( data.id === 'new' ) {
      action = delegate.resource( 'i18n' ).create( value )
    } else {
      // action = delegate.resource( data.path ).update( [ { path : 'data', value, op : 'replace' } ] )
      // Dispatching two api-redux actions causes UI to flutter
      action = async ( dispatch, getState, { api } ) => {
        await api
          .system( delegate.id )
          .resource( data.path )
          .update( [ { path : 'data', value, op : 'replace' } ] )
      }
    }

    dispatch( action )
      .then( () => dispatch( delegate.resource( 'i18n' ).get( { children : true } ) ) )
  }

  onAddUI = ( name ) => {
    const { dispatch, delegate } = this.props

    const uiModel = I18NSystemModel.create( )

    dispatch( delegate.resource( `i18n/${name}` ).create( uiModel ) )
      .then( () => dispatch( delegate.resource( 'i18n' ).get( { children : true } ) ) )
  }

  onOpenAddUI = () => {
    const { resources } = this.props
    Dialogs.show( {
      component : AddUIDialog,
      props : {
        resources,
        onSubmit : this.onAddUI
      }
    } )
  }

  render() {
    const { resources } = this.props
    
    return (
      <Content>
        <Content.Header>
          <Pane layout="flex:rowMEnd">
            <Button variant="tertiary" onClick={this.onOpenAddUI} icon="fa fa-fw fa-plus">Add UI</Button>
          </Pane>
        </Content.Header>
        <Content.Area>
          <TabPane
            className={S.editor}>
            {resources
              .sort( ( rA ) => {
                return rA.path === 'i18n' ? -1 : 1
              } )
              .map( ( rsrc ) => {
                const pathParts = rsrc.path.split( '/' )
        
                const isDefault = pathParts.length === 1 && pathParts[0] === 'i18n'
                const name = isDefault ? 'UI Defaults' : StringUtil.camelCaseToTitleCase( pathParts[1] )

                return (
                  <TabPane.Tab
                    key={rsrc.id}
                    tabId={rsrc.id}
                    tabName={name}>
                    {ModelEditorFactory.create( rsrc.data, {
                      eventData : rsrc,
                      onChange : this.onChange
                    } )}
                  </TabPane.Tab>
                )
              } )}
          </TabPane>
        </Content.Area>
      </Content>
    )
  }
}

const EMPTY_ARRAY = []
const rsrcCache = new ValueCache()
function calcResources( resources ) {
  const hasDefault = resources.some( ( rsrc ) => {
    return rsrc.path === 'i18n'
  } )

  if ( !hasDefault ) {
    return [
      { id : 'new', path : 'i18n', data : I18NSystemModel.create() },
      ...resources
    ]
  }

  return resources
}

export default connect( ( state, ownProps ) => {
  const { delegate } = ownProps

  const resources = delegate.resource( 'i18n' ).getRsrc( state )?.items || EMPTY_ARRAY
  
  return {
    resources : rsrcCache.calc( calcResources, resources ),
  }
} )( SystemTranslationScreen )
