import ModelUtil from '@leverege/model-util'
import { unflatten } from '@leverege/object-util'

import I18nAttributeModel from './I18NAttributeModel.js'

const TYPE = 'imagine.I18NNamespaceModel'

function create( extend ) {
  return {
    type : TYPE,
    translations : {
      
    },
    attributes : {

    },
    ...extend
  }
}

const exp = {
  TYPE,
  type : TYPE,
  create,
  ...ModelUtil.createAllMap( 'translations' ),
  ...ModelUtil.createAllMap( 'attributes' ),
  getTranslationData( model ) {
    const ret = {
      ...unflatten( exp.getTranslations( model ), '.' ),
    }

    if ( exp.getAttributeEntries( model ).length > 0 ) {
      ret.attributes = exp
        .getAttributeEntries( model )
        .reduce( ( prev, [ key, value ] ) => {
          return {
            ...prev,
            [key] : I18nAttributeModel.getTranslationData( value )
          }
        }, {} )
    }

    return ret
  }
}

export default exp
