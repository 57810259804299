import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util' 

const { isPathValid, isPathCollection } = Util

const TYPE = 'relationship';
const RELATIONSHIP_TYPES = [ 'oneToOne', 'oneToMany', 'manyToMany', 'manyToOne' ]
const RELATIONSHIP_TYPES_LOOKUP = {
  ONE_TO_ONE : 'oneToOne',
  ONE_TO_MANY : 'oneToMany',
  MANY_TO_ONE : 'manyToOne',
  MANY_TO_MANY : 'manyToMany'
}

export default {

  RELATIONSHIP_TYPES,
  RELATIONSHIP_TYPES_LOOKUP,
  
  name() {
    return 'Relationship'
  },

  typeName() {
    return TYPE
  },

  categoryType() {
    return 'relationship'
  },

  create( path, name ) { 
    if ( !isPathValid( path ) || isPathCollection( path ) || path.split( '/' ).length > 1 ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }
    return { 
      type : this.typeName(), 
      path : path || 'relationship',
      isCollection : false,
      name : name || path, 
      desc : null,
      tags : [],
      targetBlueprintId : null, // the model id
      relationshipType : 'oneToMany',
      owned : false // If true, deleting the owner would delete all 'children'
      // isDevice : true || false
    }
  },

  createFrom( attr ) {
    if ( attr.type === this.typeName() ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );

    return convert;
  },

  relationshipTypeIsOneTo( relType ) {
    return relType === RELATIONSHIP_TYPES_LOOKUP.ONE_TO_ONE || relType === RELATIONSHIP_TYPES_LOOKUP.ONE_TO_MANY
  },

  isOneTo( attr ) {
    return attr.type === TYPE && this.relationshipTypeIsOneTo( attr.relationshipType )
  },
  
  /**
   * determines if an attribute meets the requirements to forward data
   * @param {Object} attr the attribute to check
   * @returns {boolean} if the attribute can forward data
   */
  canForwardData( attr ) {
    return attr.type === TYPE && attr.relationshipType === RELATIONSHIP_TYPES_LOOKUP.ONE_TO_ONE && attr.forwardData
  },

  /**
   * determines if an attribute meets the requirements to write parent id's to a child
   * @param {Object} attr the attribute to check
   * @returns {boolean} if the attribute can write parent id's
   */
  canSetParentIds( attr ) {
    return attr.type === TYPE && this.isOneTo( attr )
  },

  /**
   * determines if an attribute relationship type meets the requirements to set parent ids
   * @param {string} relType the relationship type to check
   * @returns {boolean} if the attribute relationship type can forward data
   */
  relationshipTypeCanSetParentIds( relType ) {
    return this.relationshipTypeIsOneTo( relType )
  }
}
