// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_ldxhJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editorContainer_gABs7 {
  display: flex;
}

.editorContainer_gABs7 > *:not(:last-child) {
  margin-right: 8px;
}

.actionButtons_OzR4Q {
  margin-left: auto;
  margin-right: var( --size-padMedium, 8px );
}`, "",{"version":3,"sources":["webpack://./src/GeoPointEditor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,0CAA0C;AAC5C","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.editorContainer {\n  display: flex;\n}\n\n.editorContainer > *:not(:last-child) {\n  margin-right: 8px;\n}\n\n.actionButtons {\n  margin-left: auto;\n  margin-right: var( --size-padMedium, 8px );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_ldxhJ`,
	"editorContainer": `editorContainer_gABs7`,
	"actionButtons": `actionButtons_OzR4Q`
};
export default ___CSS_LOADER_EXPORT___;
