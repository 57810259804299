/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'

import I18N from '@leverege/i18n'
import { 
  Content, Button, Pane, Text, 
  TextInput, NumericInput, Flex, 
  Label, Popup, Checkbox } from '@leverege/ui-elements'
import { useInstanceCallback, useValueChange } from '@leverege/ui-hooks'
import { R } from '@leverege/value-resolver'

import Card from '../model/Card'
import CardTitle from '../shared/CardTitle'
import VariantCreator from '../shared/VariantCreator'
import { Selector, useDashboardContext } from '../shared'
import CardGrid from './CardGrid'
import CardSelector from './CardSelector'
import { useCardDrag, useCardDrop } from './CardUtil'
import ToolbarContext from '../shared/ToolbarContext'

const { t } = I18N.ns( 'uiDashboard.editor.CardEditor' )

export default function CardEditor( props ) {
  const { value, onDropCard, eventData, onRemoveCard, children, sharedProps } = props
  const [ { isDragging }, drag, preview ] = useCardDrag( { value, onRemoveCard } )
  const [ collectedProps, drop ] = useCardDrop( { value, onDropCard, index : eventData, isDragging } )
  const dashboardContext = useDashboardContext()
  const { editors, viewers, models, columns : maxColumns, deferEditing } = dashboardContext
  const onModelChange = useValueChange( Card, props )
  const [ onAdd ] = useInstanceCallback( [ CardGrid.addCard, CardGrid.removeCard ],
    {
      ...props,
      value : value.content,
      onChange : onModelChange,
      eventData : 'setContent',
      sharedProps,
      dashboardContext
    }
  )
  const [ toolbarRef, setToolbarRef ] = useState( null )
  const vc = new VariantCreator( 'CardEditor', value.style )
  
  const isDropTarget = collectedProps.isOver && collectedProps.canDrop
  const isPossibleDropTarget = !collectedProps.isOver && collectedProps.canDrop
  // Hide card if we are dragging so we dont drop onto ourself. This is not currently
  // detectable and will break the model structure 
  const style = {
    gridColumnEnd : `span ${Math.min( maxColumns, value.width || 1 )}`,
    gridRowEnd : `span ${value.height || 1}`,
    visibility : isDragging ? 'hidden' : 'visible'
  }

  const variant = isDropTarget ? `${vc.get( 'Target' )}|${vc.get()}` : vc.get()
  const hVariant = isDropTarget ? 
    ( `${vc.get( 'HeaderTarget' )}|${vc.get( 'Header' )}` ) :
    ( isPossibleDropTarget ? `${vc.get( 'HeaderHighlight' )}|${vc.get( 'Header' )}` : vc.get( 'Header' ) )

  const eProps = { value : value.content, onChange : onModelChange, onAdd, eventData : 'setContent', sharedProps }
  const content = editors.create( value.content, eProps )
  const viewer = viewers.create( value.content, { 
    value : value.content, 
    sharedProps,
    onChange : onModelChange, 
    eventData : 'setContent' 
  } )
  const plugin = editors.get( value.content )
  let typeName = plugin ? plugin.getCardTitle || plugin.name || plugin.NAME || plugin.TYPE : null
  let typeIcon = plugin ? plugin.getCardIcon || plugin.icon || plugin.ICON : 'fa fa-arrows'

  typeName = R( typeName, value.content, dashboardContext )
  typeIcon = R( typeIcon, value.content, dashboardContext )

  if ( !plugin ) {
    return null
  }
 
  return (
    <div ref={drop} style={style}>
      <Pane variant={variant} domRef={preview}>
        <Content relative>
          <Content.Header variant={hVariant}>
            <CardTitle
              titleVisible={Card.isTitleVisible( value )}
              title={Card.getTitle( value )}
              icon={Card.getIcon( value )}
              typeName={typeName} 
              typeIcon={typeIcon}
              drag={drag} 
              variant={vc.get( 'Title' )}>
              <Flex justify="space-between" style={{ flexGrow : 1 }}>
                <Flex>
                  <Popup variant={vc.get( 'Popup' )} title={<i className="fa fa-gear"/>}>
                    <Flex variant="colL">
                      <LayoutEditor value={value} onChange={onModelChange} maxColumns={maxColumns}/>
                      { plugin.menuEditor && <Popup.Divider/> }
                      { plugin.menuEditor && <plugin.menuEditor {...eProps}/> }
                    </Flex>
                  </Popup>
                  {plugin.isContainer && <CardSelector models={models} onSelect={onAdd} />}
                </Flex>
                <Flex align="center">
                  <div ref={ref => setToolbarRef( ref )} />
                  { value.deletable !== false && (
                    <Button variant="link" icon="fa fa-times" eventData={value} onClick={onRemoveCard} />
                  ) }
                </Flex>
              </Flex>
            </CardTitle>
          </Content.Header>
          <Content.Area variant={vc.get( 'Area' )}>
            <ToolbarContext.Provider value={toolbarRef}>
              <Flex variant="colM" align="stretch">
                {children}
                {( deferEditing && !plugin.isContainer ) ? viewer : content}
              </Flex>
            </ToolbarContext.Provider>
          </Content.Area>
          { plugin.footerEditor && (
            <Content.Footer variant={vc.get( 'Footer' )}>
              <plugin.footerEditor {...eProps} />
            </Content.Footer>
          ) }
        </Content>
      </Pane>
    </div>
  )
}

function LayoutEditor( { value, maxColumns, onChange } ) {
  const { variants } = useDashboardContext() // we could pass variant through this
  return (
    <Pane variant="padding">
      <Flex variant="colM" align="stretch">
        <Text variant="title">{t( 'cardOptions' )}</Text>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'title' )}</Label>
          <TextInput 
            variant="dashboardCardEditor|small" 
            value={value.title || ''} 
            size={20}
            eventData="setTitle" 
            onChange={onChange}/>
        </Flex>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'icon' )}</Label>
          <TextInput 
            variant="dashboardCardEditor|small" 
            value={value.icon || ''} 
            size={20}
            eventData="setIcon" 
            onChange={onChange}/>
        </Flex>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'showTitle' )}</Label>
          <Checkbox 
            variant="dashboardCardEditor|small" 
            value={Card.isTitleVisible( value )} 
            eventData="setTitleVisible" 
            onChange={onChange}/>
        </Flex>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'size' )}</Label>
          <Flex variant="rowM" align="center" justify="flex-end">
            <Label variant="dashboardCardEditor|small">{t( 'width' )}</Label>
            <NumericInput 
              variant="dashboardCardEditor|small" 
              size={2} 
              value={value.width || 1} 
              eventData="setWidth" 
              min={1} 
              max={maxColumns} 
              title={`Width can be 1 - ${maxColumns}`}
              onChange={onChange}/>
            <Label variant="dashboardCardEditor|small">{t( 'height' )}</Label>
            <NumericInput variant="dashboardCardEditor|small" 
              size={2} 
              value={value.height || 1} 
              eventData="setHeight" 
              min={1} 
              onChange={onChange}/>
          </Flex>
        </Flex>
        { variants != null && (
          <Flex variant="rowM" align="center" justify="space-between">
            <Label variant="dashboardCardEditor|small">{t( 'style' )}</Label>
            <Selector 
              variant="dashboardCardEditor|small" 
              value={Card.getStyle( value )}
              valueField="value"
              textField="name"
              values={variants} 
              eventData="setStyle" 
              onChange={onChange}/>
          </Flex> 
        ) }
      </Flex>
    </Pane>
  )
}
