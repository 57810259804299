export default class StringShortener {

  static shorten( str, len = 2 ) {
    if ( str == null || typeof str !== 'string' || str.length <= len ) { 
      return str 
    }

    const arr = str.split( ' ' )
    if ( arr.length === 1 ) {
      return arr[0].substring( 0, len )
    }
    if ( arr.length <= len ) {
      return arr.map( s => s[0] ).join( '' )
    }
    return arr.slice( 0, len ).map( s => s[0] ).join( '' )
  }
}