import React, { useState, useCallback, useMemo } from 'react'
import { Button, CompositeTextInput, Flex, Pane, Popup, PropertyGrid, TextInput } from '@leverege/ui-elements'

import S from './TranslationsEditor.css'

const defaultGetStandardTranslations = () => []

function TranslationPicker( props ) {
  const { onAdd, translations, source, getStandardTranslations = defaultGetStandardTranslations } = props
  const [ customKey, setCustomKey ] = useState()
  const [ opened, setOpened ] = useState()
  const [ isInError, setIsInError ] = useState()
  const options = useMemo( () => {
    return getStandardTranslations( source ).reduce( ( prev, curr ) => {
      if ( !translations[curr.eventData] ) {
        prev.push(
          <Popup.Item
            key={curr.eventData}
            value={curr.value}
            eventData={curr.eventData}
            onClick={onAdd}>
            {curr.label}
          </Popup.Item>
        )
      }

      return prev
    }, [] )
  }, [ translations, onAdd, source ] )
  const onKeyDown = useCallback( ( evt ) => {
    if ( evt.key === 'Enter' ) {
      if ( !isInError ) {
        onAdd( {
          data : customKey
        } )
        setOpened( false )
      }
    }
  }, [ onAdd, customKey, setOpened, isInError ] )
  const onPopupChange = useCallback( ( { isOpened } ) => {
    setOpened( isOpened )
    setCustomKey( '' )
  }, [ setOpened, setCustomKey ] )
  const validator = useCallback( ( value ) => {
    if ( translations[value] != null ) {
      setIsInError( true )
      return false
    }

    setIsInError( false )
    return true
  }, [ translations, setIsInError ] )

  return (
    <Popup
      onChange={onPopupChange}
      opened={opened}
      title="Add Translation"
      icon="fa fa-fw fa-plus">
      {options.length > 0 && <Popup.Header>Standard</Popup.Header>}
      {options.length > 0 && options}
      {options.length > 0 && <Popup.Divider />}
      <Popup.Menu title="Custom">
        <Popup.Item close={false}>
          <CompositeTextInput
            autoFocus
            validator={validator}
            value={customKey}
            variant="button"
            style={{ pointerEvents : 'all', width : 'auto' }}
            button="Add"
            onChange={( { value } ) => { setCustomKey( value ) }}
            onKeyDown={onKeyDown}
            changeOnReturn={false}
            alwaysFireOnReturn={false}
            buttonProps={{
              disabled : customKey == null || customKey.length === 0 || isInError,
              style : { pointerEvents : 'all' },
              eventData : customKey,
              onClick : onAdd
            }} />
        </Popup.Item>
      </Popup.Menu>
    </Popup>
  )
}

export default function TranslationsEditor( props ) {
  const { value, model, onChange, eventData, source, getStandardTranslations } = props
  const translations = model.entries( value )
  const onAddTranslation = useCallback( ( { data, value : v } ) => {
    const nModel = model.set( value, data, v || '' )

    onChange( {
      value : nModel,
      data : eventData
    } )
  }, [ onChange, model, value, eventData ] )
  const onRemoveTranslation = useCallback( ( { data } ) => {
    const nModel = model.remove( value, data )

    onChange( {
      value : nModel,
      data : eventData
    } )
  }, [ model, value, eventData, onChange ] )

  return (
    <Pane variant="editor">
      <PropertyGrid>
        {translations.map( ( [ key, v ] ) => {
          return (
            <PropertyGrid.Item
              key={key}
              label={key}>
              <Flex variant="rowM">
                <TextInput
                  onChange={onAddTranslation}
                  eventData={key}
                  className={S.input}
                  value={v} />
                <Button
                  eventData={key}
                  onClick={onRemoveTranslation}
                  variant="tertiary"
                  icon="fa fa-fw fa-trash" />
              </Flex>
            </PropertyGrid.Item>
          )
        } )}
        <PropertyGrid.Item
          label={(
            <TranslationPicker
              getStandardTranslations={getStandardTranslations}
              source={source}
              onAdd={onAddTranslation}
              translations={model.getAll( value )} />
          )}
          newRow />
      </PropertyGrid>
    </Pane>
  )
}
