/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Flex, TextInput, PropertyGrid } from '@leverege/ui-elements'
import { useInstanceCallback, useValueChange } from '@leverege/ui-hooks'
import { ObjectizerModel as Model, RuleModel, LogicCondition, PropertyCondition } from '@leverege/condition'
import { DnDUtil, SharedContext, useSharedContext } from '@leverege/ui-plugin'
import { ns } from '@leverege/i18n'

import ConditionSelector from '../selector/ConditionSelector'
import RuleRow from './RuleRow'
import S from './ObjectizerEditor.css'

const { t } = ns( 'condition.ObjectizerEditor' )
const CREATORS = [
  { get name() { return t( 'orGrp' ) }, layout : { sort : 'b' }, create : () => { return LogicCondition.create( { operator : 'or' } ) } },
  { get name() { return t( 'orGrp' ) }, layout : { sort : 'a' }, create : () => { return LogicCondition.create( { operator : 'and' } ) } },
  { get name() { return t( 'temp' ) }, layout : { get sectionName() { return t( 'properties' ) }, sort : 'p.b' }, create : () => { return PropertyCondition.create( { path : 'temp' } ) } },
  { get name() { return t( 'name' ) }, layout : { get sectionName() { return t( 'properties' ) }, sort : 'p.a' }, create : () => { return PropertyCondition.create( { path : 'name' } ) } },

]
const { dropItem, removeItem, updateChildItem } = DnDUtil

const onAddRule = ( { value, createValue, eventData, onChange }, e ) => { 
  if ( e.value?.create ) {
    const rule = RuleModel.create( { condition : e.value.create(), value : createValue?.() || null } )
    const nModel = Model.addRule( value, rule )
    onChange( { data : eventData, value : nModel } )
  }
}

export default function ObjectizerEditor( props ) {
  const { 
    value, valueEditor : ValueEditor = TextInput, 
    conditionLabel, valueLabel, defaultValueLabel,
    eventData, onChange : onObjectizerChange,
    valueProps } = props
  const { conditionCreators = CREATORS } = useSharedContext()
  const onChange = useValueChange( Model, props )
  const rules = Model.getRules( value ) || [ ]
  const onAdd = useInstanceCallback( onAddRule, props )
  
  const [ onDrop, onRemove, onChildChange ] = useInstanceCallback( 
    [ dropItem, removeItem, updateChildItem ], 
    Model.rules(), 
    props ) 

  return (
    <SharedContext conditionCreators={conditionCreators}>
      <Flex variant="colM" className={S.logical}>
        { ( rules.length > 0 && (
          <div className={S.rules}>
            <div className={S.ruleHolder}>
              { rules.length > 0 &&
              rules.map( ( rule, idx ) => {
                return (
                  <RuleRow 
                    key={rule.type + idx}
                    valueLabel={valueLabel || t( 'valueLabel' )}
                    conditionLabel={conditionLabel || t( 'conditionLabel' )}
                    objectizerModel={value} 
                    onObjectizerChange={onObjectizerChange}
                    onObjectizerEventData={eventData}
                    valueEditor={ValueEditor}
                    valueProps={valueProps}
                    value={rule} 
                    index={idx}
                    eventData={rule}
                    onDrop={onDrop}
                    onRemove={onRemove}
                    onChange={onChildChange}/>
                )
              } )}
            </div>
          </div>
        ) ) }
        <PropertyGrid>
          <PropertyGrid.Item label={defaultValueLabel || t( 'defaultValueLabel' )}>
            <div className={S.defaultRow}>
              <ValueEditor 
                value={Model.getDefaultValue( value )} 
                eventData="setDefaultValue"
                onChange={onChange} />
              <ConditionSelector 
                className={S.addRule}
                onSelect={onAdd} 
                icon="fa fa-plus-circle fa-fw" 
                title={t( 'title' )}/>
            </div>
          </PropertyGrid.Item>
        </PropertyGrid>   
      </Flex>
    </SharedContext>
  )
}
