import latLonGeohash from 'latlon-geohash'

function degToFloat( str ) {
  if ( typeof str === 'number' ) {
    return str
  }
  if ( typeof str !== 'string' ) {
    return null
  }
  const pieces = str.split( /[:]/ )
  const deg = Number( pieces[0] )
  const min = pieces[1] ? Number( pieces[1] ) : 0
  const sec = pieces[2] ? Number( pieces[2] ) : 0
  if ( deg < 0 ) {
    return deg - ( min / 60 ) - ( sec / 3600 )
  }
  return deg + ( min / 60 ) + ( sec / 3600 )
}

function objToHash( value ) {
  if ( !value?.lat && !value?.lon ) {
    return null
  }
  const val = {}
  if ( typeof value.lat === 'string' ) {
    val.lat = degToFloat( val.lat )
  }
  if ( typeof val.lon === 'string' ) {
    val.lon = degToFloat( val.lon )
  }
  let toReturn = null
  try {
    toReturn = latLonGeohash.encode( value.lat, value.lon )
  } catch ( err ) {
    // ignore
  }
  return toReturn
}

function hashToObj( hash ) {
  if ( !hash ) {
    return null
  }
  let toReturn = null
  try {
    toReturn = latLonGeohash.decode( hash )
  } catch ( err ) {
    // ignore
  }
  return toReturn
}

const validLatLon = /(^[+-]?[0-9]*[.][0-9]*$)|(^[+-]?[0-9]+$)|(^[+-]?[0-9]*[:][0-9]*$)|(^[+-]?[0-9]*[:][0-9]*[:][0-9]*$)|(^[+-]?[0-9]*[:][0-9]*[:][0-9]*[.][0-9]*$)/
const validLat = /(^[0-9]*[.][0-9]*[NSns]?$)|(^[0-9]+[NSns]$)|(^[0-9]*[:][0-9]*[NSns]?$)|(^[+-]?[0-9]*[:][0-9]*[:][0-9]*[.][0-9]*[NSns]$)|(^[0-9]*[:][0-9]*[:][0-9]*[.][0-9]*[NSns]?$)/
const validLon = /(^[0-9]*[.][0-9]*[EWew]?$)|(^[0-9]+[EWew]$)|(^[0-9]*[:][0-9]*[EWew]?$)|(^[+-]?[0-9]*[:][0-9]*[:][0-9]*[.][0-9]*$)[EWew]|(^[0-9]*[:][0-9]*[:][0-9]*[.][0-9]*[EWew]?$)/

function isValidLatStr( str ) {
  return validLatLon.test( str ) || validLat.test( str )
}

function isValidLonStr( str ) {
  return validLatLon.test( str ) || validLon.test( str )
}

export default {
  degToFloat,
  objToHash,
  hashToObj,
  isValidLatStr,
  isValidLonStr
}
