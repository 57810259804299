// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.placeholder_S5Fej {
  grid-column-start : 1;
  grid-column-end : end;
}`, "",{"version":3,"sources":["webpack://./src/editor/PlaceholderCard.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":["\n.placeholder {\n  grid-column-start : 1;\n  grid-column-end : end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `placeholder_S5Fej`
};
export default ___CSS_LOADER_EXPORT___;
