import React from 'react'
import DrawControl from '../DrawControl'
import Modes from './Modes'

const MODES = { 
  simple_select : Modes.SimpleSelect,
  direct_select : Modes.DirectSelect,
  draw_polygon : Modes.DrawPolygon
}

/**
 * The SingleShapeControl is a Mapbox Draw Control to make and edit one shape.
 * It will prevent deselection of the shape and supply sublte changes to the Delete.
 * Example:
 * 
 * export default class MyPointer {
 *   constructor( options = { } ) {
 *     // Make a new Shape Controller for every construction
 *    this.controller = React.createElement( SingleShapeControl, { key : Date.now() } )
 *   }
 * 
 *   onDrawUpdate = ( evt ) => { console.log( 'Updated', evt ) }
 *   onDrawDelete = ( evt ) => { console.log( 'Delete', evt ) }
 *   onDrawCreate = ( evt ) => { console.log( 'Create', evt ) }
 *   render() { return this.controller }
 *   getComponents( ) { return null }
 * }
 */

export default class SingleShapeController extends React.Component {

  constructor( props ) {
    super( props )
   
    let mode = DrawControl.DRAW_POLYGON
    this.feature = props.feature
    if ( this.feature ) {
      this.feature = { id : `SSC-${Date.now()}`, ...this.feature }
      mode = 'direct_select'
      this.collection = { type : 'FeatureCollection', features : [ this.feature ] }
    }
    this.ref = React.createRef()
    this.state = { mode }
    this.controls = { trash : true }
  }

  onDrawCreate = ( evt ) => { 
    this.feature = evt.features[0]
    this.collection = { type : 'FeatureCollection', features : [ this.feature ] }
    this.setState( { mode : 'direct_select' } )
  }

  onDrawUpdate = ( evt ) => {
    this.feature = evt.features[0]
    this.collection = { type : 'FeatureCollection', features : [ this.feature ] }
  }

  onDrawDelete = ( evt ) => {
    this.feature = null
    this.collection = null
    this.setState( { mode : DrawControl.DRAW_POLYGON } )
  }

  componentDidMount() {
    if ( this.feature ) {
      this.ref.current.draw.set( this.collection )
      this.ref.current.draw.changeMode( 'direct_select', { featureId : this.feature.id } )
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props

    if ( typeof onUnmount === 'function' ) {
      onUnmount()
    }
  }

  render() {
    const { mode } = this.state
    const { mapName = 'map', styles } = this.props
    return ( 
      <DrawControl 
        key="SingleShapeControl"
        ref={this.ref}
        styles={styles}
        keybindings
        onDrawCreate={this.onDrawCreate}
        onDrawDelete={this.onDrawDelete}
        onDrawUpdate={this.onDrawUpdate}
        controls={this.controls}
        displayControlsDefault={false} 
        defaultMode={DrawControl.DRAW_POLYGON} 
        mode={mode}
        modes={MODES}
        mapName={mapName} />
    )
  }
}
