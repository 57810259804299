import NumberCreator from './NumberCreator' 

const TYPE = 'percent';

export default {
  
  name() {
    return 'Percent'
  },

  typeName() {
    return TYPE
  },

  categoryType() {
    return 'float'
  },

  create( path, name ) {
    const attr = NumberCreator.create.call( this, path, name )
    attr.min = 0
    attr.max = 100
    return attr
  },
  createFrom( attr ) {
    const cAttr = NumberCreator.createFrom.call( this, attr ) 
    attr.min = 0
    attr.max = 100
    return cAttr;
  }
}
