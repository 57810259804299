/**
 * This represents a global access for the resource enums. Call
 * resourceEnums.set() to initialize them
 */

let lookup = { defaults : { } }
const listeners = new Set()

const getByType = ( type, resourceId ) => {
  return lookup[resourceId]?.[type] || lookup.defaults[type]
}

const get = ( ) => {
  return { ...lookup }
}

const add = ( enm, fire = true ) => {
  if ( enm ) {
    let key = 'defaults'
    if ( enm.resourceId ) {
      key = enm.resourceId
      lookup[key] = lookup[key] || {}
    }

    lookup[key][enm.type] = enm
    if ( fire ) {
      fireEvent( { type : 'resourceEnumAdded', enum : enm } )
    }
  }
}

const remove = ( enm ) => {
  if ( enm ) {
    const key = enm.resourceId || 'defaults'
    if ( lookup[key] == null || lookup[key][enm.type] == null ) {
      return
    }
    delete lookup[key][enm.type]
    fireEvent( { type : 'resourceEnumRemoved', enum : enm } )
  }
}

const clear = ( fire = true ) => { 
  lookup = { defaults : {} }
  if ( fire ) {
    fireEvent( { type : 'resourceEnumsCleared' } )
  }
}

/**
 * 
 * @param {Array<enm>} enums 
 * @param {boolean} clear if true, the current set is cleared
 * @returns 
 */
const set = ( enmArray, clearOpt = true ) => {
  if ( clearOpt ) {
    clear( false )
  }
  
  if ( Array.isArray( enmArray ) ) {
    
    for ( let n = 0; n < enmArray.length; n++ ) {
      add( enmArray[n], false )
    }
  }
  fireEvent( { type : 'resourceEnumsSet' } )
}

const removeListener = ( listener ) => {
  listeners.delete( listener )
}

const addListener = ( listener ) => {
  listeners.add( listener )
  return ( ) => { removeListener( listener ) }
}

function fireEvent( event ) {
  listeners.forEach( ( l ) => {
    try {
      l( event )
    } catch ( err ) { }
  } )
}

export default {
  getByType,
  get,
  add,
  remove,
  clear,
  set,
  removeListener,
  addListener
}
