/**
 * This is used to collect the rollover and selectable layer ids that can participate
 * in the current rollover/selection.
 */
class Interaction {

  constructor( options = { } ) {
    this.selectableIds = {}
    this.rolloverIds = {}
    this.rolloverLocked = false
    this.selectableLocked = false
    this.drawControl = null
  }

  /**
   * Clears all keys and locks
   */
  clear() {
    this.selectableIds = {}
    this.rolloverIds = {}
    this.rolloverLocked = false
    this.selectableLocked = false
    this.drawControl = null
  }

  /**
   * Returns the drawControl
   */
  getDrawControl() {
    return this.drawControl
  }

  /**
   * Sets the drawControl. This will only set it if one has not
   * already be set
   * @param {DrawControl} drawControl the drawControl to set
   * @return {boolean} true if a modification occurred 
   */
  setDrawControl = ( drawControl ) => {
    if ( this.drawControl == null ) {
      this.drawControl = drawControl
      return true
    }
    return false
  }

  /**
   * Adds one or more selectable Ids to the list. If the selectable layer is lock this 
   * will do nothing
   * @param {Array|string} id the id or array of ids to add to the selectable id list
   * @return {boolean} true if a modification occurred
   */
  addSelectableId( id ) {
    if ( this.selectableLocked ) {
      return false
    }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { this.selectableIds[k] = true } )
    } else if ( typeof id === 'string' ) {
      this.selectableIds[id] = true
    }
    return true
  }
  
  removeSelectableId( id ) {
    if ( this.selectableLocked ) {
      return false
    }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { delete this.selectableIds[k] } )
    } else if ( typeof id === 'string' ) {
      delete this.selectableIds[id]
    }
    return true
  }

  /**
   * Adds one or more rollover Ids to the list. If the rollover layer is lock this 
   * will do nothing
   * @param {Array|string} id the id or array of ids to add to the rollover id list
   * @return {boolean} true if a modification occurred
   */
  addRolloverId( id ) {
    if ( this.rolloverLocked ) {
      return false
    }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { this.rolloverIds[k] = true } )
    } else if ( typeof id === 'string' ) {
      this.rolloverIds[id] = true
    }
    return true
  }

  removeRolloverId( id ) {
    if ( this.rolloverLocked ) {
      return false
    }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { delete this.rolloverIds[k] } )
    } else if ( typeof id === 'string' ) {
      delete this.rolloverIds[id]
    }
    return true
  }

  /**
   * Replaces the rollverable Ids with the list/id. This will lock the rollovers by default
   * @param {Array|string} id the id or array of ids to add to the rollverable id list
   * @param {boolean} lock if true, locks further additions
   * @return {boolean} true if a modification occurred
   */
  setSelectableId( id, lock = true ) {
    if ( this.selectableLocked ) {
      return false
    }
    this.selectableLocked = lock
    this.selectableIds = { }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { this.selectableIds[k] = true } )
    } else if ( typeof id === 'string' ) {
      this.selectableIds[id] = true
    }
    return true
  }

  /**
   * Replaces the rollverable Ids with the list/id. This will lock the rollovers by default
   * @param {Array|string} id the id or array of ids to add to the rollverable id list
   * @param {boolean} lock if true, locks further additions
   * @return {boolean} true if a modification occurred
   */
  setRolloverId( id, lock = true ) {
    if ( this.rolloverLocked ) {
      return false
    }

    this.rolloverLocked = lock
    this.rolloverIds = { }
    if ( Array.isArray( id ) ) {
      id.forEach( ( k ) => { this.rolloverIds[k] = true } )
    } else if ( typeof id === 'string' ) {
      this.rolloverIds[id] = true
    }
    return true
  }

  /**
   * Returns the rollover Ids
   */
  getRolloverIds() {
    return Object.keys( this.rolloverIds )
  }

  /**
   * Returns the selectable keys
   */
  getSeletableIds() {
    return Object.keys( this.selectableIds )
  }

  /**
   * Returns true if rollover is locked
   */
  isRolloverLocked() {
    return this.rolloverLocked
  }

  /**
   * Returns true if selectable is locked
   */
  isSelectableLocked() {
    return this.selectableLocked
  }
}

export default Interaction
