import React from 'react'
import { GlobalState, Selection, UserSettings } from '@leverege/ui-redux'
import { Dialogs } from '@leverege/ui-elements'
import { ContextMenu, ActionsLayout, ToolbarActionsLayout } from '@leverege/ui-plugin'
import { DataSources, RolloverModel, Attributes } from '@leverege/ui-attributes'
import SelectionCard from './SelectionCard'
import RolloverCard from './RolloverCard'
import Util from '../Util'

export default class RolloverSelectionPointer {

  constructor( options = { } ) {
    this.clickList = null
    this.lastIndex = -1
    this.lastPoint = { x : -999999, y : -999999 }
    this.lastRolloverPoint = null
    this.distanceThreshold = options?.distanceThreshold || 10
    this.getRolloverModel = createModelGetter( 'rolloverModel', options?.rolloverModel )
    this.getSelectionModel = createModelGetter( 'selectionModel', options?.selectionModel )
    this.pointerContext = {}

    this.rolloverCard = React.createRef()
    const context = { use : 'contextMenu', client : 'MapBoxRolloverSelectionPointer' }
    const tContext = { use : 'itemBar', client : 'MapBoxRolloverSelectionPointer' }
    this.selectToolbar = new ToolbarActionsLayout( { context : tContext, hasContextMenu : true } )
    this.contextMenuLayout = new ActionsLayout( { context } )
    this.isMenuVisible = false
    this.isTouchDevice = Util.isTouchDevice()
    this.context = context
  }

  setPointerContext( context ) {
    this.pointerContext = context || {}
  }

  /**
   * Builds a context arguement for the given target array 
   * @param {array} target and array of data ref objects
   * @return {object} context object
   */
  getContext = ( target ) => {
    // Get Data for the targets
    const cxt = { ...this.pointerContext, ...this.context }
    if ( Array.isArray( target ) ) {
      const data = DataSources.getData( target )
      // need to merge this into the targets
      cxt.target = target.map( ( item, idx ) => { return { ...item, data : data[idx] } } )
    } else {
      cxt.target = target
    }
    return cxt
  }

  onContexMenuClosed = ( ) => {
    this.isMenuVisible = false
  }

  /**
   * Triggered from Mapbox when the user triggers a contextual action.
   * Trigger the menu to appear at the location.
   */
  onContextMenu = ( event ) => {
    event.originalEvent.stopPropagation() 
    const pos = event.originalEvent.target.getBoundingClientRect()
    const mapScreen = event.point
    // Todo: use geoPosition to lock point
    const p = {
      left : `${pos.left + mapScreen.x + document.body.scrollLeft}px`,
      top : `${pos.top + mapScreen.y + document.body.scrollTop}px`
    }
    const state = GlobalState.get()
    const rollover = Selection.get( state, 'rollover' )
    const selected = Selection.get( state, 'selected' )
    const isRollover = rollover && rollover.items?.length > 0
    let target = null, secondaryTarget = null
    if ( isRollover ) { 
      const id = rollover.items[0].id
      if ( Selection.isSelected( selected, id ) ) {
        target = selected.items
      
      } else {
        target = rollover.items
        secondaryTarget = selected?.items?.length > 0 ? selected.items : null
      }
    } else {
      secondaryTarget = selected?.items?.length > 0 ? selected.items : null
    }

    // const target = 
    //   ( rollover && rollover.items?.length > 0 && rollover.items ) || 
    //   ( selected && selected.items?.length > 0 && selected.items ) || []

    const context = this.getContext( target )
    context.event = event
    context.secondaryTarget = secondaryTarget
    if ( this.contextMenuLayout.handles( context ) ) {
      this.isMenuVisible = true
      Dialogs.show( {
        component : ContextMenu,
        props : { position : p, context, actionsLayout : this.contextMenuLayout },
        onClose : this.onContexMenuClosed
      } )
    }
  }

  /**
   * Triggered from Mapbox when the user clicks on the map.
   * This will attempt to select an object beneath the cursor, 
   * and cycle through the as the same position is clicked.
   */
  onMouseClick( event, opts ) {
    const { interaction, map } = opts
    const evt = { }
    if ( this.distanceFromLast( event.point ) > this.distanceThreshold ) {
      this.clickList = null
      this.lastPoint = event.point
      this.lastIndex = -1
    }
    const layers = Util.checkLayers( interaction.getSeletableIds(), map )
    let features = map.queryRenderedFeatures( event.point, { layers } )
    features = dedup( features )
    this.clickList = features
    if ( features && features.length > 0 ) {
      // Cycle through selections
      evt.features = features
      let nextIndex = this.lastIndex + 1
      if ( nextIndex >= features.length ) {
        nextIndex = 0
      }
      this.lastIndex = nextIndex
      const f = features[nextIndex]
      const p = f.properties
      if ( p.dataId != null && p.dataType != null ) {
        evt.id = p.dataId
        evt.type = p.dataType
        evt.ref = p.dataRef
      }
    } else {
      this.clickList = null
      this.lastIndex = -1
    }

    const selected = Selection.get( GlobalState.get(), 'selected' )
    if ( ( evt.id == null && Selection.isEmpty( selected ) ) || Selection.isSelected( selected, evt.id ) ) {
      return
    }

    if ( evt.id ) {
      GlobalState.dispatch( Selection.set( 'selected', evt ) )      
    } else {
      GlobalState.dispatch( Selection.clear( 'selected' ) )
    }
  }

  onMouseMove( event, opts ) {

    // Rollover interactions should be disabled for touch devices.
    if ( this.isTouchDevice ) {
      return
    }

    const { interaction, map } = opts
    const evt = { }
    const layers = Util.checkLayers( interaction.getRolloverIds(), map )
    const features = map.queryRenderedFeatures( event.point, { layers, validate : false } )
    if ( features && features.length > 0 ) {

      evt.features = features
      const f = Util.getSmallestArea( features )
      const p = f.properties
      if ( p.dataId != null ) { evt.id = p.dataId }
      if ( p.dataType != null ) { evt.type = p.dataType }
      if ( p.dataRef != null ) { evt.ref = p.dataRef }
    } 

    // Get Pointer position
    const cPos = this.lastRolloverPoint
    const rolloverRef = this.rolloverCard.current
    // Is rollover the same?
    let cp = event.point
    const rolloverModel = this.getRolloverModel()
    if ( evt.id != null && RolloverModel.needsPointPosition( rolloverModel, evt.type ) ) {
      const obj = DataSources.getData( evt )
      if ( obj ) {
        const ll = Attributes.get( 'geoPosition', evt.type, obj, null )
        if ( ll ) {
          // If the target has a geoPosition, use its screen position
          cp = event.target.project( [ ll.lon, ll.lat ] )
        }
      }
      // Clamp the position to int values to avoid unncessary updates
      const p = { x : Math.round( cp.x ), y : Math.round( cp.y ) }

      if ( cPos == null || cPos.x !== p.x || cPos.y !== p.y ) {
        this.lastRolloverPoint = p
        if ( rolloverRef ) { rolloverRef.setState( { pointerPosition : p } ) }
      }
    } else if ( cPos != null ) {
      this.lastRolloverPoint = null
      if ( rolloverRef ) { rolloverRef.setState( { pointerPosition : null } ) }
    }

    const rollover = Selection.get( GlobalState.get(), 'rollover' )
    if ( ( evt.id == null && Selection.isEmpty( rollover ) ) || Selection.isSelected( rollover, evt.id ) ) {
      return
    }

    if ( evt.id ) {
      GlobalState.dispatch( Selection.set( 'rollover', evt ) )      
    } else {
      GlobalState.dispatch( Selection.clear( 'rollover' ) )
    }
  }

  onMouseOut( event ) {

    // Rollover interactions should be disabled for touch devices.
    if ( this.isTouchDevice ) {
      return
    }

    // Add this in will leave the rollover visible 
    if ( this.isMenuVisible === false ) {
      GlobalState.dispatch( Selection.clear( 'rollover' ) )
    }
  }

  distanceFromLast( pt ) {
    return Math.hypot( pt.x - this.lastPoint.x, pt.y - this.lastPoint.y )
  }


  /**
   * Render the rollover card and grab a ref to it so we
   * can pass the position to it as needed
   */
  renderRollover = ( ) => {

    // The rendering of the Rollover card is independent from interactions and selections.

    return (
      <RolloverCard key="rollover"
        ref={this.rolloverCard}
        getRolloverModel={this.getRolloverModel}
        getContext={this.getContext} />
    )
  }

  /**
   * Render the selection card, giving it the contextual menu 
   * for use in the header.
   */
  renderSelection = ( ) => {
    return (
      <SelectionCard key="selection"
        getContext={this.getContext}
        getSelectionModel={this.getSelectionModel} 
        toolbarLayout={this.selectToolbar}
        contextMenuLayout={this.contextMenuLayout} />
    )
  }


  getComponents( ) {
    return [
      this.renderSelection(),
      this.renderRollover(),
    ]
  }
}

/**
 * Remove duplicate layers
 */
function dedup( arr ) {
  const map = {}
  const nArr = []
  for ( let n = 0; n < arr.length; n++ ) {
    const id = arr[n].properties.dataId
    const type = arr[n].properties.dataType || 'no_type'
    if ( map[id] == null ) {
      map[id] = { [type] : true }
      nArr.push( arr[n] )
    } else if ( map[id][type] == null ) {
      map[id][type] = true
      nArr.push( arr[n] )
    }
  }
  return nArr
}


function createModelGetter( name, funcOrObj ) {
  return ( ) => {
    if ( typeof funcOrObj === 'function' ) {
      return funcOrObj
    } 
    if ( funcOrObj != null ) {
      return ( ) => funcOrObj
    }
    const toReturn = UserSettings.get( GlobalState.get(), name )
    if ( toReturn instanceof Promise ) {
      return null
    }
    return toReturn
  }
}