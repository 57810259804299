/**
 * This represents a global access for the blueprints in the system. Call
 * Blueprints.set() to initialize them
 */

let lookup = {}
let blueprints = Object.freeze( [] )
const listeners = new Set()

const get = () => {
  return blueprints
}

const getByType = ( type ) => {
  return lookup[type]
}

const getMapping = ( type ) => {
  return { ...lookup }
}

const add = ( blueprint ) => {
  if ( blueprint ) {
    const index = blueprints.findIndex( b => blueprint.id === b.id )
    lookup[blueprint.id] = blueprint
    if ( blueprint.alias ) {
      lookup[blueprint.alias] = blueprint
    }
    if ( index >= 0 ) {
      blueprints = [ ...blueprints ]
      blueprints.splice( index, 1 )
    }
    blueprints = Object.freeze( [ ...blueprints, blueprint ] )
    fireEvent( { type : 'blueprintAdded', blueprint, blueprints } )
  }
}

const remove = ( blueprint ) => {
  if ( blueprint && ( lookup[blueprint.id] != null || lookup[blueprint.alias] != null ) ) {
    const index = blueprints.findIndex( b => blueprint.id === b.id )
    if ( index >= 0 ) {
      delete lookup[blueprint.id]
      delete lookup[blueprint.alias]
      blueprints = [ ...blueprints ]
      blueprints.splice( index, 1 )
      blueprints = Object.freeze( blueprints )
      fireEvent( { type : 'blueprintRemoved', blueprint, blueprints } )
    }
  }
}

const clear = ( fire = true ) => { 
  lookup = {}
  blueprints = Object.freeze( [] )
  if ( fire ) {
    fireEvent( { type : 'blueprintsCleared' } )
  }
}

/**
 * 
 * @param {Array<Blueprint>} blueprints 
 * @param {boolean} clear if true, the current set is cleared
 * @returns 
 */
const set = ( blueprintArray, clearOpt = true ) => {
  if ( clearOpt ) {
    clear( false )
  }
  
  if ( Array.isArray( blueprintArray ) ) {
    
    for ( let n = 0; n < blueprintArray.length; n++ ) {
      const b = blueprintArray[n]
      lookup[b.id] = b
      lookup[b.alias] = b
    }
    blueprints = Object.freeze( [ ...blueprintArray ] )
    fireEvent( { type : 'blueprintsSet', blueprints } )
  }
}

const removeListener = ( listener ) => {
  listeners.delete( listener )
}

const addListener = ( listener ) => {
  listeners.add( listener )
  return ( ) => { removeListener( listener ) }
}

function fireEvent( event ) {
  listeners.forEach( ( l ) => {
    try {
      l( event )
    } catch ( err ) { }
  } )
}

export default {
  get,
  getByType,
  getMapping,
  add,
  remove,
  clear,
  set,
  addListener,
  removeListener
}
