// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conditions_NOlH4 {
  width: 100%;
}

.conditionHolder_P0jvw {
  display : grid;
  row-gap: var( --size-padMedium, 16px );
}

.condition_kfe2J {
  display : grid;
  grid-template-columns: 1fr auto;

  justify-content: space-between;
  position : relative;
  flex-direction: row;
  flex-wrap: nowrap;
}

.condition_kfe2J > :first-child {
  flex : 1;
}

.condition_kfe2J > :last-child {
  flex : 0 0 auto;
}

.logical_ilEEw .logical_ilEEw {
  border : 1px solid var(--color-base100);
}

.logical_ilEEw {
  flex-grow: 1;
}

.dropTarget_ZIoN7 {
  background : var( --color-dropTarget, #f3f5f6 );
}

.dragging_IM4AM {
  visibility : hidden;
}

.option_O9vQ0 {
  display : grid;
  grid-template-columns: 1fr auto;
  width : 100%;
  column-gap: var( --size-padMedium, 16px);
  align-items: center;
}

.toolbar_fWS97 {
  margin-top: 0.5em;
}

.editorFlex_GWQm7 {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/logic/LogicConditionEditor.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,+BAA+B;;EAE/B,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,YAAY;EACZ,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".conditions {\n  width: 100%;\n}\n\n.conditionHolder {\n  display : grid;\n  row-gap: var( --size-padMedium, 16px );\n}\n\n.condition {\n  display : grid;\n  grid-template-columns: 1fr auto;\n\n  justify-content: space-between;\n  position : relative;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n\n.condition > :first-child {\n  flex : 1;\n}\n\n.condition > :last-child {\n  flex : 0 0 auto;\n}\n\n.logical .logical {\n  border : 1px solid var(--color-base100);\n}\n\n.logical {\n  flex-grow: 1;\n}\n\n.dropTarget {\n  background : var( --color-dropTarget, #f3f5f6 );\n}\n\n.dragging {\n  visibility : hidden;\n}\n\n.option {\n  display : grid;\n  grid-template-columns: 1fr auto;\n  width : 100%;\n  column-gap: var( --size-padMedium, 16px);\n  align-items: center;\n}\n\n.toolbar {\n  margin-top: 0.5em;\n}\n\n.editorFlex {\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditions": `conditions_NOlH4`,
	"conditionHolder": `conditionHolder_P0jvw`,
	"condition": `condition_kfe2J`,
	"logical": `logical_ilEEw`,
	"dropTarget": `dropTarget_ZIoN7`,
	"dragging": `dragging_IM4AM`,
	"option": `option_O9vQ0`,
	"toolbar": `toolbar_fWS97`,
	"editorFlex": `editorFlex_GWQm7`
};
export default ___CSS_LOADER_EXPORT___;
