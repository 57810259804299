// TODO: Map this an object or based on mapName so different maps can have different 
// options

/**
 * This file is used to control the types and url of the mapboxs imagery.
 **/

const SATELLITE = 'satellite'
const VECTOR = 'vector'

let MAP_STYLES = {
  [SATELLITE] : process.env.MAPBOX_SATELLITE || 'mapbox://styles/mapbox/satellite-v9',
  [VECTOR] : process.env.MAPBOX_VECTOR || 'mapbox://styles/mapbox/light-v9'
}

export default {
  SATELLITE,
  VECTOR,
  /**
   * Sets the options for the style.
   * @param {object} options
   * @param {object} options.styles
   * @param {string} options.styles.<STYLE KEY> the mapbox url for the style
   **/
  init : ( options ) => {
    if ( options && options.styles ) {
      MAP_STYLES = { ...options.styles }
    }
  },
  /**
   * Returns the next style, assuming the types exist in a list
   * @param {string} style either SATELLITE or VECTOR
   * @return the next style, or for VECTOR if the style is unknown
   **/
  next : ( style ) => {
    if ( style === SATELLITE ) {
      return VECTOR
    }
    return SATELLITE
  },
  /**
   * Returns the mapbox url for the given style
   * @param {string} style either SATELLITE or VECTOR
   * @return the mapbox url for the style, or for vector if 
   * the style is unknown
   **/
  url : ( style ) => {
    return MAP_STYLES[style] || MAP_STYLES[VECTOR]
  },
}