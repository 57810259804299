import React from 'react'
import { Text, IconUtil } from '@leverege/ui-elements'
import { R } from '@leverege/value-resolver'

import S from './CardTitle.css'

const imgOpts = {
  height : 16,
  width : 16
}

export default function CardTitle( props ) {
  const {
    icon : pIcon,
    titleVisible = true,
    typeIcon, title,
    typeName,
    children,
    drag,
    variant = 'dashboardCardTitle'
  } = props

  let hdr = null
  let icw = null
  let name = title == null || title === '' ? typeName : title
  name = R( name )
  let icon = pIcon == null || pIcon === '' ? typeIcon : pIcon
  icon = R( icon )

  if ( icon == null ) {
    hdr = <Text variant={`${variant}|title`}>{name}</Text>
  } else if ( typeof icon === 'string' ) {
    hdr = (
      <Text
        variant={`${variant}|title`}
        icon={icon}
        imageOpts={imgOpts}>{name}
      </Text>
    )
  } else {
    icw = IconUtil.create( icon, imgOpts )
  }
  return (
    <div className={S.content} ref={drag}>
      {titleVisible && (
        <div className={S.title}>
          {icw}
          {hdr}
        </div>
      )}
      {children}
    </div>
  )
}
