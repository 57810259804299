import React from 'react'
import classnames from 'classnames'
import DragList from 'react-drag-listview'
import { Content, List, Flex, Button, Text, Pane, ListSelectors } from '@leverege/ui-elements'

import { ModelEditorFactory } from './Factory.js'
import Constants from './Constants.js'

import S from './ModelListEditor.css'

/**
 * Creates an editor with a List and an Editor section. When an elements
 * in the list is selected, its editor will appear in the Editor section.
 * The 
 */
export default class ModelListEditor extends React.PureComponent {
  
  static EVT_CHILD_CHANGE = Constants.EVT_CHILD_CHANGE
  static EVT_CHILD_REMOVE = Constants.EVT_REMOVE_CHANGE

  static defaultProps = {
    paneVariant : 'collectionEditor',
    listSectionVariant : 'collectionEditorListSection',
    listHeaderVariant : 'collectionEditorListHeader',
    listContentVariant : 'collectionEditorListContent',
    listFooterVariant : 'collectionEditorListFooter',
    selectionSectionVariant : 'collectionEditorSelectionSection',
    selectionHeaderVariant : 'collectionEditorSelectionHeader',
    selectionContentVariant : 'collectionEditorSelectionContent',
    selectionFooterVariant : 'collectionEditorSelectionFooter',
    dragHandleSelector : null,
    allowReorder : true,
    autoSelect : true,
    renderer : Renderer,
    rendererNameKey : 'name', // for default renderer
    rendererIsRemovable : true, // for default renderer
  }

  constructor( props ) {
    super( props )

    this.state = { selected : props.autoSelect ? 0 : -1 }
    this.selector = new ListSelectors.IndexSelector( { // eslint-disable-line
      useMeta : true,
      getItems : ( ) => {
        let items = this.props.model.getAll( this.props.value )

        if ( !items ) {
          return []
        }

        if ( typeof sort === 'function' ) {
          items.sort( this.props.sort )
        }

        if ( typeof filter === 'function' ) {
          items = items.filter( this.props.filter )
        }

        return items
      }, // eslint-disable-line
      getSelected : ( ) => { return this.state.selected }, // eslint-disable-line
      setSelected : ( selected, cb ) => { this.setState( { selected }, cb ) }
    } )
  }

  onChildChange = ( e ) => {
    const { value, model, onChange, eventData } = this.props

    if ( onChange ) {
      // get index of model to replace 
      const idx = model.indexOf( value, e.data )

      // Replace it, creating a new model
      const nModel = model.set( value, idx, e.value )

      // fire the event
      onChange( { value : nModel, data : eventData, oldValue : value } )
    }
  }

  onMove = ( fromIndex, toIndex ) => {
    const { selected } = this.state
    const { value, model, onChange, eventData } = this.props
    if ( onChange ) {
      const nModel = model.move( value, fromIndex, toIndex )
      onChange( { value : nModel, data : eventData, oldValue : value } )

      // move selection too
      if ( selected === fromIndex ) {
        this.setState( { selected : toIndex } )
      } else if ( selected >= toIndex && fromIndex > selected ) {
        this.setState( { selected : selected + 1 } )
      } else if ( selected <= toIndex && fromIndex < selected ) {
        this.setState( { selected : selected - 1 } )
      }
    }
  }

  onEvent = ( evt ) => {
    const { value, model, onChange, eventData } = this.props

    if ( evt.type === Constants.EVT_CHILD_REMOVE ) {
      if ( onChange ) {
        // If its a remove, remove the item from the list and send it
        const nModel = model.remove( value, evt.item )
        onChange( { value : nModel, data : eventData, oldValue : value } )
      }
    } else if ( evt.type === Constants.EVT_CHILD_CHANGE ) {
      /**
       * Expects { data : old value, value: new value}
       */
      this.onChildChange( evt )
    }

  }

  /**
   * This is given to each renderer to allow it to perform copy/remove/etc
   * actions, all which need to send a change. This will take the value
   * from the event (which should be the model holding the list being edited )
   * and send an event with the appropriate data set
   **/
  onParentChange = ( evt ) => {
    const { onChange, eventData } = this.props  
    if ( onChange ) {
      onChange( { ...evt, data : eventData } )
    }
  }

  render() {

    const {
      value,
      model,
      paneVariant,
      listSectionVariant,
      selectionSectionVariant,
      sort,
      filter,
      allowCollapseToOne = false
    } = this.props

    let items = model.getAll( value )

    if ( typeof sort === 'function' ) {
      items.sort( sort )
    }

    if ( typeof filter === 'function' ) {
      items = items.filter( filter )
    }

    const { selected } = this.state
    const selectedItem = items && items[selected]

    const collapse = allowCollapseToOne && items.length === 1
    
    return (
      <Content variant={paneVariant} horizontal>
        {!collapse && (
          <Content.Header variant={listSectionVariant}>
            <div className={S.control}>
              { this.getListHeader( selectedItem ) }
              { this.getListContent( items, selectedItem ) }
              { this.getListFooter( selectedItem ) }

            </div>
          </Content.Header>
        )}
        <Content.Area variant={selectionSectionVariant}>
          <Content>
            { this.getSelectionHeader( selectedItem ) }
            { this.getSelectionContent( selectedItem ) }
            { this.getSelectionFooter( selectedItem ) }
          </Content>
        </Content.Area>
      </Content>
    )
  }

  getListHeader( item ) {
    const { listHeader, listHeaderVariant } = this.props
    if ( listHeader ) {
      const hdr = listHeader( item )
      if ( hdr ) {
        return <Pane variant={listHeaderVariant} className={S.header}>{hdr}</Pane>
      }
    }
    return null
  }

  getListContent( items, item ) {
    const { 
      onEvent, renderer, itemProps, listContentVariant, listClassName,
      rendererNameKey : nameKey, rendererIsRemovable : removable,
      allowReorder, dragHandleSelector, value, model } = this.props

    const iProps = ( renderer === Renderer ) ? 
      { ...itemProps, nameKey, removable, onParentChange : this.onParentChange, parent : value, model } : 
      { ...itemProps, onParentChange : this.onParentChange, parent : value, model }

    const list = (
      <List 
        onEvent={onEvent || this.onEvent} 
        selector={this.selector} 
        data-drag-row={allowReorder && dragHandleSelector == null}
        className={classnames( S.list, listClassName )}>
        <List.Dynamic items={items} component={renderer} itemProps={iProps} />
      </List>
    )
    if ( allowReorder ) {
      return (
        <Pane variant={listContentVariant} className={S.listPane}>
          <DragList 
            onDragEnd={this.onMove} 
            nodeSelector={`.${S.list} > div`}
            handleSelector={dragHandleSelector}>
            {list}
          </DragList> 
        </Pane>
      )
    }
    return (
      <Pane variant={listContentVariant} className={S.listPane}>{list}</Pane>
    )
  }

  getListFooter( item ) {
    const { listFooter, listFooterVariant } = this.props
    if ( listFooter ) {
      const ftr = listFooter( item )
      if ( ftr ) {
        return <Pane variant={listFooterVariant} className={S.footer}>{ftr}</Pane>
      }
    }
    return null
  }

  /**
   * Returns the header for the selection section
   */
  getSelectionHeader( item ) {
    const { selectionHeaderVariant, selectionHeader } = this.props
    if ( selectionHeader ) {
      const hdr = selectionHeader( item )
      if ( hdr ) {
        return <Content.Header variant={selectionHeaderVariant}>{hdr}</Content.Header> 
      }
    }
    return null
  }

  /**
   * Returns the selection area for the editor section
   */
  getSelectionContent( item ) {
    const { 
      selectionContent, selectionContentVariant, selectedProps,
      modelEditorFactory = ModelEditorFactory } = this.props

    let editor = null
    if ( selectionContent ) {
      editor = selectionContent( item, selectedProps )
    } else {
      const eProps = { 
        ...selectedProps, 
        onChange : this.onChildChange, 
        eventData : item 
      }
      editor = modelEditorFactory.create( item, eProps )

    }
    return (
      <Content.Area variant={selectionContentVariant}>
        {editor}
      </Content.Area>
    )
  }

  /**
   * Returns the footer for the selection section
   */
  getSelectionFooter( item ) {
    const { selectionFooterVariant, selectionFooter } = this.props
    if ( selectionFooter ) {
      const hdr = selectionFooter( item )
      if ( hdr ) {
        return <Content.Footer variant={selectionFooterVariant}>{hdr}</Content.Footer> 
      }
    }
    return null
  }
}

function Renderer( props ) {
  const { item, onEvent, nameKey, removable = true, parent, model, onParentChange, ...rest } = props
  let name = nameKey ? item[nameKey] : item.name
  if ( name == null ) {
    name = item.displayName || item.name || item.type
  }
  const onDelete = removable ? ( e ) => { 
    onEvent( { 
      type : Constants.EVT_CHILD_REMOVE, 
      item, 
      data : props.eventData, } ) 
  } : null
  
  return (
    <List.Item item={item} {...rest}>
      <Flex variant="rowM" justify="space-between" align="center">
        <Text variant="listItemSubtitle">{name}</Text>
        { removable && <Button variant="link" eventData={item} onClick={onDelete} icon="fa fa-trash" /> }
      </Flex>
    </List.Item> 
  )
}
