import React from 'react'

import I18N from '@leverege/i18n'
import { Pane, Flex, TextInput, Text, Label, NumericInput } from '@leverege/ui-elements'
import { useValueChange } from '@leverege/ui-hooks'

import CardGrid from './CardGrid'
import CardSelector from './CardSelector'
import DashboardContext, { useDashboardContext } from '../shared/DashboardContext'
import VariantCreator from '../shared/VariantCreator'

import { Grid } from '../model'
import S from './GridEditor.css'

const { t } = I18N.ns( 'uiDashboard.editor.GridEditor' )

export default function GridEditor( props ) {
  let { addText, placeholderContent } = props
  let { value } = props
  value = value || {}

  const { style, className, onAdd, ...rProps } = props
  const dashboardContext = useDashboardContext()
  const { addWidgetText, models, options, optionEditors, depth, sharedProps } = dashboardContext
  const onModelChange = useValueChange( Grid, props )
  const ovc = new VariantCreator( 'OptionsEditor', value.style )

  if ( addText == null ) {
    addText = <span><i className="fa fa-plus"/> {addWidgetText || t( 'addWidget' )}</span>
  }
  
  if ( placeholderContent == null ) {
    placeholderContent = (
      <div className={S.addWidget}>
        <CardSelector title={addText} onSelect={onAdd} />
      </div>
    )
  }

  const mdl = depth === 0 && models.create( value )
  const req = mdl && mdl.getRequired && mdl.getRequired( value, models, dashboardContext )

  if ( req && req.length > 0 ) {
    return (
      <DashboardContext depth={depth + 1} options={value.options}>
        <Flex variant="colM" align="right" style={style} className={className} >
          <Flex align="center" variant={`${ovc.get()}|rowM`}>
            <Label>{t( 'dashboardOptions' )}</Label>
            {
              req.map( ( req ) => { 

                const onChange = ( evt ) => { 
                  const options = value.options ? { ...value.options } : { }
                  options[req] = evt.value
                  onModelChange( { data : 'setOptions', value : options } )
                }
                const oProps = {
                  key : req,
                  eventData : req,
                  value : value.options[req] || options[req],
                  sharedProps,
                  onChange
                }
                return optionEditors.create( req, oProps )
              } )
            }
          </Flex>
          <CardGrid placeholderContent={placeholderContent} {...rProps} />
        </Flex>
      </DashboardContext>
    )
  }

  return (
    <DashboardContext depth={depth + 1} options={value.options} columns={Grid.getColumns( value ) || 2} > 
      <CardGrid 
        placeholderContent={placeholderContent} 
        {...rProps} 
        style={style} 
        className={className} 
        sharedProps={sharedProps} />
    </DashboardContext>
  )
}

GridEditor.MenuEditor = ( props ) => {
  const { value, maxColumns } = props
  const onModelChange = useValueChange( Grid, props )

  return (
    <Pane variant="padding">
      <Flex variant="colM" align="stretch">
        <Text variant="title">{t( 'gridOptions' )}</Text>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'columns' )}</Label>
          <NumericInput 
            variant="dashboardCardEditor|small" 
            size={2} 
            value={value.columns || 1} 
            eventData="setColumns" 
            min={1} 
            max={maxColumns} 
            onChange={onModelChange}/>
        </Flex>        
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'columnWidth' )}</Label>
          <TextInput variant="dashboardCardEditor|small" 
            size={8} 
            value={value.columnSize || '1fr'} 
            eventData="setColumnSize"
            min={1} 
            onChange={onModelChange}/>
        </Flex>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'rowGap' )}</Label>
          <TextInput variant="dashboardCardEditor|small" 
            value={value.rowGap || '24px'}
            eventData="setRowGap"
            onChange={onModelChange} />
        </Flex>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'columnGap' )}</Label>
          <TextInput variant="dashboardCardEditor|small" 
            value={value.columnGap || '24px'}
            eventData="setColumnGap"
            onChange={onModelChange} />
        </Flex>
      </Flex>
    </Pane>
  )
}

GridEditor.TYPE = Grid.TYPE
GridEditor.NAME = 'Grid'
GridEditor.ICON = 'fa fa-th'
