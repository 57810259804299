import { useDrop, useDrag } from 'react-dnd'
import { flushSync } from 'react-dom'

function useModelDrag( { value, dragType, onRemove, collect } ) {
  return useDrag( {
    type : dragType,
    item : { type : dragType, item : value },
    end : ( item, monitor ) => {
      let result = null
      flushSync( () => { result = monitor.getDropResult() } )
      const shouldRemove = result && result.dropEffect === 'move' && result.dropResult !== 'move'
      if ( result && result.droppedItem != null && shouldRemove ) {
        onRemove( { data : value, prompt : false } )
      }
    },
    collect : ( monitor ) => {
      const c = collect ? collect( monitor ) : { }
      return {
        isDragging : monitor.isDragging(),
        ...c
      }
    }
  } )
}

function useModelDrop( { value, accept, index, onDrop, canDrop, drop, isDragging, collect } ) {
  return useDrop( {
    accept,
    canDrop : ( item ) => {
      if ( item.item === value || ( canDrop && !canDrop( item ) ) ) {
        return false
      }
      return onDrop != null
    },
    collect : ( monitor, props ) => {
      const c = collect ? collect( monitor ) : { }
      return {
        isOver : monitor.isOver( { shallow : true } ),
        canDrop : monitor.canDrop(),
        ...c
      }
    },
    drop : drop || ( ( item, monitor ) => {
      if ( monitor.didDrop() ) {
        return monitor.getDropResult() // { droppedItem : null, isMove : false, dropResult : 'alreadyHandled' }
      }
      try {
        const isMove = onDrop( { index, item : item.item, value } )
        return { droppedItem : item, dropTarget : value, dropResult : isMove ? 'move' : 'copy' }
      } catch ( ex ) {
        // eslint-disable-next-line no-console
        console.error( ex )
        return { droppedItem : null, dropTarget : value, dropResult : 'error' }
      }
    } )
  } )
}

/**
 * This is meant to be used in conjunction with useInstanceCallback so the value, onChange, and eventData
 * can be rebound without triggering changes to children. OnChange will be invoked with an event containing
 * { value : <New Model with the moved item>, data : eventData } 
 * const onDrop = useInstanceCallback( [ dropItem], MyModel.listItems(), { value, onChange, eventData } )
 * @param {Model} ListModel a model object containing indexOf, move and add functions.
 * @param {Object} options
 * @param {Object}  options.value the model that contains the children
 * @param {Any}  options.eventData the eventData to send in the onChange Event
 * @param {Function}  options.onChange the change callback

 * @param {*} evt the event containing { item : <thing to move>, index : <location to move to> }
 * @returns true or false depending on whether or not the dropped item was moved in place.
 */
function dropItem( ListModel, { value, onChange, eventData }, evt ) {
  let nModel
  const curr = ListModel.indexOf( value, evt.item )
  const move = curr >= 0 // If -1, this item is not found in current list.
  if ( move ) {
    nModel = ListModel.move( value, curr, evt.index )
  } else {
    const item = JSON.parse( JSON.stringify( evt.item ) )
    nModel = ListModel.add( value, item, evt.index )
  }
  if ( onChange && nModel !== value ) {
    onChange( { value : nModel, data : eventData } )
  }
  return move
}

/**
 * This will remove the child from the value. The ListModel is the accessors into
 * the array in value where the children live. 
 * const onRemove = useInstanceCallback( [ removeItem ], MyModel.children(), { value, onChange, eventData } )
 * @param {Model} ListModel a model object containing remove and removeAt functions.
 * @param {Object} options
 * @param {Object}  options.value the model that contains the children
 * @param {Any}  options.eventData the eventData to send in the onChange Event
 * @param {Function}  options.onChange the change callback
 * @param {Object} evt The event (evt) should contain { value, data }, where value is the new 
 * value of the child, and data is either the actual object still in the current model, or its index. 
 */
function removeItem( ListModel, { value, onChange, eventData }, evt ) {
  const nValue = typeof evt.data === 'number' ? 
    ListModel.removeAt( value, evt.data ) :
    ListModel.remove( value, evt.data )
  if ( onChange && nValue !== value ) {
    onChange( { value : nValue, data : eventData } )
  }
}

/**
 * This will update the value with the new child. The ListModel is the accessors into
 * the array in value where the children live. 
 * const onChildChange = useInstanceCallback( [ updateChildItem], MyModel.children(), { value, onChange, eventData } )
 * @param {Model} ListModel a model object containing indexOf and set functions.
 * @param {Object} options
 * @param {Object}  options.value the model that contains the children
 * @param {Any}  options.eventData the eventData to send in the onChange Event
 * @param {Function}  options.onChange the change callback
 * @param {Object} evt The event (evt) should contain { value, data }, where value is the new 
 * value of the child, and data is the actual object still in the current model. 
 */
function updateChildItem( ListModel, { value, onChange, eventData }, evt ) {
  const idx = ListModel.indexOf( value, evt.data )
  const nModel = ListModel.set( value, idx, evt.value )
  onChange( { data : eventData, value : nModel } )
}
export default { useModelDrag, useModelDrop, dropItem, removeItem, updateChildItem }
