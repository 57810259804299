/**
 * This represents a global access for the enums. Call
 * enums.set() to initialize them
 */

let lookup = {}
const listeners = new Set()

const get = () => {
  return lookup
}

const getByType = ( type ) => {
  return lookup[type]
}

const add = ( enm ) => {
  if ( enm ) {
    lookup[enm.type] = enm
    fireEvent( { type : 'enumAdded', enum : enm, enums : lookup } )
  }
}

const remove = ( enm ) => {
  if ( enm && lookup[enm.type] != null ) {
    delete lookup[enm.type]
    fireEvent( { type : 'enumRemoved', enum : enm, enums : lookup } )
  }
}

const clear = ( fire = true ) => { 
  lookup = {}
  if ( fire ) {
    fireEvent( { type : 'enumsCleared' } )
  }
}

/**
 * 
 * @param {Array<enm>} enums 
 * @param {boolean} clear if true, the current set is cleared
 * @returns 
 */
const set = ( enmArray, clearOpt = true ) => {
  if ( clearOpt ) {
    clear( false )
  }
  
  if ( Array.isArray( enmArray ) ) {
    
    for ( let n = 0; n < enmArray.length; n++ ) {
      lookup[enmArray[n].type] = enmArray[n]
    }
  }
  fireEvent( { type : 'enumsSet', enums : lookup } )
}

const removeListener = ( listener ) => {
  listeners.delete( listener )
}

const addListener = ( listener ) => {
  listeners.add( listener )
  return ( ) => { removeListener( listener ) }
}

function fireEvent( event ) {
  listeners.forEach( ( l ) => {
    try {
      l( event )
    } catch ( err ) { }
  } )
}

export default {
  get,
  getByType,
  add,
  remove,
  clear,
  set,
  removeListener,
  addListener
}
