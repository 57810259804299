import React, { useState } from 'react'

import I18N from '@leverege/i18n'
import { Flex, Label } from '@leverege/ui-elements'

import CardGridView from './CardGridView'
import { Grid } from '../model'
import DashboardContext, { useDashboardContext } from '../shared/DashboardContext'
import VariantCreator from '../shared/VariantCreator'

const { t } = I18N.ns( 'uiDashboard.view.GridView' )

export default function GridView( props ) {
  const [ optionOverrides, setOptionOverides ] = useState( null )
  const { value : v, onChange, eventData } = props
  const value = v || {}
  const { style, className, ...rProps } = props
  const dashboardContext = useDashboardContext()
  const { models, optionEditors, depth, sharedProps, options } = dashboardContext
  const ovc = new VariantCreator( 'OptionsView', value.style )

  const mdl = depth === 0 && models.create( value )
  const req = mdl && mdl.getRequired && mdl.getRequired( value, models, dashboardContext )
  const opt = value.options != null || optionOverrides != null || options != null ?
    { ...( options || {} ), ...( value.options || { } ), ...( optionOverrides || { } ) } : null 
  if ( req && req.length > 0 ) {
    
    return (
      <DashboardContext depth={depth + 1} options={opt} columns={Grid.getColumns( value ) || 2}>
        <Flex variant="colM" align="right" >
          <Flex align="center" variant={`${ovc.get()}|rowM`}>
            <Label>{t( 'dashboardOptions' )}</Label>
            {
              req.map( ( req ) => { 

                const onChange = ( evt ) => { 
                  const options = { ...opt }
                  options[req] = evt.value
                  setOptionOverides( options )
                }
                const oProps = { key : req, eventData : req, value : opt[req], sharedProps, onChange }
                return optionEditors.create( req, oProps )
              } )
            }
          </Flex>
          <CardGridView {...rProps} onChange={onChange} eventData={eventData}/>
        </Flex>
      </DashboardContext>
    )
  }
  
  return (
    <DashboardContext depth={depth + 1} options={opt}> 
      <CardGridView {...rProps} onChange={onChange} eventData={eventData}/>
    </DashboardContext>
  )
}

GridView.NAME = 'Grid'
