import Path from '@leverege/path' 
import StringUtil from '@leverege/string-util' 

import sortAttributesByPath from './sortAttributesByPath' 

class TreeOrganizer {

  set tags( tags ) {
    this._tags = tags
    this._recalc = true
  }

  set attributes( attributes ) {
    this._attributes = attributes
    this._recalc = true
  }

  getTree( ) {
    if ( this._recalc === false ) {
      return this._tree;
    }
    
    const tree = { children : [] };
    if ( this._attributes == null || this._attributes.length === 0 ) {
      this._tree = tree
      this._recalc = false
      return tree
    }

    const groupFnc = this.createGroupFunction( this._attributes, this._tags )
    const attrs = sortAttributesByPath( this._attributes );
    const groups = {};
    for ( let n = 0; n < attrs.length; n++ ) {
      let grp = groupFnc( attrs[n] )
      if ( grp !== false && grp != null ) {
        if ( grp === true ) {
        
        } else if ( typeof grp === 'string' ) {
          grp = [ grp ];
        }
        for ( let gn = 0; gn < grp.length; gn++ ) {
          groups[grp[gn]] = groups[grp[gn]] || [];
          groups[grp[gn]].push( attrs[n] );
        }
      }
    }

    const keys = Object.keys( groups );
    for ( let n = 0; n < keys.length; n++ ) {
      const key = keys[n];
      const arr = groups[key];

      let name = StringUtil.replaceAll( key, '/', '_' );
      name = StringUtil.camelCaseToTitleCase( name, true );
      const tnode = this.getNode( tree, Path( key ).pathArray() );
      
      for ( let an = 0; an < arr.length; an++ ) {
        tnode.children.push( { name : arr[an].name, attribute : arr[an], pathname : arr[an].path } );
      }
    }

    for ( let n = 0; n < tree.children.length; n++ ) {
      this.consolidateNodes( tree.children[n] );
    }

    this._tree = tree
    this._recalc = false
    return tree
  }

  getNode( root, path ) {
    if ( root.children == null ) {
      root.children = []
    }
    // put all root level things into one container
    if ( path.length === 0 ) {
      path = [ '' ]
    }

    let cnode = root;
    
    let pathname = ''
    for ( let n = 0; n < path.length; n++ ) {
      pathname = n === 0 ? path[n] : `${pathname}/${path[n]}`;
      const cIndex = this.findChild( cnode.children, path[n] )

      if ( cIndex < 0 ) {
        const child = { type : 'group', pathname, name : path[n], children : [] }
        cnode.children.push( child )
        cnode = child
      } else {
        cnode = cnode.children[cIndex];
      }
    }
    return cnode;
  }

  findChild( arr, name ) {
    for ( let n = 0; n < arr.length; n++ ) {
      if ( arr[n].type === 'group' && arr[n].name === name ) {
        return n;
      }
    }
    return -1;
  }

  createGroupFunction( attrs, tags ) {
    if ( tags == null || tags.length === 0 ) {
      return function( attr ) { 
        return Path( attr.path ).removeLast().path(); 
      }
    } 
    return function( attr ) {
      let atags
      if ( attr.tags == null ) { 
        atags = Path( attr.path ).pathArray();
      } else {
        atags = attr.tags
      }
      const rtn = []
      for ( let t = 0; t < tags.length; t++ ) {
        if ( atags.indexOf( tags[t] ) >= 0 ) {
          rtn.push( tags[t] );
        }
      }

      return rtn.length === 0 ? null : rtn;
    }
  }

  consolidateNodes( node ) {
    if ( node.type === 'group' ) {
      for ( let n = 0; n < node.children.length; n++ ) {
        this.consolidateNodes( node.children[n] )
      }
      if ( node.children.length === 1 && node.children[0].type === 'group' ) {
        if ( node.children[0].name !== node.name ) {
          node.name = `${node.name} ${node.children[0].name}`
        }
        node.pathname = node.children[0].pathname
        node.children = node.children[0].children;
      } else if ( node.children.length === 1 ) {
        node.type = 'attribute'
        if ( node.children[0].name !== node.name ) {
          node.name = `${node.name} ${node.children[0].name}`
        }
        node.pathname = node.children[0].pathname
        node.attribute = node.children[0].attribute
        delete node.children
      } else if ( node.children.length > 1 ) {

        // make sure they have diff names
        const conflicts = {}
        const visit = [];
        for ( let n = 0; n < node.children.length; n++ ) {
          const c = node.children[n];
          if ( c.type !== 'group' ) {
            conflicts[c.name] = conflicts[c.name] || [];
            conflicts[c.name].push( n );
            if ( conflicts[c.name].length > 1 ) {
              if ( visit.indexOf( c.name ) < 0 ) {
                visit.push( c.name );
              }
            }
          }
          
        }
        for ( let n = 0; n < visit.length; n++ ) {
          const key = visit[n];
          const indices = conflicts[key];
          let resolved = false;
          let depth = 2;
          while ( !resolved ) {
            const r = {};
            resolved = true;
            for ( let n = 0; n < indices.length; n++ ) {
              const cnode = node.children[indices[n]];
              cnode.name = Path( cnode.attribute.path ).pathArray().slice( -depth ).join( ' ' )
              r[cnode.name] = r[cnode.name] == null ? 1 : r[cnode.name] + 1;
              if ( r[cnode.name] > 1 ) {
                resolved = false;
              }
            }
            depth++;
          }
        }
        for ( let n = 0; n < node.children.length; n++ ) {
          const cn = node.children[n]
          if ( cn.type !== 'group' && cn.name.endsWith( node.name ) && cn.name.length !== node.name.length ) {
            cn.name = cn.name.substring( 0, cn.name.length - node.name.length )
          }
        }
      }
    }
  }
}

export default TreeOrganizer
