import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Plugins } from '@leverege/plugin'
import { Label, Pane, Popup, Theme, Button, Flex } from '@leverege/ui-elements'

import { LatLonEditor } from './geoPointEditors'
import S from './GeoPointEditor.css'

export default class GeoPointEditor extends React.Component {

  static contextType = Theme.Context

  static factory = Plugins.createFactory( 'GeoPointEditor', {
    strategy : Plugins.reactStrategy( React, 'model' ),
    pluginKey : 'editor', 
    pluginTypeKey : 'type' 
  } )

  static editors = Plugins.createView( 'GeoPointEditor', { 
    name : 'editors', 
    filter : ( plg ) => { return plg.editor != null } 
  } )

  static actions = Plugins.createView( 'GeoPointEditor', { 
    name : 'actions', 
    filter : ( plg ) => { return plg.action != null && plg.location !== 'top' } 
  } )

  static topLevelActions = Plugins.createView( 'GeoPointEditor', { 
    name : 'topLevelActions', 
    filter : ( plg ) => { return plg.action != null && plg.location === 'top' } 
  } )

  static propTypes = {
    label : PropTypes.string,
    showLabel : PropTypes.bool,
    format : PropTypes.string,
    value : PropTypes.shape( {
      lat : PropTypes.number,
      lon : PropTypes.number
    } ),
    onChange : PropTypes.func,
    onChangeFormat : PropTypes.func,
    editorProps : PropTypes.object,
    editorPopupClassName : PropTypes.string,
    editorPopupVariant : PropTypes.string,
    containerClassName : PropTypes.string,
    containerVariant : PropTypes.string,
    headerVariant : PropTypes.string,
    headerClassName : PropTypes.string,
    titleVariant : PropTypes.string,
    titleClassName : PropTypes.string,
    editorContainerVariant : PropTypes.string,
    editorContainerClassName : PropTypes.string,
    actionButtonsContainerClassName : PropTypes.string,
    actionButtonsContainerVariant : PropTypes.string,
    actionButtonClassName : PropTypes.string,
    actionButtonVariant : PropTypes.string,
    showMenu : PropTypes.bool
  }

  static defaultProps = {
    label : 'Position',
    showLabel : true,
    format : LatLonEditor.TYPE,
    value : null,
    onChange : () => {},
    onChangeFormat : () => {},
    editorProps : {},
    editorPopupClassName : null,
    editorPopupVariant : 'geoEditor',
    containerClassName : null,
    containerVariant : 'geoEditor',
    headerClassName : null,
    headerVariant : 'geoEditorHeader',
    titleClassName : null,
    titleVariant : 'geoEditor|input',
    editorContainerClassName : null,
    editorContainerVariant : 'geoEditorContent',
    actionButtonsContainerClassName : null,
    actionButtonsContainerVariant : 'geoEditor',
    actionButtonClassName : null,
    actionButtonVariant : 'geoEditorActionButton',
    showMenu : true
  }

  constructor( props ) {
    super( props )
    this.state = { action : null }
  }

  static getDerivedStateFromProps( props, state ) {
    if ( state.format == null ) {
      return { format : props.format || 'latLon' }
    }
    return null
  }

  componentWillUnmount( ) {
    const { action } = this.state
    if ( action ) {
      action.cancel()
    }
  }

  cancelAction( setState = false ) {
    const { action } = this.state
    if ( action ) {
      action.cancel()
      if ( setState ) {
        this.setState( { action : null } )
      }
    }
  }

  onDisplayFormatChange = ( evt ) => {
    const { onChangeFormat, eventData } = this.props
    this.setState( { format : evt.data } )
    if ( onChangeFormat ) {
      onChangeFormat( { value : evt.data, data : eventData } )
    }
    this.cancelAction( true )
  }

  onAction = ( evt ) => {
    const { data } = evt
    const { value, onChange, eventData } = this.props
    
    this.cancelAction( false )
    const action = data.action( value )
    this.setState( { action } )
    action.result().then( ( val ) => { 
      if ( val ) {
        onChange( { value : val, data : eventData } )
        this.setState( { action : null } )
      }
    } )
  }

  render() {    
    const theme = this.context
    const {
      value,
      label,
      showLabel,
      onChange,
      eventData,
      variant,
      className,
      editorProps,
      editorPopupClassName,
      editorPopupVariant,
      containerClassName,
      containerVariant,
      headerVariant,
      headerClassName,
      titleVariant,
      titleClassName,
      editorContainerVariant,
      editorContainerClassName,
      actionButtonsContainerClassName,
      actionButtonsContainerVariant,
      actionButtonClassName,
      actionButtonVariant,
      showMenu : pShowMenu
    } = this.props
    let { format : currFormat } = this.state
    const cn = theme.classes( 'GeoPointEditor', 'geoPointEditor', variant, className )

    let Clazz = GeoPointEditor.factory.get( currFormat )
    if ( Clazz == null ) {
      // make sure format exists. 
      currFormat = GeoPointEditor.editors.get()[0].type
      Clazz = GeoPointEditor.factory.get( currFormat )
    }
    const currPlg = GeoPointEditor.editors.get().find( plg => plg.type === currFormat )
    const contents = <Clazz value={value} {...editorProps} onChange={onChange} eventData={eventData} />
    const avail = GeoPointEditor.editors.get()
    const actions = GeoPointEditor.actions.get()
    const topActions = GeoPointEditor.topLevelActions.get()
    const title = avail.length > 1 ? currPlg.name : <i className="fa fa-ellipsis-v " />
    const showMenu = pShowMenu && ( actions.length > 0 || avail.length > 1 )

    return (
      <Pane variant={containerVariant} className={classnames( cn, S.container, containerClassName )}>
        <Pane variant={headerVariant} className={classnames( S.header, headerClassName )}>
          {showLabel && <Label variant={titleVariant} className={classnames( S.title, titleClassName )}>{label}</Label>}
          {
            topActions.length > 0 && (
              <Flex variant={actionButtonsContainerVariant} className={classnames( S.actionButtons, actionButtonsContainerClassName )}>
                {
                  // TODO: Change this the ui-plugin menu creation mechanism
                  topActions.map( ( plg ) => {
                    return ( 
                      <Button
                        icon={plg.fontIcon}
                        key={plg.type} 
                        onClick={this.onAction} 
                        eventData={plg}
                        variant={actionButtonVariant}
                        className={classnames( S.actionButton, actionButtonClassName )}
                        {...plg.buttonProps}>
                        {typeof plg.name === 'function' ? plg.name() : plg.name}
                      </Button>
                    )
                  } )
                }
              </Flex>
            )
          }
          { showMenu && (
            <Popup variant={editorPopupVariant} className={classnames( S.editorPopup, editorPopupClassName )} title={title}>
              {
                avail.length > 1 && avail.map( ( plg ) => {
                  // TODO: These should be radio buttons (have check beside active one)
                  return ( 
                    <Popup.Item key={plg.type} 
                      checkStyle="check"
                      checked={plg.type === currFormat}
                      onClick={this.onDisplayFormatChange} 
                      eventData={plg.type}>
                      {typeof plg.name === 'function' ? plg.name() : plg.name}
                    </Popup.Item>
                  )
                } )
              }
              { avail.length > 1 && GeoPointEditor.actions.get().length > 0 && <Popup.Divider /> }
              {
                // TODO: Change this the ui-plugin menu creation mechanism
                actions.map( ( plg ) => {
                  return ( 
                    <Popup.Item key={plg.type} 
                      onClick={this.onAction} 
                      eventData={plg}>
                      {typeof plg.name === 'function' ? plg.name() : plg.name}
                    </Popup.Item>
                  )
                } )
              }
            
            </Popup>
          ) }
        </Pane>
        <Pane variant={editorContainerVariant} className={classnames( S.editorContainer, editorContainerClassName )}>
          {contents}
        </Pane>
      </Pane>
    )

  }
}
