import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util' 

const { isPathValid, isPathCollection } = Util

const TYPE = 'geoJson';

export default {
  
  name() {
    return 'GeoJson'
  },

  typeName() {
    return TYPE
  },

  categoryType() {
    return 'geo'
  },

  create( path, name ) {
    if ( !isPathValid( path ) ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }

    return { 
      type : this.typeName(), 
      path : path || 'geoJson',
      isCollection : isPathCollection( path ),
      name : name || path, 
      desc : null,
      tags : []
    }
  },

  createFrom( attr ) {
    if ( attr.type === this.typeName() ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );

    return convert;
  } 
}
