/* eslint-disable react/no-array-index-key */
import React from 'react'
import { PropertyGrid } from '@leverege/ui-elements'
import { useValueChange } from '@leverege/ui-hooks'
import { DragDropView, ModelEditorFactory, Toolbar, useSharedContext } from '@leverege/ui-plugin'
import { RuleModel } from '@leverege/condition'
import S from './ObjectizerEditor.css'

const RuleMatches = { 
  client : 'condition.ObjectizerEditor',
  use : 'ruleActionBar'
}

export default function RuleRow( props ) {
  const { 
    objectizerType = 'objectizer', onDrop, onRemove, index,
    conditionLabel, valueLabel,
    valueEditor : ValueEditor, valueProps, 
    value } = props
    
  const { conditionOptions, conditionCreators } = useSharedContext()
  const onChange = useValueChange( RuleModel, props )

  const ruleCondition = RuleModel.getCondition( value )
  const ruleValue = RuleModel.getValue( value )
  
  const cProps = { 
    value : ruleCondition, 
    eventData : 'setCondition',
    onChange
  }
  const conditionEditor = ModelEditorFactory.create( ruleCondition, cProps )
 
  return (
    <DragDropView 
      accept={objectizerType} 
      dragType={objectizerType} 
      onDrop={onDrop} 
      onRemove={onRemove} 
      value={value} 
      index={index}>
      <div className={S.rule}>
        <PropertyGrid>
          <PropertyGrid.Item label={conditionLabel}>
            {conditionEditor}
          </PropertyGrid.Item>
          <PropertyGrid.Item label={valueLabel}>
            <ValueEditor 
              {...valueProps}
              eventData="setValue" 
              onChange={onChange} 
              value={ruleValue}/>
          </PropertyGrid.Item>
        </PropertyGrid>
        <span>
          <Toolbar
            variant="conditionBar|actionBar"
            prefer="icon"
            matchContext={RuleMatches}
            context={props} />
        </span>
      </div>
    </DragDropView>
  )
}
