/* eslint-disable max-len */
import React from 'react'
import classnames from 'classnames'
import { Pane } from '@leverege/ui-elements'

import DnDUtil from './DnDUtil.js'

import S from './DragDropView.css'

const { useModelDrag, useModelDrop } = DnDUtil
const EMPTY = [ { isDragging : false, isOver : false }, null ]

/**
 * This will create a Drop Target around the given children and a Drag Source around
 * the first child. No Drop Target is created if onDrop or accept is null. No DragSource
 * is create if dragType or onRemove is null
 * @param {Object} props 
 * @param {Object} props.value The Model data for the item represented by this view 
 * @param {number} props.index The index in the containing array
 * @param {String} props.accept The type of drag types accepted by this drop area 
 * @param {Object} props.dragType The type of object that will be created when a drag occurs.
 * @param {Object} props.onDrop The method to invoke on drop, usually created by useInstanceCallback
 * with DnDUtil.dropItem
 * @param {Object} props.onRemove The method to invoke on drag completion from one list to a different target list, 
 * usually created by useInstanceCallback with DnDUtil.removeItem
 * @returns a React Component
 */
export default function DragDropView( props ) {
  const { value, index, variant, className, onDrop, onRemove, children, accept, dragType } = props
  const [ { isDragging }, dragRef ] = onRemove && dragType ? useModelDrag( { dragType, value, onRemove } ) : EMPTY
  const [ { isOver }, dropRef ] = onDrop && accept ? useModelDrop( { accept, onDrop, value, index, isDragging } ) : EMPTY
  const cn = classnames( S.item, className, isOver && S.dropTarget, isDragging && S.dragging )
  let editor = children, rest = null
  if ( Array.isArray( children ) ) {
    editor = children[0]
    rest = children.slice( 1 )
  } 
 
  if ( variant ) {
    return (
      <Pane variant={variant} domRef={dropRef} className={cn}>
        <div ref={dragRef}>
          {editor}
        </div>
        {rest}
      </Pane>
    )
  }

  return (
    <div ref={dropRef} className={cn}>
      <div ref={dragRef}>
        {editor}
      </div>
      {rest}
    </div>
  )
}
