// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.renderer_zRfVz {
  text-transform: capitalize;
}
.check_f1RNX {
  opacity: 0;
  margin-right: 16px !important;
}

.selected_ZJrQi {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/shared/Selector.css"],"names":[],"mappings":";AACA;EACE,0BAA0B;AAC5B;AACA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\n.renderer {\n  text-transform: capitalize;\n}\n.check {\n  opacity: 0;\n  margin-right: 16px !important;\n}\n\n.selected {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"renderer": `renderer_zRfVz`,
	"check": `check_f1RNX`,
	"selected": `selected_ZJrQi`
};
export default ___CSS_LOADER_EXPORT___;
