import React from 'react'
import classnames from 'classnames'
import { Popup, TextInput, CompositeTextInput } from '@leverege/ui-elements'
import PropTypes from 'prop-types'

import S from './Geocoder.css'

const MAPBOX_APIKEY = process.env.MAPBOX_APIKEY
const MAPBOX_ENDPOINT = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places'

/**
 * Searches a places API (e.g., mapbox places) for a string, and returns a list of matching
 * geocoded results. Can be used as a component with a search bar and results display
 * or static methods can be used to capture queries and results without mounting the
 * component.
 *
 * As a Component: <Geocoder onSelect={console.log} />
 *
 * As a static class: Geocoder.queryMapbox( 'Leverege HQ', { near : '71.234,-31.523' } )
 */
export default class Geocoder extends React.Component {
  /**
   * @param {string} searchTerm
   * @param {object} opts
   * @param {string} opts.near `lon,lat` : biases results to those nearest the given lon,lat
   * @param {string} opts.apiKey mapbox api key to use
   * @param {string} opts.endpoint mapbox places endpoint (default : https://api.tiles.mapbox.com/geocoding/v5/mapbox.places )
   * @returns {object} results: results.features is an array of the hits
   */
  static async queryMapbox( searchTerm, opts = {} ) {
    const { near, apiKey = MAPBOX_APIKEY, endpoint = MAPBOX_ENDPOINT } = opts
    const uri = Geocoder.createQueryURI( searchTerm, { proximity : near, apiKey, endpoint, source : 'mapbox' } )
    const res = await fetch( uri, { headers : { Accept : 'application/json' } } )
    const jsonRes = await res.json()
    return Geocoder.processResults( { type : 'mapbox', results : jsonRes } )
  }

  /**
   * Creates a URI to query the source API
   * @param {string} searchTerm
   * @param {object} opts
   * @param {string} opts.proximity `lon,lat` : biases results to those nearest the given lon,lat
   * @param {string} opts.source which API to use (mapbox, google, bing) (currently only mapbox is supported)
   * @param {string} opts.endpoint API endpoint to use
   * @param {string} opts.apiKey API key for source
   */
  static createQueryURI( searchTerm, opts ) {
    const { endpoint, proximity, source, apiKey } = opts
    let uri
    if ( source === 'mapbox' ) {
      const prox = proximity ? `&proximity=${proximity}` : ''
      uri = `${endpoint}/${encodeURIComponent( searchTerm )}.json?access_token=${apiKey}${prox}`
    }
    return uri
  }

  /**
   * Query function to use for a given api
   */
  static getQueryBySource( source ) {
    switch ( source.toLowerCase() ) {
      case 'mapbox':
        return Geocoder.queryMapbox
      default:
        return null
    }
  }

  /**
   * Function for normalizing results array from a given source
   * @param {object} data
   * @param {string} data.type the type of processor to use
   * @param {any} data.results the results to process
   * @returns {object[]} { id, location : <String>, position : { lat, lon } }
   */
  static processResults( data ) {
    const { results } = data
    switch ( data.type.toLowerCase() ) {
      case 'mapbox':
        return results?.features.map( f => ( {
          ...f,
          location : f.place_name,
          position : { lat : f.center?.[1], lon : f.center?.[0] }
        } ) )
      default:
        return []
    }
  }

  static propTypes = {
    className : PropTypes.string,
    /** CSS class for the wrapping <div> component */
    onChange : PropTypes.func.isRequired,
    /** What happens when a user selects a result from the list */
    onInputChange : PropTypes.func,
    /** What, besides querying the API, happens when a user submits a query */
    onResults : PropTypes.func,
    /** Called with query results */
    eventData : PropTypes.any,
    /** Returned as data field on select */
    inputVariant : PropTypes.string,
    /** Builder theme variant for TextInput */
    inputClass : PropTypes.string,
    /** CSS class for the TextInput search field */
    resultsVariant : PropTypes.string,
    /** Builder theme variant for Popup that surrounds the results list */
    resultsClass : PropTypes.string,
    /** CSS class for the PopUp with results */
    value : PropTypes.string,
    /** Value passed to input field */
    useUserLocation : PropTypes.bool,
    /** If true, and props.near is nullish, attempts to use browser's geolocation api to bias results to those nearby user */
    near : PropTypes.string,
    /** 'lon,lat' format - if present, biases results to those near the given lon,lat */
    source : PropTypes.string,
    /** Which places api to use. Currently only mapbox is supported  */
    apiKey : PropTypes.string,
    /** API key for places API (defaults to value of MAPBOX_APIKEY from env) */
    endpoint : PropTypes.string,
    /** API endpoint (defaults to mapbox v5) */
    disabled : PropTypes.bool,
    /** disables the input field */
    hint : PropTypes.string,
    /** input field hint */
    focus : PropTypes.bool,
    /** input field focus */
    icon : PropTypes.any,
    iconOpts : PropTypes.object,
    imageOpts : PropTypes.object,
  }

  static defaultProps = {
    className : null,
    eventData : null,
    inputClass : null,
    inputVariant : null,
    resultsVariant : null,
    resultsClass : null,
    onInputChange : () => {},
    onResults : () => {},
    value : '',
    useUserLocation : true,
    near : '',
    source : 'mapbox',
    apiKey : MAPBOX_APIKEY,
    endpoint : MAPBOX_ENDPOINT,
    disabled : false,
    hint : ''
  }

  constructor( props ) {
    super( props )
    const { source } = this.props

    this.query = Geocoder.getQueryBySource( source )
    this.state = {
      results : [],
      query : props.value,
      currentLocation : null
    }
  }

  componentDidMount() {
    const { useUserLocation } = this.props
    if ( useUserLocation ) {
      this.getUserLocation()
    }
  }

  componentDidUpdate( prevProps ) {
    const { useUserLocation, value } = this.props
    if ( useUserLocation && !prevProps.useUserLocation ) {
      this.getUserLocation()
    }
    if ( !useUserLocation && prevProps.useUserLocation && this.watchId ) {
      navigator.geolocation.clearWatch( this.watchId )
    }
    if ( value !== prevProps.value ) {
      this.setState( { query : value } ) // eslint-disable-line
    }
  }

  componentWillUnmount() {
    if ( this.watchId ) {
      navigator.geolocation.clearWatch( this.watchId )
    }
  }

  setCurrentLocation = ( currentLocation ) => {
    this.setState( { currentLocation } )
  }

  getUserLocation = () => {
    if ( navigator.geolocation ) {
      this.watchId = navigator.geolocation.watchPosition( this.setCurrentLocation )
    }
  }

  getProximity = () => {
    const { useUserLocation } = this.props
    const { currentLocation } = this.state
    if ( useUserLocation && currentLocation ) {
      const lat = currentLocation?.coords?.latitude
      const lon = currentLocation?.coords?.longitude
      if ( lat && lon ) {
        return `${lon},${lat}`
      }
    }
    return ''
  }

  getQueryOpts = () => {
    const { near, apiKey, endpoint } = this.props
    const proximity = near || this.getProximity()
    return { apiKey, endpoint, near : proximity }
  }

  onSearch = async ( evt, { setOpened, isOpened } ) => {
    const { onInputChange, onResults } = this.props
    const { value : query } = evt
    onInputChange( evt )
    this.setState( { query } )
    if ( !query ) return
    const queryOpts = this.getQueryOpts()
    const results = await this.query( query, queryOpts )
    if ( results?.length && !isOpened ) {
      setOpened( true )
    }
    onResults( results )
    this.setState( { results } )
  }

  /**
   * @returns {object} { value : <place information>, data : <eventData supplied as prop> }
   */
  onSelect = ( { data } ) => {
    const { onChange, eventData } = this.props
    this.setState( { query : data.location } )
    if ( typeof onChange === 'function' ) {
      onChange( { value : data, data : eventData } )
    }
  }

  renderGeocoder = ( { ref, setOpened, isOpened } ) => {
    const { className, inputClass, inputVariant, disabled, hint, focus, icon, iconOpts, imageOpts } = this.props

    const { query } = this.state
    this.setOpened = setOpened
    const IClass = icon ? CompositeTextInput : TextInput
    const input = (
      <IClass
        hint={hint}
        focus={focus}
        icon={icon}
        iconOpts={iconOpts}
        imageOpts={imageOpts}
        value={query}
        className={classnames( S.input, inputClass )}
        alwaysFireOnReturn={false}
        variant={inputVariant}
        disabled={disabled}
        changeOnReturn={false}
        onChange={q => this.onSearch( q, { setOpened, isOpened } )} />
    )
    return (
      <div ref={ref} className={className}>
        {input}
      </div>
    )
  }

  renderResults = ( results = [] ) => {
    return results.map( place => (
      <Popup.Item
        eventData={place}
        onClick={this.onSelect}
        key={place.id}>
        {place.place_name}
      </Popup.Item>
    ) )
  }

  render() {
    const { resultsVariant, resultsClass } = this.props
    const { results } = this.state
    const items = this.renderResults( results )
    return (
      <Popup className={resultsClass} variant={resultsVariant} trigger={this.renderGeocoder}>
        {items}
      </Popup>
    )
  }
}
