import { Layout } from '@leverege/plugin'
import Prom from '@leverege/promise'

function handles( items, cxt ) {
  if ( items == null || items.length === 0 ) {
    return false
  }

  return items.some( ( item ) => {
    if ( item.type === Layout.NODE_TYPE ) {
      return handles( item.items, cxt )
    }
    if ( item.handles == null ) {
      // eslint-disable-next-line no-console
      console.log( 'Missing handles() on ', item.action )
    }
    return item.handles != null && item.handles( cxt )
  } )
}

function itemIsPrepared( item, cxt ) {
  if ( item?.type === Layout.NODE_TYPE ) {
    return isPrepared( item.items, cxt )
  }
  if ( typeof item?.isPrepared === 'function' ) {
    try {
      return item.isPrepared( cxt )
    } catch ( err ) {
      console.warn( 'isPrepared function threw error', item, err )
      // fallthrough to true
    }
  }
  return true
}

function isPrepared( items, cxt ) {
  if ( Array.isArray( items ) ) {
    // note: array.every returns true for length 0 arrays
    return items.every( i => itemIsPrepared( i, cxt ) )
  }
  return itemIsPrepared( items, cxt )
}

function prepareItem( item, cxt, options ) {
  const { isPreparing, onPreparing } = options
  if ( item?.type === Layout.NODE_TYPE ) {
    return prepare( item.items, cxt, options )
  }
  if ( !itemIsPrepared( item, cxt ) && item.id && !isPreparing( item.id ) ) {
    return onPreparing( item.id, item.prepare( cxt ) )
  }
  return Promise.resolve( null )
}

async function prepare( items, cxt, options ) {
  if ( Array.isArray( items ) ) {
    return Prom.all( items.map( i => prepareItem( i, cxt, options ) ), { handleReject : true } )
  }
  return prepareItem( items, cxt, options )
}

/**
 * This will perform a shallow one level deep comparison
 */
function shallowEquals( a, b, indx ) {
  if ( a === b || ( a == null && b == null ) ) {
    return true
  }

  if ( a == null || b == null ) {
    return false
  }

  // perform a shallow equals on the keys in a and b
  const ak = Object.keys( a )
  const bk = Object.keys( b )
  if ( ak.length !== bk.length ) {
    return false
  }
  for ( let n = 0; n < ak.length; n++ ) {
    if ( a[ak[n]] !== b[ak[n]] ) {
      return false
    }
  }
  return true
}

const variantCache = { }

function variantWith( base, ext ) {
  if ( ext == null ) {
    return base
  }
  if ( base == null ) {
    return ext
  }
  let cached = variantCache?.[base]?.[ext]
  if ( cached ) {
    return cached
  }
  const bases = base.split( '|' )
    .map( m => m.trim() )
    .filter( m => m.length > 0 )
    .map( b => b[0].toUpperCase() + b.substring( 1 ) )
  const exts = ext.split( '|' ).map( m => m.trim() ).filter( m => m.length > 0 )

  cached = bases.map( ( b ) => {
    return exts.map( x => x + b ).join( '|' )
  } ).join( '|' )
  cached = `${cached}|${base}`
  variantCache[base] = variantCache[base] || {}
  variantCache[base][ext] = cached
  return cached
}

function sortLayoutNodeItems( node ) {
  const items = Array.isArray( node ) ? node : node.items
  return items.sort( ( a, b ) => ( a?.layout?.sort < b?.layout?.sort ? -1 : a?.layout?.sort > b?.layout?.sort ? 1 : 0 ) )
}

export default {
  shallowEquals,
  handles,
  variantWith,
  sortLayoutNodeItems,
  prepare,
  isPrepared
}
