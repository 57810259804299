export default {
  
  name() {
    return 'Step GeoPoint'
  },

  typeName() {
    return 'stepGeoPoint' 
  },

  handles( attributeType ) {
    return attributeType.typeName() === 'geoPoint'
  },

  create() { 
    return { 
      type : this.typeName(), 
      update : 30000,
      stepAt : 1
    }
  }
}
