export default {
  
  name() {
    return 'Linear'
  },

  typeName() {
    return 'linear' 
  },

  handles( attributeType ) {
    return attributeType.categoryType() === 'float'
  },

  create() { 
    return { 
      type : this.typeName(), 
      update : 30000,
      addNoise : false,
      range1 : 0.1,
      range2 : -0.1
    }
  }
}
