import GeoPointEditor from './GeoPointEditor.jsx'
import Geocoder from './Geocoder.jsx'

export {
  GeoPointEditor,
  Geocoder
}

export default {
  GeoPointEditor,
  Geocoder
}
