import React from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend'

import I18N from '@leverege/i18n'
import { Pane, Flex, Popup, Label, NumericInput, Text, TextInput } from '@leverege/ui-elements'
import { useInstanceCallback, useValueChange } from '@leverege/ui-hooks'

import DashboardContext, { useDashboardContext } from '../shared/DashboardContext'
import GridEditor from './GridEditor'
import CardGrid from './CardGrid'
import { CardTitle } from '../shared'
import { Dashboard } from '../model'
import CardSelector from './CardSelector'

const { t } = I18N.ns( 'uiDashboard.editor.DashboardEditor' )

const HTML5toTouch = {
  backends : [
    {
      id : 'html5',
      backend : HTML5Backend,
      transition : MouseTransition,
    },
    {
      id : 'touch',
      backend : TouchBackend,
      options : { enableMouseEvents : true },
      preview : true,
      transition : TouchTransition,
    },
  ],
}

function DashboardEditor( props ) {
  const { 
    models, value, sharedProps, editors, viewers, optionEditors, eventData, onChange,
    style, className, options, variant = 'dashboardEditor', variants,
    addWidgetText, deferEditing, objectType, getModelsFor, getModelCreators } = props

  const dashboardContext = useDashboardContext()
  const { columns : maxColumns } = dashboardContext
  const onModelChange = useValueChange( Dashboard, props )
  const [ onAdd ] = useInstanceCallback( [ CardGrid.addCard, CardGrid.removeCard ], { ...props, dashboardContext } )
  
  return (
    <div style={style}>
      <DndProvider options={HTML5toTouch}>
        <DashboardContext 
          variants={variants} 
          addWidgetText={addWidgetText}
          models={models} 
          editors={editors} 
          viewers={viewers} 
          optionEditors={optionEditors}
          sharedProps={sharedProps}
          deferEditing={deferEditing}
          objectType={objectType}
          getModelsFor={getModelsFor}
          getModelCreators={getModelCreators}
          depth={0} 
          options={options}>
          <Pane variant={variant} style={style} className={className}>
            <CardTitle title={t( 'title' )}>
              <Flex style={{ flexGrow : 1 }}>
                <Popup variant="dashboardEditorPopup|dashboardCardEditorPopup" title={<i className="fa fa-gear"/>}>
                  <Flex variant="colL">
                    <LayoutEditor value={value} onChange={onModelChange} maxColumns={maxColumns}/>
                  </Flex>
                </Popup>
                <CardSelector
                  onSelect={onAdd} />
              </Flex>
            </CardTitle>
            <GridEditor value={value} eventData={eventData} onChange={onChange} onAdd={onAdd} />
          </Pane>
        </DashboardContext>
      </DndProvider>
    </div>
  )
} 

export default React.memo( DashboardEditor )

function LayoutEditor( props ) {
  const { onChange, value, maxColumns } = props

  return (
    <Pane variant="padding">
      <Flex variant="colM" align="stretch">
        <Text variant="title">{t( 'gridOptions' )}</Text>
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'columns' )}</Label>
          <NumericInput 
            variant="dashboardCardEditor|small" 
            size={2} 
            value={value.columns || 1} 
            eventData="setColumns" 
            min={1} 
            max={maxColumns} 
            onChange={onChange}/>
        </Flex>        
        <Flex variant="rowM" align="center" justify="space-between">
          <Label variant="dashboardCardEditor|small">{t( 'columnWidth' )}</Label>
          <TextInput variant="dashboardCardEditor|small" 
            size={8} 
            value={value.columnSize || '1fr'} 
            eventData="setColumnSize" 
            min={1} 
            onChange={onChange}/>
        </Flex>
      </Flex>
    </Pane>
  )
}
