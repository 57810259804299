/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from 'react'

import I18N from '@leverege/i18n'
import { Dialog } from '@leverege/ui-elements'
import { useInstanceCallback } from '@leverege/ui-hooks'

import { Card, Grid } from '../model'
import PlaceholderCard from './PlaceholderCard'
import { useDashboardContext } from '../shared/DashboardContext'

import S from './CardGrid.css'

const { t } = I18N.ns( 'uiDashboard.editor.CardGrid' )
  
function addCard( vars, evt ) {
  const { value, onChange, eventData, dashboardContext } = vars
  // TODO: check this 
  const content = evt.data.create( null, dashboardContext )
  let nCard = evt.data.isCard === true ? content : Card.create( content, dashboardContext )

  // If this is a layout card (like a grid) hide its title by default
  if ( evt.data.isLayout ) {
    nCard = Card.setTitleVisible( nCard, false )
  }

  if ( evt.data.isLayout === true ) {
    if ( typeof Grid.getColumns( value ) === 'number' ) {
      nCard.width = Grid.getColumns( value )
    }
  }

  const nModel = Grid.addCard( value, nCard )
  if ( onChange && nModel !== value ) {
    onChange( { value : nModel, changing : false, data : eventData } )
  }
}

function changeCard( { value, onChange, eventData, keyFor }, evt ) {
  const cValue = Grid.getCard( value, evt.data )
  const nValue = Grid.setCard( value, evt.data, evt.value )
  if ( onChange && nValue !== value ) {
    keyFor.current.moveKey( cValue, evt.value )
    onChange( { value : nValue, changing : false, data : eventData } )
  }
}

function removeCard( { value, onChange, eventData }, evt ) {
  const nValue = Grid.removeCard( value, evt.data )
  if ( onChange && nValue !== value ) {
    onChange( { value : nValue, changing : false, data : eventData } )
  }
}

function dropCard( { value, onChange, eventData }, evt ) {
  let nModel
  const curr = Grid.indexOfCard( value, evt.item )
  const move = curr >= 0
  if ( move ) {
    nModel = Grid.moveCard( value, curr, evt.index )
  } else { 
    const item = JSON.parse( JSON.stringify( evt.item ) )
    nModel = Grid.addCard( value, item, evt.index )
  }
  if ( onChange && nModel !== value ) {
    onChange( { value : nModel, changing : false, data : eventData } )
  }
  return move
}

function CardGrid( props ) {
  let { value } = props
  value = value || {}
  const { onChange, eventData, placeholderContent, showPlaceholder = true } = props
  const dCxt = useDashboardContext( )
  const { editors, sharedProps } = dCxt
  const keyFor = useRef( createKeyFor() )
  const [ cardToRemove, setCardToRemove ] = useState( null )
  const [ onCardChange, onRemoveCard, onDropCard ] = useInstanceCallback( 
    [ changeCard, removeCard, dropCard ], 
    { ...props, keyFor, dashboardContext : dCxt } 
  )

  // needs to be bound to current closure
  const onRemoveCardPrompt = ( evt ) => { 
    if ( evt.prompt === false || ( evt.sourceEvent && evt.sourceEvent.shiftKey ) ) {
      onRemoveCard( evt )
      setCardToRemove( null ) 
    } else {
      setCardToRemove( evt.data ) 
    }
  }

  const columns = Math.max( 1, value.columns || 2 )
  const cards = Grid.getCards( value )
  const style = {
    gridTemplateColumns : `repeat( ${columns}, ${value.columnSize || '1fr'} )`,
    rowGap : value.rowGap,
    columnGap : value.columnGap
  }
  return (
    <div className={S.cardLayout} style={style} >
      { 
        cards.map( ( card, idx ) => {
          const props = {
            key : keyFor.current( card ),
            value : card, 
            onChange : onCardChange,
            onRemoveCard : onRemoveCardPrompt,
            onDropCard,
            eventData : idx,
            sharedProps
          }
          return editors.create( card, props )
        } ) 
      }
      { showPlaceholder && (
        <PlaceholderCard value={value} onChange={onChange} eventData={eventData} index={cards.length}>
          {placeholderContent}
        </PlaceholderCard> 
      ) }
      <Dialog.Question 
        show={cardToRemove != null}
        title={t( 'dialogTitle' )}
        message={t( 'dialogMessage' )}
        okay={t( 'dialogOkay' )}
        eventData={cardToRemove}
        onCancel={( ) => setCardToRemove( null )}
        onOkay={evt => onRemoveCardPrompt( { ...evt, prompt : false } )} />
    </div>
  )
} 

/**
 * Creates a function that will map an object to a key for use in 
 * react's key mechanism
 */
function createKeyFor( ) {
  let K = 1
  const map = new Map()
  const f = ( obj ) => {
    let key = map.get( obj )
    if ( key == null ) {
      key = K++
      map.set( obj, key )
    }
    return key
  }
  f.remove = obj => map.delete( obj )
  f.moveKey = ( obj, toObj ) => {
    const k = map.get( obj )
    if ( k ) {
      map.set( toObj, k )
      map.delete( obj )
    }
  }
  return f
}

const pure = React.memo( CardGrid )
pure.addCard = addCard
pure.removeCard = removeCard
pure.changeCard = changeCard
pure.dropCard = dropCard

export default pure
