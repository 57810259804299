import { unit } from 'mathjs'
import Path from '@leverege/path';

import Util from './Util.js'

/* Initialize Globals */
const types = {};
let unitTypes = null;
const scales = {}
const unitBySymbol = { }

/* Initialize Constants */
const LENGTH = 'length';
const SPEED = 'speed';
const ACCELERATION = 'acceleration';
const SURFACE = 'surface';
const VOLUME = 'volume';
const MASS = 'mass';
const TIME = 'time';
const FREQUENCY = 'frequency';
const ANGLE = 'angle';
const CURRENT = 'current';
const TEMPERATURE = 'temperature';
const AMOUNT_OF_SUBSTANCE = 'substanceAmount';
const LUMINOUS_INTENSITY = 'luminousIntensity';
const FORCE = 'force';
const ENERGY = 'energy';
const POWER = 'power';
const PRESSURE = 'pressure';
const ELECTRIC_CHARGE = 'electricCharge';
const ELECTRIC_CAPACITANCE = 'electricCapacitance';
const ELECTRIC_POTENTIAL = 'electricPotential';
const ELECTRIC_RESISTANCE = 'electricResistance';
const ELECTRIC_INDUCTANCE = 'electricInductance';
const ELECTRIC_CONDUCTANCE = 'electricConductance';
const MAGNETIC_FLUX = 'magneticFlux';
const MAGNETIC_FLUX_DENSITY = 'magneticFluxDensity';
const BIT = 'bit';
const FLOW_RATE = 'flow'
// const RELATIVE_HUMIDITY = 'relativeHumidity';

/**
 * Gets units of a type.
 * @param {String} type - Unit type, e.g. 'length'.
 */
function getUnitsOfType( type ) {
  return types[type];
}

/**
 * Gets unit types.
 */
function getUnitTypes( ) {
  if ( unitTypes == null ) {
    unitTypes = Object.keys( types )
  }
  return unitTypes
}

/**
 * Gets base unit types.
 * @param {String} type - Unit type, e.g. 'length'.
 */
function getBaseUnitTypes( type ) {
  const baseScales = scales[type]
  if ( baseScales == null ) { return [] }
  const baseUnitTypes = Object.keys( baseScales ).map( ( baseUnitSymbol ) => {
    return {
      value : baseUnitSymbol, 
      name : baseScales[baseUnitSymbol].name,
    }
  } )
  return baseUnitTypes;
}

/**
 * Safely gets a unit symbol.
 * @param {String|Object} unit - A unit string or object.
 */
function unitSymbol( unit ) {
  if ( unit == null ) {
    return null;
  }
  if ( typeof unit === 'string' ) {
    return unit;
  }
  return unit.symbol || null;
}

/**
 * Converts a value from one unit to another.
 * @param {Number} value - Numerical value to convert.
 * @param {String|Object} fromUnit - Unit type of numerical value, e.g 'km'.
 * @param {String|Object} toUnit -  Desired unit type of numerical value, e.g. 'mi'
 */
function convert( fromValue, fromUnit, toUnit ) {

  // Get parameters
  const fromSymbol = unitSymbol( fromUnit );
  const toSymbol = unitSymbol( toUnit );

  // Validate parameters
  if ( fromValue == null || typeof fromValue !== 'number' || fromSymbol == null || toSymbol == null ) {
    return fromValue;
  }

  // Convert and return value
  const toValue = unit( fromValue, fromSymbol ).toNumber( toSymbol )
  return toValue
}

/**
 * Converts a value with unit autoscaling.
 * @param {String} unitType - Type of unit, e.g. 'length'.
 * @param {Number} fromValue - Numerical value to convert.
 * @param {String|Object} fromUnit - Unit type of numerical value, e.g 'km'.
 * @param {String|Object} toBaseUnit - Autoscaling base unit, e.g. 'm'.
 */
function convertSliding( { unitType, fromValue, fromUnit, toBaseUnit } ) {

  // Get parameters
  const fromSymbol = unitSymbol( fromUnit ) 
  const baseSymbol = unitSymbol( toBaseUnit )
  
  // Validate parameters
  if ( unitType == null || fromValue == null || typeof fromValue !== 'number' || 
    fromSymbol == null || baseSymbol == null ) {
    return [ fromValue, fromSymbol ]
  }

  // Get more parameters
  const baseValue = unit( fromValue, fromSymbol ).toNumber( baseSymbol );
  const baseScales = Path( `${unitType}/${baseSymbol}/scales` ).get( scales );

  // Get autoscaled unit by choosing unit with the lowest order of magnitude value.
  let toSymbol = baseSymbol
  let minAbsOrder = Math.abs( Util.getOrderOfMagnitude( baseValue ) )
  Object.keys( baseScales ).forEach( ( symbol ) => {
    const scale = baseScales[symbol]
    const value = baseValue / scale
    const absOrder = Math.abs( Util.getOrderOfMagnitude( value ) )
    if ( absOrder < minAbsOrder ) {
      minAbsOrder = absOrder
      toSymbol = symbol
    }
  } )

  // Convert and return value
  const toValue = unit( baseValue, baseSymbol ).toNumber( toSymbol )
  return [ toValue, toSymbol ]
}

/**
 * Creates a unit object.
 * @param {*} unitType - The type of unit, e.g. 'length'.
 * @param {*} symbol - The unit symbol, e.g. 'm'.
 * @param {*} name - The unit name, e.g. 'meters'.
 * @param {*} abbrev - The unit abbreviation, e.g. 'm'.
 */
function Unit( unitType, symbol, name, abbrev, displaySymbol ) {
  return Object.freeze( {
    /**
     * The name of the Unit (eg. 'Meters')
     */
    get name() { return name },

    /**
     * What kind of unit is this? (eg. 'length', 'volume')
     */
    get type() { return unitType },

    /**
     * What is the (mathjs) symbol (eg. 'm', 'ft' )
     */
    get symbol() { return symbol },

    /**
     * Returns a symbol used for display or symbol
     */
    get displaySymbol() { return displaySymbol || symbol },

    // What are the human readable abbrev and name: I18N
    get abbrev() { return abbrev || symbol }
  } )
}

/**
 * Create a scale entry. Used for performing sliding-scale conversions.
 * @param {String} unitType - Unit type, e.g. 'length'.
 * @param {String} symbol - Unit symbol, e.g. 'm'.
 * @param {Object|Array} scaleOptions - Scale options.
 * @param {String} scaleOptions.base - Base unit for scale, e.g. 'm', NOTE: Must be a valid unit symbol.
 * @param {String} scaleOptions.name - Display name of the base unit, e.g. 'm' could map to '*-meters' or 'Metric'.
 * @param {String} scaleOptions.scale - Value scaling factor from base unit, e.g. scale from 'm' to 'cm' would be 1/100.
 */
function createScale( unitType, symbol, scaleOptions ) {
  if ( !scaleOptions ) { return }
  const optionsSets = Array.isArray( scaleOptions ) ? scaleOptions : [ scaleOptions ]
  optionsSets.forEach( ( options ) => {
    const { base, name, scale } = options
    if ( !scales[unitType] ) {
      scales[unitType] = {}
    }
    if ( !scales[unitType][base] ) {
      scales[unitType][base] = {
        scales : {},
      }
    }
    scales[unitType][base].scales[symbol] = scale
    if ( name != null ) {
      scales[unitType][base].name = name
    }
  } )
}

/**
 * Creates a unit entry. Used for converting between units.
 * @param {String} unitType - Unit type, e.g. 'length'.
 * @param {String} symbol - Unit symbol, e.g. 'm'.
 * @param {String} name - Unit name, e.g. 'Meters'.
 * @param {Object} scaleOptions - Unit auto-scaling options, see createScale function.
 */
function createUnit( unitType, symbol, name, scaleOptions, abbrev, displaySymbol ) {
  
  // Update scale
  createScale( unitType, symbol, scaleOptions )

  // Initailize entry
  let arr = types[unitType];
  if ( arr == null ) {
    types[unitType] = [];
    arr = types[unitType];
    unitTypes = null;
  }

  // Add unit to entry
  const unit = Unit( unitType, symbol, name, abbrev, displaySymbol );
  unitBySymbol[symbol] = unit
  arr.push( unit );
  return unit;
}

function getUnit( symbol ) {
  return unitBySymbol[symbol]
}

/* Build Module */
const rtn = {

  // Methods
  getUnitTypes,
  getUnitsOfType,
  getBaseUnitTypes,
  getUnit,
  convert,
  convertSliding,
  createUnit,
  
  // Constants
  LENGTH,
  SPEED,
  ACCELERATION, 
  SURFACE,
  VOLUME,
  FLOW_RATE,
  MASS,
  TIME,
  FREQUENCY,
  ANGLE,
  CURRENT,
  TEMPERATURE,
  AMOUNT_OF_SUBSTANCE,
  LUMINOUS_INTENSITY,
  FORCE,
  ENERGY,
  POWER,
  PRESSURE,
  ELECTRIC_CHARGE,
  ELECTRIC_CAPACITANCE,
  ELECTRIC_POTENTIAL,
  ELECTRIC_RESISTANCE,
  ELECTRIC_INDUCTANCE,
  ELECTRIC_CONDUCTANCE,
  MAGNETIC_FLUX,
  MAGNETIC_FLUX_DENSITY,
  BIT, // RELATIVE_HUMIDITY,

  // SPEED
  metersPerSecond : createUnit( SPEED, 'm/s', 'Meters Per Second' ),
  milesPerHour : createUnit( SPEED, 'mph', 'Miles Per Hour' ),
  kilometersPerHour : createUnit( SPEED, 'km/h', 'Kilometers Per Hour' ),

  // ACCELERATION
  metersPerSecond2 : createUnit( ACCELERATION, 'm/s^2', 'Meters Per Second2' ),

  // LENGTH
  meters : createUnit( LENGTH, 'm', 'meters', { base : 'm', name : 'Metric', scale : 1 } ), // 1:0
  centimeters : createUnit( LENGTH, 'cm', 'centimeters', { base : 'm', scale : 1 / 100 } ), // 0.01:0
  millimeters : createUnit( LENGTH, 'mm', 'millimeters', { base : 'm', scale : 1 / 1000 } ), // 0.001:0
  kilometers : createUnit( LENGTH, 'km', 'kilometers', { base : 'm', scale : 1000 } ), // 1000:0
  megameters : createUnit( LENGTH, 'Mm', 'megameters', { base : 'm', scale : 1000000 } ), // 1000000:0
  foot : createUnit( LENGTH, 'ft', 'foot', { base : 'ft', name : 'Imperial', scale : 1 } ), // 0.3048:0
  inches : createUnit( LENGTH, 'in', 'inches', { base : 'ft', scale : 1 / 12 } ), // 0.0254:0
  yards : createUnit( LENGTH, 'yd', 'yards', { base : 'ft', scale : 3 } ), // 0.9144:0
  miles : createUnit( LENGTH, 'mi', 'miles', { base : 'ft', scale : 5280 } ), // 1609.344:0
  links : createUnit( LENGTH, 'li', 'links' ), // 0.201168:0
  rods : createUnit( LENGTH, 'rd', 'rods' ), // 5.02921:0
  chains : createUnit( LENGTH, 'ch', 'chains' ), // 20.1168:0
  angstroms : createUnit( LENGTH, 'angstrom', 'angstroms' ), // 1e-10:0
  mil : createUnit( LENGTH, 'mil', 'mil' ), // 0.0000254:0
 
  // SURFACE
  m2 : createUnit( SURFACE, 'm2', 'm2', { base : 'm2', name : 'Metric', scale : 1 } ), // 1:0
  cm2 : createUnit( SURFACE, 'cm2', 'cm2', { base : 'm2', scale : 1 / 100 ** 2 } ), // 0.0001:0
  mm2 : createUnit( SURFACE, 'mm2', 'mm2', { base : 'm2', scale : 1 / 1000 ** 2 } ), // 0.000001:0
  km2 : createUnit( SURFACE, 'km2', 'km2', { base : 'm2', scale : 1000 ** 2 } ), // 1000000:0
  Mm2 : createUnit( SURFACE, 'Mm2', 'Mm2', { base : 'm2', scale : 1000000 ** 2 } ), // 1000000000000:0
  sqfeet : createUnit( SURFACE, 'sqft', 'sqfeet', { base : 'sqft', name : 'Imperial', scale : 1 } ), // 0.09290304:0
  sqin : createUnit( SURFACE, 'sqin', 'sqin', { base : 'sqft', scale : 1 / 12 ** 2 } ), // 0.00064516:0
  sqyard : createUnit( SURFACE, 'sqyd', 'sqyard', { base : 'sqft', scale : 3 ** 2 } ), // 0.83612736:0
  sqmiles : createUnit( SURFACE, 'sqmi', 'sqmiles', { base : 'sqft', scale : 1 / 5280 ** 2 } ), // 2589988.110336:0
  sqrd : createUnit( SURFACE, 'sqrd', 'sqrd' ), // 25.29295:0
  sqch : createUnit( SURFACE, 'sqch', 'sqch' ), // 404.6873:0
  sqmil : createUnit( SURFACE, 'sqmil', 'sqmil' ), // 6.4516e-10:0
  acres : createUnit( SURFACE, 'acre', 'acres', { base : 'acre', name : 'Acres', scale : 1 } ), // 4046.86:0
  hectares : createUnit( SURFACE, 'hectare', 'hectares', { base : 'acre', scale : 2.47105 } ), // 10000:0
 
  // VOLUME
  m3 : createUnit( VOLUME, 'm3', 'm3', { base : 'm3', name : 'Metric (Meters)', scale : 1 } ), // 1:0
  cm3 : createUnit( VOLUME, 'cm3', 'cm3', { base : 'm3', scale : 1 / 100 ** 3 } ), // 1e-9:0
  mm3 : createUnit( VOLUME, 'mm3', 'mm3', { base : 'm3', scale : 1 / 1000 ** 3 } ), // 1e-9:0
  km3 : createUnit( VOLUME, 'km3', 'km3', { base : 'm3', scale : 1000 ** 3 } ), // 1000000000:0
  Mm3 : createUnit( VOLUME, 'Mm3', 'Mm3', { base : 'm3', scale : 1000000 ** 3 } ), // 1000000000000000000:0
  litres : createUnit( VOLUME, 'L', 'litres', { base : 'L', name : 'Metric (Litres)', scale : 1 } ), // 0.001:0
  millilitres : createUnit( VOLUME, 'mL', 'millilitres', { base : 'L', scale : 1 / 1000 } ), // 0.000001:0
  centilitres : createUnit( VOLUME, 'cL', 'centilitres', { base : 'L', scale : 1 / 100 } ), // 0.00001:0
  kilolitres : createUnit( VOLUME, 'm3', 'kilolitres', { base : 'L', scale : 1000 } ), // 1:0
  megalitres : createUnit( VOLUME, 'ML', 'megalitres', { base : 'L', scale : 1000000 } ), // 1000:0
  cuft : createUnit( VOLUME, 'cuft', 'cuft', { base : 'cuft', name : 'Imperial (Feet)', scale : 1 } ), // 0.028316846592:0
  cuin : createUnit( VOLUME, 'cuin', 'cuin', { base : 'cuft', scale : 1 / 12 ** 3 } ), // 0.000016387064:0
  cuyd : createUnit( VOLUME, 'cuyd', 'cuyd', { base : 'cuft', scale : 3 ** 3 } ), // 0.764554857984:0
  cups : createUnit( VOLUME, 'cp', 'cups', { base : 'cups', name : 'Imperial (Cups)', scale : 1 } ), // 0.0002365882:0
  tablespoons : createUnit( VOLUME, 'tablespoon', 'tablespoons', { base : 'teaspoon', scale : 1 / 16 } ), // 0.000015:0
  teaspoons : createUnit( VOLUME, 'teaspoon', 'teaspoons', { base : 'cups', scale : 1 / 48 } ), // 0.000005:0
  pints : createUnit( VOLUME, 'pt', 'pints', { base : 'cups', scale : 2 } ), // 0.0004731765:0
  quarts : createUnit( VOLUME, 'qt', 'quarts', { base : 'cups', scale : 4 } ), // 0.0009463529:0
  gallons : createUnit( VOLUME, 'gal', 'gallons', { base : 'cups', scale : 16 } ), // 0.003785412:0
  drop : createUnit( VOLUME, 'gtt', 'drop' ), // 5e-8:0
  minims : createUnit( VOLUME, 'minim', 'minims' ), // 6.161152e-8:0
  fluiddrams : createUnit( VOLUME, 'fldr', 'fluiddrams' ), // 0.0000036966911:0
  fluidounces : createUnit( VOLUME, 'floz', 'fluidounces' ), // 0.00002957353:0
  gills : createUnit( VOLUME, 'gi', 'gills' ), // 0.0001182941:0
  beerbarrels : createUnit( VOLUME, 'bbl', 'beerbarrels', { base : 'bbl', name : 'Barrels', scale : 1 } ), // 0.1173478:0
  oilbarrels : createUnit( VOLUME, 'obl', 'oilbarrels', { base : 'bbl', scale : 1.35484 } ), // 0.1589873:0
  hogsheads : createUnit( VOLUME, 'hogshead', 'hogsheads' ), // 0.238481:0
 
  // FLOW RATE
  cubicMetersPerSecond : createUnit( FLOW_RATE, 'm3/s', 'Cubic Meters Per Second' ),
  sccm : createUnit( FLOW_RATE, 'sccm', 'Standard Cubic Centimeters Per Minute' ), 
  cubicFeetPerSecond : createUnit( FLOW_RATE, 'ft3/s', 'Cubic Feet Per Second' ), 
  gallonsPerMinute : createUnit( FLOW_RATE, 'gpm', 'Gallons Per Minute' ), 

  // MASS
  grams : createUnit( MASS, 'g', 'grams', { base : 'g', name : 'Grams', scale : 1 } ), // 0.001:0
  kilograms : createUnit( MASS, 'kg', 'kilograms', { base : 'g', scale : 1000 } ), // 1:0
  milligrams : createUnit( MASS, 'mg', 'milligrams', { base : 'g', scale : 1 / 1000 } ), // 0.000001:0
  centigrams : createUnit( MASS, 'cg', 'centigrams', { base : 'g', scale : 1 / 100 } ), // 0.00001:0
  megagrams : createUnit( MASS, 'Mg', 'megagrams', { base : 'g', scale : 1000000 } ), // 1000:0
  // eslint-disable-next-line max-len
  tons : createUnit( MASS, 'ton', 'tons', [ { base : 'tons', name : 'Tons', scale : 1 }, { base : 'lb', scale : 2000 } ] ), // 907.18474:0
  ktons : createUnit( MASS, 'kton', 'ktons', { base : 'tons', scale : 1000 } ), // 907184.74:0
  mtons : createUnit( MASS, 'mton', 'mtons', { base : 'tons', scale : 1 / 1000 } ), // 0.90718474:0
  ctons : createUnit( MASS, 'cton', 'ctons', { base : 'tons', scale : 1 / 100 } ), // 9.071847400000001:0
  Mtons : createUnit( MASS, 'Mton', 'Mtons', { base : 'tons', scale : 1000000 } ), // 907184740:0
  tonnes : createUnit( MASS, 'tonne', 'tonnes', { base : 'tonnes', name : 'Tonnes', scale : 1 } ), // 1000000:0
  ktonnes : createUnit( MASS, 'ktonne', 'ktonnes', { base : 'tonnes', scale : 1000 } ), // 1000000:0
  mtonnes : createUnit( MASS, 'mtonne', 'mtonnes', { base : 'tonnes', scale : 1 / 100 } ),
  ctonnes : createUnit( MASS, 'ctonne', 'ctonnes', { base : 'tonnes', scale : 1 / 10000 } ), // 10:0
  Mtonnes : createUnit( MASS, 'Mtonne', 'Mtonnes', { base : 'tonnes', scale : 1000000 } ), // 1000000000:0
  grains : createUnit( MASS, 'gr', 'grains' ), // 0.00006479891:0
  drams : createUnit( MASS, 'dr', 'drams' ), // 0.0017718451953125:0
  ounces : createUnit( MASS, 'oz', 'ounces', { base : 'lb', scale : 1 / 16 } ), // 0.028349523125:0
  poundmasses : createUnit( MASS, 'lb', 'poundmasses', { base : 'lb', name : 'Ounces, Pounds, Tons', scale : 1 } ), // 0.45359237:0
  hundredweights : createUnit( MASS, 'cwt', 'hundredweights' ), // 45.359237:0
  sticks : createUnit( MASS, 'stick', 'sticks' ), // 0.115:0
  stone : createUnit( MASS, 'stone', 'stone' ), // 6.35029318:0
 
  // TIME
  seconds : createUnit( TIME, 's', 'seconds', { base : 'h', scale : 1 / 360 } ), // 1:0
  kiloseconds : createUnit( TIME, 'ks', 'kiloseconds' ), // 1000:0
  milliseconds : createUnit( TIME, 'ms', 'milliseconds', { base : 'h', scale : 1 / ( 360 * 1000 ) } ), // 0.001:0
  centiseconds : createUnit( TIME, 'cs', 'centiseconds' ), // 0.01:0
  megaseconds : createUnit( TIME, 'Ms', 'megaseconds' ), // 1000000:0
  minutes : createUnit( TIME, 'min', 'minutes', { base : 'h', scale : 1 / 60 } ), // 60:0
  hours : createUnit( TIME, 'h', 'hours', { base : 'h', name : 'Full Range (Milliseconds -> Millennium)', scale : 1 } ), // 3600:0
  days : createUnit( TIME, 'day', 'days', { base : 'h', scale : 24 } ), // 86400:0
  weeks : createUnit( TIME, 'week', 'weeks', { base : 'h', scale : 168 } ), // 604800:0
  months : createUnit( TIME, 'month', 'months', { base : 'h', scale : 730 } ), // 2629800:0
  years : createUnit( TIME, 'year', 'years', { base : 'h', scale : 8760 } ), // 31557600:0
  year : createUnit( TIME, 'year', 'year' ), // 315576000:0
  century : createUnit( TIME, 'century', 'century', { base : 'h', scale : 876000 } ), // 3155760000:0
  millennium : createUnit( TIME, 'millennium', 'millennium', { base : 'h', scale : 8760000 } ), // 31557600000:0
 
  // FREQUENCY
  hertz : createUnit( FREQUENCY, 'Hz', 'hertz', { base : 'Hz', name : 'Hertz', scale : 1 } ), // 1:0
  kilohertz : createUnit( FREQUENCY, 'kHz', 'kilohertz', { base : 'Hz', scale : 1000 } ), // 1000:0
  centihertz : createUnit( FREQUENCY, 'cHz', 'centihertz', { base : 'Hz', scale : 1 / 100 } ), // 0.01:0
  millihertz : createUnit( FREQUENCY, 'mHz', 'millihertz', { base : 'Hz', scale : 1 / 1000 } ), // 0.001:0
  megahertz : createUnit( FREQUENCY, 'MHz', 'megahertz', { base : 'Hz', scale : 1000000 } ), // 1000000:0
 
  // ANGLE
  radians : createUnit( ANGLE, 'rad', 'radians' ), // 1:0
  degrees : createUnit( ANGLE, 'deg', 'degrees' ), // 0.017453292519943295:0
  gradians : createUnit( ANGLE, 'grad', 'gradians' ), // 0.015707963267948967:0
  cycles : createUnit( ANGLE, 'cycle', 'cycles' ), // 6.283185307179586:0
  arcseconds : createUnit( ANGLE, 'arcsec', 'arcseconds' ), // 0.00000484813681109536:0
  arcminutes : createUnit( ANGLE, 'arcmin', 'arcminutes' ), // 0.0002908882086657216:0
 
  // CURRENT
  amperes : createUnit( CURRENT, 'A', 'amperes', { base : 'A', name : 'Amps', scale : 1 } ), // 1:0
  kiloamperes : createUnit( CURRENT, 'kA', 'kiloamperes', { base : 'A', scale : 1000 } ), // 1000:0
  milliamperes : createUnit( CURRENT, 'mA', 'milliamperes', { base : 'A', scale : 1 / 1000 } ), // 0.001:0
  centiamperes : createUnit( CURRENT, 'cA', 'centiamperes', { base : 'A', scale : 1 / 100 } ), // 0.01:0
  megaamperes : createUnit( CURRENT, 'MA', 'megaamperes', { base : 'A', scale : 1000000 } ), // 1000000:0
 
  // TEMPERATURE
  celsius : createUnit( TEMPERATURE, 'degC', 'celsius', null, null, '°C' ), // 1:273.15
  kelvin : createUnit( TEMPERATURE, 'K', 'kelvin' ), // 1:0
  fahrenheit : createUnit( TEMPERATURE, 'degF', 'fahrenheit', null, null, '°F' ), // 0.5555555555555556:459.67
  rankine : createUnit( TEMPERATURE, 'degR', 'rankine', null, null, '°R' ), // 0.5555555555555556:0
 
  // AMOUNT_OF_SUBSTANCE
  moles : createUnit( AMOUNT_OF_SUBSTANCE, 'mol', 'moles', { base : 'mol', name : 'Moles', scale : 1000 } ), // 1:0
  kilomoles : createUnit( AMOUNT_OF_SUBSTANCE, 'kmol', 'kilomoles', { base : 'mol', scale : 1000 } ), // 1000:0
  millimoles : createUnit( AMOUNT_OF_SUBSTANCE, 'mmol', 'millimoles', { base : 'mol', scale : 1 / 1000 } ), // 0.001:0
  centimoles : createUnit( AMOUNT_OF_SUBSTANCE, 'cmol', 'centimoles', { base : 'mol', scale : 1 / 100 } ), // 0.01:0
  megamoles : createUnit( AMOUNT_OF_SUBSTANCE, 'Mmol', 'megamoles', { base : 'mol', scale : 1000000 } ), // 1000000:0
 
  // LUMINOUS_INTENSITY
  candela : createUnit( LUMINOUS_INTENSITY, 'cd', 'candela' ), // 1:0
 
  // FORCE
  newton : createUnit( FORCE, 'N', 'newton' ), // 1:0
  kilonewton : createUnit( FORCE, 'kN', 'kilonewton' ), // 1000:0
  millinewton : createUnit( FORCE, 'mN', 'millinewton' ), // 0.001:0
  centinewton : createUnit( FORCE, 'cN', 'centinewton' ), // 0.01:0
  meganewton : createUnit( FORCE, 'MN', 'meganewton' ), // 1000000:0
  dyne : createUnit( FORCE, 'dyn', 'dyne' ), // 0.00001:0
  millidyne : createUnit( FORCE, 'mdyn', 'millidyne' ), // 1e-8:0
  centidyne : createUnit( FORCE, 'cdyn', 'centidyne' ), // 1.0000000000000001e-7:0
  megadyne : createUnit( FORCE, 'Mdyn', 'megadyne' ), // 10:0
  poundforce : createUnit( FORCE, 'lbf', 'poundforce' ), // 4.4482216152605:0
  kips : createUnit( FORCE, 'kip', 'kips' ), // 4448.2216:0
  kilokips : createUnit( FORCE, 'kilokip', 'kilokips' ), // 4448221.6:0
  centikips : createUnit( FORCE, 'centikip', 'centikips' ), // 44.482216:0
  millikips : createUnit( FORCE, 'millikip', 'millikips' ), // 4.4482216:0
  megakips : createUnit( FORCE, 'megakip', 'megakips' ), // 4448221600:0
 
  // ENERGY
  joules : createUnit( ENERGY, 'J', 'joules' ), // 1:0
  kjoules : createUnit( ENERGY, 'kJ', 'kjoules' ), // 1000:0
  mjoules : createUnit( ENERGY, 'mJ', 'mjoules' ), // 0.001:0
  cjoules : createUnit( ENERGY, 'cJ', 'cjoules' ), // 0.01:0
  Mjoules : createUnit( ENERGY, 'MJ', 'Mjoules' ), // 1000000:0
  erg : createUnit( ENERGY, 'erg', 'erg' ), // 0.00001:0
  Wh : createUnit( ENERGY, 'Wh', 'Wh' ), // 3600:0
  kWh : createUnit( ENERGY, 'kWh', 'kWh' ), // 3600000:0
  mWh : createUnit( ENERGY, 'mWh', 'mWh' ), // 3.6:0
  cWh : createUnit( ENERGY, 'cWh', 'cWh' ), // 36:0
  MWh : createUnit( ENERGY, 'MWh', 'MWh' ), // 3600000000:0
  BTUs : createUnit( ENERGY, 'BTU', 'BTUs' ), // 1055.05585262:0
  electronvolts : createUnit( ENERGY, 'eV', 'electronvolts' ), // 1.602176565e-19:0
  kiloelectronvolts : createUnit( ENERGY, 'keV', 'kiloelectronvolts' ), // 1.602176565e-16:0
  millielectronvolts : createUnit( ENERGY, 'meV', 'millielectronvolts' ), // 1.602176565e-22:0
  centielectronvolts : createUnit( ENERGY, 'ceV', 'centielectronvolts' ), // 1.602176565e-21:0
  megaelectronvolts : createUnit( ENERGY, 'MeV', 'megaelectronvolts' ), // 1.602176565e-13:0
 
  // POWER
  watts : createUnit( POWER, 'W', 'watts' ), // 1:0
  kilowatts : createUnit( POWER, 'kW', 'kilowatts' ), // 1000:0
  milliwatts : createUnit( POWER, 'mW', 'milliwatts' ), // 0.001:0
  centiwatts : createUnit( POWER, 'cW', 'centiwatts' ), // 0.01:0
  megawatts : createUnit( POWER, 'MW', 'megawatts' ), // 1000000:0
  hp : createUnit( POWER, 'hp', 'hp' ), // 745.6998715386:0
  VAR : createUnit( POWER, 'VAR', 'VAR' ), // i:0
  kVAR : createUnit( POWER, 'kVAR', 'kVAR' ), // NaN:0
 
  // PRESSURE
  Pa : createUnit( PRESSURE, 'Pa', 'Pa' ), // 1:0
  kPa : createUnit( PRESSURE, 'kPa', 'kPa' ), // 1000:0
  mPa : createUnit( PRESSURE, 'mPa', 'mPa' ), // 0.001:0
  cPa : createUnit( PRESSURE, 'cPa', 'cPa' ), // 0.01:0
  MPa : createUnit( PRESSURE, 'MPa', 'MPa' ), // 1000000:0
  psi : createUnit( PRESSURE, 'psi', 'psi' ), // 6894.75729276459:0
  atm : createUnit( PRESSURE, 'atm', 'atm' ), // 101325:0
  bar : createUnit( PRESSURE, 'bar', 'bar' ), // 100000:0
  torr : createUnit( PRESSURE, 'torr', 'torr' ), // 133.322:0
  mmH2O : createUnit( PRESSURE, 'mmH2O', 'mmH2O' ), // 9.80665:0
  cmH2O : createUnit( PRESSURE, 'cmH2O', 'cmH2O' ), // 98.0665:0
 
  // ELECTRIC_CHARGE
  coulombs : createUnit( ELECTRIC_CHARGE, 'C', 'coulombs' ), // 1:0
  kilocoulombs : createUnit( ELECTRIC_CHARGE, 'kC', 'kilocoulombs' ), // 1000:0
  centicoulombs : createUnit( ELECTRIC_CHARGE, 'cC', 'centicoulombs' ), // 0.01:0
  millicoulombs : createUnit( ELECTRIC_CHARGE, 'mC', 'millicoulombs' ), // 0.001:0
  megacoulombs : createUnit( ELECTRIC_CHARGE, 'MC', 'megacoulombs' ), // 1000000:0
 
  // ELECTRIC_CAPACITANCE
  farads : createUnit( ELECTRIC_CAPACITANCE, 'F', 'farads' ), // 1:0
  kilofarads : createUnit( ELECTRIC_CAPACITANCE, 'kF', 'kilofarads' ), // 1000:0
  centifarads : createUnit( ELECTRIC_CAPACITANCE, 'cF', 'centifarads' ), // 0.01:0
  millifarads : createUnit( ELECTRIC_CAPACITANCE, 'mF', 'millifarads' ), // 0.001:0
  megafarads : createUnit( ELECTRIC_CAPACITANCE, 'MF', 'megafarads' ), // 1000000:0
 
  // ELECTRIC_POTENTIAL
  volts : createUnit( ELECTRIC_POTENTIAL, 'V', 'volts' ), // 1:0
  kilovolts : createUnit( ELECTRIC_POTENTIAL, 'kV', 'kilovolts' ), // 1000:0
  centivolts : createUnit( ELECTRIC_POTENTIAL, 'cV', 'centivolts' ), // 0.01:0
  millivolts : createUnit( ELECTRIC_POTENTIAL, 'mV', 'millivolts' ), // 0.001:0
  megavolts : createUnit( ELECTRIC_POTENTIAL, 'MV', 'megavolts' ), // 1000000:0
 
  // ELECTRIC_RESISTANCE
  ohms : createUnit( ELECTRIC_RESISTANCE, 'ohm', 'ohms' ), // 1:0
  kiloohms : createUnit( ELECTRIC_RESISTANCE, 'kohm', 'kiloohms' ), // 1000:0
  milliohms : createUnit( ELECTRIC_RESISTANCE, 'mohm', 'milliohms' ), // 0.001:0
  centiohms : createUnit( ELECTRIC_RESISTANCE, 'cohm', 'centiohms' ), // 0.01:0
  megaohms : createUnit( ELECTRIC_RESISTANCE, 'Mohm', 'megaohms' ), // 1000000:0
 
  // ELECTRIC_INDUCTANCE
  henry : createUnit( ELECTRIC_INDUCTANCE, 'H', 'henry' ), // 1:0
  kilohenry : createUnit( ELECTRIC_INDUCTANCE, 'kH', 'kilohenry' ), // 1000:0
  centihenry : createUnit( ELECTRIC_INDUCTANCE, 'cH', 'centihenry' ), // 0.01:0
  millihenry : createUnit( ELECTRIC_INDUCTANCE, 'mH', 'millihenry' ), // 0.001:0
  megahenry : createUnit( ELECTRIC_INDUCTANCE, 'MH', 'megahenry' ), // 1000000:0
 
  // ELECTRIC_CONDUCTANCE
  siemens : createUnit( ELECTRIC_CONDUCTANCE, 'S', 'siemens' ), // 1:0
  kilosiemens : createUnit( ELECTRIC_CONDUCTANCE, 'kS', 'kilosiemens' ), // 1000:0
  centisiemens : createUnit( ELECTRIC_CONDUCTANCE, 'cS', 'centisiemens' ), // 0.01:0
  millisiemens : createUnit( ELECTRIC_CONDUCTANCE, 'mS', 'millisiemens' ), // 0.001:0
  megasiemens : createUnit( ELECTRIC_CONDUCTANCE, 'MS', 'megasiemens' ), // 1000000:0
 
  // MAGNETIC_FLUX
  webers : createUnit( MAGNETIC_FLUX, 'Wb', 'webers' ), // 1:0
  kilowebers : createUnit( MAGNETIC_FLUX, 'kWb', 'kilowebers' ), // 1000:0
  centiwebers : createUnit( MAGNETIC_FLUX, 'cWb', 'centiwebers' ), // 0.01:0
  milliwebers : createUnit( MAGNETIC_FLUX, 'mWb', 'milliwebers' ), // 0.001:0
  megawebers : createUnit( MAGNETIC_FLUX, 'MWb', 'megawebers' ), // 1000000:0
 
  // MAGNETIC_FLUX_DENSITY
  teslas : createUnit( MAGNETIC_FLUX_DENSITY, 'T', 'teslas' ), // 1:0
  kiloteslas : createUnit( MAGNETIC_FLUX_DENSITY, 'kT', 'kiloteslas' ), // 1000:0
  centiteslas : createUnit( MAGNETIC_FLUX_DENSITY, 'cT', 'centiteslas' ), // 0.01:0
  milliteslas : createUnit( MAGNETIC_FLUX_DENSITY, 'mT', 'milliteslas' ), // 0.001:0
  megateslas : createUnit( MAGNETIC_FLUX_DENSITY, 'MT', 'megateslas' ), // 1000000:0
 
  // BIT
  bits : createUnit( BIT, 'b', 'bits' ), // 1:0
  kilobits : createUnit( BIT, 'kb', 'kilobits' ), // 1000:0
  megabits : createUnit( BIT, 'Mb', 'megabits' ), // 1000000:0
  bytes : createUnit( BIT, 'B', 'bytes' ), // 8:0
  kilobytes : createUnit( BIT, 'kB', 'kilobytes' ), // 8000:0
  megabytes : createUnit( BIT, 'MB', 'megabytes' ), // 8000000:0

  //  relativeHumidity : createUnit( RELATIVE_HUMIDITY, '%RH', 'humidity' ) // 
}

/* Export Modules */
export default rtn
