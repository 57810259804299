import React from 'react'
import { Layout } from '@leverege/plugin'
import { Button, Checkbox, Popup, Radio, ToggleButton } from '@leverege/ui-elements'
import { R } from '@leverege/value-resolver'

import Util from './Util.js'
import MenuFactory from './MenuFactory.jsx'
import Toolbar from './Toolbar.jsx'
import StringShortener from '../util/StringShortener.js'

/**
 * This factory will produce at the top level, toggle butons, buttons,
 * checkboxs, radiobuttons and popup components to support the various
 * types of actions. It will use a MenuFactory for all deep items
 */
export default class ToolbarFactory {

  constructor( options ) { 
    const opts = options || {}
    this.menuFactory = new MenuFactory()
    this.prefer = opts.prefer    
    this.preferIcon = this.prefer === 'icon'
    this.preferText = this.prefer === 'text'
    this.hideWhenIconOnly = opts.hideWhenIconOnly === true
    this.shortenWhenIconOnly = opts.hideWhenIconOnly !== false
    this.hideHeaderWhenIconOnly = opts.hideHeaderWhenIconOnly !== false
    this.blankIcon = opts.blankIcon === undefined ? 'fa fa-fw' : opts.blankIcon // used in vertical only
    this.vertical = opts.vertical === true
    this.variant = opts.variant || 'toolbar'
    this.toggleButtonVariant = opts.toggleButtonVariant || this.variant
    this.buttonVariant = opts.buttonVariant || this.variant
    this.checkboxVariant = opts.checkboxVariant || this.variant
    this.radioButtonVariant = opts.radioButtonVariant || this.variant
    this.popupVariant = opts.popupVariant || this.toggleButtonVariant 
    this.variantWith = opts.variantWith || Util.variantWith
    if ( opts.popupToggleVariant ) {
      this.popupToggleVariant = opts.popupToggleVariant
    } else {
      const tbv = this.toggleButtonVariant.split( '|' ).map( v => `${v}Menu` ).join( '|' )
      this.popupToggleVariant = `${tbv}|${this.toggleButtonVariant}`   
    }
  }

  onClick = ( evt ) => {
    const { action } = evt.data
    if ( typeof action?.perform === 'function' ) {
      // const result =  // ToDo: report back a cancellable action?
      action.perform( { sourceEvent : evt.sourceEvent, ...evt.data } )
      // if ( isThenable( result ) ) {
      // cancel/catch/etc
      // }
    }  
  }

  createIcon( str ) {
    if ( str == null ) { 
      return null
    }
    if ( typeof str === 'string' ) {
      if ( str.startsWith( 'data:' ) || str.startsWidth( 'http:' ) || str.startsWidth( 'https:' ) ||
           str.endsWith( '.png' ) || str.endsWith( '.jpg' ) || str.endsWith( '.gif' ) || str.endsWith( '.svg' ) ) {
        return <img src={str} width={this.iconSize} /> // eslint-disable-line
      }
      return <i className={str}/>
    }
    if ( typeof str === 'function' ) { 
      return str()
    }
    return null
  }

  createItemWithSubmenu( props ) {
    const { action } = props
    const { 
      id, name : rname, icon : ricon, help : rhelp, iconOff, iconOn, disabled, 
      visible, checked, checkStyle, variant,
      accessory, sublayout, sublayoutContext } = action.appearance( props )

    const name = R( rname )
    const icon = R( ricon )
    const help = R( rhelp )
    const blank = this.vertical ? this.blankIcon : null
    const pIcon = this.preferIcon
    const pText = this.preferText

    if ( visible === false || ( icon == null && name == null ) ) {
      return null
    }

    if ( pIcon && icon == null && this.hideWhenIconOnly ) {
      return null
    }

    const tip = pIcon && typeof name === 'string' ? name : null
    let str, ion, ioff
    str = pIcon && icon ? null : name

    if ( checkStyle === 'toggle' || checkStyle === 'check' ) {
      ioff = pText && name ? null : iconOff || icon || blank
      ion = pText && name ? null : iconOn || icon || blank
    } else {
      ion = pText && name ? null : icon || blank
    }

    if ( pIcon && str && this.shortenWhenIconOnly ) {
      str = StringShortener.shorten( str )
      if ( checkStyle === 'toggle' || checkStyle === 'check' ) {
        if ( ion === blank ) { ion = null }
        if ( ioff === blank ) { ioff = null }
      } else {
        ion = null // remove the blank
      }
    }

    return (
      <Popup.Item
        id={id}
        title={tip}
        checkStyle={checkStyle}
        help={help}
        checkIconOn={checkStyle === 'check' ? ion : null}
        checkIconOff={checkStyle === 'check' ? ion : null}
        radioIconOn={checkStyle === 'radio' ? ion : null}
        radioIconOff={checkStyle === 'radio' ? ioff : null}
        variant={variant}
        value={checked}
        disabled={disabled}
        accessory={accessory}
        submenu={() => this.createComponents( R( sublayout, sublayoutContext ), sublayoutContext )}>
        {str}
      </Popup.Item>
    )
  }

  createComponent( props ) {
    const { context, action } = props
    const blank = this.vertical ? this.blankIcon : null
    if ( action.handles == null || !action.handles( context ) ) {
      return null
    }
    const { 
      id, name : rname, type, icon : ricon, iconOff, iconOn, disabled,
      visible, checked, checkStyle, variant, sublayout, sublayoutContext } = action.appearance( props )
    const name = R( rname )
    const icon = R( ricon )
    if ( visible === false || ( icon == null && name == null ) ) {
      return null
    }
    const pIcon = this.preferIcon
    const pText = this.preferText

    if ( pIcon && icon == null && this.hideWhenIconOnly ) {
      return null
    }

    if ( type === 'selector' ) {
      const ioff = iconOff || icon || blank
      const ion = iconOn || icon || blank
      return (
        <Popup 
          variant={this.popupVariant}
          toggleVariant={variant}
          targetAttachment="bottom left"
          attachment="top left"
          key={name}
          title={name}
          toggleAttributes={{ iconOn : ion, iconOff : ioff, title : name }}
          render={( onClose ) => {
            return this.menuFactory.createComponents( R( sublayout, sublayoutContext ), sublayoutContext, onClose )
          }} />
      )
    }

    if ( sublayout ) {
      return this.createItemWithSubmenu( props )
    }

    const tip = pIcon && typeof name === 'string' ? name : null

    if ( checkStyle === 'toggle' ) {
      let str = pIcon && icon ? null : name
      let ioff = pText && name ? null : iconOff || icon || blank
      let ion = pText && name ? null : iconOn || icon || blank
      if ( pIcon && str && this.shortenWhenIconOnly ) {
        str = StringShortener.shorten( str )
        if ( ion === blank ) { ion = null }
        if ( ioff === blank ) { ioff = null }
      }
      // Todo: If icons and veritcal, leave space on ones without
      return (
        <ToggleButton
          id={id}
          title={tip}
          variant={this.variantWith( this.toggleButtonVariant, variant )}
          key={action.key} 
          value={checked} 
          disabled={disabled} 
          onChange={this.onClick} 
          iconOn={ion}
          iconOff={ioff}
          eventData={props}>
          {str}
        </ToggleButton>
      )
    }
    let str = pIcon && icon ? null : name
    let ion = pText && name ? null : icon || blank
    if ( pIcon && str && this.shortenWhenIconOnly ) {
      str = StringShortener.shorten( str )
      ion = null // remove the blank
    }
    if ( checkStyle === 'check' ) {
      // add icon if factory says too
      return (
        <div>
          <Checkbox
            id={id}
            title={tip}
            variant={this.variantWith( this.checkboxVariant, variant )}
            label={name}
            key={action.key} 
            value={checked} 
            disabled={disabled} 
            onClick={this.onClick} 
            eventData={props} />
        </div>
      )
    }
    if ( checkStyle === 'radio' ) {
      // add icon if factory says too
      return (
        <div>
          <Radio
            id={id}
            title={tip}
            variant={this.variantWith( this.radioButtonVariant, variant )}
            label={name}
            key={action.key} 
            value={checked} 
            disabled={disabled} 
            onClick={this.onClick} 
            eventData={props} />
        </div>
      )
    }
    
    return (
      <Button
        id={id}
        title={tip}
        variant={this.variantWith( this.buttonVariant, variant )}
        key={action.key} 
        value={checked} 
        disabled={disabled} 
        icon={ion}
        onClick={this.onClick}
        eventData={props}>
        {str}
      </Button>
    )
  }

  createMenu( item, cxt ) {

    const name = R( item.name, cxt )
    const str = this.preferIcon && item.icon ? null : name
    const icon = this.preferText && item.name ? null : R( item.icon, cxt )
    
    return (
      <Popup 
        variant={this.popupVariant}
        toggleVariant={this.popupToggleVariant}
        targetAttachment={this.vertical ? 'top right' : 'bottom left'}
        attachment={this.vertical ? 'top left' : 'top left'}
        key={name}
        title={str}
        toggleAttributes={{ iconOn : icon, iconOff : icon, title : str == null ? name : null }}
        render={( onClose ) => {
          return this.menuFactory.createComponents( item, cxt, onClose )
        }} />
    )
  }

  /**
   * Returns an array of items. Where groups occur, a Popup will be
   * created with submenus in it as necessary.
   * @param {PlacementTree||Array} rootLayoutNode the tree of actions
   */
  createComponents( rootLayoutNode, cxt ) {
    if ( rootLayoutNode == null ) {
      return null
    }
    let separator = null
    const r = []
    const items = Array.isArray( rootLayoutNode ) ? rootLayoutNode : rootLayoutNode.items
    items.forEach( ( item, index ) => {
      let cmp = null
      let sort = null
      let sectionName = null
      if ( item.type === Layout.NODE_TYPE ) {
        // We need to know if the menu should show up. Call handles
        // TODO: We can do the handles above this call to remove item
        // from this location
        if ( !Util.handles( item.items, cxt ) ) {
          return
        }
        sort = R( item.items?.[0]?.layout?.sort, cxt )
        sectionName = R( item.items?.[0]?.layout?.sectionName, cxt )

        cmp = this.createMenu( item, cxt )
      } else { 
        const { customComponent : Component } = item 
        sort = R( item.layout?.sort, cxt )
        sectionName = R( item.layout?.sectionName, cxt )

        if ( typeof item.getActions === 'function' ) {
          const childRootLayoutNode = item.getActions( cxt )
          Util.sortLayoutNodeItems( childRootLayoutNode )
          cmp = this.createComponents( childRootLayoutNode, cxt )
        } else if ( Component == null ) {
          cmp = this.createComponent( { action : item, context : cxt } )
        } else if ( item.handles && item.handles( cxt ) ) {
          cmp = <Component key={item.key} context={cxt} action={item} factory={this}/>
        }
      }
      const isArray = Array.isArray( cmp )
      if ( cmp == null || ( isArray && cmp.length === 0 ) ) {
        return
      }

      // Do we have a section?
      const idx = sort ? sort.lastIndexOf( '.' ) : -1
      const key = idx < 0 ? null : sort.substring( 0, idx )
      const vtl = this.vertical
      const vrt = this.variant
      const showHdr = !this.preferIcon || !this.hideHeaderWhenIconOnly

      if ( cmp != null && r.length > 0 ) {
        // do we need to add a separator?
        if ( idx >= 0 ) {
          // If the key is not the same as separator, we are in a new section. Add separator.
          if ( key !== separator ) {
            
            separator = key
            r.push( <Toolbar.Divider variant={vrt} vertical={vtl} key={`sep-${key}`}/> )
            if ( showHdr && sectionName ) {
              r.push( <Toolbar.Header variant={vrt} vertical={vtl} key={`hdr-${key}`}>{sectionName}</Toolbar.Header> )
            } else if ( sectionName ) {
              r.push( <Toolbar.Divider variant={vrt} vertical={vtl} key={`hdr-${key}`} /> )
            }
            if ( isArray ) { 
              r.push( ...cmp ) 
            } else {
              r.push( cmp )
            }
            return
          } 
          // We are in the same separator. Just add component
          if ( isArray ) { 
            r.push( ...cmp ) 
          } else {
            r.push( cmp )
          }
          return
          
        } 
        if ( separator ) {
          // We are not in a section, but the section is active. Add closing separator
          separator = '__not_grouped__'
          // eslint-disable-next-line react/no-array-index-key
          r.push( <Toolbar.Divider variant={this.variant} vertical={this.vertical} key={`sep-${index}`}/> )
          if ( isArray ) { 
            r.push( ...cmp ) 
          } else {
            r.push( cmp )
          }
          return
        }
      }
      if ( cmp ) {
        separator = key
        if ( r.length === 0 && key && showHdr && sectionName ) {
          r.push( <Toolbar.Header variant={vrt} vertical={vtl} key={`hdr-${key}`}>{sectionName}</Toolbar.Header> )
        }
        if ( isArray ) { 
          r.push( ...cmp ) 
        } else {
          r.push( cmp )
        }
      }
    } )
    return r
  }
}
