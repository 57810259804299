import React from 'react'

import { useSharedContext } from './SharedContext.jsx'

/**
 * Creates a new Component that will pass SharedContext values into the the given
 * component. This uses a PureComponent to avoid unnecessary rerenderes.
 * <pre>
 *    MyComponent = withSharedContext( MyUnAwareComponent, { xyzCreators : 'creators' } )
 *    ...
 *    <SharedContext xyzCreators={xyz}>
 *      ...
 *      <MyComponent item={item}/>
 *    </SharedContext>
 * </pre>
 * In this case, MyUnawareComponent will receive item and creatrors as props. The creators
 * will be the 'xyz' object that is stored in the SharedContext, and the items is the items
 * object given on the invokation line. In the event of colliding names, the props from the
 * SharedContext will win.
 * 
 * @param {React Component} Component Either a function or React.Component
 * @param {Object} mapping an object mapping which will extract SharedContext object
 * using the keys in this map, and pass them as named props to the component. 
 * @returns 
 */
export default function withSharedContext( Component, mapping ) {
  class SharedContextWrapper extends React.PureComponent {

    render() {
      return <Component {...this.props}/>
    }
  }

  const keys = Object.keys( mapping )
  return ( props ) => {
    const cxt = useSharedContext()
    const nProps = { }
    for ( let n = 0; n < keys.length; n++ ) {
      const key = keys[n]
      nProps[mapping[key]] = cxt[key]
    }

    return <SharedContextWrapper {...props} {...nProps} />
  }
}
