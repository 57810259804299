import StepInterpolatorCreator from './StepInterpolatorCreator' 
import LinearInterpolatorCreator from './LinearInterpolatorCreator' 
import GreatCircleInterpolatorCreator from './GreatCircleInterpolatorCreator' 
import RhumbInterpolatorCreator from './RhumbInterpolatorCreator' 
import LinearGeoPointInterpolatorCreator from './LinearGeoPointInterpolatorCreator' 
import StepGeoPointInterpolatorCreator from './StepGeoPointInterpolatorCreator' 

const TYPES = [
  StepInterpolatorCreator,
  LinearInterpolatorCreator,
  GreatCircleInterpolatorCreator,
  RhumbInterpolatorCreator,
  LinearGeoPointInterpolatorCreator,
  StepGeoPointInterpolatorCreator
]

function getCreators() {
  return [].concat( TYPES );
}

function getCreator( type ) {
  if ( type == null ) {
    return null;
  }
  for ( let n = 0; n < TYPES.length; n++ ) {
    if ( TYPES[n].typeName() === type ) {
      return TYPES[n];
    }
  }
  return null;
}

/**
 * Returns an array of updater creators that support the given
 * attribute type. The attrType can be a string, an AttributeCreator,
 * or an attribute object
 **/
function getCreatorsForAttribute( attr ) {
  
  if ( attr == null ) {
    return [];
  }

  const rtn = [];
  for ( let n = 0; n < TYPES.length; n++ ) {
    if ( TYPES[n].handles( attr ) ) {
      rtn.push( TYPES[n] );
    }
  }
  return rtn;
}

export default {
  getCreator,
  getCreators,
  getCreatorsForAttribute,
  StepInterpolatorCreator,
  LinearInterpolatorCreator
}
