import ModelUtil from '@leverege/model-util'
import { unflatten } from '@leverege/object-util'

const TYPE = 'imagine.I18NAttributeModel'

function create( extend ) {
  return {
    type : TYPE,
    translations : {

    },
    ...extend
  }
}

const exp = {
  TYPE,
  type : TYPE,
  create,
  ...ModelUtil.createAllMap( 'translations' ),
  getName : ModelUtil.createGetValue( 'name' ),
  getTranslationData( model ) {
    const translations = exp.getTranslations( model )

    return unflatten( translations, '.' )
  }
}

export default exp
