import GeoshapeStyle from './GeoshapeStyle'

const { FILL_STYLE, LINE_STYLES, LINE_LAYOUT, LABEL_LAYOUT, LABEL_PAINT } = GeoshapeStyle

/**
 * This should be derived from and supplied to the GeoshapeController
 * to perform actions related to creating, updating, and display
 * geoshapes on the map
 **/
class GeoshapeAdapter {
  
  createShape( { controller, geometry } ) {
    const shape = {
      name : `Shape ${Object.keys( controller.geoshapes ).length + 1}`,
      geoJson : { type : 'Feature', geometry },
      appearance : {
        visible : true,
        fillColor : 'rgba(0, 156, 224, 0.30)',
        outlineColor : 'rgba(0, 156, 224, 1.0)',
        outlineStyle : 'thin'
      }
    }
    return shape
  }

  /**
   * Called when a new shape should be added to the data source
   * @param {object} shape the shape to add to the data source. Returned from createShape
   * @return {Promise(Shape)} 
   **/
  addShape( { controller, shape } ) {
    // eslint-disable-next-line no-console
    console.log( 'GeoshapeAdapter.addShape() unimplemented' )
    return Promise.reject( )
  }

  onShapeAdded( { controller, shape } ) {
    // eslint-disable-next-line no-console
    console.log( 'GeoshapeAdapter.onShapeAdded() unimplemented' )
  }

  /**
   * Update a shape should be added to the data source
   * @param {object} shape the shape to add to the data source. Returned from createShape
   * @return {Promise} 
   **/
  updateShape( { controller, shape } ) {
    // eslint-disable-next-line no-console
    console.log( 'GeoshapeAdapter.addShape() unimplemented' )
    return Promise.reject( )
  }

  onModeChanged( { controller, event } ) {
    // eslint-disable-next-line no-console
    console.log( 'GeoshapeAdapter.onModeChanged() unimplemented' )
  }

  getAppearance( { constroller, shape } ) {
    return shape.appearance
  }

  getName( { constroller, shape } ) {
    return shape.name || null
  }

  getFillPaint( shape, app ) {
    return FILL_STYLE
  }

  getLinePaint( shape, outlineStyle ) {
    return LINE_STYLES[outlineStyle || 'thin']
  }

  getLineLayout( shape, outlineStyle ) {
    return LINE_LAYOUT
  }

  getLabelLayout( shape ) {
    return LABEL_LAYOUT
  }

  getLabelPaint( shape ) {
    return LABEL_PAINT
  }
}

export default GeoshapeAdapter
