import React from 'react'
import { ModelMapEditor } from '@leverege/ui-plugin'

import I18NSystemLanguageModel from '../models/I18NSystemLanguageModel.js'

export default function LanguageEditor( props ) {
  const { value, eventData, onChange } = props

  return (
    <ModelMapEditor
      allowCollapseToOne
      eventData={eventData}
      value={value}
      model={I18NSystemLanguageModel.namespaces()}
      onChange={onChange} />
  )
}
