
const families = new Set( [
  'serif', 'sans-serif', 'monospace', 'cursive', 'fantasy', 'math', 'Inter' 
] )
let familyList = null

function addFamily( family ) {
  if ( typeof family === 'string' ) {
    families.add( family )
    familyList = null
  }
}

function removeFamily( family ) {
  families.remove( family )
  familyList = null
}

/**
 * Return the families as [ { value, name } ] and inclues a null value option
 */
function getFamiliesAsOptions() {
  if ( familyList == null ) {
    const arr = Array.from( families )
    arr.sort( ( a, b ) => { return a.toLowerCase().localeCompare( b.toLowerCase() ) } )
    familyList = arr.map( ( f ) => { return { value : f, name : `${f[0].toUpperCase()}${f.substring( 1 )}` } } )
    familyList.unshift( { value : null, name : 'Not Set' } )
  }
  return familyList
}

function getFamilies() {
  return Array.from( families )
}

export default {
  addFamily,
  removeFamily,
  getFamiliesAsOptions,
  getFamilies
}