import React from 'react'
import classnames from 'classnames'
import { Popup, PropertyGrid, Text, TextInput } from '@leverege/ui-elements'
import { Layout } from '@leverege/plugin'
import { ns } from '@leverege/i18n'
import { R } from '@leverege/value-resolver'

import { ModelEditorFactory } from './Factory.js'

import S from './ModelTypeSelector.css'

const { t, tIcon } = ns( 'ui-plugin.editor.ModelTypeSelector' )

const defaultFilterOptions = {
  key : 'name',
  get placeholder() { return t( 'placeholder', 'Search...' ) },
  get noResults() { return t( 'noResults', 'No Results' ) }
}

/**
 * This component will allow the user to pick a different model
 * for use as the model at a given attribute of a model.
 * For example, if a model had a Colorizer model at field 'color', it might
 * look like this:
 *    color : { type : 'colorizer.Color', color : 'red' }
 * 
 * Using this editor will allow the user to pick a different colorizer
 * to use at the 'color' field. The available options are supplied in 
 * the props 'values' and should consist of a array contain objects like this:
 *   { type : string, name : string, create : function() { }}
 * 
 * Use for this (assuming model-util usage) would look something like this:
 *   <ModelTypeSelector 
 *     value={Model.getColor( value )} 
 *     values={availableColorizer} 
 *     onChange={onValueChange} />
 * 
 * A probably configuration of the availableColorizers might look like:
 *   Plugins.add( 'Colorizer', {
 *     type : 'colorizer.Color',
 *     name : 'Constant Color',
 *     create : ( ) => { return ConstantColorModel.create() }
 *   } )
 * 
 * If the prop showInline is true (default) and the editor for the
 * value is has isInline set to true, the editor will be displayed 
 * beside the selector
 */
export default class ModelTypeSelector extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { previousValue : { }, filterTerm : '' }
  }
  
  onSelect = ( evt ) => {
    const { onChange, eventData, value, modelKey = 'model' } = this.props
    if ( onChange ) {
      const { previousValue } = this.state
      
      const nValue = evt.value == null ? null : 
        ( previousValue[evt.value.type] || evt.value[modelKey]?.create() || evt.value.create() )
      if ( value && value.type ) {
        this.setState( { previousValue : { ...previousValue, [value.type] : value } } )
      }
      onChange( { value : nValue, data : eventData } )
    }
  }

  /**
   * @method onPopupChange Handle change event data when the Popup component changes
   * @param {bool} changeEvent.isOpened
   */
   onPopupChange = ( { isOpened } ) => {
     const { showFilter = false } = this.props
     if ( showFilter && !isOpened ) {
       this.resetFilter()
     }
   }

   /**
   * @method onChangeFilterTerm Updates the filter term
   * @param {object} event The event containing the input filter term value
   */
  onChangeFilterTerm = ( event ) => {
    const filterTerm = event.value.trim().toLowerCase()
    return this.setState( { filterTerm } )
  }

  onSimpleChange = ( evt ) => {
    const { onChange, eventData, value } = this.props
    onChange( { value : evt.value, data : eventData, oldValue : value } )
  }

  /**
   * @method resetFilter Reset the filter term
   */
   resetFilter = () => {
     this.setState( { filterTerm : '' } )
   }

   /**
   * @method filterMenuItems Filters this component's `options` by some user input `filterTerm`
   * @param {Array.<object>} menuSpec What the user passed in as the `options` prop.
   * @param {string} filterTerm The term by which to filter the `menuSpec`s elements.
   * @param {Object.<string>} filterOptions The `filterOptions`, either default or user-defined.
   * The `key` in the `filterOptions` tells this function on which key of the `menuSpec` elements to filter the menus.
   * @returns {array} The filtered `menuSpec` argument.
   */
   filterMenuItems = ( menuSpec, filterTerm, filterOptions ) => {
     const filtered = menuSpec
       .filter( ( value ) => {
         const isString = typeof value[filterOptions.key] === 'string'
         return isString && value[filterOptions.key].toLowerCase().includes( filterTerm )
       } )
     return filtered
   }

   createComponents( node, onClose ) {
     const { value, typeKey = 'type', nameKey = 'name' } = this.props
    
     if ( node == null ) {
       return null
     }
     const r = node.items.map( ( item, idx ) => {
       const nameValue = item[nameKey]
       const name = R( nameValue )
       if ( item.type === Layout.NODE_TYPE ) {
         return (
           <Popup.Menu
             key={name}
             title={name}
             render={( onClose ) => {
               return this.createComponents( item, onClose )
             }} />
         )
       }

       const type = item[typeKey]
       const checked = value != null && type === value.type
            
       return (
         <Popup.Item
           checkStyle="check"
           checked={checked}
           key={type}
           value={item}
           onClick={this.onSelect}
           onClose={onClose}>
           {name}
         </Popup.Item>
       )
     } )
     return r
   }

   createMenu = ( onClose ) => {
     const { filterTerm } = this.state
     const { allowNone = true, noneName = 'None',
             showFilter = false, filterOptions = defaultFilterOptions, filterVariant = 'popupSelectorFilter',
             value, values } = this.props

     if ( showFilter ) {
       const filteredMenuSpec = this.filterMenuItems( values, filterTerm, filterOptions )
       const filteredMenuTree = this.createComponents( Layout.create( filteredMenuSpec ), onClose )
       const noFilterResults = Array.isArray( filteredMenuTree ) && filteredMenuTree.length === 0

       return (
         <React.Fragment>
           <Popup.Header sticky>
             <TextInput
               variant={filterVariant}
               className={S.filterInput}
               onChange={this.onChangeFilterTerm}
               value={filterTerm}
               changeOnReturn={false}
               alwaysFireOnReturn={false}
               name="filterItemLayoutSelector"
               hint={filterOptions.placeholder} />
           </Popup.Header>
           {allowNone && (
             <Popup.Item
               checkStyle="check"
               checked={value == null}
               key="none"
               value={null}
               onClick={this.onSelect}
               onClose={onClose}>
               {noneName}
             </Popup.Item>
           ) }
           {filteredMenuTree}
           {noFilterResults && (
             <Popup.Item>
               {filterOptions.noResults}
             </Popup.Item>
           )}
         </React.Fragment>
       )
     }

     return (
       <React.Fragment>
         {allowNone && (
           <Popup.Item
             checkStyle="check"
             checked={value == null}
             key="none"
             value={null}
             onClick={this.onSelect}
             onClose={onClose}>
             {noneName}
           </Popup.Item>
         ) }
         {this.createComponents( Layout.create( values ), onClose )}
       </React.Fragment>
     )
   }

   // TODO: This should probably use Layout to order the values
   render() {
     const { 
       value, values, onChange, showInline = true, showName = true, eventData, editorProps, 
       popupOptions, className, allowNone, noneName = 'None', modelKey, typeKey, nameKey,
       showNoneInline = true, noneVariant = 'input', nameVariant = 'input',
       isPropertyGrid, propertyGridOpts, selectorFirst = false, icon, ...rest } = this.props
     let inlineView = null
     if ( value ) {
       const currValue = showName && values.find( v => v.type === value.type )
       const editorName = currValue?.name || null
       if ( showInline && ModelEditorFactory.isInline( value ) ) {
         const eProps = { ...editorProps, className : S.inline, value, onChange : this.onSimpleChange, key : 'mts-editor' }
         inlineView = ModelEditorFactory.create( value, eProps )
       } else if ( editorName ) {
         inlineView = <Text key="mts-editor" variant={nameVariant}>{editorName}</Text>
       } else {
         inlineView = <div key="mts-editor" />
       }
     } else {
       inlineView = showNoneInline ? <Text key="mts-editor" variant={noneVariant}>{noneName}</Text> : <div/>
     }

     const subViews = [ inlineView ]

     const selectorIcon = icon || tIcon( 'selectorIcon', 'fa fa-caret-down fa-fw' )

     const popup = (
       <Popup
         key="mts-selector" 
         className={S.popup}
         toggleAttributes={{ iconOn : selectorIcon, iconOff : selectorIcon }} 
         targetAttachment="bottom right"
         attachment="top right"
         {...popupOptions}
         render={this.createMenu}
         onChange={this.onPopupChange} />
     )

     if ( isPropertyGrid || selectorFirst ) {
       subViews.unshift( popup )
     } else {
       subViews.push( popup )
     }

     // Would be nice to be a selector, but i need to control the top level display
     return (
       <div {...rest} className={classnames( className, S.editor, isPropertyGrid ? S.propertyGridEditor : undefined )}>
         {subViews}
       </div>
     )
   }

   /**
   * Returns an array of [ ModelTypeSelect, <div>{modelEditor}</div>
   */
   static createSelectorAndEditor( selectorProps, holderClassName ) {
     const { value, editorProps, eventData, onChange, isPropertyGrid, propertyGridOpts, label } = selectorProps
     const isInline = ModelEditorFactory.isInline( value )
     if ( isInline ) {
       if ( isPropertyGrid ) {
         return ( 
           <PropertyGrid.Item newRow {...propertyGridOpts} label={label}>
             <ModelTypeSelector isPropertyGrid key="mts" {...selectorProps} />
           </PropertyGrid.Item>
         )
       }
       return [ <ModelTypeSelector key="mts" {...selectorProps} /> ]
     }
     const forward = ( evt ) => {
       onChange( { value : evt.value, data : eventData, oldValue : value } )
     }

     if ( isPropertyGrid ) {
       return [
         <PropertyGrid.Item key="mts-selector" newRow {...propertyGridOpts} label={label}>
           <ModelTypeSelector isPropertyGrid key="mts" {...selectorProps} />
         </PropertyGrid.Item>,
         <PropertyGrid.Item key="mts-editor" newRow fill inset>
           {ModelEditorFactory.create( value, { ...editorProps, value, onChange : forward } )}
         </PropertyGrid.Item>
       ]
     }
    
     return [
       <ModelTypeSelector key="mts" {...selectorProps} />,
       <div key="mth" className={holderClassName}>
         {ModelEditorFactory.create( value, { ...editorProps, value, onChange : forward } )}
       </div>
     ]
   }
}
