import React from 'react'
import { DateTimeRangePicker } from '@leverege/ui-elements'

export default function DateTimeRangeEditor( props ) {
  const { eventData, value, onChange } = props
  const v = value && typeof value === 'object' ? { ...value } : value
  if ( v && typeof v.start === 'string' ) {
    v.start = new Date( v.start )
  }
  if ( v && typeof v.end === 'string' ) {
    v.end = new Date( v.end )
  }
  return <DateTimeRangePicker variant="dashboardOption" value={v} eventData={eventData} onChange={onChange}/>
  
}

DateTimeRangeEditor.TYPE = 'dateTimeRange'
