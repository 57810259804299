import React from 'react'
import { R } from '@leverege/value-resolver'
import { Button, Pane, Label } from '@leverege/ui-elements'
import { Layout } from '@leverege/plugin'

import Util from './Util.js'
import ToolbarFactory from './ToolbarFactory.jsx'

import S from './TouchToolbarFactory.css'

export default class TouchToolbarFactory extends ToolbarFactory {
  constructor( options ) {
    const opts = options || {}
    super( opts )

    this.buttonVariant = opts.buttonVariant || 'touchToolbarButton'
    this.labelVariant = opts.labelVariant || 'touchToolbarLabel'
    this.popupToggleVariant = opts.popupToggleVariant || 'touchToolbarPopupToggle'
    this.variantWith = opts.variantWith || Util.variantWith
    this.preferIcon = true
    this.preferText = false
  }

  createComponent( props ) {
    const { context, action } = props

    if ( action.handles == null || !action.handles( context ) ) {
      return null
    }

    const { 
      id, name : rname, icon : ricon, disabled, variant,
      visible, checked } = action.appearance( props )
    const name = R( rname )
    const icon = R( ricon )

    if ( visible === false || icon == null || typeof name !== 'string' ) {
      return null
    }

    return (
      <Pane key={id || name} layout="flex:colSCenter">
        <Button
          id={id || name}
          title={name}
          variant={this.variantWith( this.buttonVariant, variant )}
          key={action.key} 
          value={checked} 
          disabled={disabled} 
          icon={icon}
          onClick={this.onClick}
          eventData={props} />
        <Label
          variant={this.variantWith( this.labelVariant, variant )}
          className={S.label}
          htmlFor={id || name}>{name}
        </Label>
      </Pane>
    )
  }

  createMenu( item, cxt ) {
    const menu = super.createMenu( item, cxt )

    const { name } = item

    return (
      <Pane key={name} layout="flex:colSCenter">
        {menu}
        <Label
          variant={this.labelVariant}
          className={S.label}>{name}
        </Label>
      </Pane> 
    )
  }

  createContextMenu( props ) {
    const { action, context } = props
    const { customComponent : Component } = action

    return (
      <Pane
        key="contextMenu"
        layout="flex:colSCenter">
        <Component
          key={action.key}
          context={context}
          action={context}
          factory={this} />
        <Label
          variant={this.labelVariant}
          className={S.label}>More
        </Label>
      </Pane>
    )
  }

  createComponents( rootLayoutNode, cxt ) {

    if ( rootLayoutNode == null ) {
      return null
    }
    const items = Array.isArray( rootLayoutNode ) ? rootLayoutNode : rootLayoutNode.items
    return items.map( ( item ) => {
      if ( item.type === Layout.NODE_TYPE ) {
        return this.createMenu( item, cxt )
      }

      const { customComponent : Component, key } = item

      if ( Component && key === 'autoToolbarContextMenu' ) {
        return this.createContextMenu( { action : item, context : cxt } )
      }

      if ( Component && item?.handles?.( cxt ) ) {
        // Let you render your custom component
        // Trusting you will make it touch device appropriate
        return (
          <Component
            key={item.key}
            context={cxt}
            action={item}
            factory={this}/>
        )
      }

      return this.createComponent( { action : item, context : cxt } )
    } )
  }
}
