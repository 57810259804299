/* eslint-disable react/no-array-index-key */
import React from 'react'

import I18N from '@leverege/i18n'
import { RP } from '@leverege/value-resolver'
import { Popup } from '@leverege/ui-elements'
import { useDashboardContext } from '../shared/DashboardContext'

const { t } = I18N.ns( 'uiDashboard.editor.CardSelector' )

export default function CardSelector( { models, onSelect, title } ) {

  const dc = useDashboardContext()
  const { sharedProps, objectType, getModelsFor, getModelCreators, models : dcModels } = dc

  if ( getModelCreators ) {
    const creators = getModelCreators( sharedProps, dc )
    // const onClick = ( { data : creator } ) => {
    //   const model = creator.create( sharedProps, dc )
    //   onSelect( { data : model } )
    // }
    return (
      // Use UI Plugin Layout mechanism here
      <Popup
        title={title || <i className="fa fa-plus" />}
        toggleAttributes={{ title : dc.addWidgetText || t( 'addWidget' ) }}>
        {
          creators.map( ( creator, idx ) => {
            const name = RP( creator, 'name' )
            const icon = RP( creator, 'icon' )
            return <Popup.Item icon={icon} key={idx} eventData={creator} onClick={onSelect}>{name}</Popup.Item>
          } ) 
        }
      </Popup>
    )
  }

  // Deprecated path
  const mdls = models || dcModels
  const avail = getModelsFor( objectType )

  // console.log( mdls, models, dcModels )

  return (
    <Popup
      title={title || <i className="fa fa-plus" />}
      toggleAttributes={{
        title : dc.addWidgetText || t( 'addWidget' )
      }}>
      {
        Object.keys( avail ).map( ( key ) => {
          if ( avail[key].isInternal === true ) {
            return null
          }
          const model = mdls.create( key )
          let name = avail[key].getTypeName || avail[key].name || avail[key].NAME || key
          if ( typeof name === 'function' ) {
            name = name.apply( avail[key] )
          }
          return <Popup.Item key={key} eventData={model} onClick={onSelect}>{name}</Popup.Item>
        } ) 
      }
    </Popup>
  )
}
