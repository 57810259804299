// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor_nEaaA {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/BlueprintTranslationScreen.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".editor {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `editor_nEaaA`
};
export default ___CSS_LOADER_EXPORT___;
