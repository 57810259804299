export default {
  
  name() {
    return 'Constant'
  },

  typeName() {
    return 'constant' 
  },

  handles( attributeType ) {
    return true
  },

  create( val ) { 
    return { 
      type : this.typeName(), 
      constant : val
    }
  }
}
