import React from 'react'
import centroid from '@turf/centroid'
import center from '@turf/center'
import centerOfMass from '@turf/center-of-mass'
import B62 from '@leverege/base62-util'
import { ValuesCache } from '@leverege/value-cache'
import { Feature, Layer } from 'react-mapbox-gl'

const centerAlgs = {
  centroid,
  center,
  centerOfMass
}

const EMPTY = { }

/**
 * Controls the creation and updating of geoshapes and their layers.
 * A geoshape has, at minimum the form:
 * {
 *    id : <uuid>,
 *    geoJson : <geoJson feature> 
 * }
 * Other specific attributes can be added by the GeoshapeAdapter. 
 * 
 **/
export default class GeoshapeController {
  
  constructor( { adapter, map, dataType = 'geoshape', geoJsonKey = 'geoJson',
                 shapeKey = 'geoshapes', selectedKey = 'geoshapeSelected',
                 labelCenterAlgorithm = 'centerOfMass', before } ) {
    this.map = map
    this.key = shapeKey
    this.geoJsonKey = geoJsonKey
    this.selectedKey = selectedKey
    this.dataType = dataType
    this.adapter = adapter
    this.before = before || 'geoshapes'
    this.cache = new ValuesCache()
    this.labelCenterAlgorithm = labelCenterAlgorithm
    if ( !( labelCenterAlgorithm === 'centroid' || 
            labelCenterAlgorithm === 'center' ||
            labelCenterAlgorithm === 'centerOfMass' ) ) {
      throw new Error( 'invalid labelCenterAlgorithm' )
    }
  }

  get geoshapes( ) {
    if ( this.adapter.getShapes ) {
      return this.adapter.getShapes()
    }
    return this.map.props[this.key] || EMPTY
  }

  onCombineShapes = ( e ) => { }

  onUncombineShapes = ( e ) => { }

  onShapeUpdate = ( e ) => {
    const geoshapes = this.geoshapes
    const f = e.features[0]
    const gs = geoshapes[f.id]
    if ( gs ) {
      // shallowing copy geoshape and set new geometry
      const shape = { ...gs }
      shape[this.geoJsonKey] = { type : 'Feature', geometry : f.geometry }
      this.adapter.updateShape( { controller : this, shape } )
    }
  }

  onCreateShape = ( e ) => {
    // Request the adapter to create a new shape
    const shape = this.adapter.createShape( { controller : this, geometry : e.features[0].geometry } )
    if ( shape.id == null ) {
      shape.id = B62.v4()
    }
    this.adapter.addShape( { controller : this, shape } ).then( ( s ) => {
      this.adapter.onShapeAdded( { controller : this, shape : s || shape } )
      // store.dispatch( Select.set( shape.id, this.selectedKey ) )
    } )
  }

  onModeChange = ( event ) => {

    // Forward any changes that occur from the DrawControl itself.
    // The DrawControl should do nothing in the case this is the
    // same value 
    this.adapter.onModeChanged( { controller : this, event } )
  }

  /**
   * Returns a FeatureCollection for the given arrays
   * @param {array} _ids 
   */
  getFeatureCollectionFor( _ids ) {
    const ids = _ids || []
    if ( this.cache.isSame( 'fc', ids ) ) {
      return this.cache.value( 'fc' )
    }
    const geoshapes = this.geoshapes
    const fc = { type : 'FeatureCollection', features : [ ] }
    ids.forEach( ( id ) => {

      const gs = geoshapes[id]
      if ( gs == null ) {
        return
      }
      const app = this.adapter.getAppearance( { controller : this, shape : gs } )
      const { fillColor, outlineColor, outlineWidth = 2, outlineStyle = 'thin' } = app
      const geo = gs && gs[this.geoJsonKey] && gs[this.geoJsonKey].geometry
      if ( geo && geo.type === 'Polygon' && geo.coordinates ) {
        const c = geo.coordinates
        const p = { dataType : 'geoshape', dataId : id, fillColor, outlineColor, outlineStyle }
        if ( c.length > 0 ) {
          fc.features.push( {
            type : 'Feature', 
            id,
            geometry : { type : 'Polygon', coordinates : c },
            properties : p
          } )
        } else {
          console.log( 'Zero coordinates in shape', gs )
        }
      }
    } )
    return this.cache.set( 'fc', fc, ids )
  }

  /**
   * Returns a array of layers to render. Because Mapbox cannot control the line properties
   * of a filled shape very well, this will create two separate layers per geoshape, one for
   * the fill and selection, on for the outline.
   **/
  getLayers( ) {
    let l, f
    const geoshapes = this.geoshapes
    const { geoshapesActive = false, userOptions } = this.map.props

    // Check for cached value
    if ( this.cache.isSame( 'layers', geoshapes, geoshapesActive, userOptions ) ) {
      return this.cache.value( 'layers' )
    }

    const meta = { isSelectable : geoshapesActive, isRolloverable : false }
    const gsOpts = ( userOptions && userOptions.geoshapes ) || { }
    const layers = []

    Object.keys( geoshapes ).forEach( ( id ) => {
      const gs = geoshapes[id]
      const opt = { controller : this, shape : gs }
      const app = this.adapter.getAppearance( opt )
      if ( !app.visible ) {
        return
      }
      const { fillColor, outlineColor, labelColor, outlineWidth = 2, outlineStyle = 'thick', showLabel = true } = app
      const geo = gs && gs[this.geoJsonKey] && gs[this.geoJsonKey].geometry
      if ( geo && geo.type === 'Polygon' && geo.coordinates ) {

        const c = geo.coordinates
        const fid = `${this.dataType}-${id}`
        const lid = `${this.dataType}-line-${id}`
        const tid = `${this.dataType}-label-${id}`
        const p = { 
          dataType : this.dataType, 
          dataId : id, 
          fillColor, 
          outlineColor, 
          labelColor,
          name : this.adapter.getName( opt ),
          outlineWidth : 2 
        }

        // Add shape
        f = ( <Feature id={id} key={id} coordinates={c} properties={p} /> )
        l = ( 
          <Layer 
            key={fid} 
            id={fid} 
            metadata={meta} 
            type="fill" 
            paint={this.adapter.getFillPaint( gs, app )} 
            before={this.before}>{f}
          </Layer> 
        )
        layers.push( l )

        // Add Outlines
        if ( outlineStyle !== 'none' && ( outlineWidth != null && outlineWidth > 0 ) ) {
          f = ( <Feature coordinates={c[0]} properties={p} /> )
          l = ( 
            <Layer key={lid}
              id={lid}
              type="line" 
              before={this.before}
              paint={this.adapter.getLinePaint( gs, outlineStyle )}
              layout={this.adapter.getLineLayout( gs, outlineStyle )}>{f}
            </Layer> 
          )

          layers.push( l )
        }

        // Add Label
        if ( showLabel && p.name != null ) {
          const cnt = centerAlgs[this.labelCenterAlgorithm]( gs[this.geoJsonKey] )
          f = ( <Feature coordinates={cnt.geometry.coordinates} properties={p} /> )
          l = ( 
            <Layer key={tid}
              id={tid}
              type="symbol" 
              before={this.before}
              paint={this.adapter.getLabelPaint( gs )}
              layout={this.adapter.getLabelLayout( gs )}>{f}
            </Layer>
          )
          layers.push( l )
        }
      }
    } )
    return this.cache.set( 'layers', layers, geoshapes, geoshapesActive, userOptions )
  }
}
