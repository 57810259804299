import { LogicCondition } from '@leverege/condition'
import { ns, tIcon } from '@leverege/i18n'
import ConditionCreators from '../../selector/ConditionCreators'

const { tf, tfIcon } = ns( 'condition.LogicConditionEditor' )

export default {
  id : 'condition.logic.ReplaceConditionAction',
  matches : {
    client : 'condition.LogicEditor',
    use : 'conditionActionBar'
  },
  layout : {
    group : [ tf( 'moreGroup' ), tf( 'replaceCondition' ) ],
    groupIcons : [ tfIcon( 'moreIcon', 'fa fa-ellipsis-v fa-fw' ) ],
    sort : 'replace'
  },
  handles( context ) {
    return context.conditionCreators != null &&
      context.conditionCreators
        .filter( c => c.type !== LogicCondition.TYPE ).length > 1
  },
  getActions( context ) {
    const { conditionCreators, parent, index, model, parentOnChange : onChange, parentEventData : eventData, value } = context

    const onSelect = ( e ) => {
      let nc = e.value.create()
      
      if ( nc.type === LogicCondition.TYPE ) {
        nc = LogicCondition.addCondition( nc, value )
      }

      onChange( { data : eventData, value : model.set( parent, index, nc ) } )
    }

    return ConditionCreators.toActions(
      conditionCreators.filter( c => c.type !== LogicCondition.TYPE ),
      onSelect,
      'foo'
    )
  }
}
