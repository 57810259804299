import GeoshapeStyle from './GeoshapeStyle'
const { FILL_STYLE, LINE_STYLES, LINE_LAYOUT, LABEL_LAYOUT, LABEL_PAINT, SYMBOL_LAYOUT } = GeoshapeStyle
 
/**
 * The purpose of this class it so supply the interface through which data
 * can be converted into mapbox shapes with the appropriate appearance.
 * Used in conjunction with the LayerCreator, this will supply a mechanism
 * to walk the data and return the shape information from the data. The 
 * LayerCreator will convert this into mapbox features and will attempt
 * to cache the data based on the data source and options given to it.
 * 
 * To 
 */
export default class GeoshapeAdapter {
  
  /**
   * This will walk the shapes data and invoke the callback for each item
   * @param {object} shapes the object that contains the data. If this is an array,
   * a for of [ { id, ... } ] is expected. If its an object and shapes.items is an array,
   * the array form will be used. Otherwise its assumed to be a { id : data } map.
   * @param {object} options the options object supplied to the layer creator
   * @param {function} cb called for each data item with ( data, id ).
   */
  iterate( shapes, options, cb ) {
    if ( shapes ) {
      if ( Array.isArray( shapes ) ) {
        shapes.forEach( ( data ) => { if ( data ) { cb( data, data.id ) } } )
      } else if ( Array.isArray( shapes.items ) ) {
        shapes.items.forEach( ( data ) => { if ( data ) { cb( data, data.id ) } } )
      } else {
        Object.keys( shapes ).forEach( ( k ) => { if ( shapes[k] ) { cb( shapes[k], k ) } } )
      }
    }
  }

  /**
   * This will invoke the callback for each item whose id is in the given ids array. If an
   * id is in the ids list but not present in the shapes data, no invokation will occur
   * for that id.
   * @param {object} shapes the object that contains the data. If this is an array,
   * a for of [ { id, ... } ] is expected. If its an object and shapes.items is an array,
   * the array form will be used. Otherwise its assumed to be a { id : data } map.
   * @param {object} options the options object supplied to the layer creator
   * @param {function} cb called for each data item with ( data, id ).
   */
  iterateOnIds( shapes, ids, options, cb ) {
    const arr = Array.isArray( ids ) ? ids : ids ? [ ids ] : null
    if ( arr && shapes ) {
      if ( Array.isArray( shapes ) ) {
        shapes.forEach( ( data ) => { 
          if ( data && ids.includes( data.id ) ) {
            cb( data, data.id )
          } 
        } )
      } else if ( Array.isArray( shapes.items ) ) {
        shapes.items.forEach( ( data ) => { 
          if ( data && ids.includes( data.id ) ) {
            cb( data, data.id )
          } 
        } )
      } else {
        ids.forEach( ( id ) => {
          if ( shapes[id] ) {
            cb( shapes[id], id )
          }
        } )
      }
    }
  }

  /**
   * ShapeData
   * @typedef {Object} ShapeData
   * @property {Object} geoJson The shape file
   * @property {Object} appearance data driven attributes about the shape. It can include
   * custom attributes that can be referenced in the paint objects. This will be attached
   * as properties to the final mapbox feature
   * @property {boolean} [appearance.visible] if false, the shape is ignored
   * @property {boolean} [appearance.layerGroup] this is used for moving rendering up
   * onto different levels. Normally one of 'rollover', 'selected', 'targerted', this
   * will default to 'default' The Layer creator will use this to but the shape in a
   * layer that can be rendered at a rollover/selected/targeted z-index.
   * @property {boolean} [appearance.showFill] true if fill should be drawn
   * @property {boolean} [appearance.fillStyle] a style group for the fill
   * @property {boolean} [appearance.showOutline] true if outline should be drawn
   * @property {boolean} [appearance.outlineStyle] a style group for the outline
   * @property {boolean} [appearance.showLabel] true if fill should be drawn
   * @property {boolean} [appearance.labelStyle] a style group for the label
   * @property {Array|Oject} [labelPosition] optional label position [lon,lat] or { lat, lon }
   */

  /**
   * This extracts shape and appearance properties for he given shape.
   * This can return null, a ShapeData Object or an Array of ShapeData objects. 
   *  
   * @param {object} options this is the object given to the LayerCreator with the shape
   * data object. Information such as rollovers, selections, visiblity options, etc can
   * be supplied via this object.
   * @return {object|array|null} if null is returned, the shape is ignored (identical to setting 
   * visible in appearance to be false). If an object is returned, it should contain { geoJson, 
   * appearance, labelPosition } where geoJson is the shape, appearance if information used for data
   * driven styling, and an optional labelPosition (which will default to the centroid).
   * The geoJson is the shape to render, and the appearance reflects how, and any data driven fields necessary
   * to render correctly. This will be put into the feature's properties object. If the data item has many
   * shapes, an array of the { geoJson, appearance } objects can be returned.
   * 
   */
  getShapeData( shape, id, options ) {
    // eslint-disable-next-line no-console
    console.warn( 'getShapeData must be overridden to make shapes appear' )
    return null
  }

  getFillPaint( style, layerGroup, options ) {
    return FILL_STYLE
  }

  getLinePaint( style, layerGroup, options ) {
    return LINE_STYLES[style || 'thin']
  }

  getLineLayout( style, layerGroup, options ) {
    return LINE_LAYOUT
  }

  getLabelLayout( style, layerGroup, options ) {
    return LABEL_LAYOUT
  }

  getLabelPaint( style, layerGroup, options ) {
    return LABEL_PAINT
  }

  getSymbolLayout( style, layerGroup, options ) {
    return SYMBOL_LAYOUT
  }

  /**
   * This returns the id of the layer which the style/layerGroup
   * layer should be placed before. The layer if must exist in the
   * map already: 'normal', 'selected', 'targeted', 'rollover' are
   * common examples
   * @param {string} style the style type of the layer group. This will be
   * the value returned in fillStyle, outlineStyle, labelStyle or 'default'.
   * @param {string} layerGroup the layerGroup type. 
   * @param {object} options the options object
   */
  getBefore( style, layerGroup, options ) {
    switch ( layerGroup ) {
      case 'selected': return 'selected'
      case 'targeted': return 'targeted'
      case 'rollover': return 'rollover'
      default: return 'normal'      
    }
    // return 'normal'
  }
}
