import React from 'react'

import SystemTranslationsEditor from './SystemTranslationsEditor.jsx'
import I18NNamespaceModel from '../models/I18NNamespaceModel.js'

const getStandardTranslations = ( name ) => {
  return [ {
    value : 'Molten UI',
    label : 'Page Title',
    eventData : 'molten.Head.Support.title'
  }, {
    value : 'https://molten-ui-assets.storage.googleapis.com/favicon-2.png',
    label : 'Favicon',
    eventData : 'molten.Head.Support.favicon'
  } ]
}

export default function I18NSystemNamespaceEditor( props ) {

  return (
    <SystemTranslationsEditor
      getStandardTranslations={getStandardTranslations}
      model={I18NNamespaceModel.translations()}
      {...props} />
  )
}
