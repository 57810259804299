import { Plugins, Context } from '@leverege/plugin'

/**
 * Returns an object with four functions mapStateToProps( state, ownProps ),
 * mapDispatchToProps( state, ownProps ), postStateToProps( state, ownProps, calcProps ),
 * and postDispatchToProps( state, ownProps, calcProps ). These will run the matching
 * plugins' corresponding methods, appending clientName and matches to the function:
 * 
 *  Plugins.add( 'ReduxConnect', {
 *   matches : {
 *     client : 'MyComponent'
 *   },
 *   mapStateToProps : ( state, ownProps, plgProps, clientName, context ) => { .. },
 *   mapDispatchToProps : ( dispatch, ownProps, plgProps, clientName, context ) => { .. },
 *   postMapStateToProps : ( state, ownProps, calcProps, plgProps, clientName, context ) => { .. },
 *   postMapDispatchToProps : ( dispatch, ownProps, calcProps, plgProps, clientName, context ) => { .. }
 * } )
 * 
 * Any of the functions can be null. 
 * 
 * The state and ownProps are the values passed by redux. plgProps is the current state
 * of the plugin contributions, and can be null. The calcProps is the combination of
 * the plugins mapXXXToProps and the normal redux call.
 */
function create( clientName, context ) {
  const view = Plugins.createView( 'ReduxConnect', {
    name : clientName,
    filter : ( plg ) => {
      return Context.isMatch( context, plg )
    }
  } )

  function runPlugins( stateOrDispatch, ownProps, methodName, calcProps ) {
    const plgs = view.get()
    let plgProps = null
    for ( let n = 0; n < plgs.length; n++ ) {
      if ( plgs[n][methodName] ) {
        const m = plgs[n][methodName]
        const r = calcProps !== false ? 
          m( stateOrDispatch, ownProps, calcProps, plgProps, clientName, context ) :
          m( stateOrDispatch, ownProps, plgProps, clientName, context )

        if ( r != null ) {
          plgProps = plgProps == null ? r : { ...plgProps, ...r }
        } 
      }
    }
    return plgProps
  }

  return {
    mapStateToProps : ( state, ownProps ) => { 
      return runPlugins( state, ownProps, 'mapStateToProps', false ) 
    },
    postMapStateToProps : ( state, ownProps, calcProps ) => { 
      return runPlugins( state, ownProps, 'postMapStateToProps', calcProps ) 
    },
    mapDispatchToProps : ( dispatch, ownProps ) => { 
      return runPlugins( dispatch, ownProps, 'mapDispatchToProps', false ) 
    },
    postMapDispatchToProps : ( dispatch, ownProps, calcProps ) => { 
      return runPlugins( dispatch, ownProps, 'postMapDispatchToProps', calcProps ) 
    }
  }
}

export default {
  create
}
