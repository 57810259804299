import React from 'react'
import { useValueChange } from '@leverege/ui-hooks'
import { IdGen, Flex, Label, TextInput, Selector, ButtonGroup, ToggleButton } from '@leverege/ui-elements'

import Model from './FontModel'
import Fonts from './Fonts'

import S from './FontEditor.css'

export default function FontEditor( props ) {
  const { value } = props
  const onValueChange = useValueChange( Model, props )
  // const style = Model.getStyle( value )
  // const onStyle = ( e ) => {
  //   onValueChange( { data : 'setStyle', value : e.data } )
  // }

  return (
    <div className={S.editor}>
      <Flex variant="rowS" align="center" className={S.family}>
        <Label variant="input">Family</Label>
        <Flex.Layout grow={1}>
          <Selector
            value={Model.getFamily( value )}
            values={Fonts.getFamiliesAsOptions()}
            eventData="setFamily"
            onChange={onValueChange} />
        </Flex.Layout>
      </Flex>

      <Flex variant="rowS" align="center" justify="flex-end" className={S.size}>
        <Label variant="input" className={S.padLeft}>Size</Label>
        <TextInput
          size={6}
          eventData="setSize"
          hint="Size"
          value={Model.getSize( value )}
          onChange={onValueChange} />
      </Flex>

      <Flex variant="rowS" align="center" className={S.weight}>
        <Label variant="input">Weight</Label>
        <Flex.Layout grow={1}>
          <Selector
            eventData="setWeight"
            value={Model.getWeight( value )}
            values={[
              { value : null, name : 'Not Set' },
              { value : 'bold', name : 'Bold' },
              { value : 'bolder', name : 'Bolder' },
              { value : 'normal', name : 'Normal' },
              { value : 'light', name : 'Light' }
            ]}
            onChange={onValueChange} />
        </Flex.Layout>
      </Flex> 

      <Flex variant="rowS" align="center" className={S.style}>
        <Label variant="input">Style</Label>
        <Flex.Layout grow={1}>
          <Selector
            eventData="setStyle"
            value={Model.getStyle( value )}
            values={[
              { value : null, name : 'Not set' },
              { value : 'normal', name : 'Normal' },
              { value : 'italic', name : 'Italic' },
              { value : 'oblique', name : 'Oblique' },
            ]}
            onChange={onValueChange} />
        </Flex.Layout>
      </Flex>
      {/* <Flex>
        <ToggleButton variant="small" value={style == null} eventData={null} onChange={onStyle}>
          <div style={{ fontFamily : 'monospaced', fontStyle : 'normal' }}>-</div>
        </ToggleButton>
        <ToggleButton variant="small" value={style === 'normal'} eventData="normal" onChange={onStyle}>
          <div style={{ fontFamily : 'monospaced', fontStyle : 'normal' }}>I</div>
        </ToggleButton>
        <ToggleButton variant="small" value={style === 'italic'} eventData="italic" onChange={onStyle}>
          <div style={{ fontFamily : 'monospaced', fontStyle : 'italic' }}>I</div>
        </ToggleButton>
      </Flex> */}
    </div>
  )
}
