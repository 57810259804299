export default class Symbols {
    
  constructor( ready, options ) {
    this.images = { }
    this.scaledImages = {}
    this.ready = ready
    this.left = 0

    if ( options && options.images ) {
      Object.keys( options.images ).forEach( ( k ) => {
        this.addImage( k, options.images[k] )
      } )
    }
  }

  /** 
   * Adds an image to the Symbols
   * @param {string} name the name of the symbol
   * @param {object} imageOptions
   * @param {string} imageOptions.src the source of the image
   * @param {int} imageOptions.width the width to scale the image to 
   * @param {int} imageOptions.height the height to scale the image to
   **/
  addImage( name, imageOptions ) {
    const { src, width, height } = imageOptions

    const image = imageOptions.image || new Image()
    if ( imageOptions.image == null ) {
      image.crossOrigin = 'Anonymous'
    }
    this.images[name] = { ...imageOptions, image }
    image.onload = ( ) => {
      if ( width || height ) {
        this.resizeImage( image, width, height ).then( ( scaledImage ) => {
          this.scaledImages[name] = scaledImage
          this.onLoad()
        } )
      } else {
        this.scaledImages[name] = image
        this.onLoad()
      }
    }
    image.onerror = ( ) => {
      // eslint-disable-next-line no-console
      console.error( 'Error loading ', src )
      this.images[name].error = true
      this.images[name].image = null
      this.scaledImages[name] = null
      this.onLoad()
    }
    this.left++
    image.src = src
  }

  /**
   * Adds an alias. Any references to name will be redirected
   * to the param mapsTo
   **/
  addAlias( name, mapsTo ) {
    this.images[name] = mapsTo
  }

  resizeImage( img, width, height ) {
    return new Promise( ( resolve, reject ) => {
      let dWidth = img.naturalWidth,
        dHeight = img.naturalHeight

      if ( width && height ) {
        dWidth = width
        dHeight = height
      } else if ( width ) {
        dWidth = width
        dHeight = img.naturalHeight * ( width / img.naturalWidth )
      } else if ( height ) {
        dHeight = height
        dWidth = img.naturalWidth * ( height / img.naturalHeight )
      }
      
      const canvas = document.createElement( 'canvas' );
      canvas.width = dWidth;
      canvas.height = dHeight;

      const ctx = canvas.getContext( '2d' );
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage( img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, dWidth, dHeight );

      const cImg = new Image();
      cImg.onload = () => {
        resolve( cImg );
      }
      cImg.onerror = () => {
        resolve( cImg )
      }
      cImg.src = canvas.toDataURL();
    } )
  }

  /**
   * Reorganizes this.images into [ [name0, image0], ..., [nameN, imageN]]
   * for mapbox
   **/
  getImagesArray() {
    return Object.keys( this.scaledImages ).map( ( name ) => {
      if ( this.scaledImages[name] == null ) {
        return null
      }

      return [ name, this.scaledImages[name] ]
    } ).filter( a => a != null )
  }

  onLoad = () => {
    this.left--;
    if ( this.left <= 0 ) {
      this.ready( this );
    }
  }

}
