import turfBbox from '@turf/bbox'

const zoomIn = ( mapName, opts ) => {
  window.dispatchEvent( 
    new CustomEvent( `${mapName}-zoomIn`, { detail : { opts } } )
  )
}

const resetNorth = ( mapName, opts ) => {
  window.dispatchEvent( 
    new CustomEvent( `${mapName}-resetNorth`, { detail : { opts } } )
  )
}

const zoomOut = ( mapName, opts ) => {
  window.dispatchEvent( 
    new CustomEvent( `${mapName}-zoomOut`, { detail : { opts } } )
  )
}

const flyTo = ( mapName, { lat, lon, zoom, bearing, duration } ) => {
  window.dispatchEvent( 
    new CustomEvent( 
      `${mapName}-flyTo`, 
      { detail : { zoom, lat, lon, bearing, duration } }
    )
  )
}

const jumpTo = ( mapName, { lat, lon, zoom, bearing } ) => {
  window.dispatchEvent( 
    new CustomEvent( 
      `${mapName}-jumpTo`,
      { detail : { zoom, lat, lon, bearing } }
    )
  )
}

function normalizeBounds( bounds ) {
  let b = bounds
  // [sw, ne]
  if ( Array.isArray( bounds ) ) {
    b = bounds
  } else if ( typeof bounds === 'object' ) {
    if ( b.west != null && b.south != null && b.east != null && b.north != null ) {
      b = [ [ b.west, b.south ], [ b.east, b.north ] ]
    } else if ( b.lat1 != null && b.lat2 != null && b.lon1 != null && b.lon2 != null ) {
      b = [ 
        [ Math.min( b.lon1, b.lon2 ), Math.min( b.lat1, b.lat2 ) ], 
        [ Math.max( b.lon1, b.lon2 ), Math.max( b.lat1, b.lat2 ) ] 
      ] 
    }
  }
  return b
}

const flyToBounds = ( mapName, bounds, options ) => {
  const b = normalizeBounds( bounds )
  window.dispatchEvent( 
    new CustomEvent( 
      `${mapName}-flyTo`, 
      { detail : { bounds : b, options } }
    )
  )
}

const jumpToBounds = ( mapName, bounds, options ) => {
  const b = normalizeBounds( bounds )
  window.dispatchEvent( 
    new CustomEvent( 
      `${mapName}-jumpTo`, 
      { detail : { bounds : b, options } }
    )
  )
}

const flyToGeoJson = ( mapName, geoJson, options = { } ) => {
  const bbox = turfBbox( geoJson )
  const opts = { padding : 40, ...options }

  flyToBounds( mapName, bbox, opts )
}

const jumpToGeoJson = ( mapName, geoJson, options = { } ) => {
  const bbox = turfBbox( geoJson )
  const opts = { padding : 40, ...options }
  jumpToBounds( mapName, bbox, opts )
}

const cancelDraw = ( mapName ) => {
  window.dispatchEvent( new CustomEvent( `${mapName}-cancelDraw` ) )
}


export default {
  zoomIn,
  zoomOut,
  resetNorth,
  flyTo,
  jumpTo,
  normalizeBounds,
  flyToBounds,
  jumpToBounds,
  flyToGeoJson,
  jumpToGeoJson,
  cancelDraw
}