import React from 'react'
import { connect } from 'react-redux'
import { Selection } from '@leverege/ui-redux'
import { RolloverCard } from '@leverege/ui-attributes'

class Card extends React.Component {

  constructor( props ) {
    super( props ) 
    this.state = { pointerPosition : null }
  }

  render( ) {
    const { rolloverModel, getContext, rollover } = this.props
    const { pointerPosition } = this.state
    if ( rolloverModel && !Selection.isEmpty( rollover ) ) {
      return (
        <RolloverCard
          model={rolloverModel} 
          pointerPosition={pointerPosition} 
          context={getContext( rollover.items )} />
      )
    }
    return null

  }
}

export default connect( ( state, props ) => {

  return {
    rolloverModel : props.getRolloverModel(),
    rollover : Selection.get( state, 'rollover' )
  }
}, null, null, { forwardRef : true } )( Card )
