// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.buttonHolder_RJqIR {
  display : flex;
  flex-direction: column;
  margin : var( --size-paddingMedium, 8px ) var( --size-paddingLarge, 16px );
}
`, "",{"version":3,"sources":["webpack://./src/action/MenuFactory.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,sBAAsB;EACtB,0EAA0E;AAC5E","sourcesContent":["\n.buttonHolder {\n  display : flex;\n  flex-direction: column;\n  margin : var( --size-paddingMedium, 8px ) var( --size-paddingLarge, 16px );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonHolder": `buttonHolder_RJqIR`
};
export default ___CSS_LOADER_EXPORT___;
