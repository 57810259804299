import React from 'react'
import { Feature, Layer, Popup } from 'react-mapbox-gl'

import Util from './Util'

const DISTANCE_THRESHOULD = 10 // Meters

export default class PolylineLayer {

  /**
   * Sets up a layer to render lines with optional points and markers.
   * The render methods should be supplied a map 
   * 
   * @pararm {string} dataType the name of the type of objects displayed
   * @param {string|function} latAccess the path or function to get the latitude
   * @param {string|function} lonAccess the path or function to get the longitude
   * @param {string|function} color the color or function( data ) to compute the color
   * @param {string} before the name of the layer that the line should be before,
   * @param {boolean} addPoints if false, only the line will be created. (no points)
   **/

  constructor({ dataType, latAccess, lonAccess,
    color, before, addPoints = true, addLabels = true,
    lineLayout, linePaint,
    circlePaint, rolloverCirclePaint, selectedCirclePaint,
    labelLayout, labelPaint, symbolLayout,
    pointProperties, labelProperties,
    isIdSelected, pointIdGenerator,
    distanceThreshold = 0 }) {
    this.dataType = dataType
    this.dataTypeSym = `${dataType}-sym`
    this.dataTypeRollover = `${dataType}-rollover`
    this.dataTypeSelected = `${dataType}-selected`
    this.dataTypeTargeted = `${dataType}-targeted`
    this.dataTypeLabels = `${dataType}-labels`
    this.dataTypeRolloverSym = `${dataType}-sym-rollover`
    this.dataTypeSelectedSym = `${dataType}-sym-selected`
    this.dataTypeTargetedSym = `${dataType}-sym-targeted`
    this.dataTypeLabels = `${dataType}-labels`
    this.before = before || 'symbol-lower-enhancements'
    this.addPoints = addPoints
    this.addLabels = addLabels
    this.pointIdGenerator = pointIdGenerator
    this.latAccess = Util.createAccess(latAccess, 'data/position/lat')
    this.lonAccess = Util.createAccess(lonAccess, 'data/position/lon')
    this.pointProperties = pointProperties
    this.labelProperties = labelProperties
    this.distanceThreshold = distanceThreshold
    this.lineLayout = lineLayout || PolylineLayer.createLineLayout()
    this.linePaint = linePaint || PolylineLayer.createLinePaint()
    this.circlePaint = circlePaint || PolylineLayer.createCirclePaint()
    this.rolloverCirclePaint = rolloverCirclePaint || PolylineLayer.createRolloverCirclePaint()
    this.selectedCirclePaint = selectedCirclePaint || PolylineLayer.createSelectedCirclePaint()
    this.labelLayout = labelLayout || PolylineLayer.createLabelLayout()
    this.labelPaint = labelPaint || PolylineLayer.createLabelPaint()
    this.symbolLayout = symbolLayout || PolylineLayer.createSymbolLayout()
    this.isIdSelected = isIdSelected || ((group, containsId) => {
      return Array.isArray(group) && group.includes(containsId)
    })
  }

  /**
   * Returns the position array [longitude, latitude] for the given data. By default
   * this will use the Path access objects to get the position
   * @param {object} data the data object
   **/
  getPosition = (data) => {
    return [this.lonAccess.get(data, null), this.latAccess.get(data, null)]
  }

  getId = (data, index, sourceId) => {
    if (this.pointIdGenerator) {
      return this.pointIdGenerator(data, index, sourceId)
    }
    return `${this.dataType}-${sourceId}-${index}`
  }

  getLineProperties(source) {
    if (source == null) {
      return null
    }
    if (typeof this.lineProperties === 'function') {
      return this.lineProperties(source)
    } else if (this.lineProperties != null) {
      return this.lineProperties
    }
    const { lineColor, lineWidth } = source
    return {
      lineColor: source.lineColor || source.color || null,
      lineWidth: source.lineWidth || null
    }
  }

  renderPoint = (point, index, sourceId, selected, rollover) => {
    const pos = this.getPosition(point)
    const id = this.getId(point, index, sourceId)
    const pProps = typeof this.pointProperties === 'function' && this.pointProperties(point, index, sourceId, selected, rollover)
    const properties = Object.assign({}, pProps, { dataType: this.dataType, dataId: id, sourceId, index })

    return <Feature key={id} type={properties.isSymbol ? 'symbol' : 'circle'} coordinates={pos} properties={properties} />
  }

  renderLabel = (point, index, sourceId) => {
    const pos = this.getPosition(point)
    const id = this.getId(point, index, sourceId)
    const properties = Object.assign({},
      this.labelProperties && this.labelProperties(point, index, sourceId),
      { dataType: this.dataType, dataId: id, sourceId })

    return <Feature key={id} type="symbol" coordinates={pos} properties={properties} />
  }

  /**
   * 
   * Returns an object { normal : [ <Layers> ], rollover : [ <Layers> ] }
   * @param {object} sourceMap this supplies information that can be used to render
   * the lines. The keys of this objects match the keys in the pointsMap. The default linePaint 
   * will look for lineColor in the object at this key to determine the color of the lines
   * specified by the objects in Array at pointsMap[key]
   **/
  getLayers(sourceMap, pointsMap, { rollover, selected, isSelectable = true, isRolloverable = true }) {
    const meta = { isSelectable, isRolloverable }

    // TODO: USE VALUE CACHE

    const lines = []
    const rPoints = []
    const sPoints = []
    const nPoints = []
    const rsPoints = []
    const ssPoints = []
    const nsPoints = []
    const labels = []


    Object.keys(pointsMap).forEach((sourceId) => {
      const source = sourceMap[sourceId]
      const points = pointsMap[sourceId]
      const linePts = []
      const lp = null
      points.forEach((p, index) => {

        // Add to line
        linePts.push(this.getPosition(p))

        if (this.addPoints) {
          // build points
          if (lp != null) {

          }
          const id = this.getId(p, index, sourceId)
          const isSel = this.isIdSelected(selected, id)
          const isRoll = this.isIdSelected(rollover, id)
          const pt = this.renderPoint(p, index, sourceId, isSel, isRoll)
          const isSym = pt.props.type === 'symbol'

          if (isSel) {
            (isSym ? ssPoints : sPoints).push(pt)
          } else if (isRoll) {
            (isSym ? rsPoints : rPoints).push(pt)
          } else {
            (isSym ? nsPoints : nPoints).push(pt)
          }
        }
        if (this.addLabels) {
          labels.push(this.renderLabel(p, index, sourceId))
        }
      })

      // Create line
      lines.push(
        <Feature key={sourceId} coordinates={linePts} properties={this.getLineProperties(source)} />
      )

    })

    return {
      normal: [
        <Layer id={this.dataTypeLabels} key={this.dataTypeLabels} type="symbol" paint={this.labelPaint} layout={this.labelLayout} before={this.before}>{labels}</Layer>,
        <Layer id={this.dataType} key={this.dataType} type="circle" metadata={meta} paint={this.circlePaint} before={this.dataTypeLabels}>{nPoints}</Layer>,
        <Layer id={this.dataTypeSym} key={this.dataTypeSym} type="symbol" metadata={meta} layout={this.symbolLayout} before={this.dataTypeLabels}>{nsPoints}</Layer>,
        <Layer
          key={`${this.dataType}-line`}
          type="line"
          before={this.dataType}
          layout={this.lineLayout}
          paint={this.linePaint}>
          {lines}
        </Layer>
      ],
      rollover: [
        <Layer id={this.dataTypeRollover} key={this.dataTypeRollover} type="circle" before={this.before} metadata={meta} paint={this.rolloverCirclePaint}>{rPoints}</Layer>,
        <Layer id={this.dataTypeRolloverSym} key={this.dataTypeRolloverSym} type="symbol" before={this.before} metadata={meta} layout={this.symbolLayout}>{rsPoints}</Layer>
      ],
      selected: [
        <Layer id={this.dataTypeSelected} key={this.dataTypeSelected} type="circle" before={this.before} metadata={meta} paint={this.selectedCirclePaint}>{sPoints}</Layer>,
        <Layer id={this.dataTypeSelectedSym} key={this.dataTypeSelectedSym} type="symbol" before={this.before} metadata={meta} layout={this.symbolLayout}>{sPoints}</Layer>
      ]
    }
  }


  static createLineLayout() {
    return {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  static createLinePaint({ color, width } = {}) {
    return {
      'line-color': {
        type: 'identity',
        property: 'lineColor',
        default: color || 'rgba( 0, 0, 0, 1 )'
      },
      'line-width': {
        type: 'identity',
        property: 'lineWidth',
        default: width || 1.5
      }
    }
  }

  static createCirclePaint(opts = {}) {
    const nOpts = Object.assign({ color: '#9999FF', radius: 5 }, opts)
    return PolylineLayer.createTemplateCirclePaint('n', nOpts)
  }

  static createRolloverCirclePaint(opts = {}) {
    const rOpts = Object.assign({ color: '#99FF99', radius: 10 }, opts)
    return PolylineLayer.createTemplateCirclePaint('r', rOpts)
  }

  static createSelectedCirclePaint(opts = {}) {
    const sOpts = Object.assign({ color: '#99FF99', radius: 10, strokeWidth: 1 }, opts)
    return PolylineLayer.createTemplateCirclePaint('s', sOpts)
  }

  static createTemplateCirclePaint(prefix, { radius, color, strokeWidth, strokeColor, opacity, strokeOpacity } = {}) {
    return {
      'circle-radius': {
        property: `${prefix}Radius`,
        type: 'identity',
        default: radius == null ? 10 : radius
      },
      'circle-color': {
        property: `${prefix}Color`,
        type: 'identity',
        default: color || '#9999FF'
      },
      'circle-opacity': {
        property: `${prefix}Opacity`,
        type: 'identity',
        default: opacity == null ? 1 : opacity
      },
      'circle-stroke-width': {
        property: `${prefix}StrokeWidth`,
        type: 'identity',
        default: strokeWidth || 0
      },
      'circle-stroke-color': {
        property: `${prefix}StrokeColor`,
        type: 'identity',
        default: strokeColor || '#000000'
      },
      'circle-stroke-opacity': {
        property: `${prefix}StrokeOpacity`,
        type: 'identity',
        default: strokeOpacity == null ? 1 : strokeOpacity
      },
      'circle-blur': {
        property: `${prefix}Blur`,
        type: 'identity',
        default: 0
      }
    }
  }

  static createLabelPaint({ color, haloColor, haloWidth } = {}) {
    return {
      'text-color': color || 'white',
      'text-halo-color': haloColor || 'black',
      'text-halo-width': haloWidth == null ? 1.5 : haloWidth
    }
  }
  static createLabelLayout({ textField, size, spacing, font, offset } = {}) {
    return {
      'text-field': textField || '{label}',
      'text-font': [font || 'Open Sans Bold'],
      'text-size': size == null ? 12 : size,
      'text-transform': 'uppercase',
      'text-letter-spacing': spacing == null ? 0.05 : spacing,
      'text-offset': offset == null ? [0, -1] : offset
    }
  }

  static createSymbolLayout() {
    return {
      'icon-image': '{symbol}',
      'icon-allow-overlap': true,
      'text-field': '{name}',
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-optional': true,
      'text-offset': [0, 2],
      'text-size': 12,
      'text-letter-spacing': 0.04,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-transform': 'uppercase'
    }
  }
}
