/* eslint-disable max-len */
import { LogicCondition, PropertyCondition, ObjectizerModel, ExpressionCondition } from '@leverege/condition'

import LogicConditionEditor from './logic/LogicConditionEditor'

import PropertyConditionEditor from './property/PropertyConditionEditor'

import ExpressionConditionEditor from './expression/ExpressionConditionEditor'

import ObjectizerEditor from './objectizer/ObjectizerEditor'
import ReplaceConditionAction from './objectizer/actions/ReplaceConditionAction'
import ReplaceLogicConditionAction from './logic/actions/ReplaceConditionAction'
import RemoveRuleAction from './objectizer/actions/RemoveRuleAction'
import DuplicateRuleAction from './objectizer/actions/DuplicateRuleAction'
import RemoveConditionAction from './logic/actions/RemoveConditionAction'
import DuplicateConditionAction from './logic/actions/DuplicateConditionAction'
import DragGrabberAction from './logic/actions/DragGrabberAction'
import NestConditionAction from './logic/actions/NestConditionAction'

export default {
  install( plg ) {
    plg.addPlugin( 'ModelEditor', { type : LogicCondition.TYPE, editor : LogicConditionEditor } )
    plg.addPlugin( 'ModelEditor', { type : PropertyCondition.TYPE, editor : PropertyConditionEditor } )
    plg.addPlugin( 'ModelEditor', { type : ObjectizerModel.TYPE, editor : ObjectizerEditor } )
    plg.addPlugin( 'ModelEditor', { type : ExpressionCondition.TYPE, editor : ExpressionConditionEditor } )

    plg.addPlugin( 'Action', ReplaceConditionAction )
    plg.addPlugin( 'Action', RemoveRuleAction )
    plg.addPlugin( 'Action', DuplicateRuleAction )

    plg.addPlugin( 'Action', RemoveConditionAction )
    plg.addPlugin( 'Action', DuplicateConditionAction )
    plg.addPlugin( 'Action', NestConditionAction )
    plg.addPlugin( 'Action', ReplaceLogicConditionAction )
    plg.addPlugin( 'Action', DragGrabberAction )
  }
}
