import React, { useMemo } from 'react'
import I18N from '@leverege/i18n'
import { useValueChange } from '@leverege/ui-hooks'
import { R } from '@leverege/value-resolver'
import { Flex, TextInput, NumericInput, Selector, Popup, Label } from '@leverege/ui-elements'
import { PropertyCondition as Model } from '@leverege/condition'
import { useSharedContext } from '@leverege/ui-plugin'

const { t, tIcon } = I18N.ns( 'condition.PropertyConditionEditor' )
const dComparators = Model.COMPARATORS

function validComparators( opts, path ) {

  if ( opts == null ) {
    return dComparators
  } 
  const ops = ( opts.path && opts.path[path] ) || opts.comparators
  return !Array.isArray( ops ) ? dComparators : dComparators.filter( ( c ) => { return ops.includes( c.value ) } )
}

function isValid( cmps, value ) {
  for ( let n = 0; n < cmps.length; n++ ) {
    if ( cmps[n].value === value ) {
      return true
    }
  }
  return false
}

export default function PropertyConditionEditor( props ) {

  const { value } = props
  const { conditionOptions } = useSharedContext()
  const onChange = useValueChange( Model, props ) 
  const isNumber = Model.isNumberComparator( value )
  const isValue2Used = Model.isValue2Used( value )
  const path = Model.getPath( value )
  const contextPath = Model.getContextPath( value )
  const opts = conditionOptions?.propertyCondition
  const comparators = useMemo( ( ) => { return validComparators( opts, path ) }, [ path, opts ] )
  const label = R( opts?.label?.[contextPath], value )
  const onComparatorChange = ( e ) => {
    const isNewNumber = Model.isNumberComparator( e.value )
    if ( isNewNumber && isNewNumber !== isNumber ) {
      const newV1 = toNumber( value.value1 )
      // Change the value1 to a number if we switch to a number 
      // comparator. Do this in bulk in order to make it work
      onChange( { 
        data : { 
          setValue1 : 'value1', 
          [e.data] : 'comparator' 
        }, 
        value : {
          value1 : newV1,
          comparator : e.value
        } } )
    } else {
      onChange( e )
    }
  }

  const onPathChange = ( e ) => {
    const cmps = validComparators( opts, e.value )
    if ( !isValid( cmps, value.comparator ) ) {
      // Do this in bulk in order to make it work
      onChange( { 
        data : { 
          [e.data] : 'path', 
          setComparator : 'comparator' 
        }, 
        value : {
          path : e.value,
          comparator : cmps[0]?.value || null
        } } )
    } else {
      onChange( e )
    }
  }
  
  const view = (
    <Flex variant="rowS" align="center">
      { label && <Label variant="conditionPropertyCondition|hint">{label}</Label> }
      <TextInput 
        eventData="setPath"
        value={path}
        onChange={onPathChange}
        hint={t( 'selectorHint' )}
        title={t( 'title' )}/>
      <Selector 
        variant="conditionPropertyCondition|inline"
        toggleVariant="conditionPropertyCondition|inline"
        eventData="setComparator"
        targetAttachment="bottom left"
        attachment="top left"
        title={renderer}
        value={Model.getComparator( value )} 
        values={comparators} 
        onChange={onComparatorChange}/>
      { !isNumber && (
        <TextInput 
          eventData="setValue1"
          value={toString( Model.getValue1( value ) )}
          onChange={onChange}
          hint={t( 'textHint' )}/>
      ) }

      { isNumber && (
        <NumericInput 
          eventData="setValue1"
          value={Model.getValue1( value )}
          onChange={onChange}
          float/>
      ) }

      { isNumber && isValue2Used && (
        <NumericInput 
          eventData="setValue2"
          value={Model.getValue2( value )}
          onChange={onChange}
          float />
      ) }
      
      <Popup icon={tIcon( 'popupIcon', 'fa fa-caret-down fa-fw' )}>
        <Popup.Item 
          eventData="setCoerceValue"
          checkStyle="check"
          onClick={onChange}
          value={!Model.getCoerceValue( value )}
          checked={Model.getCoerceValue( value )}>
          {t( 'item' )}
        </Popup.Item>
      </Popup>
      
    </Flex>
  )
  // if ( label ) {
  //   return (
  //     <div>
  //       <Label variant='conditionPropertyCondition|hint'>{label}</Label>
  //       {view}
  //     </div>
  //   )
  // }
  return view
}

function renderer( val, name, checked ) {
  return val.shortName || val.name 
}

function toNumber( str ) {
  if ( typeof str === 'number' ) {
    return str
  }
  if ( typeof str === 'string' ) {
    const n = Number( str )
    return Number.isNaN( n ) ? null : n
  }
  return null
}

function toString( v ) {
  if ( v == null ) {
    return null
  }
  if ( typeof v === 'number' ) {
    return `${v}`
  }
  return v
}
