import React from 'react'
import classnames from 'classnames'
import { Dialog, Button, Text, Flex, Content, Pane } from '@leverege/ui-elements'

import S from './OkayCancelEditor.css'

/**
 * This will act as an ok/cancel/delete container for a model editor. The
 * editor should take the model in its 'value' parameter and should take
 * an onChange prop that is fired when the value changes. This class will
 * manage the state of the value, and return it the model editor in managed
 * capacity. When the 'ok' button is click, it will fire the change out via
 * the onChange( { value, oldValue, data } ) method
 */
export default class OkayCancelEditor extends React.Component {

  static defaultProps = {
    deleteMessage : 'This will remove this item.', 
    deleteTitle : 'Delete?', 
    deleteButton : 'Delete',
    variants : {
      pane : 'rootEditor|form',
      header : 'rootEditorHeader|formHeader',
      body : 'rootEditorContent|formBody',
      title : 'rootEditorTitle|formTitle',
      footer : 'rootEditorFooter|formFooter'
    }
  }

  constructor( props ) {
    super( props )
    this.state = { value : props.value, original : props.value, showDelete : false }
    this.delayTimer = null
  }

  static getDerivedStateFromProps( props, state ) {
    if ( props.value ) {
      if ( state.original == null || state.original !== props.value ) {
        return { value : props.value, original : props.value }
      }
    } else {
      return { value : null, original : null }
    }
    return null
  }

  componentDidMount( ) {
    const { history, applyOnChange } = this.props
    const { original, value } = this.state

    if ( history && applyOnChange !== true ) {
      this.unblock = history.block( ( ) => {
        if ( original && original !== value ) {
          return 'Your changes will be lost. Are you sure your want to continue?'
        }
        // eslint-disable-next-line
        return 
      } )
    }
    window.addEventListener( 'beforeunload', this.onWindowUnload )
  }

  componentWillUnmount() {
    window.removeEventListener( 'beforeunload', this.onWindowUnload )

    if ( this.unblock ) {
      this.unblock()
    }
  }

  onWindowUnload = ( e ) => {
    const { original, value } = this.state
    if ( original && original !== value ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  onChange = ( evt ) => { 
    const { onChange, applyOnChange, delay } = this.props

    this.setState( { value : evt.value } )

    if ( applyOnChange ) {
      if ( delay ) {
        clearTimeout( this.delayTimer )

        this.delayTimer = setTimeout( () => {
          const { original : oldValue, eventData : data } = this.state
          onChange( { value : evt.value, oldValue, data } )
        }, delay )
      } else {
        const { original : oldValue, eventData : data } = this.state
        onChange( { value : evt.value, oldValue, data } )
      }
    }
  }
  
  onOkay = ( evt ) => { 
    const { eventData : data, onChange } = this.props
    const { original : oldValue, value } = this.state
    onChange( { value, oldValue, data } )
  }

  onDelete = ( item ) => {
    this.setState( { showDelete : true } )
  }

  onDoDelete = ( event ) => {
    const { value, onDelete } = this.props
    this.setState( { showDelete : false } )
    if ( this.unblock ) {
      this.unblock() // remove blocks from switch urls
    }
    onDelete( value )
  }

  onCancelDelete = ( event ) => {
    this.setState( { showDelete : false } )
  }

  onCancel = ( event ) => {
    const { eventData : data, onCancel, value } = this.props
    if ( onCancel ) {
      onCancel( { data } )
    }
    this.setState( { value } )
  }

  render() {
    const { 
      title, deleteMessage, deleteTitle, deleteButton, applyOnChange, validator,
      actionState = null, children, cancelLabel = 'Cancel', okayLabel = 'Okay', deleteLabel = 'Delete',
      className, editor : Editor, variants : v, noModelView = null, 
      onDelete, onCancel, editorProps = { }, eventData, onChange, alwaysEnableSave = false, ...rest } = this.props
    const { value, showDelete, original } = this.state
    const disabled = (
      alwaysEnableSave !== true &&
      (
        value === original || 
        actionState === 'saving' || 
        actionState === 'deleting' || 
        ( validator != null && !validator( value, original ) )
      )
    )
    const cancelDisabled = onCancel == null && disabled

    if ( value == null ) {
      return (
        <Pane variant={v.pane} className={classnames( className, S.form )}>
          {noModelView}
        </Pane>
      )
    }
    
    return (
      <Pane {...rest} variant={v.pane} className={classnames( className, S.form )}>
        <Content relative className={S.content}>
          <Content.Header variant={v.header} className={S.header}>
            <Flex variant="row" justify="space-between" align="center">
              {resolveTitle( title, value, original, v.title, this )}
              
            </Flex>
          </Content.Header>
          <Content.Area variant={v.body} className={S.area}>
            <Editor {...editorProps} value={value} onChange={this.onChange}/>
            {React.Children.count( children ) > 0 ? <div className={S.spacer}/> : null }
            {children}
          </Content.Area>
          <Content.Footer variant={v.footer} className={S.footer}>
            { onDelete == null ? <div/> : (
              <Button className={S.delete} variant="linkDestructive" onClick={this.onDelete}>{deleteLabel}</Button>
            ) }
            { applyOnChange !== true && (
              <div className={S.okayCancel}>
                <Button variant="secondary" onClick={this.onCancel} disabled={cancelDisabled}>{cancelLabel}</Button>
                <Button variant="primary" onClick={this.onOkay} disabled={disabled}>
                  {actionState === 'saving' ? <i className="fa fa-spin fa-spinner"/> : okayLabel }
                </Button>
              </div> 
            ) }
            { applyOnChange === true && ( actionState === 'saving' || actionState === 'deleting' ) && (
              <div className={S.okayCancel}>
                <i className="fa fa-spin fa-spinner"/>
              </div> 
            ) }
            <Dialog.Question
              show={showDelete}
              title={resolve( deleteTitle, value, original )}
              message={resolve( deleteMessage, value, original )}
              okay={resolve( deleteButton, value, original )}
              okayVariant="primaryDestructive"
              onCancel={this.onCancelDelete}
              onOkay={this.onDoDelete} />

          </Content.Footer>
        </Content>
      </Pane>
    )
  }
}

function resolveTitle( funcOrString, value, original, variant, editor ) {
  const r = resolve( funcOrString, value, original, variant, editor )
  if ( typeof r === 'string' ) {
    const icon = editor.props.icon
    return <Text variant={variant} icon={icon}>{r}</Text>
  }
  return r
}

function resolve( funcOrString, value, original, variant, editor ) {
  if ( typeof funcOrString === 'function' ) {
    return funcOrString( value, original, variant, editor )
  }
  return funcOrString
}
