import BlueprintTranslationEditor from './views/BlueprintTranslationEditor.jsx'
import I18NBlueprintModel from './models/I18NBlueprintModel.js'
import I18NBlueprintLanguageModel from './models/I18NBlueprintLanguageModel.js'
import I18NSystemLanguageModel from './models/I18NSystemLanguageModel.js'
import I18NNamespaceModel from './models/I18NNamespaceModel.js'
import I18NAttributeModel from './models/I18NAttributeModel.js'
import I18NSystemModel from './models/I18NSystemModel.js'
import BlueprintLanguageEditor from './views/BlueprintLanguageEditor.jsx'
import SystemLanguageEditor from './views/SystemLanguageEditor.jsx'
import NamespaceEditor from './views/NamespaceEditor.jsx'
import AttributeTranslationsEditor from './views/AttributeTranslationsEditor.jsx'
import SystemTranslationEditor from './views/SystemTranslationEditor.jsx'
import I18NSystemNamespaceEditor from './views/I18NSystemNamespaceEditor.jsx'

export default {
  install( plg ) {
    plg.addPlugin( 'Model', { type : I18NBlueprintModel.TYPE, model : I18NBlueprintModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NBlueprintModel.TYPE, editor : BlueprintTranslationEditor } )

    plg.addPlugin( 'Model', { type : I18NBlueprintLanguageModel.TYPE, model : I18NBlueprintLanguageModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NBlueprintLanguageModel.TYPE, editor : BlueprintLanguageEditor } )

    plg.addPlugin( 'Model', { type : I18NNamespaceModel.TYPE, model : I18NNamespaceModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NNamespaceModel.TYPE, editor : NamespaceEditor } )

    plg.addPlugin( 'Model', { type : I18NAttributeModel.TYPE, model : I18NAttributeModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NAttributeModel.TYPE, editor : AttributeTranslationsEditor } )

    plg.addPlugin( 'Model', { type : I18NSystemModel.TYPE, model : I18NSystemModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NSystemModel.TYPE, editor : SystemTranslationEditor } )

    plg.addPlugin( 'Model', { type : I18NSystemLanguageModel.TYPE, model : I18NSystemLanguageModel } )
    plg.addPlugin( 'ModelEditor', { type : I18NSystemLanguageModel.TYPE, editor : SystemLanguageEditor } )

    plg.addPlugin( 'ModelEditor', { type : 'imagine.I18NSystemNamespaceModel', editor : I18NSystemNamespaceEditor } )
  }
}
