import React from 'react';

export default class ActionPreparer extends React.Component {
  onChange = ( ...args ) => {
    if ( this.update !== false ) {
      this.forceUpdate()
    }
  }

  componentWillUnmount() {
    this.update = false
  }

  render() {
    const { layout, context, children } = this.props
    layout.prepare( context, this.onChange )
    if ( children == null ) {
      return null
    }
    if ( typeof children !== 'function' ) {
      console.warn( 'invalid use of ActionPreparer, expecting single child of type function' )
      return React.Children.map( children, child => React.cloneElement( child ) ) || null
    }
    return children()
  }
}
