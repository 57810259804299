import Path from '@leverege/path' 
import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util' 

const { isPathValid, isPathCollection } = Util

const TYPE = 'number';
export default {
  
  name() {
    return 'Number'
  },

  typeName() {
    return TYPE
  },

  categoryType() {
    return 'float'
  },

  create( path, name ) { 
    if ( !isPathValid( path ) ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }

    return { 
      type : this.typeName(), 
      path : path || `${this.typeName()}Value`,
      isCollection : isPathCollection( path ),
      name : name || path, 
      desc : null,
      tags : [],
      source : 'device',
      controllable : false,
      min : null,
      max : null,
      step : null,
      threshold : {
        type : 'delay',
        lower : null,
        lowerDelay : null,
        upper : null,
        upperDelay : null
      },
      sim : {
        initialValue : null,
        updater : null
      }
    }
  },

  createFrom( attr ) {
    if ( attr.type === this.typeName() ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );
    convert.controllable = attr.controllable;
    convert.source = attr.source;

    Path.setIf( convert, attr, 'controllable', 'boolean' );

    Path.setIf( convert, attr, 'min', 'number' );
    Path.setIf( convert, attr, 'max', 'number' );
    Path.setIf( convert, attr, 'step', 'number' );
    if ( attr.threshold && attr.threshold.type === 'delay' ) {
      Path.setIf( convert, attr, 'threshold/lower', 'number' );
      Path.setIf( convert, attr, 'threshold/lowerDelay', 'number' );
      Path.setIf( convert, attr, 'threshold/lower', 'number' );
      Path.setIf( convert, attr, 'threshold/lowerDelay', 'number' );
      Path.setIf( convert, attr, 'threshold/upper', 'number' );
      Path.setIf( convert, attr, 'threshold/upperDelay', 'number' );
    }
    Path.setIf( convert, attr, 'sim/initialValue', 'number' );

    return convert;
  } 
}
