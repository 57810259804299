import Util from './Util'

// const { Select } = require( '@leverege/ui-redux' )

/**
 * Returns a Symbolizer that will return { symbol, layer } where
 * symbol is the name or <name>-<targeted | selected | rollover>
 * if the data item is targeted, selected or rolled over
 **/
function create( name, isSelected, idPath ) {
  const idAcccess = Util.createAccess( idPath, 'id' )

  return ( data, { selected, targeted, rollover } ) => {
    const id = idAcccess.get( data )

    const r = isSelected( rollover, id )
    const s = isSelected( selected, id )
    const t = isSelected( targeted, id )
    let symbol = name
    if ( s ) {
      symbol = `${name}${t ? '-targeted' : ''}-selected${r ? '-rollover' : ''}`
    } else {
      symbol = `${name}${t ? '-targeted' : ''}${r ? '-rollover' : ''}`
    }
    const layer = r ? 'rollover' : s ? 'selected' : t ? 'targeted' : 'normal'
    return { symbol, layer }
  }
}

export default {
  create
}
