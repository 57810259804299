import React from 'react'
import turfCenter from '@turf/center'
import deepEquals from 'fast-deep-equal'
import { Source, Layer, Feature } from 'react-mapbox-gl'
import { ValuesCache } from '@leverege/value-cache'

const createLayerGroups = ( ) => {
  return { fillFeatures : { default : { default : [ ] } }, labelFeatures : { }, outlineFeatures : { }, symbolFeatures : { }, lineFeatures : { } }
}

/**
 * Makes sure that obj[style][layerGroup] is an array
 */
const ensure = ( obj, style, layerGroup ) => {
  if ( obj[style] == null ) {
    obj[style] = { [layerGroup] : [] }
  } else if ( obj[style][layerGroup] == null ) {
    obj[style][layerGroup] = []
  }
}


/**
 * Used to create either a set of mapbox Layers or FeatureCollections for specific ids.
 * This uses a GeoshapeAdapter to acquire the shape data and appearance properties. By
 * default, the results are cached using a deep equals equality check. The cache can
 * be disabled by setting the cacheEnabled to false, or the equality check can be changed
 * by supplying a cacheEquals function
 * 
 * Usage:
 * this.adapter = new ZoneAdapter( zOpts )
 * this.creator = new LayerCreator( { id : 'zone', dataType : 'zone', adapter : this.adapter } )
 * const layers = this.creator.getLayers( zoneData, zoneOptions )
 * <Map layer={layer}/>
 */
export default class LayerCreator {

  /**
   * Create a LayerCreator
   * @param {Object} options 
   * @param {string} options.id the id used to name the layers ( <id>-fill, <id>-outline-<type>, <id>-label )
   * @param {string} options.dataType the type used to identify the shapes. Default is 'geoshapes', should be supplied
   * @param {GeoshapeAdapter} options.adapter the adapter is used to extract geoshapes and appearance 
   * data from the data item
   * @param {boolean} [options.cacheEnabled] if false, caching will be disable
   * @param {function} [options.cacheEquals] a function( a, b, index ) that returns true if a and b
   * are deemed equal. Used by the caching algorithm. Index will be zero for the shape data, and one
   * for the options. By default, this is set to a deep equals. Setting this to null will cause the '==='
   * equality to be used.
   */
  constructor( options ) {
    this.dataType = options.dataType || 'geoshapes'
    this.dataRef = options.dataRef
    const id = options.id || 'gs'
    this.fKey = `${id}-fill`
    this.oKey = `${id}-outline`
    this.lKey = `${id}-label`
    this.sKey = `${id}-sym`
    this.lnKey = `${id}-line`
    this.addToNormal = options.addToNormal == null ? true : options.addToNormal
    this.adapter = options.adapter
    const equals = options.cacheEquals === undefined ? deepEquals : options.cacheEquals
    this.cache = options.cacheEnabled === false ? null : new ValuesCache( { equals } )
  }

  createId( base, style, layerGroup ) {
    if ( style === 'default' ) {
      return layerGroup === 'default' ? base : `${base}-${layerGroup}`
    }
    return layerGroup === 'default' ? `${base}-${style}` : `${base}-${layerGroup}-${style}`
  }

  /**
   * This will return an array of Layers for fill, outline and labels based on the shapes
   * and appearance parameters returned from the adapter
   * @param {object} shapes the data object containing the objects to create layers for. Will
   * be given back to the adapter
   * @param {object} options an object supplied to the build. Used for caching and given to the
   * iterator, paint/layout methods.
   */
  getLayers( shapes, options ) {

    if ( this.cache && this.cache.isSame( 'layers', shapes, options ) ) {
      // console.log('The cache says that they are the same', geoshapes )
      return this.cache.value( 'layers' )
    }

    const layerGroups = createLayerGroups()
    this.adapter.iterate( shapes, options, ( shape, id ) => {
      const sData = this.adapter.getShapeData( shape, id, options )
      this.addShapeDataToLayers( sData, id, layerGroups )
    } )
    const oArr = this.layerGroupsToFeatureLayers( layerGroups, options )
    return this.cache ? this.cache.set( 'layers', oArr, shapes, options ) : oArr
  }

  /**
   * This will return an array of Layers for fill, outline and labels based on the shapes
   * and appearance parameters returned from the adapter
   * @param {object} shapes the data object containing the objects to create layers for. Will
   * be given back to the adapter
   * @param {object} options an object supplied to the build. Used for caching and given to the
   * iterator, paint/layout methods.
   */
  getLayersFor( shapes, ids, options ) {

    if ( this.cache && this.cache.isSame( 'layersFor', shapes, ids, options ) ) {
      // console.log('The cache says that they are the same', geoshapes )
      return this.cache.value( 'layersFor' )
    }

    const layerGroups = createLayerGroups()
    this.adapter.iterateOnIds( shapes, ids, options, ( shape, id ) => {
      const sData = this.adapter.getShapeData( shape, id, options )
      this.addShapeDataToLayers( sData, id, layerGroups )
    } )

    const oArr = this.layerGroupsToFeatureLayers( layerGroups, options )
    return this.cache ? this.cache.set( 'layersFor', oArr, shapes, ids, options ) : oArr
  }

  /**
   * 
   * @param {ShapeData} shapeData the shape data to add to the feature arrays
   * @param {string} id the id of the shape item
   * @param {object} layerGroups the various groups to add the 
   */
  addShapeDataToLayers( shapeData, id, { fillFeatures, outlineFeatures, labelFeatures, symbolFeatures, lineFeatures } ) {

    if ( shapeData == null ) {
      return
    }
    const sData = Array.isArray( shapeData ) ? shapeData : [ shapeData ]
    for ( let n = 0; n < sData.length; n++ ) {
      const { dataType, dataRef, appearance : app, geoJson, labelCenter } = sData[n]

      if ( app.visible === false ) {
        continue // hide shape
      }
      const layerGroup = app.layerGroup || 'default'
      const { showFill = true, fillStyle = 'default', 
              showOutline = true, outlineStyle = 'default', 
              showLabel = true, labelStyle = 'default',
              showSymbol = false, symbolStyle = 'default',
              showLine = false, lineStyle = 'default'} = app
      const geo = geoJson && geoJson.geometry

      if ( geo == null || geo.coordinates == null ) {
        continue // no coordinates to draw
      }
      
      const c = geo.coordinates
      const p = { 
        dataType : dataType || this.dataType, 
        dataRef : dataRef || this.dataRef,
        dataId : id, 
        ...app
      }
      if ( showFill && geo.type === 'Polygon' ) {
        ensure( fillFeatures, fillStyle, layerGroup )
        const fill = ( <Feature key={id} id={id} coordinates={c} properties={p} /> )
        fillFeatures[fillStyle][layerGroup].push( fill )
        if ( this.addToNormal && layerGroup !== 'default' ) {
          ensure( fillFeatures, fillStyle, 'default' )
          fillFeatures[fillStyle]['default'].push( fill ) // eslint-disable-line
        }
      }
      
      if ( showOutline && ( geo.type === 'Polygon' || geo.type === 'LineString' ) ) {
        ensure( outlineFeatures, outlineStyle, layerGroup )
        // ToDo: Need to loop over all polygon's arrays
        const pts = geo.type === 'LineString' ? c : c[0]  
        const outlines = ( <Feature key={`outline-${id}`} coordinates={pts} properties={p} /> )
        outlineFeatures[outlineStyle][layerGroup].push( outlines )
        if ( this.addToNormal && geo.type === 'LineString' && layerGroup !== 'default' ) {
          ensure( outlineFeatures, outlineStyle, 'default' )
          outlineFeatures[outlineStyle]['default'].push( fill ) // eslint-disable-line
        }
      }
      
      if ( showLabel ) {
        ensure( labelFeatures, labelStyle, layerGroup )
        const cent = labelCenter || turfCenter( geoJson )
        const pt = cent.geometry ? cent.geometry.coordinates : Array.isArray( cent ) ? cent : [ cent.lat, cent.lon ]
        const label = ( <Feature key={`label-${id}`} id={`label-${id}`} coordinates={pt} properties={p} /> )
        labelFeatures[labelStyle][layerGroup].push( label )
      }

      if ( showSymbol ) {
        ensure( symbolFeatures, symbolStyle, layerGroup )
        const symbol = ( <Feature key={`symbol-${id}`} id={`symbol-${id}`} coordinates={c} properties={p} /> )
        symbolFeatures[symbolStyle][layerGroup].push( symbol )
        if ( this.addToNormal && layerGroup !== 'default' ) {
          ensure( symbolFeatures, symbolStyle, 'default' )
          symbolFeatures[symbolStyle]['default'].push( symbol ) // eslint-disable-line
        }
      }

      if ( showLine && geo.type === 'LineString' ) {
        ensure( lineFeatures, lineStyle, layerGroup )
        
        let line
        if (
          layerGroup === 'rollover' ||
          layerGroup === 'selected' ||
          layerGroup === 'targeted'
        ) {
          line = {
            "type": "Feature",
            "id": `line-${id}`,
            "properties": p,
            "geometry": {
             "type": "LineString",
             "coordinates": c
            }
           }
        } else {
          line = <Feature key={`line-${id}`} coordinates={c} properties={p} /> 
        }
        lineFeatures[lineStyle][layerGroup].push( line )
      }
    }
  }

  /**
   * Takes the layerGroup and creates a list of Layer objects
   * @param {object} layerGroups the features to create Layers for 
   * @param {object} options the options
   */
  layerGroupsToFeatureLayers( { fillFeatures, outlineFeatures, labelFeatures, symbolFeatures, lineFeatures }, options ) {
    let oArr = []
    let sourceFeatures = [];

    Object.keys( fillFeatures ).forEach( ( style ) => {
      Object.keys( fillFeatures[style] ).forEach( ( layerGroup ) => { 
        const id = this.createId( this.fKey, style, layerGroup )
        oArr.push(
          <Layer 
            type="fill"
            id={id}
            key={id}
            metadata={{ isRolloverable : true, isSelectable : true }} // This looks wrong 
            before={this.adapter.getBefore( style, layerGroup, options )}
            paint={this.adapter.getFillPaint( style, layerGroup, options )}>
            {fillFeatures[style][layerGroup]}
          </Layer> 
        ) 
      } )
    } )

    Object.keys( outlineFeatures ).forEach( ( style ) => {
      Object.keys( outlineFeatures[style] ).forEach( ( layerGroup ) => { 
        const id = this.createId( this.oKey, style, layerGroup )
        oArr.push(
          <Layer
            type="line"
            id={id}
            key={id}
            before={this.adapter.getBefore( style, layerGroup, options )}
            paint={this.adapter.getLinePaint( style, layerGroup, options )}
            layout={this.adapter.getLineLayout( style, layerGroup, options )}>
            {outlineFeatures[style][layerGroup]}
          </Layer>
        )
      } )
    } )

    Object.keys( labelFeatures ).forEach( ( style ) => {
      Object.keys( labelFeatures[style] ).forEach( ( layerGroup ) => { 
        const id = this.createId( this.lKey, style, layerGroup )
        oArr.push(
          <Layer 
            type="symbol"
            id={id}
            key={id}
            before={this.adapter.getBefore( style, layerGroup, options )}
            paint={this.adapter.getLabelPaint( style, layerGroup, options )}
            layout={this.adapter.getLabelLayout( style, layerGroup, options )}>
            {labelFeatures[style][layerGroup]}
          </Layer> 
        )
      } )
    } )

    Object.keys( symbolFeatures ).forEach( ( style ) => {
      Object.keys( symbolFeatures[style] ).forEach( ( layerGroup ) => { 
        const id = this.createId( this.sKey, style, layerGroup )
        oArr.push(
          <Layer 
            type="symbol"
            id={id}
            key={id}
            before={this.adapter.getBefore( style, layerGroup, options )}
            paint={this.adapter.getLabelPaint( style, layerGroup, options )}
            layout={this.adapter.getSymbolLayout( style, layerGroup, options )}>
            {symbolFeatures[style][layerGroup]}
          </Layer> 
        )
      } )
    } )

    Object.keys( lineFeatures ).forEach( ( style ) => {
      Object.keys( lineFeatures[style] ).forEach( ( layerGroup ) => { 
        const id = this.createId( this.lnKey, style, layerGroup )
        
        if (
          layerGroup === 'selected' ||
          layerGroup === 'rollover' ||
          layerGroup === 'targeted'
        ) {
          oArr.push(
            <Layer
              type="line"
              id={`line-${id}`}
              key={`line-${id}`}
              sourceId="source"
              before={this.adapter.getBefore( style, layerGroup, options )}
              paint={this.adapter.getLinePaint( style, layerGroup, options )}
              layout={this.adapter.getLineLayout( style, layerGroup, options )} />
          )
          oArr.push(
            <Layer
              type="symbol"
              id={`symbol-${id}`}
              key={`symbol-${id}`}
              sourceId="source"
              layout={this.adapter.getLineSymbolLayout( style, layerGroup, options )}
              paint={this.adapter.getLineSymbolPaint( style, layerGroup, options )} />
          )

          sourceFeatures.push( ...lineFeatures[style][layerGroup] )
        } else {
          oArr.push(
            <Layer
              type="line"
              id={`line-${id}`}
              key={`line-${id}`}
              before={this.adapter.getBefore( style, layerGroup, options )}
              paint={this.adapter.getLinePaint( style, layerGroup, options )}
              layout={this.adapter.getLineLayout( style, layerGroup, options )}>
              {lineFeatures[style][layerGroup]}
            </Layer>
          )
        }

      } )
    } )


    if ( sourceFeatures.length ) {

      const geoJson = {
        type : 'geojson',
        data : {
          "type": "FeatureCollection",
          "features": sourceFeatures
        }
       }

       oArr = [ <Source id="source" geoJsonSource={geoJson} />, ...oArr ]
    }

    return oArr
  }

  /**
   * Returns a FeatureCollection for all shapes in the data. This calls the adapter's iterate()
   * and will put the adapter's getShapeData() appearance object into properties
   * @param {array} array 
   */
  getFeatureCollection( shapes, options ) {
    if ( this.cache && this.cache.isSame( 'fc', shapes, options ) ) {
      return this.cache.value( 'fc' )
    }
    const fc = { type : 'FeatureCollection', features : [ ] }
    this.adapter.iterate( shapes, options, ( shape, id ) => {
      const sData = this.adapter.getShapeData( shape, id, options )
      this.addShapeDataToFeature( sData, id, fc )
    } )
    return this.cache ? this.cache.set( 'fc', fc, shapes, options ) : fc
  }

  /**
   * Returns a FeatureCollection for shapes with the given ids. This calls the adapter's iterateOnIds()
   * and will put the adapter's getShapeData() appearance object into properties
   * @param {array} array 
   */
  getFeatureCollectionFor( shapes, ids, options ) {
    if ( this.cache && this.cache.isSame( 'fcFor', shapes, ids, options ) ) {
      return this.cache.value( 'fcFor' )
    }
    const fc = { type : 'FeatureCollection', features : [ ] }
    this.adapter.iterateOnIds( shapes, ids, options, ( shape, id ) => {
      const sData = this.adapter.getShapeData( shape, id, options )
      this.addShapeDataToFeature( sData, id, fc )
    } )
    return this.cache ? this.cache.set( 'fcFor', fc, shapes, ids, options ) : fc
  }

  /**
   * 
   * @param {Object|Array} shapeData the shape data to add to the feature collection
   * @param {sting} id the id of the shape
   * @param {FeatureCollection} featureCollection the geoJson feature collection
   */
  addShapeDataToFeature( shapeData, id, featureCollection ) {

    if ( shapeData == null ) {
      return
    }
    const sData = Array.isArray( shapeData ) ? shapeData : [ shapeData ]
    
    for ( let n = 0; n < sData.length; n++ ) {
      const { dataType, dataRef, appearance : app, geoJson } = sData[n]
      if ( !app.visible ) {
        continue // hide shape
      }
      const geo = geoJson && geoJson.geometry
      if ( geo == null || geo.coordinates == null ) {
        continue // no coordinates to draw
      }
    
      const c = geo.coordinates
      
      if ( c.length > 0 ) {
        const p = { 
          dataType : dataType || this.dataType, 
          dataRef : dataRef || this.dataRef, 
          dataId : id, 
          ...app }
        featureCollection.features.push( { ...geoJson, id, properties : p } )
      } else {
        // console.log( 'Zero coordinates in shape', sData )
      }
    }
  }
}
