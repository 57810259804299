import React, { useMemo } from 'react'
import { Layout } from '@leverege/plugin'
import { MenuFactory, useSharedContext } from '@leverege/ui-plugin'
import { Popup } from '@leverege/ui-elements'
import ConditionCreators from './ConditionCreators'

const FACTORY = new MenuFactory()

export default function ConditionSelector( props ) {
  
  const { factory = FACTORY, eventData, onSelect, ...rest } = props
  const { conditionCreators } = useSharedContext()
  const layout = useMemo( ( ) => {
    return Layout.create( ConditionCreators.toActions( conditionCreators || [], onSelect, eventData ) )
  }, [ conditionCreators ] )

  const render = useMemo( ( ) => {
    return ( onClose ) => {
      return factory.createComponents( layout, {}, onClose )
    }
  }, [ layout, factory ] )

  return (
    <Popup
      variant="logicConditionSelector|conditionSelector"
      toggleAttributes={{ variant : 'logicConditionSelector|conditionSelector' }}
      {...rest}
      render={render} />
  )
}
