import React from 'react'
import { useValueChange } from '@leverege/ui-hooks'
import { Flex, TextInput, Popup } from '@leverege/ui-elements'
import I18N from '@leverege/i18n'
import { Expression, ExpressionCondition as Model } from '@leverege/condition'

const { t } = I18N.ns( 'conditions.expression.ExpressionConditionEditor' )

function isError( expression ) {
  const e = new Expression( expression )
  return !e.isValid()
}

export default function ExpressionConditionEditor( props ) {

  const { value } = props
  const expression = Model.getExpression( value )
  const onChange = useValueChange( Model, props ) 
  // const isValid = useMemo( () => {
  //   const e = new Expression( expression )
  //   return e.isValid()
  // }, [ expression ] )

  return (
    <Flex variant="rowS">
      <TextInput 
        style={{ flex : '1' }}
        eventData="setExpression"
        value={expression}
        onChange={onChange}
        validator={isError}
        hint={t( 'hint' )}
        title={t( 'title' )}/>
     
      {/* <Popup icon="fa fa-ellipsis-v fa-fw">
        <Popup.Item 
          onClick={console.log}>
          Test Expression
        </Popup.Item>
      </Popup> */}
      
    </Flex>
  )
}
