import React from 'react'
import { Popup } from '@leverege/ui-elements'

import MenuFactory from './MenuFactory.jsx'
import ActionsLayout from './ActionsLayout.js'
import ActionPreparer from './ActionPreparer.jsx'

const FACTORY = new MenuFactory()

/**
 * Returns a button that will present a popup of available
 * actions when clicked. The props are as follows
 * @param {ActionsLayout} actionsLayout If supplied, this will be used as the layout. Otherwise, actions, context, matchContext are used
 * @param {Array} actions An array of actions to layout. If null, the context is used with the Plugins
 * @param {object} context An context object used against the layouted out actions. It is given to handles and perform.
 * @param {object} matchContext An context object used to match Plugin actions with the layout. If this is not supplied, context
 * will be used. It is recommend that this be used.
 * @param {object} mustMatchContext the object that must be matched against the actions to be used
 * @param {string} actionType The type of plugins to load. By default, this is 'Action'
 * @param {string} variant the toolbar variant to use. This will feed into the Flex's variant if a layoutVariant is not supplied.
 * It also feeds into the toolbar factory if a factory is not supplied.
 * @param {string} className an optional classname.
 * @param {boolean} hideWhenEmpty if true, null is returned when no actions handle the context.
 * If false, a popup is return with an menu that indicates no actions are avaible.
 */
export default class ContextButton extends React.Component {

  /**
   * Return the actionsLayout to use
   */
  getLayout() {
    const { actionsLayout, actions } = this.props
    if ( actionsLayout ) {
      return actionsLayout
    }
    if ( this.layout == null || ( actions && actions !== this.layout.options?.actions ) ) {
      const { actionType, context, matchContext, mustMatchContext } = this.props
      this.layout = new ActionsLayout( { actionType, actions, context : matchContext || context, mustMatchContext } )
    }
    return this.layout
  }

  renderEmpty = ( onClose ) => {
    // eslint-disable-next-line
    const empty = this.props.empty || 'No options'
    return typeof empty === 'string' ? <Popup.Item disabled>{empty}</Popup.Item> : empty
  }

  render( ) {
    const {
      actionsLayout, actions, matchContext, context,
      hideWhenEmpty = true, factory = FACTORY, ...rest } = this.props
    const cLayout = this.getLayout()
    const handles = cLayout.handles( context )
    if ( hideWhenEmpty && !handles ) {
      return (
        <ActionPreparer
          layout={cLayout}
          context={context} />
      )
    }

    const renderMenu = ( onClose ) => {
      return factory.createComponents( cLayout.getRoot(), context, onClose )
    }

    return (
      <ActionPreparer
        layout={cLayout}
        context={context} >
        {() => (
          <Popup {...rest} render={handles ? renderMenu : this.renderEmpty} />
        )}
      </ActionPreparer>
    )
  }
}
