import React from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import { ValuesCache } from '@leverege/value-cache'
import Util from './Util'

export default class SymbolLayer {
  static createLayout() {
    return {
      'icon-image' : '{symbol}',
      'icon-allow-overlap' : true,
      'text-field' : '{name}',
      'text-allow-overlap' : true,
      'text-ignore-placement' : true,
      'text-optional' : true,
      'text-offset' : [ 0, 2 ],
      'text-size' : 12,
      'text-letter-spacing' : 0.04,
      'text-font' : [ 'Open Sans Bold', 'Arial Unicode MS Bold' ],
      'text-transform' : 'uppercase'
    }
  }

  static createLabelPaint( ) {
    return {
      'text-color' : 'black',
      'text-halo-color' : 'rgba(255,255,255,0.75)',
      'text-halo-width' : 1.5
    }
  }

  constructor( {
    dataType, layout = null, labelPaint = null, alwaysPushToNormal = false, cacheLayers = false,
    latAccess, lonAccess, idAccess, nameAccess, extendProps,
    layerType = 'symbol'
  } ) {
    this.cache = new ValuesCache()
    this.dataType = dataType
    this.dataTypeRollover = `${dataType}-rollover`
    this.dataTypeSelected = `${dataType}-selected`
    this.dataTypeTargeted = `${dataType}-targeted`
    this.layerType = layerType
    this.layout = layout || SymbolLayer.createLayout()
    this.labelPaint = labelPaint || SymbolLayer.createLabelPaint(),
    this.latAccess = Util.createAccess( latAccess, 'data/position/lat' )
    this.lonAccess = Util.createAccess( lonAccess, 'data/position/lon' )
    this.idAccess = Util.createAccess( idAccess, 'id' )
    this.nameAccess = Util.createAccess( nameAccess, null )
    this.alwaysPushToNormal = alwaysPushToNormal
    this.cacheLayers = cacheLayers
    this.extendProps = extendProps // called with ( props, data, id, dataType )
    this.layers = { }
    this.last = { }
  }

  /**
   * Returns the position array [longitude, latitude] for the given data. By default
   * this will use the Path access objects to get the position
   * @param {object} data the data object
   **/
  getPosition( data ) {
    return [ this.lonAccess.get( data, null ), this.latAccess.get( data, null ) ]
  }

  getCachedLayers( dataList, { symbolizer, selected, targeted, rollover, markers } ) {
    return {
      normal : this.cache.isSame( 'normal', dataList, symbolizer, markers.normal ) ? this.cache.value( 'normal' ) : null,
      selected : this.cache.isSame( 'selected', selected, symbolizer, markers.selected ) ? this.cache.value( 'selected' ) : null,
      targeted : this.cache.isSame( 'targeted', targeted, symbolizer, markers.targeted ) ? this.cache.value( 'targeted' ) : null,
      rollover : this.cache.isSame( 'rollover', rollover, symbolizer, markers.rollover ) ? this.cache.value( 'rollover' ) : null
    }
  }

  /**
   * Returns the layer names that will be generated. Useful for supplying to 
   * selectableLayerIds or rolloverableLayersIds to set what types can be clicked
   * or rolled over.
   **/
  getLayerNames() {
    const { dataType, dataTypeTargeted, dataTypeSelected, dataTypeRollover, layout } = this
    return [ dataType, dataTypeTargeted, dataTypeSelected, dataTypeRollover ]
  }

  /**
   * This will return a set of layers used to represent the dataList 
   * @param {array} dataList the objects to render
   * @param {object} options
   * @param {object} options.symbolizer function( data, { selected, targeted, rollover } ))
   * @param {object} options.selected the selected data
   * @param {object} options.targeted the targeted data for the dataList
   * @param {object} options.rollover the rollover data for the dataList
   * @param {object} options.isSelectable true if the objects should be selectable
   * @param {object} options.isRolloverable true if the objects should be rolloverable
   * @return and object container normal, rollover, selected, targeted fields that
   * hold Layer objects
   **/
  getLayers( dataList = [], options ) {
    const { symbolizer, selected, targeted, rollover, isSelectable = true, isRolloverable = true } = options

    // Make sure we need to generate a new list

    if ( this.cache.isSame( 'layers', dataList, symbolizer, selected, targeted, rollover ) ) {
      return this.cache.value( 'layers' )
    }

    const { dataType, dataTypeTargeted, dataTypeSelected, dataTypeRollover, layout, labelPaint } = this
    const markers = { normal : [], rollover : [], selected : [], targeted : [] }
    const size = dataList.length


    for ( let n = 0; n < size; n++ ) {
      const data = dataList[n]
      const id = this.idAccess.get( data, n )
      const pos = this.getPosition( data )
      if ( pos[0] == null || pos[1] == null || isNaN( pos[0] ) || isNaN( pos[1] ) ) {
        continue
      }

      const { layer, symbol } = symbolizer( data, options )
      const props = { symbol, dataId : id, dataType : this.dataType }
      if ( this.nameAccess ) {
        props.name = this.nameAccess && this.nameAccess.get( data, '' )
      }
      if ( this.extendProps ) {
        this.extendProps( props, data, id, dataType )
      }
      const m = <Feature key={id} properties={props} anchor="center" coordinates={pos} />

      markers[layer].push( m )

      // This will get rid of subtle flashing, but renders objects twice
      if ( this.alwaysPushToNormal && layer != 'normal' ) {
        markers.normal.push( m )
      }
    }

    const meta = { isSelectable, isRolloverable }

    if ( this.cacheLayers ) {
      const cacheLayers = this.getCachedLayers( dataList, { symbolizer, selected, targeted, rollover, markers } )

      this.layers = {
        normal : cacheLayers.normal || 
        this.cache.set(
          'normal',
          <Layer
            key={dataType}
            type={this.layerType}
            id={dataType}
            layout={layout}
            paint={labelPaint}
            metadata={meta}>
            {markers.normal}
          </Layer>,
          dataList,
          symbolizer ),
        targeted : cacheLayers.targeted ||
        this.cache.set(
          'targeted',
          <Layer
            key={dataTypeTargeted}
            type={this.layerType}
            id={dataTypeTargeted}
            layout={layout}
            paint={labelPaint}
            metadata={meta}>
            {markers.targeted}
          </Layer>,
          targeted,
          symbolizer
        ),
        selected : cacheLayers.selected ||
        this.cache.set(
          'selected',
          <Layer
            key={dataTypeSelected}
            type={this.layerType}
            id={dataTypeSelected}
            layout={layout}
            paint={labelPaint}
            metadata={meta}>
            {markers.selected}
          </Layer>,
          selected,
          symbolizer
        ),
        rollover : cacheLayers.rollover ||
        this.cache.set(
          'rollover',
          <Layer
            key={dataTypeRollover}
            type={this.layerType}
            id={dataTypeRollover}
            layout={layout}
            paint={labelPaint}
            metadata={meta}>
            {markers.rollover}
          </Layer>,
          rollover,
          symbolizer
        )
      }
    } else {
      this.layers = {
        normal : <Layer key={dataType} type={this.layerType} id={dataType} paint={labelPaint} layout={layout} metadata={meta}>{markers.normal}</Layer>,
        targeted : <Layer key={dataTypeTargeted} type={this.layerType} id={dataTypeTargeted} paint={labelPaint} layout={layout} metadata={meta}>{markers.targeted}</Layer>,
        selected : <Layer key={dataTypeSelected} type={this.layerType} id={dataTypeSelected} paint={labelPaint} layout={layout} metadata={meta}>{markers.selected}</Layer>,
        rollover : <Layer key={dataTypeRollover} type={this.layerType} id={dataTypeRollover} paint={labelPaint} layout={layout} metadata={meta}>{markers.rollover}</Layer>
      }
    }

    return this.cache.set( 'layers', this.layers, dataList, symbolizer, selected, targeted, rollover )
  }
}
