// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.control_bJP6f {
  height : 100%;
  display : flex;
  flex-direction: column;
  align-items: stretch;
}

.header_LHQY5, .footer_vGVx5 {
  flex : 0 0 auto;
}

.listPane_Sf_et {
  flex : 1;
}

.contentTitle_KmRUC {
  display : flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/editor/ModelMapEditor.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["\n.control {\n  height : 100%;\n  display : flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.header, .footer {\n  flex : 0 0 auto;\n}\n\n.listPane {\n  flex : 1;\n}\n\n.contentTitle {\n  display : flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `control_bJP6f`,
	"header": `header_LHQY5`,
	"footer": `footer_vGVx5`,
	"listPane": `listPane_Sf_et`,
	"contentTitle": `contentTitle_KmRUC`
};
export default ___CSS_LOADER_EXPORT___;
