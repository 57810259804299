import React from 'react'
import { Plugins } from '@leverege/plugin'

const ModelFactory = Plugins.createFactory( 'Model', { pluginKey : 'model', strategy : Plugins.LOOKUP } )
const ModelEditorFactory = Plugins.createFactory( 'ModelEditor', { 
  pluginKey : 'editor', strategy : Plugins.reactStrategy( React, 'value' ) 
} )

ModelEditorFactory.doGet = ModelEditorFactory.get

/**
 * Overrides the Factory get to lookup editor type first.
 * @param {String|Object} typeOrObj If this is an object and has 'editorType' field, that
 * will be used first to find an editor. If there is not one, it will fallback to type. 
 * @returns the object registered.
 */
ModelEditorFactory.get = ( typeOrObj ) => {
  if ( typeOrObj != null && typeof typeOrObj === 'object' && typeOrObj.editorType != null ) {
    const e = ModelEditorFactory.doGet( typeOrObj.editorType )
    if ( e ) {
      return e
    }
  }
  return ModelEditorFactory.doGet( typeOrObj )
}

/**
 * Checks to see if the editor for the type/obj is null or has isInline set to
 * true on the editor. An inline editor is one meant to fit in a similar spot as
 * a text input field.
 */
ModelEditorFactory.isInline = ( typeOrObj ) => {
  const editor = ModelEditorFactory.get( typeOrObj )
  return editor == null ? true : editor.isInline === true
}

ModelEditorFactory.createHeader = ( typeOrObj, props ) => {

  const clzz = ModelEditorFactory.get( typeOrObj )
  if ( clzz == null ) {
    return null
  }
  if ( clzz.isInline ) {
    return ModelEditorFactory.strategy( this, clzz, typeOrObj, props )
  }
  if ( clzz.supportsHeader ) {
    return ModelEditorFactory.strategy( this, clzz, typeOrObj, { ...props, showContentEditor : false } )
  }

  return null
}

ModelEditorFactory.createContent = ( typeOrObj, props ) => {
  
  const clzz = ModelEditorFactory.get( typeOrObj )
  if ( clzz == null ) {
    return null
  }
  if ( clzz.isInline ) {
    return null
  }
  if ( clzz.supportsHeader ) {
    return ModelEditorFactory.strategy( this, clzz, typeOrObj, { ...props, showHeaderEditor : false } )
  }

  return ModelEditorFactory.strategy( this, clzz, typeOrObj, props )

}

/**
 * Checks to see if the editor for the type/obj has a name field and returns it
 */
ModelFactory.getName = ( typeOrObj ) => {
  const editor = ModelFactory.get( typeOrObj )
  return editor == null ? null : editor.name || null
}

export {
  ModelFactory,
  ModelEditorFactory
}
