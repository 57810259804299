// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item_NPO0j {
  display : grid;
  grid-template-columns: 1fr auto;

  justify-content: space-between;
  position : relative;
  flex-direction: row;
  flex-wrap: nowrap;
}

.item_NPO0j > :first-child {
  flex : 1;
}

.item_NPO0j > :last-child {
  flex : 0 0 auto;
}

.dropTarget_HNcAA {
  background : var( --color-dropTarget, rgba( 200, 200, 0, 0.2 ) );
}

.dragging_xs3LP {
  visibility : hidden;
}`, "",{"version":3,"sources":["webpack://./src/dnd/DragDropView.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+BAA+B;;EAE/B,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gEAAgE;AAClE;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".item {\n  display : grid;\n  grid-template-columns: 1fr auto;\n\n  justify-content: space-between;\n  position : relative;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n\n.item > :first-child {\n  flex : 1;\n}\n\n.item > :last-child {\n  flex : 0 0 auto;\n}\n\n.dropTarget {\n  background : var( --color-dropTarget, rgba( 200, 200, 0, 0.2 ) );\n}\n\n.dragging {\n  visibility : hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `item_NPO0j`,
	"dropTarget": `dropTarget_HNcAA`,
	"dragging": `dragging_xs3LP`
};
export default ___CSS_LOADER_EXPORT___;
