import React from 'react'
import { TabPane } from '@leverege/ui-elements'

import AttributeTranslationEditor from './AttributeTranslationEditor.jsx'
import I18NNamespaceModel from '../models/I18NNamespaceModel.js'
import BlueprintTranslationsEditor from './BlueprintTranslationsEditor.jsx'

import S from './NamespaceEditor.css'

const getStandardTranslations = ( source ) => {
  const lcName = source?.name || 'Item'
  return [ {
    value : lcName,
    label : 'Name (Singular)',
    eventData : 'name_one'
  }, {
    value : `${lcName}s`,
    label : 'Name (Plural)',
    eventData : 'name_other'
  }, {
    value : `{{count}} ${lcName}`,
    label : 'Name with Count (Singular)',
    eventData : 'nameWithCount_one'
  }, {
    value : `{{count}} ${lcName}s`,
    label : 'Name With Count (Plural)',
    eventData : 'nameWithCount_other'
  } ]
}

export default function NamespaceEditor( props ) {
  const { value, eventData, onChange, blueprint } = props

  return (
    <TabPane className={S.editor}>
      <TabPane.Tab tabId="blueprint" tabName="Blueprint">
        <BlueprintTranslationsEditor
          getStandardTranslations={getStandardTranslations}
          blueprint={blueprint}
          model={I18NNamespaceModel.translations()}
          value={value}
          eventData={eventData}
          onChange={onChange} />
      </TabPane.Tab>
      <TabPane.Tab tabId="attributes" tabName="Attributes">
        <AttributeTranslationEditor
          blueprint={blueprint}
          model={I18NNamespaceModel.attributes()}
          value={value}
          eventData={eventData}
          onChange={onChange} />
      </TabPane.Tab>
    </TabPane>
  )
}
