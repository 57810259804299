import React, { useMemo, useCallback, useState } from 'react'
import { Dialog, Flex, Label, TextInput } from '@leverege/ui-elements'

export default function AddUIDialog( props ) {
  const { onClose, show, resources, onSubmit : pOnSubmit } = props

  const [ value, setValue ] = useState( '' )
  const [ isInError, setIsInError ] = useState( false )

  const usedKeys = useMemo( () => {
    return resources.map( ( rsrc ) => {
      const pathParts = rsrc.path.split( '/' )
        
      const isDefault = pathParts.length === 1 && pathParts[0] === 'i18n'
      const key = isDefault ? 'default' : pathParts[1]

      return key
    } )
  }, [ resources ] )

  const validator = useCallback( ( v ) => {
    if ( usedKeys.includes( v ) ) {
      setIsInError( true )
      return false
    }

    setIsInError( false )
    return true
  }, [ usedKeys, setIsInError ] )

  const onSubmit = useCallback( () => {
    pOnSubmit( value )
    onClose()
  }, [ value, onClose ] )

  return (
    <Dialog.Question
      okay="Add"
      title="Add UI"
      show={show}
      onClose={onClose}
      onCancel={onClose}
      onOkay={onSubmit}
      okayDisabled={isInError || value?.length === 0}>
      <Flex variant="colM" align="stretch">
        <Label>UI Key (e.g. deviceManagement)</Label>
        <TextInput
          validator={validator}
          value={value}
          onChange={( evt ) => { setValue( evt.value ) }} />
      </Flex>
    </Dialog.Question>
  )
}
