/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react'
import DashboardContext, { useDashboardContext } from '../shared/DashboardContext'
import { Grid } from '../model'

import S from './CardGridView.css'

export default function CardGridView( props ) {

  const { onChange, eventData } = props
  const value = props.value || {}
  const { viewers, sharedProps } = useDashboardContext( )
  const keyFor = useRef( createKeyFor() )
  // const onModelChange = useValueChange( Grid, props )
  const columns = Math.max( 1, value.columns || 2 )
  const cards = Grid.getCards( value )

  const style = {     
    gridTemplateColumns : `repeat( ${columns}, ${value.columnSize || '1fr'} )`,
    rowGap : value.rowGap,
    columnGap : value.columnGap
  }

  return (
    <div className={S.cardLayout} style={style} >
      <DashboardContext columns={columns}>
        { 
          cards.map( ( card, idx ) => {
            const props = {
              key : keyFor.current( card ),
              value : card, 
              eventData : idx,
              onChange : ( evt ) => { 
                if ( onChange ) {
                  const cValue = Grid.getCard( value, evt.data )
                  const nValue = Grid.setCard( value, idx, evt.value )
                  keyFor.current.moveKey( cValue, evt.value )
                  onChange( { data : eventData, value : nValue } )
                }
              },
              sharedProps
            }
            return viewers.create( card, props )
          } ) 
        }
      </DashboardContext>

    </div>
  )
}

/**
 * Creates a function that will map an object to a key for use in 
 * react's key mechanism
 */
function createKeyFor( ) {
  let K = 1
  const map = new Map()
  const f = ( obj ) => {
    let key = map.get( obj )
    if ( key == null ) {
      key = K++
      map.set( obj, key )
    }
    return key
  }
  f.remove = obj => map.delete( obj )
  f.moveKey = ( obj, toObj ) => {
    const k = map.get( obj )
    if ( k ) {
      map.set( toObj, k )
      map.delete( obj )
    }
  }
  return f
}
