import React from 'react'
// import classnames from 'classnames'
import equals from 'fast-deep-equal'
import { PortalOverlay, Pane, Button, Dialog } from '@leverege/ui-elements'

import OkayCancelEditor from './OkayCancelEditor.jsx'

import S from './EditorDialog.css'

export default class EditorDialog extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { value : { }, currentValue : { } }
  }

  static getDerivedStateFromProps( props, state ) {
    const { value } = props
    if ( value !== state.value ) {
      return { value, currentValue : value }
    }
    return null
  }

  onChange = ( evt ) => { 
    this.setState( { currentValue : evt.value } )
  }

  onOkay = ( ) => {
    const { onOkay, eventData } = this.props
    const { currentValue } = this.state
    onOkay( { value : currentValue, data : eventData } )
  }

  onPortalOkay = ( event ) => {
    const { onOkay, eventData } = this.props
    onOkay( { value : event.value, data : eventData } )
  }

  render() {
    const { 
      dialog = false, style = null, onClose, show, editor : Editor, title = 'Edit', icon, 
      editorProps = {}, validator = null, portalId,
      portalShowBackground, portalAnimation = 'slideFromTop', portalPosition = 'center',
      cancelLabel = 'Cancel', okayLabel = 'Okay', paneVariant = 'editorDialog', dialogContentVariant = 'dialogContent', editorVariants } = this.props
    const { currentValue, value } = this.state
    const isOkay = ( typeof validator === 'function' ? validator( currentValue, value ) : true ) && !equals( value, currentValue )
    const s = paneVariant == null ? { background : 'white', ...style } : style
    
    if ( !dialog ) {
      return ( 
        <PortalOverlay 
          show={show} 
          showBackground={portalShowBackground} 
          targetId={portalId} 
          animation={portalAnimation} 
          position={portalPosition} >
          <Pane variant={paneVariant} style={s}>
            <OkayCancelEditor 
              className={S.dialogContrib}
              editor={Editor}
              history={null}
              title={title}
              icon={icon}
              actionState={null}
              applyOnChange={false}
              validator={validator}
              editorProps={editorProps}
              variants={editorVariants}
              value={value || {}} 
              onCancel={onClose}
              onChange={this.onPortalOkay}
              okayLabel={okayLabel}
              cancelLabel={cancelLabel} />
          </Pane>
        </PortalOverlay>
      )
    }

    // Not sure I really need this path.
    const footer = (
      <div className={S.footer}>
        <Button variant="secondary" onClick={onClose}>{cancelLabel}</Button>
        <Button variant="primary" disabled={!isOkay} onClick={this.onOkay}>{okayLabel}</Button>
      </div>
    )
    return (
      <Dialog 
        show={show} 
        closeOnDocumentClick={false} 
        contentVariant={dialogContentVariant} 
        title={title} 
        footer={footer} 
        onClose={onClose}>
        <Editor {...editorProps} value={currentValue} onClose={onClose} onChange={this.onChange}/>
      </Dialog>
    )
  }
}
