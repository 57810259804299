export const FILL_STYLE = {
  'fill-color' : { 
    type : 'identity', 
    property : 'fillColor',
    default : 'rgba( 0, 0, 0, 0.3 )'
  }
}

export const LINE_LAYOUT = {
  'line-cap' : 'round',
  'line-join' : 'round'
}

export const LINE_STYLES = {
  thin : {
    'line-color' : { 
      type : 'identity', 
      property : 'outlineColor',
      default : 'rgba( 0, 0, 0, 1 )'
    }, 
    'line-width' : { 
      type : 'exponential',
      // B * 2^(Z - A)
      property : 'outlineWidth',
      base : 1,
      stops : [ [ 0, 1 ], [ 10, 1.5 ], [ 24, 1.5 ] ],
      default : 1.5
    },
    'line-opacity' : 1, 
  },
  medium : {
    'line-color' : { 
      type : 'identity', 
      property : 'outlineColor',
      default : 'rgba( 0, 0, 0, 1 )'
    }, 
    'line-width' : { 
      type : 'exponential',
      // B * 2^(Z - A)
      property : 'outlineWidth',
      base : 1,
      stops : [ [ 0, 1.5 ], [ 10, 3 ], [ 24, 3 ] ],
      default : 1.5
    },
    'line-opacity' : 1, 
  },
  thick : {
    'line-color' : { 
      type : 'identity', 
      property : 'outlineColor',
      default : 'rgba( 0, 0, 0, 1 )'
    }, 
    'line-width' : { 
      type : 'exponential',
      // B * 2^(Z - A)
      property : 'outlineWidth',
      base : 1,
      stops : [ [ 0, 2 ], [ 10, 7 ], [ 24, 7 ] ],
      default : 2
    },
    'line-opacity' : 1, 
  },
  dashed : {
    'line-color' : { 
      type : 'identity', 
      property : 'outlineColor',
      default : 'rgba( 0, 0, 0, 1 )'
    }, 
    'line-width' : { 
      type : 'exponential',
      // B * 2^(Z - A)
      property : 'outlineWidth',
      base : 1,
      stops : [ [ 0, 1 ], [ 10, 5 ], [ 24, 5 ] ],
      default : 1.5
    },
    'line-opacity' : 1, 
    'line-dasharray' : [ 1, 2 ]
  }
}

export const LABEL_PAINT = { 
  'text-color' : { 
    type : 'identity', 
    property : 'labelColor',
    default : 'rgba( 0, 0, 0, 1.0 )'
  },
  'text-halo-color' : 'rgba(255,255,255,0.75)',
  'text-halo-width' : 1.5,
}

export const LABEL_LAYOUT = {
  'symbol-placement' : 'point',
  'text-max-width' : 4,
  'text-field' : '{name}',
  'text-font' : [ 'Roboto Regular' ],
  'text-anchor' : 'center',
  'text-justify' : 'center',
  'text-allow-overlap' : false,
}

export const SYMBOL_LAYOUT = {
  'icon-image' : '{symbol}',
  'icon-size' : 0.5, 
  'icon-allow-overlap' : true,
  // 'text-field' : '{name}',
  'text-allow-overlap' : true,
  'text-ignore-placement' : true,
  'text-optional' : true,
  'text-offset' : [ 0, 2 ],
  'text-size' : 12,
  'text-letter-spacing' : 0.04,
  'text-font' : [ 'Open Sans Bold', 'Arial Unicode MS Bold' ],
  'text-transform' : 'uppercase',
}

export default {
  FILL_STYLE,
  LINE_LAYOUT,
  LINE_STYLES,
  LABEL_PAINT,
  LABEL_LAYOUT,
  SYMBOL_LAYOUT
}