import React, { useState } from 'react'
import { Content, Pane, Flex } from '@leverege/ui-elements'
import { R } from '@leverege/value-resolver'
import { useValueChange } from '@leverege/ui-hooks'
import { Card } from '../model'
import { CardTitle, ToolbarContext, VariantCreator } from '../shared'
import { useDashboardContext } from '../shared/DashboardContext'

export default function CardView( props ) {
  const { value } = props
  const dashboardContext = useDashboardContext()
  const { viewers, columns : maxColumns, sharedProps } = dashboardContext
  const vc = new VariantCreator( 'Card', value.style )
  const onModelChange = useValueChange( Card, props )
  const [ toolbarRef, setToolbarRef ] = useState( null )

  const style = {
    gridColumnEnd : `span ${Math.min( maxColumns, value.width || 1 )}`,
    gridRowEnd : `span ${value.height || 1}`,
  }
  const content = viewers.create( value.content, { 
    value : value.content, 
    sharedProps, 
    onChange : onModelChange, 
    eventData : 'setContent' 
  } )
  const plugin = viewers.get( value.content )

  if ( !plugin ) {
    return null
  }
  
  let typeName = plugin ? plugin.getCardTitle || plugin.name || plugin.NAME || plugin.TYPE : null
  let typeIcon = plugin ? plugin.getCardIcon || plugin.icon || plugin.ICON : null

  typeName = R( typeName, value.content, dashboardContext )
  typeIcon = R( typeIcon, value.content, dashboardContext )

  return (
    <Pane variant={vc.get( )} style={style} >
      <Content relative>
        <Content.Header variant={vc.get( 'Header' )}>
          <CardTitle
            title={Card.getTitle( value )}
            icon={Card.getIcon( value )}
            typeIcon={typeIcon}
            typeName={typeName}
            titleVisible={Card.isTitleVisible( value )}
            variant={vc.get( 'Title' )}>
            <Flex justify="space-between" style={{ flexGrow : 1 }}>
              <div />
              <div ref={ref => setToolbarRef( ref )} />
            </Flex>
          </CardTitle> 
        </Content.Header>
        <Content.Area variant={vc.get( 'Area' )}>
          <ToolbarContext.Provider value={toolbarRef}>
            <Flex variant="colM" align="stretch">
              {content}
            </Flex>
          </ToolbarContext.Provider>
        </Content.Area>
      </Content>
    </Pane>
  )
  
}
