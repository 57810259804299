import Path from '@leverege/path'
import Units from '@leverege/unit'
import Icons from './Icons'
import UnitDisplay from './UnitDisplay'
import UnitCreators from './UnitCreators'
import NumberCreator from './NumberCreator'
import IntCreator from './IntCreator'
import BooleanCreator from './BooleanCreator'
import StringCreator from './StringCreator'
import PercentCreator from './PercentCreator'
import GeoJsonCreator from './GeoJsonCreator'
import GeoPointCreator from './GeoPointCreator'
import RelationshipCreator from './RelationshipCreator'
import ParentRelationshipCreator from './ParentRelationshipCreator'
import TimestampCreator from './TimestampCreator'
import EnumCreator from './EnumCreator'
import ResourceEnumCreator from './ResourceEnumCreator'
import sortAttributesByPath from './sortAttributesByPath'
import TreeOrganizer from './TreeOrganizer'
import * as Types from './types'
import Updaters from './updaters'
import Interpolators from './interpolators'
import Util from './Util'
import Attribute from './Attribute'
import Blueprint from './Blueprint'
import Blueprints from './Blueprints'
import Enums from './Enums'
import ResourceEnums from './ResourceEnums'
import Networks from './Networks'

const CREATORS = [
  BooleanCreator, IntCreator, NumberCreator, PercentCreator,
  StringCreator, TimestampCreator, RelationshipCreator,
  GeoJsonCreator, ParentRelationshipCreator, GeoPointCreator,
  EnumCreator, ResourceEnumCreator
].concat( UnitCreators.getCreators() );

// build a lookup of type->creator
const TYPE_TO_CREATOR = { };
for ( let n = 0; n < CREATORS.length; n++ ) {
  TYPE_TO_CREATOR[CREATORS[n].typeName()] = CREATORS[n];
}

function getAttributeCreators( ) {
  return [].concat( CREATORS );
}

function getAttributeCreatorFor( type ) {
  return TYPE_TO_CREATOR[type];
}

function getTagsForAttributes( attrs, usePathToo ) {
  const tags = {}
  for ( let n = 0; n < attrs.length; n++ ) {
    const attr = attrs[n];
    if ( attr.tags && attr.tags.length > 0 ) {
      for ( let t = 0; t < attr.tags.length; t++ ) {
        tags[attr.tags[t]] = true;
      }
    }
    if ( usePathToo ) {
      const arr = Path( attr.path ).pathArray()
      for ( let t = 0; t < arr.length; t++ ) {
        tags[arr[t]] = true;
      }
    }
  }

  return Object.keys( tags );
}

/**
 * Returns an array of updater creators that support the given
 * attribute type. The attrType can be a string, an AttributeCreator,
 * or an attribute object
 **/
function getUpdaterCreatorsForAttribute( attr ) {
  let attrType = attr
  if ( typeof attrType === 'string' ) {
    attrType = getAttributeCreatorFor( attrType )
  }
  // if an attribute is passed in, get its type
  if ( attrType && typeof attrType.type === 'string' ) {
    attrType = getAttributeCreatorFor( attrType.type )
  }

  return Updaters.getCreatorsForAttribute( attrType )
}

/**
 * Returns an array of updater creators that support the given
 * attribute type. The attrType can be a string, an AttributeCreator,
 * or an attribute object
 **/
function getInterpolatorCreatorsForAttribute( attr ) {
  let attrType = attr
  if ( typeof attrType === 'string' ) {
    attrType = getAttributeCreatorFor( attrType )
  }
  // if an attribute is passed in, get its type
  if ( attrType && typeof attrType.type === 'string' ) {
    attrType = getAttributeCreatorFor( attrType.type )
  }

  return Interpolators.getCreatorsForAttribute( attrType )
}

export {
  Icons,
  BooleanCreator,
  IntCreator,
  NumberCreator,
  PercentCreator,
  StringCreator,
  TimestampCreator,
  RelationshipCreator,
  ParentRelationshipCreator,
  GeoJsonCreator,
  GeoPointCreator,
  EnumCreator,
  ResourceEnumCreator,
  getAttributeCreators,
  getAttributeCreatorFor,
  UnitCreators,
  UnitDisplay,
  getUpdaterCreatorsForAttribute,
  getInterpolatorCreatorsForAttribute,
  Units,
  Types,
  Updaters,
  Interpolators,
  getTagsForAttributes,
  sortAttributesByPath,
  TreeOrganizer,
  Blueprints,
  Blueprint,
  Attribute,
  Enums,
  ResourceEnums,
  Networks,
  Util
}
