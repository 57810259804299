import { Plugins } from '@leverege/plugin'
import { combineReducers, createStore, applyMiddleware } from 'redux' 

/**
 * This creates a reducer that uses combineReducer and plugin to dynamically
 * add reducers to the reducer state. This will look in Plugins of type
 * 'DynamicStore.Reducer'. The plugins are expected to have:
 * {
 *    type : string, // unique plugin type id
 *    name : string, // Display name, currently unused
 *    preferredKey : string, // the preferred location in the store
 *    reducer : function, // the function to install
 *    createReducer : function, // optional function that will create the reducer to install. It is given config
 *    result : function( key ) // invoked with the key where the reducer was installed
 * }
 * 
 * Normally reducer is install at the key. If createReducer is supplied, the result from 
 * createReducer( config ) will be used as the reducer
 * 
 * Because there maybe collisions between two plugins' preferredKey, a new key may 
 * need to be given to a plugin. The result( newKey ) method is invoked. 
 * 
 * General usage looks like this. As a note, reducers in this case is not the 
 * result of a combineReducers() or a function, but an object the contains 
 * the keys and the reducing functions. This will be merged with the Plugins
 * and passed into combineReducers():
 * 
 * import { DynamicStore } from '@leverege/ui-redux'
 * import reducers from './reducers'
 * 
 * const dynamicStore = new DyanmicStore( {
 *  reducers, 
 *  middleware : [ thunk.withExtraArgument( api ) ]
 * } )
 * 
 * const store = dynamicStore.create()
 */

export default class DynamicStore {

  /**
   * Creates a DynamicStore. 
   * @param {object} options 
   * @param {object} options.reducers the base, not dynamic reducers
   * @param {object} options.middleware the middleware 
   * @param {object} options.config the config handed to any plugin that has a createReducer function.
   */
  constructor( options ) {
    const { reducers = {}, middleware = [], preloaded, config } = options
    this.reducers = reducers
    this.preloaded = preloaded
    this.config = config || { }
    this.middleware = ( !Array.isArray( middleware ) ? [ middleware ] : middleware ) || null
    this.mapping = { }
    // This is used to prevent key reuse
    this.nextKeyId = 1
  }

  create() {
    const allReducers = this.getAllReducers()
    if ( this.preloaded ) {
      this.store = createStore(
        combineReducers( allReducers ),
        this.preloaded,
        applyMiddleware( ...this.middleware )
      )
    } else {
      this.store = createStore(
        combineReducers( allReducers ),
        applyMiddleware( ...this.middleware )
      )
    }
    // Begin listening
    Plugins.on( 'DynamicStore.Reducer', this.onPluginsChanged )
    return this.store
  }

  update( ) {
    if ( this.store ) {
      const allReducers = this.getAllReducers()
      this.store.replaceReducer( combineReducers( allReducers ) )
    }
  }

  getAllReducers( ) {
    const all = { ...this.reducers }
    const plgs = Plugins.get( 'DynamicStore.Reducer' )
    plgs.forEach( ( { type, preferredKey, reducer, createReducer, result } ) => {
      if ( this.mapping[type] != null ) {
        // Already mapped. ignore
        if ( createReducer ) {
          all[this.mapping[type]] = createReducer( this.config )
        } else {
          all[this.mapping[type]] = reducer
        }
      } else {
        // find a new key 
        let n = 0
        let done = false
        let key = null
        while ( !done ) {
          key = n === 0 ? preferredKey : `${preferredKey}${n}`
          if ( all[key] == null ) {
            done = true
          }
          n++
        }
        if ( createReducer ) {
          all[key] = createReducer( this.config )
        } else {
          all[key] = reducer
        }
        this.mapping[type] = key 
        result( key )
      }
    } )
    return all
  }

  onPluginsChanged = ( evt ) => {
    if ( evt.type === Plugins.PLUGIN_REMOVED ) {
      delete this.mapping[evt.plugin.type]
    }
    this.update()
  }

}