// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterInput_kzN58 {
  min-width: 89%; /* Leave room for scroll bar */
}`, "",{"version":3,"sources":["webpack://./src/editor/ItemLayoutSelector.css"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAE,8BAA8B;AAChD","sourcesContent":[".filterInput {\n  min-width: 89%; /* Leave room for scroll bar */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterInput": `filterInput_kzN58`
};
export default ___CSS_LOADER_EXPORT___;
