import React from 'react'
import { Popup, PortalOverlay } from '@leverege/ui-elements'

import MenuFactory from './MenuFactory.jsx'
import ActionsLayout from './ActionsLayout.js'
import ActionPreparer from './ActionPreparer.jsx'

const FACTORY = new MenuFactory()
const ABSOLUTE = { width : 0, height : 0, padding : 0, margin : 0, visible : 'hidden', position : 'absolute' }

/**
 * A popup menu that will display context actions.
 *
 * function showPopup( event, matchContext, context ) {
 *   const position = { left : event.clientX + 'px', top : event.clientY + 'px' }
 *   Dialogs.show( {
 *     component : ContextMenu,
 *     props : { position, context matchContext }
 *   } )
 * }
 *
 * where matchContext might look like
 * {
 *   matchContext : { use : 'contextMenu', client : 'My Client' },
 * }
 *
 * and the context would contain the targets to act on.
 */
export default class ContextMenu extends React.Component {

  /**
   * Return the actionsLayout to use
   */
  getLayout() {
    const { actionsLayout, actions } = this.props
    if ( actionsLayout ) {
      return actionsLayout
    }
    if ( this.layout == null || ( actions && actions !== this.layout.options?.actions ) ) {
      const { actionType, context, matchContext, mustMatchContext } = this.props
      this.layout = new ActionsLayout( { actionType, actions, context : matchContext || context, mustMatchContext } )
    }
    return this.layout
  }

  renderEmpty = ( onClose ) => {
    // eslint-disable-next-line
    const empty = this.props.empty || 'No options'
    return typeof empty === 'string' ? <Popup.Item disabled>{empty}</Popup.Item> : empty
  }

  /**
   * @param {boolean} show if true, the menu should be shown
   * @param {function} onClose should be called when the menu is finished
   * @param {object} position Where to place the menu on screen. { left, top } in pixels
   * @param {ActionsLayout} actionsLayout if supplied, this ActionLayout is used. Update is not invoked
   * @param {Array} actions if supplied, these actions or ActionLayout are used in the context
   * @param {object} context the context used to display the actions
   * @param {object} matchContext if supplied, this context is used to filter the available
   * action plugins to build one targeted list. If this is not supplied, context will be used,
   * which will cause more filter of plugins to occur.
   * @param {object} mustMatchContext the object that must be matched against the actions to be used
   * @param {boolean} hideWhenEmpty if true, null is returned when no actions handle the context.
   * If false, a popup is return with an menu that indicates no actions are avaible.
   */
  render( ) {
    const {
      show, onClose, position,
      actionsLayout, actions, matchContext, context,
      hideWhenEmpty = true, factory = FACTORY, ...rest } = this.props
    const cLayout = this.getLayout()
    const handles = cLayout.handles( context )
    if ( hideWhenEmpty && !handles ) {
      if ( onClose ) {
        // we are not going to show anything
        setTimeout( onClose, 0 )
      }
      return (
        <ActionPreparer
          layout={cLayout}
          context={context} />
      )
    }

    const renderMenu = ( onClose ) => {
      return factory.createComponents( cLayout.getRoot(), context, onClose )
    }
    return (
      <ActionPreparer
        layout={cLayout}
        context={context}>
        {() => (
          <PortalOverlay
            position={null}
            variant="contextMenu"
            show={show}
            showBackground
            animation="fade"
            onClose={onClose}>
            <Popup {...rest}
              opened={show}
              attachment="top left"
              title=""
              render={handles ? renderMenu : this.renderEmpty}
              style={{ ...ABSOLUTE, ...position }}
              onChange={( { isOpened } ) => {
                if ( !isOpened && onClose ) {
                  onClose()
                }
              }}/>
          </PortalOverlay>
        )}
      </ActionPreparer>
    )
  }
}
