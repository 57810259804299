function isSortable( attr ) {
  const nonSortable = attr.hasTag( 'sortable:false' )

  if ( nonSortable ) {
    return false
  }

  if (
    attr.type === 'geoPoint' ||
      attr.type === 'geoJson' ||
      attr.type === 'relationship' ||
      attr.type === 'parentRelationship'
  ) {
    return false
  }

  return true
}
  
function isFilterable( attr ) {
  const nonFilterable = attr.hasTag( 'filterable:false' )

  if ( nonFilterable ) {
    return false
  }
    
  if ( attr.type === 'relationship' ) {
    return false
  }

  return true
}

export default {
  isSortable,
  isFilterable
}
