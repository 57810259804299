import React, { useContext, useMemo } from 'react'

// DashboardContext can supply:
// * ModelsLookup
// * Editor/View Factory
// * Allows values like date/time range to be pushed down and changed (but merged)

const Context = React.createContext( {
  models : null, // models lookup 
  depth : 0, // depth of the current level 
  variants : null, // array of { name, value } where value is the variant style name (letters and numbers only)
  editors : null, // factor for editors,
  viewers : null, // factory for viewers,
  optionEditors : null, // option factory
  deferEditing : false, // whether to defer editing to viewer UI
  options : { }, // object containing key -> data. For contextual data like time range
  props : { },
  objectType : null
} )

const useDashboardContext = ( ) => {
  return useContext( Context )
}

export default function DashboardContext( 
  { models, variants, depth, editors,
    viewers, optionEditors, deferEditing, options, sharedProps,
    columns, addWidgetText, children,
    getModelsFor, objectType, getModelCreators
  } ) {
  const dc = useDashboardContext( )

  const cxt = useMemo( ( ) => {
    const v = { ...dc }
    v.models = models || v.models
    v.depth = depth || v.depth
    v.variants = variants || v.variants
    v.viewers = viewers || v.viewers
    v.editors = editors || v.editors
    v.optionEditors = optionEditors || v.optionEditors
    v.deferEditing = deferEditing || v.deferEditing
    v.columns = columns || v.columns || 2
    v.addWidgetText = addWidgetText || v.addWidgetText
    v.sharedProps = sharedProps || v.sharedProps
    v.objectType = objectType || v.objectType

    const defaultGetModelsFor = () => models.getAll()

    v.getModelsFor = getModelsFor || v.getModelsFor || defaultGetModelsFor
    v.getModelCreators = getModelCreators || v.getModelCreators

    if ( options && v.options == null ) {
      v.options = options
    } else if ( options ) {
      v.options = { ...v.options, ...options }
    }

    return v
  }, [
    dc,
    models,
    editors,
    viewers,
    optionEditors,
    deferEditing,
    sharedProps,
    columns,
    depth,
    variants,
    addWidgetText,
    options,
    objectType,
    getModelsFor,
    getModelCreators
  ] )
  return (
    <Context.Provider value={cxt}>
      {children}
    </Context.Provider>
  )
}

const Provider = Context.Provider
const Consumer = Context.Consumer

export {
  Context as DashboardContextType,
  Consumer as DashboardContextConsumer,
  Provider as DashboardContextProvider,
  useDashboardContext
}
