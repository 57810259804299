import { LogicCondition } from '@leverege/condition'
import { ns } from '@leverege/i18n'

const { exists, t, tf, tIcon, tfIcon } = ns( 'condition.LogicConditionEditor' )

export default {
  id : 'condition.logic.NestConditionAction', 
  matches : {
    client : 'condition.LogicEditor',
    use : 'conditionActionBar'
  },
  layout : {
    group : [ tf( 'moreGroup' ) ],
    groupIcons : [ tfIcon( 'moreIcon', 'fa fa-ellipsis-v fa-fw' ) ],
    sort : 'nest'
  },
  appearance( context ) {
    return {
      name : t( 'nestCondition' ),
      icon : tIcon( 'nestConditionIcon' ),
      help : exists( 'help' ) ? t( 'help' ) : null
    }
  },

  handles( context ) { return context.parent != null && context.model != null && context.parentOnChange != null },
  perform( { context } ) {
    const { parent, model, value : condition, index, parentOnChange : onChange, parentEventData : data } = context
    
    onChange( {
      data,
      value : model.set( parent, index, LogicCondition.addCondition( LogicCondition.create(), { ...condition } ) )
    } )
  }
}
