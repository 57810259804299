import React from 'react'
import classnames from 'classnames'
import { Flex, Popup } from '@leverege/ui-elements'

import S from './Selector.css'

const dIsSelected = ( v, value ) => { return v && ( value === undefined ? v.active === true : v.value === value ) }
const dGetName = ( v ) => { return v && v.name }
const dGetRenderer = ( v, name ) => { return <div className={S.renderer}>{name}</div> }
const dGetCheckRenderer = ( selected ) => {
  return <i className={classnames( 'fa fa-check', S.check, selected && S.selected )} />
}

export default class Selector extends React.Component {

  onSelect = ( evt ) => {
    const { onChange, eventData } = this.props
    if ( onChange ) {
      onChange( { value : evt.value, data : eventData } )
    }
  }

  render( ) {
    const {
      values, eventData, value,
      isSelected = dIsSelected,
      renderer = dGetRenderer,
      valueName = dGetName,
      checkRenderer = dGetCheckRenderer,
      onChange, // ignore
      title, ...rest } = this.props

    if ( values == null ) {
      return null
    }
    const cValue = values.find( ( item ) => { return item.value === value } )
    const name = ( cValue && cValue.name ) || ''

    return (
      <Popup title={title || name} {...rest} >
        { values.map( ( v, index ) => {
          const selected = isSelected( v, value )
          const check = checkRenderer( selected )
          const name = valueName( v )
          const view = renderer( v, name )
          return (
            <Popup.Item key={`Item-${name}`} value={v.value} onClick={this.onSelect}>
              <Flex variant="noSpace">
                {check}
                {view}
              </Flex>
            </Popup.Item>
          )
        } )}
      </Popup>
    )
  }
}
