import React from 'react'
import { Popup, PortalOverlay } from '@leverege/ui-elements'

const ABSOLUTE = { width : 0, height : 0, padding : 0, margin : 0, visible : 'hidden', position : 'absolute' }

export default class ContextMenu extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { visible : false, position : { x : 0, y : 0 } }
  }

  setVisible = ( position ) => {
    this.setState( { visible : true, position } )
  }

  onClose = ( evt ) => {
    this.setState( { visible : false } )
  }

  render( ) {
    const { renderMenu } = this.props
    const { visible, position } = this.state
    return (
      <PortalOverlay 
        show={visible}
        showBackground
        animation="fade"
        onClose={this.onClose}>
        <Popup opened={visible}
          attachment="top left"
          title=""
          render={renderMenu}
          style={{ ...ABSOLUTE, ...position }}
          onChange={( { isOpened } ) => { 
            if ( !isOpened ) { 
              this.setState( { visible : false } ) 
            } 
          }}/>
      </PortalOverlay>
    )
  }
}