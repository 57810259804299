import { ns } from '@leverege/i18n'

const { t, tf, tIcon, tfIcon } = ns( 'condition.LogicConditionEditor' )

export default {
  id : 'condition.logic.DuplicateConditionAction', 
  matches : {
    client : 'condition.LogicEditor',
    use : 'conditionActionBar'
  },
  layout : {
    group : [ tf( 'moreGroup' ) ],
    groupIcons : [ tfIcon( 'moreIcon', 'fa fa-ellipsis-v fa-fw' ) ],
    sort : 'duplicate'
  },
  appearance( context ) {
    return { name : t( 'duplicateCondition' ), icon : tIcon( 'copyConditionIcon' ) }
  },

  handles( context ) { return context.parent != null && context.model != null && context.parentOnChange != null },
  perform( { context } ) {
    const { parent, model, value : condition, parentOnChange : onChange, parentEventData : data } = context
    const value = model.add( parent, { ...condition } )
    onChange( { data, value } )
  }
}
