/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useDrop } from 'react-dnd'

import I18N from '@leverege/i18n'
import { Pane, Text } from '@leverege/ui-elements'

import Grid from '../model/Grid'
import VariantCreator from '../shared/VariantCreator'

import S from './PlaceholderCard.css'

const { t } = I18N.ns( 'uiDashboard.editor.PlaceholderCard' )

// onChange: the method to call with { value : newModel, eventData }
// eventData: passed into onChange
export default function PlaceholderCard( props ) {
  const { value, onChange, eventData, index = 0, accept = 'uiDashboardCard', children } = props
  const vc = new VariantCreator( 'PlaceholderCard', value.style )

  const [ collectedProps, drop ] = useDrop( {
    accept,
    collect : ( monitor, props ) => {
      return {
        isOver : monitor.isOver( { shallow : true } ),
        willChange : Grid.indexOfCard( value ) >= 0,
        canDrop : monitor.canDrop()
      }
    },
    drop : ( item, monitor ) => {
      try {
        if ( monitor.didDrop() ) {
          return monitor.getDropResult()
        }
        const curr = Grid.indexOfCard( value, item.item )
        const isMove = curr >= 0
        let nModel
        if ( isMove ) {
          if ( index === 1 ) {
            // moving and the index is one, which means there is only
            // one item. not change unless affect is copy (cant determine)
            return { droppedItem : null, dropResult : 'equal' }
          } 
          nModel = Grid.moveCard( value, curr, index - 1 )
          nModel.delta = nModel.delta == null ? 1 : nModel.delta + 1

          onChange( { value : nModel, changing : false, data : eventData } )
          return { droppedItem : item.item, dropTarget : value, dropResult : 'move' }
        } 

        // copy the card
        nModel = Grid.addCard( value, JSON.parse( JSON.stringify( item.item ) ) )
        nModel.delta = nModel.delta == null ? 1 : nModel.delta + 1

        onChange( { value : nModel, changing : false, data : eventData } )

        return { droppedItem : item.item, dropTarget : value, dropResult : 'copy' }
      } catch ( ex ) {
        return { droppedItem : null, dropTarget : value, isMove : false, dropResult : 'error' }
      }
    }
  } )

  const style = { gridColumnStart : 1, gridColumnEnd : `span ${( value.columns || 2 )}` }
  const isDropTarget = collectedProps.isOver && collectedProps.canDrop
  const isPossibleDropTarget = !collectedProps.isOver && collectedProps.canDrop

  const variant = isDropTarget ? 
    ( `${vc.get( 'Target' )}|${vc.get( )}` ) :
    ( isPossibleDropTarget ? `${vc.get( 'Highlight' )}|${vc.get( )}` : vc.get( ) )

  return (
    <Pane variant={variant} domRef={drop} className={S.placeholder} style={style}>
      { children || <Text variant={`${vc.get( 'Label' )}|inputTitle`}>{t( 'gridEmpty' )}</Text> }
    </Pane>
  )
}
