export default class VariantCreator {

  constructor( component, style ) {
    const cComponent = `${component[0].toUpperCase()}${component.substring( 1 )}`
    this.base = `dashboard${cComponent}`
    if ( typeof style === 'string' ) {
      const cStyle = `${style[0].toUpperCase()}${style.substring( 1 )}`
      this.styleBase = `dashboard${cStyle}${cComponent}`
    }
  }

  // dashboard <style> <Component> <Part>
  // dashboard <Component> <Part>
  // dashboard <Component> <Part>
  get( part ) {
    if ( part == null ) {
      return this.styleBase ? `${this.styleBase}|${this.base}` : this.base
    }
    const cPart = `${part[0].toUpperCase()}${part.substring( 1 )}`
    return this.styleBase ? `${this.styleBase}${cPart}|${this.base}${cPart}` : `${this.base}${cPart}`
  }
}
