import Path from '@leverege/path' 
import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util' 

const { isPathValid, isPathCollection } = Util

export default {
  
  name() {
    return 'String'
  },

  typeName() {
    return 'string' 
  },

  // Returns a generic what kind of unit am I.
  // This is used to allows use to prune updaters
  // for a given type.
  categoryType( ) {
    return 'string';
  },

  create( path, name ) { 
    if ( !isPathValid( path ) ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }
    return { 
      type : 'string', 
      path : path || 'stringValue',
      isCollection : isPathCollection( path ),
      name : name || path, 
      desc : null,
      tags : [],
      source : 'device',
      controllable : false,
      searchable : true,
      sim : {
        initialValue : null,
        updater : null
      }
    }
  },

  createFrom( attr ) {
    if ( attr.type === 'string' ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );
    convert.source = attr.source;
    convert.controllable = attr.controllable;

    Path.setIf( convert, attr, 'sim/initialValue', 'string' );

    return convert;
  } 
}
