import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leverege/plugin'
import { Popup, TextInput } from '@leverege/ui-elements'

import S from './ItemLayoutSelector.css'

/**
 * The ItemLayoutSelector uses an array of options objects in conjunction with the plugins Layout class
 * to produce a menu with submenus in it. You can configure the component to produce a filter input 
 * through which a user can filter the given items.
 *
 * @property {Array.<object>} options The spec `@leverege/plugin/Layout` uses to generate the tree of Popup items.
 * @property {function} onSelect Executes when the user selects an item.
 * @property {function} onChange Executes something changes in the ItemLayoutSelector — namely, when the user selects something.
 * @property {string} icon An icon to use on the left of an item in lists.
 * @property {string} title The title of the ItemLayoutSelector.
 * @property {boolean} showFilter Whether or not to show a filter input. Default is false.
 * @property {string} filterVariant A variant for the filter input. Default is `popupSelectorFilter`.
 * @property {object} filterOptions An options object for the filtering feature.
 * Must contain a `key` attribute at which to find the value in the options object that you want to filter on
 * using the filterTerm. You can also pass in a `placeholder` attribute to replace the default 'search...' text
 * and/or a `noResults` string to show when a filter yields no results.
 *
 * @returns {element} A React Component for selecting items in a tree of menus and submenus.
 *
 * @example `<ItemLayoutSelector options={menuSpec} onSelect={onAddDetail} icon="fa fa-plus" title="Add" />`
 */
export default class ItemLayoutSelector extends React.Component {

  constructor( props ) {
    super( props )
    this.state = {
      filterTerm : ''
    }
  }

  static propTypes = {
    options : PropTypes.array.isRequired,
    showFilter : PropTypes.bool,
    filterVariant : PropTypes.string,
    filterOptions : PropTypes.shape( {
      key : PropTypes.string,
      placeholder : PropTypes.string,
      noResults : PropTypes.string,
    } ),
  }

  static defaultProps = {
    showFilter : false,
    filterOptions : {
      key : 'name',
      placeholder : 'Search . . .',
      noResults : 'No Results',
    },
    filterVariant : 'popupSelectorFilter' // TODO use the theme context to get the variant.
  }

  /**
   * @method onPopupChange Handle change event data when the Popup component changes
   * @param {bool} changeEvent.isOpened
   */
  onPopupChange = ( { isOpened } ) => {
    const { showFilter } = this.props
    if ( showFilter && !isOpened ) {
      this.resetFilter()
    }
  }

  /**
   * @method resetFilter Reset the filter term
   */
  resetFilter = () => {
    this.setState( { filterTerm : '' } )
  }

  /**
   * @method onSelect an onSelect event handler when the user clicks an item in a selector menu
   * @param {object} evt The selection event object
   */
  onSelect = ( evt ) => {
    const { onChange, eventData } = this.props
    if ( onChange ) {
      onChange( { value : evt.value, data : eventData } )
    }
  }

  /**
   * @method onChangeFilterTerm Updates the filter term
   * @param {object} event The event containing the input filter term value
   */
  onChangeFilterTerm = ( event ) => {
    const filterTerm = event.value.trim().toLowerCase()
    return this.setState( { filterTerm } )
  }

  /**
   * @method filterMenuItems Filters this component's `options` by some user input `filterTerm`
   * @param {Array.<object>} menuSpec What the user passed in as the `options` prop.
   * @param {string} filterTerm The term by which to filter the `menuSpec`s elements.
   * @param {Object.<string>} filterOptions The `filterOptions`, either default or user-defined.
   * The `key` in the `filterOptions` tells this function on which key of the `menuSpec` elements to filter the menus.
   * @returns {array} The filtered `menuSpec` argument.
   */
  filterMenuItems = ( menuSpec, filterTerm, filterOptions ) => {
    const filtered = menuSpec
      .filter( ( value ) => {
        const isString = typeof value[filterOptions.key] === 'string'
        return isString && value[filterOptions.key].toLowerCase().includes( filterTerm )
      } )
    return filtered
  }

  /**
   * @method onClick Fire when a user clicks an item in the menus
   * @param {object} evt An event object
   */
  onClick = ( evt ) => {
    const { eventData, onSelect } = this.props
    onSelect( { data : eventData, value : evt.data } )
  }

  /**
   * @method createComponents Create the components for the menus and submenus in the options
   * @param {object} node
   * @param {function} onClose
   * @returns {node} A menu component with submenu components in it.
   */
  createComponents( node, onClose ) {
    if ( node == null ) {
      return null
    }
    const r = node.items.map( ( item, idx ) => {
      if ( item.type === Layout.NODE_TYPE ) {
        return (
          <Popup.Menu
            onClose={onClose}
            key={item.name}
            title={item.name}
            render={( onClose ) => {
              return this.createComponents( item, onClose )
            }} />
        )
      }
      return <Popup.Item key={idx} eventData={item} onClose={onClose} onClick={this.onClick}>{item.name}</Popup.Item>
    } )
    return r
  }

  /**
   * @method createMenu Create a menu on a plugin Layout
   * @param {function} onClose
   * @returns A menu component. A FilterInput will be added above the top-level menu if showFilter=true.
   */
  createMenu = ( onClose ) => {
    const { filterTerm } = this.state
    const { options, showFilter, filterVariant, filterOptions } = this.props

    // Filter on each call to createMenu to ensure filtering when props may change irrespective of input changing.
    if ( showFilter ) {
      const filteredMenuSpec = this.filterMenuItems( options, filterTerm, filterOptions )
      const filteredMenuTree = this.createComponents( Layout.create( filteredMenuSpec ), onClose )
      const noFilterResults = Array.isArray( filteredMenuTree ) && filteredMenuTree.length === 0
      return (
        <React.Fragment>
          <Popup.Header sticky>
            <TextInput
              variant={filterVariant}
              className={S.filterInput}
              onChange={this.onChangeFilterTerm}
              value={filterTerm}
              changeOnReturn={false}
              alwaysFireOnReturn={false}
              name="filterItemLayoutSelector"
              hint={filterOptions.placeholder} />
          </Popup.Header>
          {filteredMenuTree}
          {noFilterResults && (
            <Popup.Item>
              {filterOptions.noResults}
            </Popup.Item>
          )}
        </React.Fragment>
      )
    }

    return this.createComponents( Layout.create( options ), onClose )
  }

  render() {
    const { options, onSelect, showFilter, ...rest } = this.props

    return (
      <Popup {...rest} render={this.createMenu} onChange={this.onPopupChange} />
    )
  }
}
