// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.dialog_zVPUt {
  width: 600px;
  height : 80vh;
  position : relative;
}

.area_VLK7p {
  padding : 0 var( --size-padLarge, 16px );
}

.footer_p7Pm_ {
  padding : var( --size-padLarge, 16px );
  display : grid;
  justify-content: right;
  align-items: right;
  grid-template-columns: auto auto;
  column-gap: var( --size-padMedium, 8px );
}

.footer_p7Pm_ > * {
  min-width: 100px;
}

.dialogContrib_KnM6P > * {
  max-height: 80vh;
}`, "",{"version":3,"sources":["webpack://./src/editor/EditorDialog.css"],"names":[],"mappings":";;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;EACtC,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,gCAAgC;EAChC,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n\n.dialog {\n  width: 600px;\n  height : 80vh;\n  position : relative;\n}\n\n.area {\n  padding : 0 var( --size-padLarge, 16px );\n}\n\n.footer {\n  padding : var( --size-padLarge, 16px );\n  display : grid;\n  justify-content: right;\n  align-items: right;\n  grid-template-columns: auto auto;\n  column-gap: var( --size-padMedium, 8px );\n}\n\n.footer > * {\n  min-width: 100px;\n}\n\n.dialogContrib > * {\n  max-height: 80vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `dialog_zVPUt`,
	"area": `area_VLK7p`,
	"footer": `footer_p7Pm_`,
	"dialogContrib": `dialogContrib_KnM6P`
};
export default ___CSS_LOADER_EXPORT___;
