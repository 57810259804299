// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.vertical_X5q2C {
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

}

.horizontal_slJTT {
  display : flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.dividerHorizontal_tFXQe {
  width : var( --size-toolbarDivider, 1px );
  background : var( --color-toolbarDivider, #dddddd );
  margin-left : var( --size-toolbarMarginLeft, var( --size-padMedium, 8px ) );
}


.dividerVertical_bsyaU {
  height : var( --size-toolbarDivider, 1px );
  background : var( --color-toolbarDivider, #dddddd );
}

.headerVertical_A4jkm {
  align-self : flex-start;
}

.headerHorizontal_XtI8s {
  align-self : center;
}`, "",{"version":3,"sources":["webpack://./src/action/Toolbar.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,sBAAsB;EACtB,2BAA2B;EAC3B,oBAAoB;;AAEtB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;EACzC,mDAAmD;EACnD,2EAA2E;AAC7E;;;AAGA;EACE,0CAA0C;EAC1C,mDAAmD;AACrD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["\n.vertical {\n  display : flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: stretch;\n\n}\n\n.horizontal {\n  display : flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.dividerHorizontal {\n  width : var( --size-toolbarDivider, 1px );\n  background : var( --color-toolbarDivider, #dddddd );\n  margin-left : var( --size-toolbarMarginLeft, var( --size-padMedium, 8px ) );\n}\n\n\n.dividerVertical {\n  height : var( --size-toolbarDivider, 1px );\n  background : var( --color-toolbarDivider, #dddddd );\n}\n\n.headerVertical {\n  align-self : flex-start;\n}\n\n.headerHorizontal {\n  align-self : center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical": `vertical_X5q2C`,
	"horizontal": `horizontal_slJTT`,
	"dividerHorizontal": `dividerHorizontal_tFXQe`,
	"dividerVertical": `dividerVertical_bsyaU`,
	"headerVertical": `headerVertical_A4jkm`,
	"headerHorizontal": `headerHorizontal_XtI8s`
};
export default ___CSS_LOADER_EXPORT___;
