import { LogicCondition, RuleModel } from '@leverege/condition'
import ConditionCreators from '../../selector/ConditionCreators'

export default {
  id : 'condition.objectizer.ReplaceConditionAction', 
  matches : {
    client : 'condition.ObjectizerEditor',
    use : 'ruleActionBar'
  },
  layout : {
    group : [ 'more', 'Replace Condition' ],
    groupIcons : [ 'fa fa-ellipsis-v fa-fw' ],
    sort : 'replace'
  },

  handles( context ) { return context.conditionCreators != null },
  getActions( context ) {
    const { conditionCreators, onChange, eventData, value } = context 
    const onSelect = ( e ) => {
      let nc = e.value.create()
      if ( nc.type === LogicCondition.TYPE ) {
        nc = LogicCondition.addCondition( nc, RuleModel.getCondition( value ) )
      }
      onChange( { data : eventData, value : RuleModel.setCondition( value, nc ) } )
    }

    return ConditionCreators.toActions(
      conditionCreators,
      onSelect,
      'foo'
    )
  }
}
