import Err from '@leverege/error' 
import Path from '@leverege/path' 
import ObjUtil from '@leverege/object-util' 
// apparently unsafe regex
// const PATH_TEST = /^([a-zA-Z0-9_]+[@*]?\/)+([a-zA-Z0-9_]+[@*]?)$/

const PATH_PART_TEST = /^[a-zA-Z0-9_]+[@*]?$/

function isPathCollection( path ) {
  if ( !isPathValid( path ) ) {
    throw Err.illegalArgument( `invalid path: ${path}` )
  }
  return !path.split( '/' ).every( part => ( !part.endsWith( '*' ) && !part.endsWith( '@' ) ) )
}

// same as isPathCollection, but only checks last path part
function isLeafCollection( path ) {
  if ( !isPathValid( path ) ) {
    throw Err.illegalArgument( `invalid path: ${path}` )
  }
  const sPath = path.split( '/' )
  return sPath[sPath.length - 1].endsWith( '*' ) || sPath[sPath.length - 1].endsWith( '@' )
}

function isPathValid( path ) {
  if ( typeof path !== 'string' || path.length === 0 ) {
    return false
  }
  return path.split( '/' ).every( PATH_PART_TEST.test.bind( PATH_PART_TEST ) )
}

function isRelationshipPathValid( path ) {
  return isPathValid( path ) && !isPathCollection( path ) && !( path.split( '/' ).length > 1 )
}

function getPathCollections( path ) {
  if ( !isPathValid( path ) ) {
    throw Err.illegalArgument( `invalid path: ${path}` )
  }
  return path.split( '/' )
    .filter( part => ( part.endsWith( '*' ) || part.endsWith( '@' ) ) )
}

function getPathIds( path ) {
  return getPathCollections( path )
    .map( part => `${part.slice( 0, -1 )}Id` )
}

function getResolvedPath( path, cxt, ids, colls ) {
  let nPath = path
  colls.forEach( ( collection, index ) => {
    nPath = nPath.replace( collection, `${collection.slice( 0, -1 )}/${cxt[ids[index]]}` )
  } )
  return nPath
}

function getAccessor( attr ) {
  if ( attr == null || typeof attr !== 'object' || !isPathValid( attr.path ) ) {
    throw Err.illegalArgument( `invalid path: ${attr.path}` )
  }

  // just a normal path, no complexity
  if ( !isPathCollection( attr.path ) ) {
    const p = Path( attr.path )
    return p.get.bind( p )
  }

  // things with collections are more complicated
  const ids = getPathIds( attr.path )
  const colls = getPathCollections( attr.path )
  return ( data, dft, cxt ) => {
    const resPath = getResolvedPath( attr.path, cxt, ids, colls )
    return Path( resPath ).get( data, dft )
  }
}

function getCollectionAccessor( attr ) {
  if ( attr == null || typeof attr !== 'object' || !isPathValid( attr.path ) ) {
    throw Err.illegalArgument( `invalid path: ${attr.path}` )
  }

  // just a normal path, invalid here
  if ( !isPathCollection( attr.path ) ) {
    throw Err.illegalArgument( `path not collection: ${attr.path}` )
  }

  // things with collections are more complicated
  const ids = getPathIds( attr.path )
  const colls = getPathCollections( attr.path )
  return ( data, dft, cxt ) => {
    const resPath = getResolvedPath( attr.path, cxt, ids, colls ).slice( 0, -1 )
    return Path( resPath ).get( data, dft )
  }
}

function getSetter( attr ) {
  if ( attr == null || typeof attr !== 'object' || !isPathValid( attr.path ) ) {
    throw Err.illegalArgument( `invalid path: ${attr.path}` )
  }

  // just a normal path, no complexity
  if ( !isPathCollection( attr.path ) ) {
    const p = Path( attr.path )
    return p.get.bind( p )
  }

  // things with collections are more complicated
  const ids = getPathIds( attr.path )
  const colls = getPathCollections( attr.path )
  return ( data, cxt ) => {
    const resPath = getResolvedPath( attr.path, cxt, ids, colls )
    return Path( resPath ).set( data )
  }
}

function addPath( p, context, acc ) {
  return add => ( {
    path : `${p}/${add}`,
    context : { ...context, [`${acc}Id`] : add }
  } )
}

function getCollectionHandler( attr ) {
  if ( attr == null || typeof attr !== 'object' || !isPathValid( attr.path ) ) {
    throw Err.illegalArgument( `invalid path: ${attr.path}` )
  }

  // just a normal path
  if ( !isPathCollection( attr.path ) ) {
    return null
  }

  // thing with collections
  const splitPath = attr.path.split( '/' )
  return ( data, cxt = {} ) => {
    // must start with a constant
    let interimPaths = []
    const initPathPart = splitPath[0]
    if ( initPathPart.endsWith( '*' ) || initPathPart.endsWith( '@' ) ) {
      if ( cxt[`${initPathPart.slice( 0, -1 )}Id`] != null ) {
        interimPaths.push( {
          path : `${initPathPart.slice( 0, -1 )}/${cxt[`${initPathPart.slice( 0, -1 )}Id`]}`,
          context : { [`${initPathPart.slice( 0, -1 )}Id`] : cxt[`${initPathPart.slice( 0, -1 )}Id`] }
        } ) // `${initPathPart.slice( 0, -1 )}/${cxt[`${initPathPart.slice( 0, -1 )}Id`]}` )
      } else {
        let toAdd = initPathPart.endsWith( '*' ) ? Path( initPathPart.slice( 0, -1 ) ).get( data, [] ).map( ( v, i ) => i ) : Object.keys( Path( initPathPart.slice( 0, -1 ) ).get( data, {} ) )
        if ( !Array.isArray( toAdd ) && typeof toAdd === 'object' ) {
          toAdd = Object.keys( toAdd )
        }
        interimPaths = toAdd.map( add => ( {
          path : `${initPathPart.slice( 0, -1 )}/${add}`,
          context : { [`${initPathPart.slice( 0, -1 )}Id`] : add }
        } ) )
      }
    } else {
      interimPaths.push( { path : initPathPart } )
    }
    for ( let i = 1; i < splitPath.length; i++ ) {
      const pathPart = splitPath[i]
      if ( pathPart.endsWith( '*' ) || pathPart.endsWith( '@' ) ) {
        let toReplace = []
        if ( cxt[`${pathPart.slice( 0, -1 )}Id`] != null ) {
          // loop through the previous loops result
          for ( let j = 0; j < interimPaths.length; j++ ) {
            const p = interimPaths[j]
            const add = cxt[`${pathPart.slice( 0, -1 )}Id`]
            toReplace.push( {
              path : `${p.path}/${pathPart.slice( 0, -1 )}/${add}`,
              context : { ...p.context, [`${pathPart.slice( 0, -1 )}Id`] : add }
            } ) // `${p}/${pathPart.slice( 0, -1 )}/${add}` )
          }
        } else {
          
          for ( let j = 0; j < interimPaths.length; j++ ) {
            const acc = pathPart.slice( 0, -1 )
            const p = `${interimPaths[j].path}/${acc}`
            const toAdd = pathPart.endsWith( '*' ) ? Path( p ).get( data, [] ).map( ( v, i ) => i ) : Object.keys( Path( p ).get( data, {} ) )
            toReplace = toReplace.concat( toAdd.map( addPath( p, interimPaths[j].context, acc ) ) )
          }
        }

        interimPaths = toReplace
      } else {
        interimPaths = interimPaths.map( intPath => ( {
          ...intPath,
          path : `${intPath.path}/${pathPart}`
        } ) )
      }
    }
    return {
      iterator : () => {
        let i = 0
        return {
          hasNext : () => {
            return i < interimPaths.length
          },
          next : () => {
            return { index : i, total : interimPaths.length, context : interimPaths[i].context, value : Path( interimPaths[i++].path ).get( data ) }
          },
          current : () => {
            return { index : i, total : interimPaths.length, context : interimPaths[i].context, value : Path( interimPaths[i].path ).get( data ) }
          }
        }
      },
      length : () => interimPaths.length,
      keys : () => [ ...interimPaths ],
      values : () => interimPaths.map( path => Path( path.path ).get( data ) )
    }
  }
}

function arrayEqual( arr1, arr2 ) {
  if ( arr1.length !== arr2.length ) {
    return false
  }
  for ( let i = 0; i < arr1.length; i++ ) {
    if ( arr1[i] !== arr2[i] ) {
      return false
    }
  }
  return true
}

function hasConflict( attrs, path, isNew, isGroup ) {
  // if the path is already in attributes in some capacity
  if ( attrs.find( ( { path : aPath } ) => path === aPath ) ) {
    return isNew
  }
  // trailing slash prevents partial matches
  if ( isGroup ) {
    return !attrs.find( ( { path : aPath } ) => aPath.startsWith( `${path}/` ) )
  }

  // test for overlap
  const test = {}
  attrs.forEach( a => Path( a.path.replace( /[@*]/g, '' ) ).set( test, true ) )
  Path( path.replace( /[@*]/g, '' ) ).set( test, true )
  const toAdd = isNew ? 1 : 0
  const t1 = Object.keys( ObjUtil.flatten( test ) ).length
  if ( t1 < attrs.length + toAdd ) {
    return true
  }

  // test for collections
  const p = Path( path ).pathArray()
  for ( let i = 1; i < p.length; i++ ) {
    const prefix = p.slice( 0, i )
    const strippedPrefix = prefix.join( '/' ).replace( /[@*]/g, '' )
    const prefixColls = getPathCollections( prefix.join( '/' ) )
    for ( let j = 0; j < attrs.length; j++ ) {
      const { path } = attrs[j]
      const aPrefix = Path( path ).pathArray().slice( 0, i )
      const aStrippedPrefix = aPrefix.join( '/' ).replace( /[@*]/g, '' )
      const aPrefixColls = getPathCollections( aPrefix.join( '/' ) )
      if ( strippedPrefix === aStrippedPrefix && !arrayEqual( aPrefixColls, prefixColls ) ) {
        return true
      }
    }
  }

  // default to no conflict
  return false

}

export default {
  isPathCollection,
  isLeafCollection,
  isPathValid,
  isRelationshipPathValid,
  getPathCollections,
  getResolvedPath,
  getPathIds,
  getAccessor,
  getCollectionAccessor,
  getSetter,
  getCollectionHandler,
  hasConflict
}
