import React from 'react'
import { Pane, Text } from '@leverege/ui-elements'
import { ValueCache } from '@leverege/value-cache'

import ToolbarActionsLayout from './ToolbarActionsLayout.jsx'
import ToolbarFactory from './ToolbarFactory.jsx'
import ActionPreparer from './ActionPreparer.jsx'

import S from './Toolbar.css'

/**
 * The props are as follows
 * @param {ActionsLayout} actionsLayout If supplied, this will be used as the layout. Otherwise, actions, context, matchContext are used
 * @param {Array} actions An array of actions to layout. If null, the context is used with the Plugins
 * @param {object} context An context object used against the layouted out actions. It is given to handles and perform.
 * @param {object} matchContext An context object used to match Plugin actions with the layout. If this is not supplied, context
 * will be used. It is recommend that this be used. 
 * @param {string} actionType The type of plugins to load. By default, this is 'Action'
 * @param {string} variant the toolbar variant to use. This will feed into the Flex's variant if a layoutVariant is not supplied.
 * It also feeds into the toolbar factory if a factory is not supplied. Suggested variants: 'topNavBar', 'sideNavBar', 'actionBar', 
 * 'itemBar', 'secondaryNavBar' and 'secondarySideNavBar
 * @param {string} layoutVariant the variant given to the pane.
 * @param {string} className an optional classname.
 * @param {boolean} vertical true if the toolbar is vertical. This will be given to the factory if the factory is not given.
 * @param {string} prefer 'icon' or 'text' or null. Determines if icons, text or both are rendered at the top level.
 * @param {boolean} hasContextMenu if true, a context menu will automatically be added to the toolbar at the end.
 * @param {boolean} contextMenuHideWhenEmpty if true, the context menu will hide if no actions are present
 * @param {object} contextMenuToggleAttributes options for the context menu popup
 * @param {object} contextMenuMatchContext normally this is calculated based on the toolbar match context, but if supplied,
 * this will be used as the action filter for the menu button.
 */
export default class Toolbar extends React.Component {

  constructor( props ) {
    super( props )
    this.factoryCache = new ValueCache()
  }

  /**
   * Return the actionsLayout to use
   */
  getLayout() {
    const { actionsLayout, actions } = this.props
    if ( actionsLayout ) {
      return actionsLayout
    }
    if ( this.layout == null || ( actions && actions !== this.layout.options?.actions ) ) {
      const { 
        actionType, context, matchContext, mustMatchContext, hasContextMenu, 
        contextMenuHideWhenEmpty, contextMenuToggleAttributes, 
        contextMenuMatchContext, contextMenuMustMatchContext } = this.props
      this.layout = new ToolbarActionsLayout( { 
        actions,
        context : matchContext || context, 
        mustMatchContext,
        actionType,
        hasContextMenu,
        contextMenuOptions : !hasContextMenu ? null : {
          hideWhenEmpty : contextMenuHideWhenEmpty,
          toggleAttributes : contextMenuToggleAttributes,
          matchContext : contextMenuMatchContext,
          mustMatchContext : contextMenuMustMatchContext
        }
      } )
    }
    return this.layout
  }

  createFactory = ( variant, vertical, prefer ) => { 
    return new ToolbarFactory( { variant, vertical, prefer } )
  }

  render( ) {
    const { 
      variant = 'toolbar', factory, layoutVariant,
      context, className, children, vertical = false, prefer } = this.props
    const fVariant = ( layoutVariant || 
      `flex:${variant}${vertical ? 'Vertical' : 'Horizontal'}|${variant}|${vertical ? 'col' : 'row'}` )
    const fac = factory || this.factoryCache.calc( this.createFactory, variant, vertical, prefer )

    const cLayout = this.getLayout()
    return (
      <ActionPreparer
        layout={cLayout}
        context={context}>
        {() => (
          <Pane variant={variant} layout={fVariant} className={className}>
            {fac.createComponents( cLayout.getRoot(), context )}
            {children}
          </Pane>
        )}
      </ActionPreparer>
    )
  }
}

function Divider( props ) {
  const { vertical = false, variant } = props
  const vs = vertical ? 'Vertical' : 'Horizontal'
  const lvs = vertical ? 'vertical' : 'horizontal'
  let v = null
  if ( variant ) {
    if ( variant.indexOf( '|' ) > 0 ) {
      v = variant.split( '|' )
        .map( ( vt ) => {
          const cv = vt.trim() 
          return `${cv}${vs}Divider|${cv}Divider`
        } ).join( '|' )
        
      v = `${v}|${lvs}ToolbarDivider|toolbarDivider`
    } else {
      v = `${variant}${vs}Divider|${variant}Divider|${lvs}ToolbarDivider|toolbarDivider`
    }
  } else {
    v = `${lvs}ToolbarDivider|toolbarDivider`
  }
  // const v = variant ? 
  //   `${variant}${vs}Divider|${variant}Divider|${lvs}ToolbarDivider|toolbarDivider` : 
  //   `${lvs}ToolbarDivider|toolbarDivider`
  return <Pane variant={v}/>
  // return <div className={vertical ? S.dividerVertical : S.dividerHorizontal}/>
}

function Header( props ) {
  const { vertical = false, variant, children } = props
  if ( vertical !== true ) {
    return null // these work, but i think are of limited use
  }
  // const vs = vertical ? 'Vertical' : 'Horizontal'
  // const lvs = vertical ? 'vertical' : 'horizontal'
  // const v = variant ? 
  //   `${variant}${vs}Header|${variant}Header|${vs}ToolbarHeader|toolbarHeader` : 
  //   `${lvs}ToolbarHeader|toolbarHeader`
  let v = null
  if ( variant ) {
    if ( variant.indexOf( '|' ) > 0 ) {
      v = variant.split( '|' )
        .map( ( vt ) => {
          const cv = vt.trim() 
          return `${cv}Header|toolbarHeader`
        } ).join( '|' )
        
      v = `${v}|toolbarDivider`
    } else {
      v = `${variant}Header|toolbarHeader`
    }
  } else {
    v = 'toolbarHeader'
  }

  // const v = variant ? `${variant}Header|toolbarHeader` : 'toolbarHeader'
  return <Text variant={v} className={vertical ? S.headerVertical : S.headerHorizontal}>{children}</Text>
}

Toolbar.Header = Header
Toolbar.Divider = Divider
