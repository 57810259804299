/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import { DnDUtil, SharedContext, useSharedContext } from '@leverege/ui-plugin'
import { Pane, Flex, Selector, Text, Popup } from '@leverege/ui-elements'
import { useInstanceCallback, useValueChange } from '@leverege/ui-hooks'
import { PropertyCondition, LogicCondition as Model, LogicCondition } from '@leverege/condition'
import { ns } from '@leverege/i18n'

import ConditionSelector from '../selector/ConditionSelector'
import ConditionCreators from '../selector/ConditionCreators'
import NoRows from './NoRows'
import ConditionRow from './ConditionRow'
import S from './LogicConditionEditor.css'

const { t, tIcon } = ns( 'condition.LogicConditionEditor' )

const comparators = [
  { value : 'and', get name() { return t( 'allTrue' ) } },
  { value : 'or', get name() { return t( 'onePlusTrue' ) } },
  { value : 'xor', get name() { return t( 'oneTrue' ) } },
  { value : 'nor', get name() { return t( 'noneTrue' ) } },
  { value : 'xnor', get name() { return t( 'allOrNoneTrue' ) } },
  { value : 'nand', get name() { return t( 'notAllTrue' ) } }
]

const onAddCondition = ( { value, eventData, onChange }, e ) => { 
  if ( e.value?.create ) {
    const nModel = Model.addCondition( value, e.value.create() )
    onChange( { data : eventData, value : nModel } )
    return 
  }
  const nModel = Model.addCondition( value,
    e.data === 'property' ? PropertyCondition.create() : Model.create() )
  onChange( { data : eventData, value : nModel } )
}

export default function LogicConditionEditor( props ) {
  const { 
    value, onChange : parentOnChange,
    eventData : parentEventData, showHeaderEditor = true, showContentEditor = true, preamble } = props
  const { conditionOptions, conditionCreators : gCreators, objectType } = useSharedContext()
  const onChange = useValueChange( Model, props )
  const conditions = Model.getConditions( value ) || [ ]
  const onAdd = useInstanceCallback( onAddCondition, props )
  const [ onDrop, onRemove, onChildChange ] = useInstanceCallback(
    [ DnDUtil.dropItem, DnDUtil.removeItem, DnDUtil.updateChildItem ],
    Model.conditions(),
    props )
  const conditionCreators = useMemo( ( ) => { 
    if ( gCreators ) { 
      return gCreators
    }
    return ConditionCreators.getCreatorsFor( objectType )
  }, [ gCreators, objectType ] )
  const logicalOperators = conditionOptions?.logicalOperators || null
  const comps = useMemo( ( ) => { 
    if ( logicalOperators == null ) {
      return comparators
    }
    return comparators.filter( ( c ) => { return logicalOperators.includes( c.value ) } )
  }, [ logicalOperators ] )

  return (
    <SharedContext conditionCreators={conditionCreators}>
      <Pane
        variant="logicConditionEditor|conditionEditor"
        className={S.logical}
        layout="flex:colM">
        { showHeaderEditor && (
          <Flex variant="rowM" align="center">
            {preamble && (
              <Text>{preamble}</Text>
            )}
            <Selector
              variant="logicConditionOperator"
              eventData="setOperator"
              targetAttachment="bottom left"
              attachment="top left"  
              value={Model.getOperator( value )}
              values={comps}
              renderer={OptionRenderer}
              onChange={onChange}
              toggleAttributes={{
                variant : 'logicConditionOperator|selector',
                icon : tIcon( 'operatorSelectorIcon', 'fa fa-fw fa-chevron-down' )
              }} />
          </Flex>
        ) }
        { showContentEditor && (
          <Flex variant="colM" align="flex-start">
            <Pane variant="conditionsList" className={S.conditions}>
              <div className={S.conditionHolder}>
                { conditions.length > 0 &&
              conditions.map( ( cnd, idx ) => {
                return (
                  <ConditionRow 
                    key={cnd.id || ( cnd.type + idx )}
                    parent={value}
                    model={LogicCondition.conditions()}
                    parentOnChange={parentOnChange}
                    parentEventData={parentEventData}
                    onDrop={onDrop}
                    value={cnd} 
                    index={idx}
                    onChange={onChildChange}
                    onRemove={onRemove}
                    eventData={cnd} />
                )
              } )}
                { conditions.length === 0 && <NoRows value={value} onDrop={onDrop}/> }
              </div>
            </Pane>
            <ConditionSelector
              onSelect={onAdd}
              icon={tIcon( 'conditionSelectorIcon', 'fa fa-plus-circle fa-fw' )}
              title={t( 'conditionSelectorTitle' )} />
          </Flex>
        ) }
      </Pane>
    </SharedContext>
  )
}

function OptionRenderer( item ) {
  const { name, value } = item

  return (
    <Popup.Item
      value={value}
      accessory={<Text variant="hint">{value.toUpperCase()}</Text>}>
      {name}
    </Popup.Item>
  )
  return (
    <div className={S.option}>
      <Text>{name}</Text>
      <Text variant="hint">{value.toUpperCase()}</Text>
    </div>
  )
}

LogicConditionEditor.supportsHeader = true
