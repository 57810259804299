import Unit from '@leverege/unit' 
/*
  Icon:
    url : <url of image>
    font : <font class>
    color : for font
*/

const defaults = {
  [Unit.AMOUNT_OF_SUBSTANCE] : { url : 'https://s3.amazonaws.com/lev-icons/flask.png' },
  [Unit.POWER] : { url : 'https://s3.amazonaws.com/lev-icons/battery.png' },
  [Unit.TEMPERATURE] : { url : 'https://s3.amazonaws.com/lev-icons/thermometer.png' }
}

function createIcon( icon ) {
  if ( icon == null ) {
    return null
  }

  if ( icon.url && icon.url.trim().length > 0 ) {
    return {
      type : 'image-icon',
      url : icon.url 
    }
  }
  if ( icon.font ) {
    return {
      type : 'font-icon',
      fontClass : icon.font,
      color : icon.fontColor || { r : 0, g : 0, b : 0, a : 255 }
    }
  }
  return null
}

function getIcon( attr ) {
  if ( attr.icon ) {
    if ( attr.icon.url || attr.icon.font ) {
      return createIcon( attr.icon )
    }
  }  
  return getDefaultIcon( attr )
}

function getDefaultIcon( attr ) {
  return createIcon( defaults[attr.type] )
}

export default {
  getIcon
}
