// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logical_GGUMa {
}

.rules_vTR2m {
  border : 1px solid #eeeeee;

  /* padding-left: var( --size-padLarge, 32px ); */
  padding-top : var( --size-padMedium, 16px ); 
  padding-bottom : var( --size-padMedium, 16px ); 
  overflow: auto;
}
.ruleScroll_pColf {
}

.ruleHolder_THM4y {
  display : grid;
  row-gap: var( --size-padMedium, 16px );
}

.rule_nBy1F {
  /* display : flex; */
  display : grid;
  grid-template-columns: 1fr auto;

  justify-content: space-between;
  position : relative;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #eeeeee;
}
.rule_nBy1F:last-child {
  border-bottom-color: transparent;
}

.rule_nBy1F:hover {
  background : rgba( 0, 0, 0, 0.02);
}

.rule_nBy1F > :first-child {
  flex : 1;
}

.rule_nBy1F > :last-child {
  /* margin-left : var( --size-padMedium, 32px ); */
  flex : 0 0 auto;
}

.logical_GGUMa > .logical_GGUMa {
  border : 1px solid #333333;
}

.defaultRow_DriUi {
  display : flex;
}

.defaultRow_DriUi :first-child {
  flex : 1;
}
`, "",{"version":3,"sources":["webpack://./src/objectizer/ObjectizerEditor.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,0BAA0B;;EAE1B,gDAAgD;EAChD,2CAA2C;EAC3C,8CAA8C;EAC9C,cAAc;AAChB;AACA;AACA;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,oBAAoB;EACpB,cAAc;EACd,+BAA+B;;EAE/B,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,gCAAgC;AAClC;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,iDAAiD;EACjD,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,QAAQ;AACV","sourcesContent":[".logical {\n}\n\n.rules {\n  border : 1px solid #eeeeee;\n\n  /* padding-left: var( --size-padLarge, 32px ); */\n  padding-top : var( --size-padMedium, 16px ); \n  padding-bottom : var( --size-padMedium, 16px ); \n  overflow: auto;\n}\n.ruleScroll {\n}\n\n.ruleHolder {\n  display : grid;\n  row-gap: var( --size-padMedium, 16px );\n}\n\n.rule {\n  /* display : flex; */\n  display : grid;\n  grid-template-columns: 1fr auto;\n\n  justify-content: space-between;\n  position : relative;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  border-bottom: 1px solid #eeeeee;\n}\n.rule:last-child {\n  border-bottom-color: transparent;\n}\n\n.rule:hover {\n  background : rgba( 0, 0, 0, 0.02);\n}\n\n.rule > :first-child {\n  flex : 1;\n}\n\n.rule > :last-child {\n  /* margin-left : var( --size-padMedium, 32px ); */\n  flex : 0 0 auto;\n}\n\n.logical > .logical {\n  border : 1px solid #333333;\n}\n\n.defaultRow {\n  display : flex;\n}\n\n.defaultRow :first-child {\n  flex : 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logical": `logical_GGUMa`,
	"rules": `rules_vTR2m`,
	"ruleScroll": `ruleScroll_pColf`,
	"ruleHolder": `ruleHolder_THM4y`,
	"rule": `rule_nBy1F`,
	"defaultRow": `defaultRow_DriUi`
};
export default ___CSS_LOADER_EXPORT___;
