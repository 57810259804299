/*
  {
    type : 'resourceEnum',
    resourceType : 'system', // optional, defaults to project level enum
    enumType : 'enumType', // the name of the enum type
    ...
  }
 */
import Path from '@leverege/path' 
import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util'

const { isPathValid, isPathCollection } = Util

export default {
  
  name() {
    return 'Resource Enum'
  },

  typeName() {
    return 'resourceEnum' 
  },

  categoryType( ) {
    return 'enum';
  },

  create( path, name ) { 
    if ( !isPathValid( path ) ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }
    return { 
      type : 'resourceEnum',
      enumType : null,
      resourceType : 'system',
      path : path || 'enumValue',
      isCollection : isPathCollection( path ),
      name : name || path,
      desc : null,
      tags : [],
      source : 'device',
      controllable : false,
      searchable : true,
      sim : {
        initialValue : null,
        updater : null
      }
    }
  },

  createFrom( attr ) {
    if ( attr.type === 'enum' ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );
    convert.source = attr.source;
    convert.controllable = attr.controllable;
    convert.enumType = attr.enumType
    convert.resourceType = attr.resourceType

    Path.setIf( convert, attr, 'sim/initialValue', 'string' );

    return convert;
  } 
}
