import ObjectUtil from '@leverege/object-util' 
import Err from '@leverege/error' 

import Util from './Util' 

const { isPathValid, isPathCollection } = Util

const TYPE = 'parentRelationship';

export default {
  
  name() {
    return 'Parent Relationship'
  },

  typeName() {
    return TYPE
  },

  categoryType() {
    return 'parentRelationship'
  },

  create( path, parentPath, relationshipType, name ) { 
    if ( !isPathValid( path ) || isPathCollection( path ) || path.split( '/' ).length > 1 ) {
      throw Err.illegalArgument( `invalid path: ${path}` )
    }
    return { 
      type : this.typeName(), 
      path : path || 'relationship',
      parentPath : parentPath || path,
      isCollection : false,
      name : name || path,
      desc : null,
      tags : [],
      targetBlueprintId : null, // the model id
      relationshipType : relationshipType || 'oneToMany'
    }
  },

  createFrom( attr ) {
    if ( attr.type === this.typeName() ) {
      return ObjectUtil.copy( attr );
    }

    const convert = this.create( attr.path, attr.parentPath, attr.relationshipType, attr.name );
    convert.desc = attr.desc;
    convert.tags = ObjectUtil.copy( attr.tags );

    return convert;
  } 
}
