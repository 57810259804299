import React, { useCallback, useState, useMemo } from 'react'
import { ModelMapEditor } from '@leverege/ui-plugin'
import { Button, Dialog, Dialogs, Flex, Label, Selector } from '@leverege/ui-elements'
import languageList from 'language-list'

import I18NSystemModel from '../models/I18NSystemModel.js'
import I18NSystemLanguageModel from '../models/I18NSystemLanguageModel.js'

function AddLanguageDialog( props ) {
  const { show, onClose, languages = {}, onSubmit : pOnSubmit } = props
  const [ value, setValue ] = useState()
  const values = useMemo( () => {
    return languageList()
      .getData()
      .filter( lang => languages[lang.code] == null )
      .map( lang => ( { name : lang.language, value : lang.code } ) )
  }, [ languages ] )

  const onSubmit = useCallback( () => {
    pOnSubmit( value )
    onClose()
  }, [ pOnSubmit, onClose, value ] )

  return (
    <Dialog.Question
      okay="Add"
      title="Add Language"
      show={show}
      onOkay={onSubmit}
      onClose={onClose}
      onCancel={onClose}>
      <Flex variant="colM" align="stretch">
        <Label>Language</Label>
        <Selector
          showFilter
          value={value}
          onChange={( evt ) => { setValue( evt.value ) }}
          values={values} />
      </Flex>
    </Dialog.Question>
  )
}

export default function SystemTranslationEditor( props ) {
  const { value, eventData, onChange } = props

  const header = useCallback( () => {
    const onClick = () => {
      Dialogs.show( {
        component : AddLanguageDialog,
        props : {
          languages : I18NSystemModel
            .getLanguages( value ),
          onSubmit : ( lang ) => {
            const nValue = I18NSystemModel.setLanguage( value, lang, I18NSystemLanguageModel.create() )

            onChange( {
              value : nValue,
              data : eventData
            } )
          }
        }
      } )
    }

    return (
      <Button
        variant="tertiary"
        icon="fa fa-fw fa-plus"
        onClick={onClick}>Add
      </Button>
    )
  }, [ value, eventData, onChange ] )

  return (
    <ModelMapEditor
      header={header}
      eventData={eventData}
      value={value}
      model={I18NSystemModel.languages()}
      onChange={onChange} />
  )
}
