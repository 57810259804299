// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor_eikT6 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.propertyGridEditor_bEg1c {
  justify-content: normal;
}

.inline_WN3vp {
  flex: 1;
}

.popup_KG85J {
  flex: 0 0 auto;
}

.filterInput_Ma3zS {
  min-width: 89%; /* Leave room for scroll bar */
}`, "",{"version":3,"sources":["webpack://./src/editor/ModelTypeSelector.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc,EAAE,8BAA8B;AAChD","sourcesContent":[".editor {\n  display: inline-flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.propertyGridEditor {\n  justify-content: normal;\n}\n\n.inline {\n  flex: 1;\n}\n\n.popup {\n  flex: 0 0 auto;\n}\n\n.filterInput {\n  min-width: 89%; /* Leave room for scroll bar */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `editor_eikT6`,
	"propertyGridEditor": `propertyGridEditor_bEg1c`,
	"inline": `inline_WN3vp`,
	"popup": `popup_KG85J`,
	"filterInput": `filterInput_Ma3zS`
};
export default ___CSS_LOADER_EXPORT___;
