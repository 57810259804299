import { ns } from '@leverege/i18n'

const { t, tIcon } = ns( 'condition.LogicConditionEditor' )

export default {
  id : 'condition.logic.RemoveConditionAction', 
  matches : {
    client : 'condition.LogicEditor',
    use : 'conditionActionBar'
  },
  layout : {
    sort : 'zzz'
  },

  appearance( context ) {
    return { name : t( 'removeCondition' ), icon : tIcon( 'removeConditionIcon' ) }
  },

  handles( context ) { return context.onRemove != null },
  perform( { context } ) {
    context.onRemove( { data : context.eventData } )
  }
}
