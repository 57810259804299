import Conditions from '@leverege/condition'
import { Plugins } from '@leverege/plugin'

function toActions( conditionCreators, onSelect, eventData ) {
  return conditionCreators.map( ( c ) => {
    return {
      type : c.type,
      layout : c.layout || {},
      handles : () => true,
      appearance : ( ) => { return { name : c.name, icon : c.icon, help : c.help } },
      perform : ( evt ) => {
        onSelect( { value : c, data : eventData } )
      }
    }
  } ) 
}

function toCreators( conditions ) {
  return conditions.map( ( c ) => { 
    return {
      type : c.TYPE,
      name : c.name || c.type,
      icon : c.icon,
      help : c.help,
      layout : c.layout,
      create : ( ) => c.create() 
    }
  } )
}

/**
 * Looks up conditions that can be used with the objectType and returns
 * and array of creators for them.
 * @param {String} objectType The type of object to get creators for
 * @returns An array of creators
 */
function getCreatorsFor( objectType ) {
  // this is an object type. 
  const filter = Conditions.createFilterByObjectType( objectType )
  const name = `creators.${objectType}`
  
  return Plugins.createView( 'Condition', { name, filter, processor : toCreators } ).get()
}

export default {
  toActions,
  toCreators,
  getCreatorsFor
}
