const TRUE = true
const FALSE = false
const UNSET = null

function displayName( type ) {
  // i18n
  if ( type === TRUE ) return 'True'
  if ( type === FALSE ) return 'False'
  return 'Not Set'
}

function values() {
  return [ 
    { value : UNSET, name : displayName( UNSET ) },
    { value : TRUE, name : displayName( TRUE ) },
    { value : FALSE, name : displayName( FALSE ) }
  ]
}

export default {
  TRUE,
  FALSE,
  UNSET,

  values,
  displayName
}
