import { ValueCache } from '@leverege/value-cache'
import { Plugins, Layout, Context, Match } from '@leverege/plugin'

import Util from './Util.js'

let uuid = 1 // for unique key generation
const EMPTY_ARRAY = []

/**
 * Lays out action in a placement tree. This will also add a key to each action
 * that can be used as react key.
 * 
 */
export default class ActionsLayout {

  /**
   * This will cache and layout actions for use in toolbars and context menus
   * @param {object} options
   * @param {array} options.actions  the array of actions. If not present, matches will be used
   * @param {object} options.context the matches object used to filter the plugins
   * @param {stirng} options.actionType the plugin name to lookup. Defaults to 'Action'
   */
  constructor( options ) {
    this.options = options || {}
    this.actionType = this.options.actionType || 'Action'
    this.pluginCache = new ValueCache( { equals : Util.shallowEquals } )
    this.actionCache = new ValueCache()
    this.layoutCache = new ValueCache( { equals : Util.shallowEquals } )
    this.prepareMap = {}
  }

  /**
   * Invoke to update the available actions and the layout. This should be invoked
   * prior to handles being called
   */
  update( ) {
    const { actions, context, mustMatchContext } = this.options

    // No actions, use plugins to find them
    if ( actions == null && context ) {
      const plgs = Plugins.get( this.actionType )

      // create actions array with keys
      this.actions = this.pluginCache.calc( ( pv, cxt ) => {
        return plgs.filter( ( action ) => { 
          
          if ( !Context.isMatch( cxt, action ) ) {
            return false
          }
          if ( mustMatchContext ) {
            return action.matches ? Match.isMatch( action.matches, mustMatchContext ) : false
          }
          return true
        } ).map( copyAction )

      }, Plugins.getVersion( this.actionType ), context )
    } else {
      this.actions = this.actionCache.calc( ( actions ) => {
        if ( actions == null || !Array.isArray( actions ) ) {
          return EMPTY_ARRAY
        }
        return actions.map( copyAction )
      }, actions )
    }

    this.layout = this.layoutCache.calc( ( actions ) => {
      const root = Layout.create( this.actions )
      this.appendToRoot( root )
      return root
    }, this.actions )
  }

  /**
   * Returns true if at least one action handles the context
   */
  handles( cxt ) {
    this.update()
    return Util.handles( this.layout.items, cxt )
  }

  isPrepared( cxt ) {
    this.update()
    return Util.isPrepared( this.layout.items, cxt )
  }

  onPrepared( itemId, res, onChange ) {
    delete this.prepareMap[itemId]
    if ( onChange ) {
      onChange( itemId, res )
    }
  }

  isPreparing = ( itemId ) => {
    return !!this.prepareMap[itemId]
  }

  onPreparing = ( itemId, prom, onChange ) => {
    if ( !prom.then ) {
      return Promise.resolve( null )
    }
    this.prepareMap[itemId] = prom.then( res => this.onPrepared( itemId, res, onChange ) )
    return prom
  }

  prepare( cxt, onChange ) {
    if ( !this.isPrepared( cxt ) ) {
      return Util.prepare(
        this.layout.items,
        cxt,
        {
          isPreparing : this.isPreparing,
          onPreparing : ( itemId, prom ) => this.onPreparing( itemId, prom, onChange )
        }
      )
    }
    return null
  }

  /**
   * Returns the layout that containts the location in the 
   * tree where the actions should be placed
   */
  getRoot( ) {
    this.update()
    return this.layout
  }

  /**
   * Subclasses can manipulate the root node after its been laid out.
   */
  appendToRoot( root ) {
  }
}

/**
 * Adds a key to the action
 */
function copyAction( a ) {
  return { ...a, key : `a-${uuid++}` }
}
