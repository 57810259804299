import React from 'react'
import { Layer } from 'react-mapbox-gl'
import MapView from './MapView'
import Util from './Util'
import Interaction from './Interaction'

import S from './Map.css'

const LAYERS = [ 'normal', 'targeted', 'selected', 'rollover' ]

export default class Map extends React.Component {
  static propTypes = {}

  constructor( props ) {
    super( props )
    this.state = {}
    this.interaction = new Interaction()
    this.normal = <Layer key="normal" id="normal" />
    this.rollover = <Layer key="rollover" id="rollover" />
    this.selected = <Layer key="selected" id="selected" />
    this.targeted = <Layer key="targeted" id="targeted" />
    
  }

  onMapReady = ( map ) => {
    if ( this.map ) {
      this.map.off( 'click', this.onMapClick )
      this.map.off( 'mousemove', this.onMapRollover )    
      this.map.off( 'mouseup', this.onMouseUp )      
      this.map.off( 'mousedown', this.onMouseDown )     
      this.map.off( 'mouseout', this.onMouseOut )      
      this.map.off( 'mouseover', this.onMouseOver )      
      this.map.off( 'contextmenu', this.onContextMenu );      
      this.map.off( 'dragend', this.onDragEnd )
      this.map.off( 'drag', this.onDrag )
      this.map.off( 'dragstart', this.onDragStart )
      this.map.off( 'movestart', this.onMoveStart )
      this.map.off( 'moveend', this.onMoveEnd )
      this.map.off( 'zoomstart', this.onZoomStart )
      this.map.off( 'zoomend', this.onZoomEnd )
      this.map.off( 'keyup', this.onKeyUp )
      this.map.off( 'keydown', this.onKeyDown )
      
      this.map.off( 'draw.create', this.onDrawCreate )
      this.map.off( 'draw.delete', this.onDrawDelete )
      this.map.off( 'draw.update', this.onDrawUpdate )
      this.map.off( 'draw.selectionchange', this.onDrawSelectionChange )
      this.map.off( 'draw.modechange', this.onDrawModeChange )
      this.map.off( 'draw.actionable', this.onDrawActionable )
      this.map.off( 'draw.combine', this.onDrawCombine )
      this.map.off( 'draw.uncombine', this.onDrawUncombine )
      this.map.off( 'draw.render', this.onDrawRender )
      this.map.off( 'boxzoomend', this.onBoxZoomEnd )
      this.map.off( 'error', this.onError )


    }
    this.map = map
    if ( this.map ) {
      this.map.on( 'click', this.onMapClick )
      this.map.on( 'mousemove', this.onMapRollover )      
      this.map.on( 'mouseup', this.onMouseUp )      
      this.map.on( 'mousedown', this.onMouseDown )      
      this.map.on( 'mouseout', this.onMouseOut )      
      this.map.on( 'mouseover', this.onMouseOver )      
      this.map.on( 'contextmenu', this.onContextMenu )      
      this.map.on( 'dragend', this.onDragEnd )
      this.map.on( 'drag', this.onDrag )
      this.map.on( 'dragstart', this.onDragStart )
      this.map.on( 'movestart', this.onMoveStart )
      this.map.on( 'moveend', this.onMoveEnd )
      this.map.on( 'zoomstart', this.onZoomStart )
      this.map.on( 'zoomend', this.onZoomEnd )
      this.map.on( 'keyup', this.onKeyUp )
      this.map.on( 'keydown', this.onKeyDown )
      
      this.map.on( 'draw.create', this.onDrawCreate )
      this.map.on( 'draw.delete', this.onDrawDelete )
      this.map.on( 'draw.update', this.onDrawUpdate )
      this.map.on( 'draw.selectionchange', this.onDrawSelectionChange )
      this.map.on( 'draw.modechange', this.onDrawModeChange )
      this.map.on( 'draw.actionable', this.onDrawActionable )
      this.map.on( 'draw.combine', this.onDrawCombine )
      this.map.on( 'draw.uncombine', this.onDrawUncombine )
      this.map.on( 'draw.render', this.onDrawRender )
      this.map.on( 'boxzoomend', this.onBoxZoomEnd )
      this.map.on( 'error', this.onError )
    }


    const { onMapReady, dragRotate, touchZoomRotate } = this.props

    if ( typeof onMapReady === 'function' ) {
      onMapReady( map )
    }
    // Disable map rotation using right click + drag
    if ( dragRotate === false ) {
      map.dragRotate.disable()
    }
    // Disable map rotation using touch rotation gesture
    if ( touchZoomRotate === false ) {
      map.touchZoomRotate.disableRotation()
    }
  }

  onError = ( ex ) => {
    if ( ex && ex.error && ex.error.message === 'An image with this name already exists.' ) {
      return
    }
    console.error( ex )
  }

  onMapClick = ( event ) => {
    const { lngLat } = event
    const { mapName } = this.props
    const cEvt = new CustomEvent( 'lev-mapClick', { 
      detail : { 
        event, 
        mapName,
        position : { 
          lat : lngLat.lat,
          lon : lngLat.lng
        } 
      } 

    } )
    document.dispatchEvent( cEvt )
    if ( event.defaultPrevented === true ) {
      return
    }

    const { onClick, drawControl, pointer } = this.props
    if ( pointer ) {
      // need press, release, etc
      if ( pointer.onMouseClick ) {
        pointer.onMouseClick( event, { interaction : this.interaction, map : this.map } )
      }
      return
    }
    
    if ( onClick == null ) {
      return
    }
   
    // If a drawControl is present and it has selections, let it handle the mouse interactions.
    const dc = drawControl || this.interaction.getDrawControl()
    if ( dc && 
         ( dc.getSelectedIds().length > 0 ||
           dc.draw.getMode() === 'draw_polygon' ) ) {
      return
    }

    const evt = { event }
    const layers = Util.checkLayers( this.interaction.getSeletableIds(), this.map )
    const features = this.map.queryRenderedFeatures( event.point, { layers } )
    if ( features && features.length > 0 ) {
      // TODO Cycle through selections
      evt.features = features
      const f = features[0]
      const p = f.properties
      if ( p.dataId != null && p.dataType != null ) {
        evt.id = p.dataId
        evt.type = p.dataType
      }
    }
    onClick( evt )
  }

  onMapRollover = ( event ) => {
    // Note : this.map.loaded()/isSourceLoaded is a possibility here. It is true if there is
    // the layers should be done rendering. For now, we are going to mulitply renderer the
    // objects on the normal layer
    if ( this.moving || this.zooming ) {
      return
    }

    const { onRollover, pointer } = this.props
    if ( pointer ) {
      if ( pointer.onMouseMove ) {
        pointer.onMouseMove( event, { interaction : this.interaction, map : this.map } )
      }
      return
    }

    if ( onRollover == null ) {
      return
    }

    const evt = { event }
    const layers = Util.checkLayers( this.interaction.getRolloverIds(), this.map )
    const features = this.map.queryRenderedFeatures( event.point, { layers, validate : false } )
    if ( features && features.length > 0 ) {
      evt.features = features
      const f = Util.getSmallestArea( features )
      const p = f.properties
      if ( p.dataId != null ) {
        evt.id = p.dataId
      }
      if ( p.dataType != null ) {
        evt.type = p.dataType
      }
      if ( p.dataRef != null ) {
        evt.ref = p.dataRef
      }
    } 
    onRollover( evt )
  }

  forward( event, type ) {
    const { pointer } = this.props
    const f = this.props[type] // eslint-disable-line
    if ( typeof f === 'function' ) {
      f( event )
    }
    if ( pointer && pointer[type] ) {
      pointer[type]( event, { map : this.map, interaction : this.interaction } )
    }
  }

  onMoveStart = ( e ) => {
    this.moving = true

    this.forward( e, 'onMoveStart' )
  }

  onMoveEnd = ( e ) => {
    this.moving = false

    this.forward( e, 'onMoveEnd' )
  }

  onZoomStart = ( e ) => {
    this.zooming = true

    this.forward( e, 'onZoomStart' )
  }

  onZoomEnd = ( e ) => {
    this.zooming = false

    this.forward( e, 'onZoomEnd' )
  }
  

  /**
   * Handle Small drags as a click to avoid a threshold check in
   * DrawControl.
   **/
  onDragStart = ( event ) => {
    this.dragStart = { x : event.originalEvent.offsetX, y : event.originalEvent.offsetY }
  }

  onDragEnd = ( event ) => {
    const { onClick } = this.props
    const up = { x : event.originalEvent.offsetX, y : event.originalEvent.offsetY }
    if ( Math.abs( this.dragStart.x - up.x ) <= 4 && 
         Math.abs( this.dragStart.y - up.y ) <= 4 && 
         onClick ) {
      onClick( { event } )
    }


  }

  onDrag = ( event ) => { this.forward( event, 'onDrag' ) }

  onContextMenu = ( event ) => { this.forward( event, 'onContextMenu' ) }
  onMouseUp = ( event ) => { this.forward( event, 'ononMouseUp' ) }
  onMouseDown = ( event ) => { this.forward( event, 'onMouseDown' ) }
  onMouseOut = ( event ) => { this.forward( event, 'onMouseOut' ) }
  onMouseOver = ( event ) => { this.forward( event, 'onMouseOver' ) }
  
  onDrawModeChange = ( event ) => { this.forward( event, 'onDrawModeChange' ) }
  onDrawCreate = ( event ) => { this.forward( event, 'onDrawCreate' ) }
  onDrawUpdate = ( event ) => { this.forward( event, 'onDrawUpdate' ) }
  onDrawDelete = ( event ) => { this.forward( event, 'onDrawDelete' ) }
  onDrawSelectionChange = ( event ) => { this.forward( event, 'onDrawSelectionChange' ) }
  onDrawActionable = ( event ) => { this.forward( event, 'onDrawActionable' ) }
  onDrawCombine = ( event ) => { this.forward( event, 'onDrawCombine' ) }
  onDrawUncombine = ( event ) => { this.forward( event, 'onDrawUncombine' ) }
  onDrawRender = ( event ) => { this.forward( event, 'onDrawRender' ) }
  onBoxZoomEnd = ( event ) => { this.forward( event, 'onBoxZoomEnd' ) }

  onKeyDown = ( event ) => { this.forward( event, 'onKeyDown' ) }
  onKeyUp = ( event ) => { this.forward( event, 'onKeyUp' ) }

  render() {
    /* eslint-disable */
    const { layers = [], popups = [], ui, symbols, selectableLayerIds, pointer,
            rolloverableLayerIds, className, mapStyle, mapName = 'map', 
            initialPosition, onMapReady, onClick, onRollover, zoomRotateControl,
            onDrawModeChange, onDrawCreate, onDrawUpdate, onDrawDelete,
            onDrawSelectionChange, onDrawActionable, onDrawCombine, onDrawUncombine,
            onDrawRender, onBoxZoomEnd, onContextMenu, onMouseDown, onMouseUp,
            ...rest } = this.props; /* eslint-enable */

    // const orderLayers = [ <Layer key="symbol-images" type="symbol" id="symbol-images" images={symbols} /> ]
    const buildSelectableLayerIds = selectableLayerIds == null
    const buildRolloverableLayerIds = rolloverableLayerIds == null
    // const slIds = selectableLayerIds || []
    // const rlIds = rolloverableLayerIds || []

    // this.interaction.setRolloverId( rlIds, !buildRolloverableLayerIds )
    // this.interaction.setSelectableId( slIds, !buildSelectableLayerIds )
    
    // Combine normal/selected/etc layers
    const layerResults = flat( layers ).map( ( layer ) => {
      // If its a layer, just add it in order
      if ( layer == null ) {
        return null
      }
      if ( layer.type != null ) {
        addToInteraction( layer, buildSelectableLayerIds, buildRolloverableLayerIds, this.interaction )
        return { normal : React.cloneElement( layer, { interaction : this.interaction } ) }
      }
      addToInteraction( layer.normal, buildSelectableLayerIds, buildRolloverableLayerIds, this.interaction )
      addToInteraction( layer.targeted, buildSelectableLayerIds, buildRolloverableLayerIds, this.interaction )
      addToInteraction( layer.selected, buildSelectableLayerIds, buildRolloverableLayerIds, this.interaction )
      addToInteraction( layer.rollover, buildSelectableLayerIds, buildRolloverableLayerIds, this.interaction )

      return layer
    } )

    // Add the symbols as a layer so they are available
    const merged = [ 
      <Layer key="geoshapes" type="symbol" id="geoshapes" />,
      <Layer key="symbol-lower-enhancements" type="symbol" id="symbol-lower-enhancements" /> ]
    if ( symbols != null ) {
      merged.push( <Layer key="symbol-images" type="symbol" id="symbol-images" images={symbols} /> )
    }
    merged.push( this.normal )
    LAYERS.forEach( ( name ) => {
      layerResults.forEach( ( layer, index ) => {
        if ( layer && layer[name] ) {
          if ( Array.isArray( layer[name] ) ) {
            layer[name].forEach( ( l ) => {
              merged.push( l )
            } )
          } else {
            merged.push( layer[name] )
          }
        }
      } )
    } )
    merged.push( this.selected )
    merged.push( this.targeted )
    merged.push( this.rollover )
    
    
    return (
      <MapView
        className={className}
        mapStyle={mapStyle || 'mapbox://styles/mapbox/light-v9?optimize=true'}
        mapName={mapName}
        layers={merged}
        popups={popups}
        initialPosition={initialPosition}
        zoomRotateControl={zoomRotateControl}
        pointer={pointer}
        onMapReady={this.onMapReady}
        {...rest} />
    )
  }
}

// adapted from https://stackoverflow.com/a/39000004
function flat( arr, res = [] ) {
  if ( !Array.isArray( arr ) ) {
    res.push( arr )
    return res
  }
  for ( let i = 0; i < arr.length; i++ ) {
    flat( arr[i], res )
  }
  return res
}

function addToInteraction( layer, fillSelectable, fillRollover, interaction ) {
  // Handle array case
  if ( Array.isArray( layer ) ) {
    layer.forEach( ( l ) => { 
      addToInteraction( l, fillSelectable, fillRollover, interaction )
    } )
    return
  }

  if ( layer == null || layer.props == null ) {
    return
  }
  const { metadata, id } = layer.props
  if ( id && metadata ) {
    if ( fillSelectable && metadata.isSelectable ) {
      interaction.addSelectableId( id )
    } 
    if ( fillRollover && metadata.isRolloverable ) {
      interaction.addRolloverId( id )
    }
  }
}
