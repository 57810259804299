/**
 * Gets the order of magnitude of a number.
 * @param {Number} number - Numerical value.
 * @returns {Integer} orderOfMagnitude - The order of magnitude.
 */
function getOrderOfMagnitude( number ) {
  let numberCopy = number
  let orderOfMagnitude = 0
  if ( numberCopy === 0 ) {
    return orderOfMagnitude
  }
  if ( numberCopy > 1 ) {
    while ( numberCopy > 1 ) {
      numberCopy /= 10
      orderOfMagnitude += 1
    }
  } else {
    while ( numberCopy < 1 ) {
      numberCopy *= 10
      orderOfMagnitude -= 1
    }
  }
  return orderOfMagnitude
}

/* Export Modules */
export default {
  getOrderOfMagnitude
}
