// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_iJuDR {
  display : flex;
  flex-direction : row;
  align-items : stretch;
  justify-content : space-between
}

.title_JjZ2L {
  display : inline-flex;
  flex-direction : row;
  align-items : center;
  justify-content: left;
}

.actions_TGIgL {
  display : inline-flex;
  flex-direction : row;
  align-items : center;
  justify-content: right;
}`, "",{"version":3,"sources":["webpack://./src/shared/CardTitle.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,qBAAqB;EACrB;AACF;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".content {\n  display : flex;\n  flex-direction : row;\n  align-items : stretch;\n  justify-content : space-between\n}\n\n.title {\n  display : inline-flex;\n  flex-direction : row;\n  align-items : center;\n  justify-content: left;\n}\n\n.actions {\n  display : inline-flex;\n  flex-direction : row;\n  align-items : center;\n  justify-content: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_iJuDR`,
	"title": `title_JjZ2L`,
	"actions": `actions_TGIgL`
};
export default ___CSS_LOADER_EXPORT___;
